import React from 'react';
import ServicesStep from './ServicesStep';
import LanguagesStep from './LanguagesStep';
import ContactReasonStep from './ContactReasonStep';
import ContactSolutionStep from './ContactSolutionStep';
import CommunicatorStep from './CommunicatorStep';
import CustomerStep from './CustomerStep';
import SummaryStep from './SummaryStep';

export const WIZARD_STEPS = [
  {
    name: 'Products',
    component: obj => (
      <ServicesStep
        items={obj.props.initialData.services}
        handleNext={obj.handleNextStep}
        reset={obj.reset}
      />
    )
  },
  {
    name: 'Communicator',
    component: obj => (
      <CommunicatorStep
        items={obj.props.serviceRelatedData.communicators}
        handleNext={obj.handleNextStep}
        reset={obj.reset}
      />
    )
  },
  {
    name: 'Languages',
    component: obj => (
      <LanguagesStep
        items={obj.props.serviceRelatedData.languages}
        handleNext={obj.handleNextStep}
        reset={obj.reset}
      />
    )
  },
  {
    name: 'Customer',
    component: obj => <CustomerStep handleNext={obj.handleNextStep} reset={obj.reset} />
  },
  {
    name: 'Contact reason',
    component: obj => (
      <ContactReasonStep
        items={obj.props.serviceRelatedData.contactReasons}
        handleNext={obj.handleNextStep}
        reset={obj.reset}
      />
    )
  },
  {
    name: 'Contact solution',
    component: obj => (
      <ContactSolutionStep
        items={obj.props.serviceRelatedData.contactSolutions}
        handleNext={obj.handleNextStep}
        reset={obj.reset}
      />
    )
  },
  {
    name: 'Summary',
    component: obj => (
      <SummaryStep
        initialData={obj.props.initialData}
        serviceRelatedData={obj.props.serviceRelatedData}
        stepsData={obj.state.data}
        handleNext={obj.handleSubmit}
      />
    )
  }
];
