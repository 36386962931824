import React from 'react';

import Loading from '@common/components/Loading';
import { getFlag } from '../../icons/flags';
import { getIcon } from '../../icons/icons';
import { Link } from 'react-router-dom';
import { Table, TableColumn } from '@common/components/Table.jsx';

const tableOptions = {
  defaultSortName: 'name',
  defaultSortOrder: 'asc'
};

class ServicesListData extends React.Component {
  onServiceDelete(servicedataKey) {
    this.props.deleteService(servicedataKey);
  }

  render() {
    return (
      <div className="mt-3 mb-3">
        {this.props.fetchingData ? (
          <Loading loadingClass="throbber--middle" />
        ) : (
          <div className="table-striped table-sm">
            <Table
              data={this.props.fetchedData}
              options={tableOptions}
              tableClassName="mb-0 text-center"
            >
              <TableColumn
                dataKey="name"
                headerLabel="Product name"
                dataSort={true}
                dataFormat={this.renderNameFields.bind(this)}
              />
              <TableColumn
                dataKey="languages"
                headerLabel="Languages"
                dataFormat={this.renderLanguagesFields.bind(this)}
              />
              <TableColumn
                dataKey="contactCaseReasonsCount"
                headerLabel="Contact reasons"
                dataAlign="center"
                dataSort={true}
              />
              <TableColumn
                dataKey="contactCaseSolutionsCount"
                headerLabel="Contact solutions"
                dataAlign="center"
                dataSort={true}
              />
              <TableColumn
                dataKey="communicatorsCount"
                headerLabel="Comunicators"
                dataAlign="center"
                dataSort={true}
              />
              <TableColumn
                dataKey="usersCount"
                headerLabel="Users"
                dataAlign="center"
                dataSort={true}
              />
              <TableColumn
                dataKey="action"
                headerLabel="Action"
                width="150px"
                dataFormat={this.renderActionFields.bind(this)}
              />
            </Table>
          </div>
        )}
      </div>
    );
  }

  renderNameFields(cell, row) {
    return (
      <Link to={'/admin_gate/services/' + row.id}>
        <span className="td-link">{row.name}</span>
      </Link>
    );
  }

  renderLanguagesFields(cell, row) {
    const flags = row.languages.map(item => {
      return (
        <span key={item}>
          <i className="icon icon-flag-small mr-1">{getFlag(item)}</i>
        </span>
      );
    });
    return <div className="flags-wrapper">{flags}</div>;
  }

  renderActionFields(cell, row) {
    return (
      <div className="action-icons">
        <Link to={'/admin_gate/services/' + row.id}>
          <i className="icon icon--secondary icon-action cursor-pointer edit-icon">
            {getIcon('editIcon')}
          </i>
        </Link>
        <i
          onClick={() => this.onServiceDelete(row.id)}
          className="icon icon--secondary icon-hover--danger icon-action cursor-pointer"
        >
          {getIcon('deleteIcon')}
        </i>
      </div>
    );
  }
}

export default ServicesListData;
