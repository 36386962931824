import React, { useCallback, useMemo } from 'react';
import ReactDatetimeClass from 'react-datetime';
import { useField } from 'formik';

//fix for vite issue https://github.com/vitejs/vite/issues/2139
const Datetime = ReactDatetimeClass.default ?? ReactDatetimeClass;

const DateTimePickerComponent = ({ label, ...props }) => {
  const readOnly = props.readOnly !== null ? props.readOnly : true;
  const [field, meta, helpers] = useField(props);
  const hasError = meta.touched && meta.error;

  const inputProps = useMemo(
    () => ({
      className: 'form-control',
      readOnly,
      disabled: props.disabled,
      placeholder: props.placeholder
    }),
    [readOnly, props.disabled, props.placeholder]
  );

  const onChange = useCallback(date => {
    helpers.setValue(date);
  }, []);

  return (
    <div className={`form-group ${hasError ? 'has-error' : ''}`}>
      {label && <label htmlFor={field.name}>{label}</label>}
      <Datetime
        {...field}
        onChange={onChange}
        dateFormat={props.dateFormat}
        timeFormat={props.timeFormat}
        className={props.containerClass}
        inputProps={inputProps}
      />
      {hasError && <div className="form-group-error">{meta.error}</div>}
    </div>
  );
};

export default DateTimePickerComponent;
