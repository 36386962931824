import React, { Component } from 'react';
import { FieldArray, withFormik } from 'formik';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import { validateEmail } from '../../../common/helpers';
import ReadOnlyInput from '@common/components/ReadOnlyInput';
import Error from '@common/components/Error';
import { CheckboxField, InputField, SelectField } from '@common/form';

class UserListAddUserModal extends Component {
  render() {
    return (
      <Modal isOpen={true} toggle={this.props.onModalClose}>
        {this.isResponseStatus('success') ? (
          <div>
            <ModalHeader toggle={this.props.onModalClose}>
              User has been created successfully!
            </ModalHeader>
            <ModalBody>{this.renderStepUserCreated()}</ModalBody>
          </div>
        ) : (
          <div>
            <ModalHeader toggle={this.props.onModalClose}>Create new user</ModalHeader>
            <ModalBody>{this.renderStepCreateUser()}</ModalBody>
          </div>
        )}
      </Modal>
    );
  }

  isResponseStatus(status) {
    return this.props.userActionStatus === status;
  }
  renderStepCreateUser() {
    return (
      <div className="row justify-content-center">
        <div className="col-8">
          <form onSubmit={this.props.handleSubmit}>
            {this.isResponseStatus('error') ? <Error errors={this.props.userActionError} /> : null}
            <div className="form-group">
              <InputField
                type="text"
                name="email"
                placeholder="Type email address"
                label="Email address"
              />
            </div>
            <div className="row">
              <div className="col-12">
                <SelectField
                  label="User group"
                  name="user_group_id"
                  options={this.props.userGroups}
                  emptyOption="Select user group"
                />
              </div>
              <div className="col-12">
                <label>Products:</label>
                <FieldArray
                  name="service_ids"
                  render={arrayHelpers => (
                    <CheckboxField
                      data={this.props.services}
                      containerClass="d-inline-block col-6"
                      toggleAll={true}
                      {...arrayHelpers}
                    />
                  )}
                />
              </div>
            </div>
            <button
              type="submit"
              role="button"
              disabled={
                this.props.pristine ||
                this.props.isSubmitting ||
                this.isResponseStatus('processing')
              }
              className="btn btn-primary btn-modal"
            >
              {this.isResponseStatus('processing') ? 'Creating user...' : 'Create user'}
            </button>
          </form>
        </div>
      </div>
    );
  }

  renderStepUserCreated() {
    return (
      <div className="row justify-content-center">
        <div className="col-8">
          <div className="text-center">
            <div className="text-with-label mb-3">
              <p className="text-with-label__text">Password for user:</p>
              <p className="text-with-label__label">{this.props.userSelected.email}</p>
            </div>
            <ReadOnlyInput data={this.props.userSelected.password} />
            <button
              onClick={this.props.onModalClose}
              type="submit"
              role="button"
              className="btn btn-primary btn-modal"
            >
              Done
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const onValidate = values => {
  const errors = {};

  if (!validateEmail(values.email)) {
    errors.email = 'Invalid email address';
  }

  if (!values.user_group_id) {
    errors.user_group_id = 'User group is required';
  }

  return errors;
};

UserListAddUserModal = withFormik({
  mapPropsToValues: () => ({ email: '', user_group_id: '', service_ids: [] }),
  validate: onValidate,
  handleSubmit: (values, { props }) => {
    values.user_group_id = parseInt(values.user_group_id, 10);
    props.onCreateUser(values);
  },
  displayName: 'add_user'
})(UserListAddUserModal);

export default UserListAddUserModal;
