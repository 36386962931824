import axios from 'axios';
import * as TYPES from './types';
import { MOBILE_SUBSCRIPTIONS } from '../../mocks';
import { SUBSCRIPTIONS_CANCEL_MOBILE_URL, SUBSCRIPTIONS_MOBILE_FIND_URL } from '../../consts';
import { API_RESPONSE_VALIDATION_FAILED_CODE } from '../../../common/consts';
import { showErrorAlert, showSuccessAlert } from '../../../common/actions';
import { objectToQueryString } from '../../../common/helpers';
import { mock } from '../../../common/mocks';

// MOBILE SUBSCRIPTIONS - PRIVATE
function handleSubscriptionsFetchErrors(dispatch, errors) {
  const flatErrors = errors.map(item => {
    return item.code;
  });

  if (flatErrors.indexOf(903) !== -1) {
    showInfoAlert(dispatch, 'Cannot find subscriptions for given query parameters.');
  } else {
    showErrorAlert(dispatch, 'Server Error! Cannot load subscription list.');
  }
}

function fetchMobileSubscriptions(dispatch, requestData) {
  dispatch({ type: TYPES.MOBILE_SUBSCRIPTIONS.REQUEST });

  if (MOCK_DATA) {
    return mock(
      'FETCH_MOBILE_SUBSCRIPTIONS_MOCK_RESPONSE',
      function() {
        dispatch({ type: TYPES.MOBILE_SUBSCRIPTIONS.SUCCESS, payload: MOBILE_SUBSCRIPTIONS });
      },
      function() {
        showErrorAlert(dispatch, 'Server Error. Cannot load subscription list');
      }
    );
  }

  return axios
    .post(SUBSCRIPTIONS_MOBILE_FIND_URL, requestData)
    .then(response => {
      dispatch({ type: TYPES.MOBILE_SUBSCRIPTIONS.SUCCESS, payload: response.data });
    })
    .catch(error => {
      if (error.response.status === API_RESPONSE_VALIDATION_FAILED_CODE) {
        handleSubscriptionsFetchErrors(dispatch, error.response.data.errors);
      } else if (error.response.status === 500) {
        showErrorAlert(dispatch, 'Server Error! Cannot load subscription list.');
      }
    });
}

// MOBILE SUBSCRIPTIONS - PUBLIC
export function getMobileSubscriptions(requestData) {
  return function(dispatch) {
    return fetchMobileSubscriptions(dispatch, requestData);
  };
}

export function cancelSubscriptionMobile(cancelParams) {
  const queryString = objectToQueryString({ force_deactivation: true });
  const url = `${SUBSCRIPTIONS_CANCEL_MOBILE_URL}?${queryString}`;

  return function(dispatch) {
    if (MOCK_DATA) {
      return mock(
        'CANCEL_SUBSCRIPTION_MOBILE_MOCK_RESPONSE',
        function() {
          dispatch({
            type: TYPES.CANCEL_SUBSCRIPTION_MOBILE.SUCCESS,
            payload: cancelParams.payment_uid
          });
          showSuccessAlert(dispatch, 'Subscription has been cancelled successfully');
        },
        function() {
          showErrorAlert(dispatch, [{ code: 202, msg: 'Service id is invalid' }], { list: true });
        }
      );
    }

    return axios
      .post(url, cancelParams)
      .then(response => {
        dispatch({ type: TYPES.CANCEL_SUBSCRIPTION_MOBILE.SUCCESS, payload: response.data });
        showSuccessAlert(dispatch, 'Subscription has been cancelled successfully');
      })
      .catch(error => {
        if (error.response.status == API_RESPONSE_VALIDATION_FAILED_CODE) {
          showErrorAlert(dispatch, error.response.data.errors, { list: true });
        } else {
          showErrorAlert(dispatch, error.response.data.error);
        }
        throw error;
      });
  };
}
