import React, { Component } from 'react';
import { Card, CardBody } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';

import { changeOrder } from '../helpers';
import { debounce, displayTimestamp, getTimestamp } from '../../../common/helpers';
import { changePage } from '../../../common/actions';
import Loading from './../../../common/components/Loading';
import TablePagination from './../../../common/components/TablePagination';
import {
  clearResolveAlertError,
  exportNethoneAlertCSV,
  getNethoneAlerts,
  resolveNethoneAlert
} from '../actions';
import {
  DEFAULT_SORT_KEY,
  DEFAULT_SORT_ORDER,
  EDITABLE_ALERT_STATUS,
  NETHONE_ALERT_MODAL,
  NETHONE_ALERTS_PER_PAGE, RESULT_STATUS_MAP
} from '../consts';

import { deleteModal, showModal } from '../../../modal/actions/ModalActions';
import AlertResponseModal from './AlertResponseModal';
import List from './List';
import Filters from './Filters';
import Modal from '../../../modal/components/Modal';
import { getIcon } from '../../../icons/icons';

class NethoneAlertsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: '',
      date_since: null,
      date_until: null,
      limit: NETHONE_ALERTS_PER_PAGE,
      searchQuery: '',
      sort: {
        order: DEFAULT_SORT_ORDER,
        key: DEFAULT_SORT_KEY
      },
      currentPage: 1,
      offset: null,
      modalInitialValues: null,
      modalAdditionalValues: null,
      searchError: false
    };

    this.debounceSearch = debounce(this.onSearch, 300);
  }

  componentDidMount() {
    this.props.changePage('NethoneAlerts');
    if (!this.props.nethoneAlerts.length) {
      this.props.getNethoneAlerts(this.getParams());
    }
  }

  getParams = () => {
    const params = {
      limit: NETHONE_ALERTS_PER_PAGE,
      sort: `${this.state.sort.order}${this.state.sort.key}`
    };
    if (this.state.status && this.state.status.length) {
      params.status = this.state.status;
    }
    if (this.state.offset) {
      params.offset = this.state.offset;
    }
    if (this.state.date_since) {
      params.date_since = this.state.date_since;
    }
    if (this.state.date_until) {
      params.date_until = this.state.date_until;
    }
    if (this.state.searchQuery.length) {
      params.query = this.state.searchQuery;
    }
    return { params };
  };

  handleSort = key => {
    this.refreshData({
      sort: changeOrder(key, this.state.sort.order)
    });
  };

  onSubmit = (id, data) => {
    const params = {
      result: data.result,
      ...(data.amount && {amount: data.amount}),
      ...(data.currency && {currency: data.currency}),
      ...(data.timestamp && {timestamp: getTimestamp(data.timestamp)})
    };

    this.props.resolveNethoneAlert(id, params, () => {
      this.props.deleteModal(NETHONE_ALERT_MODAL);
      this.refreshData();
    });
  };

  handleStatusSelect = value => {
    this.refreshData({status: value});
  };

  onSearch = value => {
    if (value.length && value.length < 3) {
      this.setState({searchError: true});
      return;
    } else if (this.state.searchError) {
      this.setState({searchError: false});
    }
    this.refreshData({searchQuery: value});
  };

  refreshData = (newState = {}) => {
    this.setState(
      {
        offset: null,
        currentPage: 1,
        ...newState
      },
      () => {
        this.props.getNethoneAlerts(this.getParams());
      }
    );
  };

  handleDatePicker = (date, type) => {
    const isDateValid = moment(date, 'DD-MM-YYYY', true).isValid();
    if (date.length === 0) {
      this.refreshData({[type]: null});
    } else if (isDateValid) {
      this.refreshData({[type]: date.unix()});
    }
  };

  getOffset(page) {
    return page > 1 ? Math.ceil((page - 1) * NETHONE_ALERTS_PER_PAGE) : null;
  }

  handlePaginationClick = page => {
    const offset = this.getOffset(page);
    this.refreshData({
      offset: offset,
      currentPage: page
    });
  };

  handleSearch = value => {
    this.setState({searchQuery: value}, () => {
      this.debounceSearch(this.state.searchQuery);
    });
  }

  handleExportClick = () => {
    const {limit, ...params} = this.getParams().params;
    this.props.exportNethoneAlertCSV(params);
  }

  openModal = data => {
    const resolutionRefundValue = data.resolution && data.resolution.refund_value;
    const readOnly = data.status !== EDITABLE_ALERT_STATUS;

    const result = readOnly ?
      data.resolution && data.resolution.result :
      RESULT_STATUS_MAP.fullRefund;

    const amount = readOnly ?
      resolutionRefundValue && data.resolution.refund_value.amount_decimal :
      data.refund_value.amount_decimal;

    const currency = readOnly ?
      resolutionRefundValue && data.resolution.refund_value.currency :
      data.refund_value.currency;

    const timestamp = readOnly ?
      (data.resolution && data.resolution.refund_timestamp &&
      displayTimestamp(data.resolution.refund_timestamp, 'DD-MM-YYYY HH:mm')) :
      displayTimestamp(new Date(), 'DD-MM-YYYY HH:mm');

    this.setState(
      {
        modalInitialValues: { result, amount, currency, timestamp },
        modalAdditionalValues: {
          error: data.error,
          readOnly,
          id: data.id
        }
      },
      () => {
        this.props.clearResolveAlertError();
        this.props.showModal(NETHONE_ALERT_MODAL);
      }
    );
  };

  renderNoDataMessage() {
    return (
      <Card>
        <CardBody>
          <span className="ui-heading ui-heading-secondary">Alerts list is empty.</span>
        </CardBody>
      </Card>
    );
  }

  render() {
    const totalPages = Math.ceil(this.props.totalNethoneAlerts / NETHONE_ALERTS_PER_PAGE);
    return (
      <div className="alerts-container refunds container">
        <div className="row">
          <div className="col-12 mt-3">
            <div className="d-flex justify-content-between align-items-center">
              <Filters
                searchQuery={this.state.searchQuery}
                handleSearch={this.handleSearch}
                searchError={this.state.searchError}
                status={this.state.status}
                handleStatusSelect={this.handleStatusSelect}
                dateSince={this.state.date_since}
                dateUntil={this.state.date_until}
                handleDatePicker={this.handleDatePicker}
              />
              <div className="btn btn-secondary ml-3" onClick={this.handleExportClick}>
                <span className="d-inline-block align-middle mr-1">{getIcon('exportIcon')}</span>
                Export CSV
              </div>
            </div>
            {this.props.fetchingData ? (
              <Loading loadingClass="throbber--middle throbber--relative" />
            ) : (
              <>
                {this.props.nethoneAlerts.length ? (
                  <List
                    data={this.props.nethoneAlerts}
                    sortOrder={this.state.sort.order}
                    handleSort={this.handleSort}
                    onAlertResponseClick={this.openModal}
                  />
                ) : this.renderNoDataMessage()}
                {totalPages > 1 ? (
                  <TablePagination
                    currentPage={this.state.currentPage}
                    totalPages={totalPages}
                    onPageChange={this.handlePaginationClick}
                  />
                ) : null}
              </>
            )}
          </div>
        </div>
        <Modal id={NETHONE_ALERT_MODAL}>
          <AlertResponseModal
            onSubmit={this.onSubmit}
            initialValues={this.state.modalInitialValues}
            additionalValues={this.state.modalAdditionalValues}
            closeModal={() => this.props.deleteModal(NETHONE_ALERT_MODAL)}
            isSubmitting={this.props.resolving}
            errors={this.props.resolveAlertError}
          />
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    nethoneAlerts: state.nethoneAlerts.nethoneAlerts,
    totalNethoneAlerts: state.nethoneAlerts.totalNethoneAlerts,
    fetchingData: state.nethoneAlerts.fetchingData,
    resolving: state.nethoneAlerts.resolve.loading,
    resolveAlertError: state.nethoneAlerts.resolve.error,
  };
}

export default connect(mapStateToProps, {
  getNethoneAlerts,
  resolveNethoneAlert,
  clearResolveAlertError,
  changePage,
  showModal,
  deleteModal,
  exportNethoneAlertCSV
})(NethoneAlertsContainer);
