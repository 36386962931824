import {
  CLEAR_ALERT_RESOLVE_ERROR,
  NETHONE_ALERT_RESOLVE,
  NETHONE_ALERTS_FETCH
} from './types';

const initialState = {
  fetchingData: false,
  nethoneAlerts: [],
  totalNethoneAlerts: 0,
  resolve: {
    loading: false,
    error: false
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case NETHONE_ALERTS_FETCH.SUCCESS:
      return {
        ...state,
        nethoneAlerts: action.payload.transaction_alerts,
        totalNethoneAlerts: action.payload.total,
        fetchingData: false
      };

    case NETHONE_ALERTS_FETCH.ERROR:
      return {
        ...state,
        nethoneAlerts: [],
        totalNethoneAlerts: 0,
        fetchingData: false
      };

    case NETHONE_ALERTS_FETCH.PENDING:
      return {
        ...state,
        nethoneAlerts: [],
        totalNethoneAlerts: 0,
        fetchingData: true
      };

    case NETHONE_ALERT_RESOLVE.PENDING:
      return {
        ...state,
        resolve: {
          loading: true,
          error: false
        }
      };

    case NETHONE_ALERT_RESOLVE.SUCCESS:
      return {
        ...state,
        resolve: {
          loading: false,
          error: false
        }
      };

    case NETHONE_ALERT_RESOLVE.ERROR:
      return {
        ...state,
        resolve: {
          loading: false,
          error: action.payload
        }
      };

    case CLEAR_ALERT_RESOLVE_ERROR:
      return {
        ...state,
        resolve: {
          loading: false,
          error: null
        }
      };
  }
  return state;
}
