import React, { useCallback, useEffect, useState } from 'react';

import { sortAsc, sortDesc } from '../helpers';

const SORT_ORDER_ASCENDING = 'asc';
const SORT_ORDER_DESCENDING = 'desc';

// interface ITableProps {
//   data: any[];
//   options?: {
//     defaultSortName?: string;
//     defaultSortOrder?: SORT_ORDER_ASCENDING | SORT_ORDER_DESCENDING
//   };
//   onSort?: (sortKey: string, sortOrder: SORT_ORDER_ASCENDING | SORT_ORDER_DESCENDING) => void;
//   children: React.ReactNode;
// }

// interface ITableColumnProps {
//   dataKey: string;
//   headerLabel: string;
//   dataFormat?: (value, row) => any;
//   dataSort?: boolean;
//   hidden?: boolean;
//   width?: string;
//   cellCustomClass?: string;
// }

const TableColumn = () => <div></div>;

const SortIcon = ({ order }) => (
  <span className={'order' + (order === SORT_ORDER_DESCENDING ? ' dropup' : '')}>
    <span className="caret" />
  </span>
);

const Cell = ({ child, row }) => {
  if (!child || child.props.hidden) return null;
  if (!React.isValidElement(child)) {
    return new Error('Please use TableColumn component inside Table component');
  }
  const value = row[child.props.dataKey];
  return (
    <td style={{ width: child.props.width }} className={child.props.cellCustomClass}>
      {child.props.dataFormat && typeof child.props.dataFormat === 'function'
        ? child.props.dataFormat(value, row)
        : value}
    </td>
  );
};

function Table(props) {
  const [data, setData] = useState(props.data);
  const [sortKey, setSortKey] = useState(props.options && props.options.defaultSortName);
  const [sortOrder, setSortOrder] = useState(
    (props.options && props.options.defaultSortOrder) || SORT_ORDER_DESCENDING
  );

  const sortData = useCallback((data, key, order) => {
    if (key && data) {
      const sortable = data.sort((aItem, bItem) => {
        const a = aItem[key];
        const b = bItem[key];
        return order === SORT_ORDER_DESCENDING ? sortDesc(a, b) : sortAsc(a, b);
      });
      setData(sortable);
    }
  }, []);

  useEffect(() => {
    if (!props.onSort) {
      sortData(props.data, sortKey, sortOrder);
    }
  }, [sortData, props.data]);

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  const handleSort = dataKey => {
    let newSortOrder = sortOrder;
    if (dataKey === sortKey) {
      newSortOrder =
        sortOrder === SORT_ORDER_DESCENDING ? SORT_ORDER_ASCENDING : SORT_ORDER_DESCENDING;
      setSortOrder(newSortOrder);
    } else {
      setSortKey(dataKey);
    }
    if (props.onSort) {
      props.onSort(dataKey, newSortOrder);
    } else {
      sortData(props.data, dataKey, newSortOrder);
    }
  };

  if (!props.children) return null;
  return (
    <table className={`table table-hover ${props.tableClassName}`}>
      <thead className="thead-default">
        <tr>
          {React.Children.map(props.children, child => {
            if (!React.isValidElement(child)) {
              return null;
            }

            if (child.props && !child.props.hidden) {
              return (
                <th
                  style={{ width: child.props.width }}
                  className={`${child.props.cellCustomClass} ${
                    child.props.dataSort ? 'cursor-pointer' : ''
                  }`}
                  onClick={() => (child.props.dataSort ? handleSort(child.props.dataKey) : {})}
                >
                  {child.props.headerLabel}
                  {sortKey && child.props.dataKey === sortKey && <SortIcon order={sortOrder} />}
                </th>
              );
            }
          })}
        </tr>
      </thead>
      <tbody>
        {data && data.length ? (
          data.map(row => (
            <tr key={row.id}>
              {React.Children.map(props.children, child => (
                <Cell child={child} row={row} />
              ))}
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={props.children.length} className="text-center">
              There is no data to display
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}

export { Table, TableColumn };
