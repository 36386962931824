import classNames from 'classnames';

import React, { Component } from 'react';

export default class LinkedItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: null
    };
  }

  componentDidMount() {
    if (this.props.options) {
      if (this.props.options.defaultSelected) {
        this.selectDefaultItem();
      }
    }
  }

  handleItemClick(item) {
    this.setState({ selected: item.id }, () => this.props.onClick(item));
  }

  selectDefaultItem() {
    if (this.props.items.length) {
      this.handleItemClick(this.props.items[0]);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.reset && prevProps.reset !== this.props.reset) {
      if (this.props.options && this.props.options.defaultSelected) {
        this.selectDefaultItem();
      } else {
        this.setState({ selected: null });
      }
    }
  }

  render() {
    const { itemsPerRow = 1 } = this.props.options || {};
    const listClass = itemsPerRow !== 1 ? 'flex-wrap' : 'flex-column';
    const childClass =
      itemsPerRow !== 1 ? `per-row--${itemsPerRow} mx-15` : null;

    return (
      <ul className={'text-center d-flex ' + listClass}>
        {this.props.items.map(item => {
          const classes = {
            'linked-item mb-15 cursor-pointer': true,
            active: item.id === this.state.selected
          };
          return (
            <li
              key={item.id}
              className={classNames(classes, childClass)}
              onClick={() => this.handleItemClick(item)}
            >
              {item.name}
            </li>
          );
        })}
      </ul>
    );
  }
}
