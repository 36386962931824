import React from 'react';
import { connect } from 'react-redux';
import { deleteNotification } from '../actions/NotificationsActions';

const sort = (a, b) => {
  return b.id - a.id;
};

const renderMessagesList = messages => {
  return (
    <ul className="list-unstyled">
      {messages.map((message, index) => {
        return <li key={index}>{message.msg}</li>;
      })}
    </ul>
  );
};

const Notifications = props => {
  const notifications = props.notifications.sort(sort);
  return (
    <ul className="notifications-container">
      {notifications.map(notification => {
        return (
          <li
            key={notification.id}
            className={`notification-message notification-message-${notification.type}`}
          >
            {Array.isArray(notification.message) ? (
              renderMessagesList(notification.message)
            ) : (
              <p>{notification.message}</p>
            )}
            <button
              type="button"
              className="close"
              onClick={() => props.deleteNotification(notification.id)}
            >
              <span>x</span>
            </button>
          </li>
        );
      })}
    </ul>
  );
};

const mapStateToProps = ({ notifications }) => {
  return {
    notifications: notifications
  };
};

const mapDispatchToProps = {
  deleteNotification
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
