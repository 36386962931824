const NETHONE_ALERT_RESOLVE_MSG = 'nethone alert resolve';
const NETHONE_ALERTS_FETCH_MSG = 'nethone alerts fetch';

export const NETHONE_ALERTS_FETCH = {
  SUCCESS: `${NETHONE_ALERTS_FETCH_MSG} SUCCESS`,
  ERROR: `${NETHONE_ALERTS_FETCH_MSG} ERROR`,
  PENDING: `${NETHONE_ALERTS_FETCH_MSG} PENDING`
};

export const NETHONE_ALERT_RESOLVE = {
  SUCCESS: `${NETHONE_ALERT_RESOLVE_MSG} SUCCESS`,
  ERROR: `${NETHONE_ALERT_RESOLVE_MSG} ERROR`,
  PENDING: `${NETHONE_ALERT_RESOLVE_MSG} PENDING`
};

export const CLEAR_ALERT_RESOLVE_ERROR = 'clear nethone alert resolve error';
