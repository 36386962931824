import React, { Component } from 'react';

export default class ProductDetailsStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.item.name
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleNext = this.handleNext.bind(this);
  }

  handleNext() {
    if (!this.state.name.length) {
      return;
    }
    this.props.handleNext(this.state);
  }

  handleInputChange(event) {
    this.setState({
      name: event.target.value
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.reset && prevProps.reset !== this.props.reset) {
      this.setState({ name: '' });
    }
  }

  render() {
    return (
      <div className="col-6 mx-auto">
        <div className={`form-group mb-0 ${this.state.emailError ? 'has-error' : ''}`}>
          <label className="col-xs-2 control-label">Product name</label>
          <div className="col-xs-3">
            <input
              name="product_name"
              type="text"
              className="form-control input-sm"
              onChange={this.handleInputChange}
              value={this.state.name}
            />
          </div>
        </div>
        <div className={'text-center'}>
          <button
            type="button"
            className={`btn btn-primary mt-6 ${!this.state.name.length ? 'disabled' : ''}`}
            onClick={() => this.handleNext()}
          >
            Next
          </button>
        </div>
      </div>
    );
  }
}
