import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Switch } from 'react-router-dom';

import { logoutUser } from './users/actions';
import { USER_EMAIL_KEY } from './users/consts';
import {
  action,
  hasAccountManagementPermissionTo,
  hasContactCasesPermissionTo,
  hasRefundsPermissionTo,
  hasServicesPermissionTo,
  hasSubscriptionsPermissionTo,
  hasAlertsPermissionTo
} from './users/permissions';
import Header from './Header';
import AuthorizedRoute from '@common/components/AuthorizedRoute';
import RefundsContainer from './refunds/list/RefundsContainer';
import MobileSubscriptionsContainer from './subscriptions/mobile/MobileSubscriptionsContainer';
import AddContactCaseWizardContainer from './contact_cases/add/AddContactCaseWizardContainer';
import ContactCasesContainer from './contact_cases/list/ContactCasesContainer';
import UserListContainer from './users/list/UserListContainer';
import ServicesListContainer from './services/list/ServicesListContainer';
import ContactCaseReasons from './services/productItems/ContactCaseReasons';
import ContactCaseSolutions from './services/productItems/ContactCaseSolutions';
import Communicators from './services/productItems/Communicators';
import AddServiceContainer from './services/add/AddServiceContainer';
import EditServiceContainer from './services/edit/EditServiceContainer';
import Notifications from './notifications/components/Notifications.jsx';
import CardSubscriptions from './subscriptions/cards/CardSubscriptions';
import NethoneAlertsContainer from './alerts/nethone/components/NethoneAlertsContainer';

const App = ({ logoutUser }) => (
  <div id="page-wrapper" className="open">
    <div id="content-wrapper">
      <Header username={localStorage.getItem(USER_EMAIL_KEY)} onLogoutClick={() => logoutUser()} />
      <Switch>
        <AuthorizedRoute
          path="/alerts/nethone"
          component={NethoneAlertsContainer}
          hasPermission={hasAlertsPermissionTo(action.list)}
        />

        <AuthorizedRoute
          path="/subscription/find/paycard"
          component={CardSubscriptions}
          hasPermission={hasSubscriptionsPermissionTo('showPayCard')} />

        <AuthorizedRoute
          path="/subscription/find/refunds"
          component={RefundsContainer}
          hasPermission={hasRefundsPermissionTo(action.list)}
        />

        <AuthorizedRoute
          path="/subscription/find/mobile"
          component={MobileSubscriptionsContainer}
          hasPermission={hasSubscriptionsPermissionTo('showMobile')}
        />

        <AuthorizedRoute
          path="/contact_cases/add"
          component={AddContactCaseWizardContainer}
          hasPermission={hasContactCasesPermissionTo(action.add)}
        />

        <AuthorizedRoute
          path="/contact_cases/list"
          component={ContactCasesContainer}
          hasPermission={hasContactCasesPermissionTo(action.list)}
        />

        <AuthorizedRoute
          path="/admin_gate/users/list"
          component={UserListContainer}
          hasPermission={hasAccountManagementPermissionTo(action.list)}
        />

        <AuthorizedRoute
          path="/admin_gate/services/list"
          component={ServicesListContainer}
          hasPermission={hasServicesPermissionTo(action.list)}
        />

        <AuthorizedRoute
          path="/admin_gate/services/reasons"
          component={ContactCaseReasons}
          hasPermission={hasServicesPermissionTo(action.list)}
        />

        <AuthorizedRoute
          path="/admin_gate/services/solutions"
          component={ContactCaseSolutions}
          hasPermission={hasServicesPermissionTo(action.list)}
        />

        <AuthorizedRoute
          path="/admin_gate/services/communicators"
          component={Communicators}
          hasPermission={hasServicesPermissionTo(action.list)}
        />

        <AuthorizedRoute
          path="/admin_gate/services/add"
          component={AddServiceContainer}
          hasPermission={hasServicesPermissionTo(action.list)}
        />

        <AuthorizedRoute
          path="/admin_gate/services/:id"
          component={EditServiceContainer}
          hasPermission={hasServicesPermissionTo(action.list)}
        />

        <Redirect from="/" to="/subscription/find/paycard" />
      </Switch>
      <Notifications />
    </div>
  </div>
);

export default connect(null, { logoutUser })(App);
