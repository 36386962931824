import React, { Component } from 'react';
import SmilingFaceImage from '@images/smiling-face.svg';
import SadFaceImage from '@images/sad-face.svg';

import BoxItems from '@common/components/BoxItems';
import { validateEmail } from '@common/helpers';

const CASE_SOLVED = 1;
const CASE_UNSOLVED = 0;
const SELECT_ITEM_OPTIONS = [
  { id: CASE_SOLVED, name: 'Case solved', image: SmilingFaceImage },
  { id: CASE_UNSOLVED, name: 'Case unsolved', image: SadFaceImage }
];

export default class CustomerStep extends Component {
  constructor(props) {
    super(props);
    let emailInput;
    this.state = {
      hasEmail: true,
      emailError: true,
      customerEmail: ''
    };

    this.handleCheckboxClick = this.handleCheckboxClick.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleItemSelect = this.handleItemSelect.bind(this);
    this.handleNext = this.handleNext.bind(this);
  }

  handleNext() {
    const emailError = this.state.hasEmail && this.state.emailError;
    const satisfactionError = this.state.caseSolved === undefined;

    if (emailError || satisfactionError) {
      this.setState({ emailError, satisfactionError });
    } else {
      const wizardData = { caseSolved: this.state.caseSolved };
      if (this.state.hasEmail) {
        wizardData.customerEmail = this.state.customerEmail;
      }
      this.props.handleNext(wizardData);
    }
  }

  handleItemSelect(itemData) {
    this.setState({ caseSolved: itemData.id === CASE_SOLVED });
    this.focusEmailInput();
  }

  handleCheckboxClick() {
    this.setState({ hasEmail: !this.state.hasEmail, customerEmail: '' }, () => {
      if (this.state.hasEmail) {
        this.focusEmailInput();
      }
    });
  }

  handleInputChange(event) {
    this.setState({
      customerEmail: event.target.value,
      emailError: !validateEmail(event.target.value)
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.reset && prevProps.reset !== this.props.reset) {
      this.setState({ hasEmail: true, customerEmail: '' });
    }
  }

  focusEmailInput() {
    if (this.emailInput) {
      this.emailInput.focus();
    }
  }

  render() {
    const buttonDisabled =
      this.state.caseSolved === undefined ||
      (this.state.hasEmail && !this.state.customerEmail) ||
      (this.state.hasEmail && this.state.emailError);
    return (
      <div className="col-8 mx-auto">
        <BoxItems
          items={SELECT_ITEM_OPTIONS}
          customClasses={'col-5 customer-step'}
          onClick={this.handleItemSelect}
          options={{ defaultSelected: true }}
          reset={this.props.reset}
        />

        <div className={`form-group ${this.state.emailError ? 'has-error' : ''}`}>
          <label className="col-xs-2 control-label"> Customer email </label>
          <div className="col-xs-3">
            {this.state.hasEmail ? (
              <input
                name="customer_email"
                ref={input => (this.emailInput = input)}
                type="text"
                className="form-control input-sm"
                onChange={this.handleInputChange}
                value={this.state.customerEmail}
              />
            ) : null}
            <span className="mt-2 d-block">
              <label htmlFor="no-email" className="cursor-pointer">
                <input
                  type="checkbox"
                  id="no-email"
                  className="mr-15"
                  onChange={this.handleCheckboxClick}
                  checked={!this.state.hasEmail}
                />
                I don't have email
              </label>
            </span>
          </div>
        </div>
        <div className={'text-center'}>
          <button
            type="button"
            className={`btn btn-primary mt-6 ${buttonDisabled ? 'disabled' : ''}`}
            onClick={() => this.handleNext()}
          >
            Next
          </button>
        </div>
      </div>
    );
  }
}
