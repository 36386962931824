import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import ReadOnlyInput from '@common/components/ReadOnlyInput';
import Error from '@common/components/Error';

class UserListResetPasswordModal extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Modal isOpen={true} toggle={this.props.onModalClose}>
        {this.isResponseStatus('success') ? (
          <div>
            <ModalHeader toggle={this.props.onModalClose}>
              Password has been reset successfully!
            </ModalHeader>
            <ModalBody>{this.renderStepPasswordReset()}</ModalBody>
          </div>
        ) : (
          <div>
            <ModalHeader toggle={this.props.onModalClose}>Reset password</ModalHeader>
            <ModalBody>{this.renderStep()}</ModalBody>
          </div>
        )}
      </Modal>
    );
  }

  isResponseStatus(status) {
    return this.props.userActionStatus === status;
  }

  renderStep() {
    return (
      <div>
        <div className="text-with-label text-center mb-6 mt-2">
          {this.isResponseStatus('error') ? <Error errors={this.props.userActionError} /> : null}
          <p className="text-with-label__text">Are you sure you want reset password for user:</p>
          <div className="text-with-label__label">{this.props.itemData.email}</div>
        </div>
        <div className="row justify-content-center">
          <div className="col-10">
            <div className="row">
              <div className="col-6">
                <button
                  type="button"
                  role="button"
                  onClick={this.props.onModalClose}
                  className="btn btn-primary btn-block"
                >
                  Cancel
                </button>
              </div>
              <div className="col-6">
                <button
                  type="button"
                  role="button"
                  disabled={this.isResponseStatus('processing')}
                  onClick={() => this.props.onResetUserPassword(this.props.itemData)}
                  className="btn btn-danger btn-block"
                >
                  {this.isResponseStatus('processing') ? 'Resetting password...' : 'Reset password'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderStepPasswordReset() {
    return (
      <div className="row justify-content-center">
        <div className="col-8">
          <div className="text-center">
            <div className="text-with-label mb-3">
              <p className="text-with-label__text">New password for user:</p>
              <p className="text-with-label__label">{this.props.userSelected.email}</p>
            </div>
            <ReadOnlyInput data={this.props.userSelected.password} />
            <button
              onClick={this.props.onModalClose}
              type="submit"
              role="button"
              className="btn btn-primary btn-modal"
            >
              Done
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default UserListResetPasswordModal;
