import * as TYPES from './types';
import {
  RESET_MULTIPLE_SUBSCRIPTIONS,
  CANCEL_MULTIPLE_SUBSCRIPTIONS,
  REFUND_MULTIPLE_SUBSCRIPTIONS
} from './cards/store/types';
import { getAllChargesToRefund } from './cards/store/cardReducer';

export function notificationsReducer(state = null, {type, payload, store}) {
  switch (type) {
    case TYPES.NOTIFICATION_RESET:
    case RESET_MULTIPLE_SUBSCRIPTIONS:
      return null;

    case TYPES.NOTIFICATION_SENDING:
      return {...state, status: 'processing'};

    case TYPES.NOTIFICATION_SEND_SUCCESS:
      return { ...state, status: 'success' };

    case TYPES.NOTIFICATION_SEND_ERROR:
      return { ...state, status: 'error' };

    case CANCEL_MULTIPLE_SUBSCRIPTIONS.REQUEST:
    case REFUND_MULTIPLE_SUBSCRIPTIONS.REQUEST:
      return { completed: 0, failed: 0, status: 'processing'};

    case CANCEL_MULTIPLE_SUBSCRIPTIONS.SUCCESS: {
      const completed = state.completed + 1;
      const status =
        completed + state.failed === store.subscriptions.cards.allIds.length ?
          'success' :
          'processing';

      return { ...state, completed, status};
    }

    case CANCEL_MULTIPLE_SUBSCRIPTIONS.ERROR: {
      const failed = state.failed + 1;
      const status =
        failed + state.completed === store.subscriptions.cards.allIds.length ?
          'success' :
          'processing';
      return { ...state, failed, status };
    }

    case REFUND_MULTIPLE_SUBSCRIPTIONS.SUCCESS: {
      const chargesToRefund = getAllChargesToRefund(store);

      const completed = state.completed + 1;
      const status =
        completed + state.failed === chargesToRefund.length ?
          'success' :
          'processing';
      return { ...state, completed, status };
    }

    case REFUND_MULTIPLE_SUBSCRIPTIONS.ERROR: {
      const chargesToRefund = getAllChargesToRefund(store);

      const failed = state.failed + 1;
      const status =
        failed + state.completed === chargesToRefund.length ?
          'success' :
          'processing';
      return { ...state, failed, status };
    }

    default:
      return state;
  }
}
