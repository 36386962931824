import React, { PureComponent } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

import AsyncButton from 'react-async-button';

export default class SubscriptionsItemActions extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      dropdownLanguagesOpen: false
    };
  }

  toggleDropdown = () => {
    this.setState({ dropdownLanguagesOpen: !this.state.dropdownLanguagesOpen });
  };

  render() {
    return (
      <div className="ml-2">
        <div className="d-flex actions-container">
          <button
            type="button"
            className="btn btn-secondary mr-1"
            onClick={this.props.handleShowNotificationModalClick}
          >
            Send notification
          </button>
          {
            this.props.showCancelSubscriptionBtn ?
              <AsyncButton
                className={
                  this.props.canCancelSubscription ?
                    'btn btn-danger' :
                    'btn btn-success btn-success-icon'
                }
                text={
                  this.props.canCancelSubscription ?
                    'Cancel account' :
                    'Account cancelled'
                }
                pendingText="Processing.."
                fulFilledText="Account cancelled"
                rejectedText="Cancel account (!)"
                onClick={this.props.handleCancelSubscriptionClick}
                fulFilledClass="btn-success-icon"
                disabled={!this.props.canCancelSubscription}
              /> :
              null
          }
        </div>
        <div className={'d-flex actions-container'}>
          <Dropdown
            isOpen={this.state.dropdownLanguagesOpen}
            toggle={this.toggleDropdown}
            className="mt-1 dropdown-languages"
          >
            <DropdownToggle caret outline color="secondary" block>
              {this.props.language.name}
            </DropdownToggle>
            <DropdownMenu className="w-100">
              {this.props.languages.map(language => {
                return (
                  <DropdownItem
                    key={language.id}
                    onClick={() => this.props.handleChangeLanguage(language)}
                  >
                    {language.name}
                  </DropdownItem>
                );
              })}
            </DropdownMenu>
          </Dropdown>
          <AsyncButton
            className={'btn btn-danger mt-1 ml-1'}
            text={'Clear data'}
            pendingText="Processing.."
            fulFilledText="Data Cleared"
            rejectedText="Clear data (!)"
            onClick={this.props.handleClearData}
            fulFilledClass="btn-success-icon disabled"
            disabled={!this.props.canClearData}
          />
        </div>
      </div>
    );
  }
}
