import React, { Component } from 'react';
import { getFlag } from './../../../icons/flags';
import MultiSelectBox from '../../../../lib/msb/react/msb';

export default class LanguagesStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      languageIds: []
    };
    props.items.forEach(item => {
      item.image = getFlag(item.locale);
    });
    this.handleMultiSelectChange = this.handleMultiSelectChange.bind(this);
    this.handleNext = this.handleNext.bind(this);
  }

  get languages() {
    return this.props.items.map(item => {
      return { id: item.id, name: item.name, value: item.id, selected: item.selected };
    });
  }

  handleNext() {
    if (!this.state.languageIds.length) {
      return;
    }
    this.props.handleNext(this.state);
  }

  handleMultiSelectChange(languages) {
    const languageIds = languages.map(language => language.value);
    this.setState({
      languageIds
    });
  }

  render() {
    return (
      <div className="col-10 mx-auto">
        <MultiSelectBox
          data={this.languages}
          options={{ selectableLabel: 'Languages' }}
          onChange={this.handleMultiSelectChange}
          reset={this.props.reset}
        />
        <div className={'text-center'}>
          <button
            type="button"
            className={`btn btn-primary mt-6 ${!this.state.languageIds.length ? 'disabled' : ''}`}
            onClick={this.handleNext}
          >
            Next
          </button>
        </div>
      </div>
    );
  }
}
