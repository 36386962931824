import { DELETE_MODAL, SHOW_MODAL } from '../actions/ModalActions';

const modal = (state = [], { type, payload }) => {
  switch (type) {
    case SHOW_MODAL:
      return [...state, payload];
    case DELETE_MODAL:
      return state.filter(id => id !== payload);
    default:
      return state;
  }
};

export default modal;
