import React from 'react';

import Loading from '@common/components/Loading';
import SubscriptionItemContainer from './SubscriptionItemContainer';

const SubscriptionsData = props => (
  <div className="mt-15 p-relative">
    {props.fetchDataStatus === 'loading' ? (
      <Loading loadingClass="throbber--center mt-2" />
    ) : (
      <ul className="list-unstyled">
        {props.fetchedData && props.fetchedData.length ? (
          props.fetchedData.map((item, index) => {
            return <SubscriptionItemContainer key={index} item={item} {...props} />;
          })
        ) : props.fetchDataStatus === 'success' ? (
          <li className="card">
            <div className="card-header">
              <div className="row card-header-content">
                <div className="col-12">
                  <span className="ui-heading ui-heading-secondary">
                    Sorry, no results could be found. Try again.
                  </span>
                </div>
              </div>
            </div>
          </li>
        ) : null}
      </ul>
    )}
  </div>
);

export default SubscriptionsData;
