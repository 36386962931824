import moment from 'moment';

export function mapValuesToSubmit(values) {
  let { queryParams, email, ...params } = values;
  if (queryParams) {
    queryParams = {...queryParams};
    Object.keys(queryParams).map(key => {
      if (moment.isMoment(queryParams[key])) {
        queryParams[key] = moment(queryParams[key]).format('YYYY-MM-DD');
      }
    });
  }

  const { expirationDate } = params;
  if (expirationDate) {
    delete params.expirationDate;
  }

  if (email) {
    email = email.toLowerCase();
  }

  return {
    ...params,
    ...(expirationDate && { ...expirationDate }),
    ...(email && { email }),
    ...(queryParams && { queryParams })
  };
}
