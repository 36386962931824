import React from 'react';
import { connect } from 'react-redux';

import { changePage } from './../../common/actions';

import {
  fetchUsers,
  createUser,
  resetUserSelected,
  deactivateUser,
  resetPassword,
  filterUsers,
  fetchUser,
  updateUser,
  fetchUserGroups
} from '../actions';
import UserListData from './UserListData';
import UserListAddUserModal from './modals/UserListAddUserModal';
import UserListEditUserModal from './modals/UserListEditUserModal';
import UserListResetPasswordModal from './modals/UserListResetPasswordModal';
import { getServices } from '../../services/actions';

class UserListContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      currentlyDisplayed: null,
      showModalAddUser: false,
      showModalResetPassword: false
    };

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleDeactivateUser = this.handleDeactivateUser.bind(this);
    this.handleUserEdit = this.handleUserEdit.bind(this);
    this.resetUserPassword = this.resetUserPassword.bind(this);
    this.createUser = this.createUser.bind(this);
    this.filterUsers = this.filterUsers.bind(this);
  }

  componentDidMount() {
    this.props.changePage('Users list');
    this.props.fetchUsers();
    this.props.fetchUserGroups();
    this.props.getServices();
  }

  handleOpenModal(item, type) {
    this.setState({
      showModalAddUser: type === 'addUser',
      showModalEditUser: type === 'editUser',
      showModalResetPassword: type === 'resetPassword',
      item: item
    });
  }

  handleCloseModal() {
    this.setState({
      showModalAddUser: false,
      showModalEditUser: false,
      showModalResetPassword: false,
      item: null
    });
    this.props.resetUserSelected();
  }

  createUser(user) {
    this.props.createUser(user);
  }

  handleDeactivateUser(user) {
    this.props.deactivateUser(user);
  }

  resetUserPassword(user) {
    this.props.resetPassword(user);
  }

  filterUsers(e) {
    this.props.filterUsers(e.target.value);
  }

  handleUserEditModalOpen(user) {
    this.props.fetchUser(user);
    this.handleOpenModal(user, 'editUser');
  }

  handleUserEdit(user) {
    this.props.updateUser(user);
  }

  render() {
    return (
      <div>
        <nav className="sub-navbar">
          <div className="container-fluid">
            <div className="row">
              <div className="col-auto mr-auto d-flex align-items-center">
                <span className="text-secondary users-amount mr-2">
                  <span className="text-primary">
                    {this.props.data ? this.props.data.length : null}
                  </span>
                  users
                </span>
                <input
                  className="form-control user-search-input"
                  type="text"
                  onChange={this.filterUsers}
                  placeholder="Search..."
                />
              </div>
              <div className="col-auto">
                <button
                  onClick={item => this.handleOpenModal(item, 'addUser')}
                  className="btn btn-primary btn-add-user-icon btn-width-140"
                >
                  New user
                </button>
              </div>
            </div>
          </div>
        </nav>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xs-12"></div>
            <div className="col-10">
              <UserListData
                fetchingData={this.props.fetchingData}
                fetchedData={this.props.data}
                deactivateUser={this.handleDeactivateUser}
                resetPassword={item => this.handleOpenModal(item, 'resetPassword')}
                editUser={this.handleUserEditModalOpen.bind(this)}
                userActionStatus={this.props.userActionStatus}
              />
            </div>
          </div>
        </div>
        {this.state.showModalAddUser ? (
          <UserListAddUserModal
            onModalClose={this.handleCloseModal}
            services={this.props.services}
            onCreateUser={this.createUser}
            userSelected={this.props.userSelected}
            userActionStatus={this.props.userActionStatus}
            userActionError={this.props.userActionError}
            userGroups={this.props.userGroups}
          />
        ) : null}
        {this.state.showModalEditUser && this.props.userSelected ? (
          <UserListEditUserModal
            onModalClose={this.handleCloseModal}
            services={this.props.services}
            onUserEdit={this.handleUserEdit}
            selectedUserData={this.props.userSelected}
            userActionStatus={this.props.userActionStatus}
            userActionError={this.props.userActionError}
            userGroups={this.props.userGroups}
          />
        ) : null}
        {this.state.showModalResetPassword ? (
          <UserListResetPasswordModal
            itemData={this.state.item}
            onModalClose={this.handleCloseModal}
            userSelected={this.props.userSelected}
            onResetUserPassword={this.resetUserPassword}
            userActionStatus={this.props.userActionStatus}
            userActionError={this.props.userActionError}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const auth = state.auth;
  let servicesIds = [];
  let userSelected;
  const services = state.services.all;
  if (services && auth.userSelected) {
    servicesIds = services.map(item => item.id);
    userSelected = {
      ...auth.userSelected,
      services: auth.userSelected.services?.filter(item => servicesIds.includes(item.id))
    };
  }

  return {
    fetchingData: auth.fetchingData,
    data: auth.usersList,
    error: auth.error,
    services: services,
    userSelected: userSelected,
    userGroups: auth.userGroups,
    userActionStatus: auth.userActionStatus,
    userActionError: auth.userActionError
  };
};

export default connect(mapStateToProps, {
  changePage,
  fetchUsers,
  fetchUser,
  fetchUserGroups,
  getServices,
  createUser,
  updateUser,
  resetUserSelected,
  deactivateUser,
  resetPassword,
  filterUsers
})(UserListContainer);
