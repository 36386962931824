import { USER_GROUP_KEY } from './consts';

export const DEV1 = 'Dev1';
export const GROUP_TESTERS = 'Testers';

// Group permssions
const SUBSCRIPTIONS = {
  creditCardSearchEnhancement: false
};

const DEFAULT_PERMISSIONS = {
  SUBSCRIPTIONS: {
    ...SUBSCRIPTIONS,
    creditCardSearchEnhancement: false
  }
};

const GROUP_TESTER_PERMISSIONS = {
  SUBSCRIPTIONS: {
    ...SUBSCRIPTIONS,
    creditCardSearchEnhancement: true
  }
};

const DEV1_PERMISSIONS = {
  SUBSCRIPTIONS: {
    ...SUBSCRIPTIONS,
    creditCardSearchEnhancement: true
  }
};

const getUserGroup = () => {
  return localStorage.getItem(USER_GROUP_KEY);
};

export const hasGroupPermission = section => {
  switch (getUserGroup()) {
    case GROUP_TESTERS:
      return GROUP_TESTER_PERMISSIONS[section];
    case DEV1:
      return DEV1_PERMISSIONS[section];
    default:
      return DEFAULT_PERMISSIONS[section];
  }
};

export const hasGroupPermissionTo = action => {
  return hasGroupPermission('SUBSCRIPTIONS')[action];
};
