export const LOGIN_USER = 'login_user';
export const LOGOUT_USER = 'logout_user';
export const LOGIN_ERROR = 'login_error';
export const USERS_FETCHED = 'users_fetched';
export const USERS_FETCHING = 'users_fetching';
export const USER_FETCHED = 'user_fetched';
export const USER_FETCHING = 'user_fetching';
export const USER_GROUPS_FETCHED = 'user_group_fetched';
export const USER_CREATED = 'user_created';
export const USER_CREATED_ERROR = 'user_created_error';
export const USER_UPDATED = 'user_updated';
export const USER_UPDATED_ERROR = 'user_updated_error';
export const USER_PASSWORD_RESET = 'user_password_reset';
export const USER_PASSWORD_RESET_ERROR = 'user_password_reset_error';
export const USER_DEACTIVATED = 'user_deactivated';
export const RESET_SELECTED_USER = 'reset_selected_user';
export const FILTER_USERS = 'filter_users';
export const USER_PROCESSING = 'user_processing';
