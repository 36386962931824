import React from 'react';

import { ALL_STATUS_FILTER_OPTIONS } from '../consts';
import DatePicker from './DatePicker';

function Option() {
  return ALL_STATUS_FILTER_OPTIONS.map(item => {
    return (
      <option key={JSON.stringify(item)} value={item.id}>
        {item.name}
      </option>
    );
  });
}

function Filters({
  searchQuery,
  handleSearch,
  searchError,
  status,
  handleStatusSelect,
  dateSince,
  dateUntil,
  handleDatePicker
}) {
  return (
    <div className="d-flex actions-wrapper align-items-center my-3">
      {/*Search*/}
      <p className="mr-1 my-0">Search</p>
      <div className="position-relative">
        <input
          className="form-control user-search-input pr-5"
          type="text"
          value={searchQuery}
          onChange={e => handleSearch(e.target.value)}
          placeholder="card/descriptor/transaction reference/alert response"
        />
        {searchError ?
          (<span className="text-danger position-absolute">
            At least 3 characters are required
          </span>) : null
        }
        <button type="button" className="close h-100" aria-label="Delete" onClick={() => handleSearch('')}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      {/*Alert date*/}
      <p className="ml-3 mr-1 my-0 text-nowrap">Alert date</p>
      <React.Fragment>
        <DatePicker
          placeholder="Select date from"
          onChange={date => handleDatePicker(date, 'date_since')}
          value={dateSince ? dateSince * 1000 : null}
        />
        <span className="mx-1">-</span>
        <DatePicker
          placeholder="Select date to"
          onChange={date => handleDatePicker(date, 'date_until')}
          value={dateUntil ? dateUntil * 1000 : null}
        />
      </React.Fragment>

      {/*Status*/}
      <p className="ml-3 mr-1 my-0">Status</p>
      <select
        name="status"
        className="form-control custom-select"
        value={status}
        onChange={e => handleStatusSelect(e.target.value)}
      >
        <Option />
      </select>
    </div>
  );
}

export default Filters;
