import {
  REFUND_CONFIRMED,
  REFUND_CONFIRMED_ERROR,
  REFUND_CONFIRMING,
  REFUND_DELETED,
  REFUND_DELETED_ERROR,
  REFUND_DELETING,
  REFUNDS_FETCHED,
  REFUNDS_FETCHING,
  SINGLE_REFUND_CONFIRMED,
  SINGLE_REFUND_CONFIRMED_ERROR,
  SINGLE_REFUND_CONFIRMING,
  REFUNDS_COUNTERS_ERROR,
  REFUNDS_COUNTERS_SUCCESS, MANUAL_REFUND_SUCCESS, MANUAL_REFUND_ERROR
} from './types';
import { chargeStatus } from '../subscriptions/consts';

const removeChargeFormPayment = (currentState, paymentIndex, chargeIndex) => {
  let modifiedObjectArray;

  if (currentState.refunds[paymentIndex].charges.length - 1 === 0) {
    modifiedObjectArray = [];
  } else {
    modifiedObjectArray = [
      {
        ...currentState.refunds[paymentIndex],
        charges: [
          ...currentState.refunds[paymentIndex].charges.slice(0, chargeIndex),
          ...currentState.refunds[paymentIndex].charges.slice(chargeIndex + 1)
        ]
      }
    ];
  }
  return modifiedObjectArray;
};

const changeChargeStatus = (currentState, chargeStatus, refundIDs) => {
  return currentState.refunds.map(refund => {
    const isPayment = refundIDs && refundIDs.indexOf(refund.id) > -1;
    if (isPayment) {
      return {
        ...refund,
        charge: { ...refund.charge, status: chargeStatus }
      };
    }
    return refund;
  });
};

const changeRefundStatus = (currentState, refundStatus, refundID) => {
  return currentState.refunds.map(refund => {
    const isPayment = refund.id === refundID;
    if (isPayment) {
      return { ...refund, status: refundStatus };
    }
    return refund;
  });
};

const initialState = {
  offset: 0,
  refunds: [],
  counters: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case REFUNDS_FETCHED:
      return {
        ...state,
        refunds: action.payload.refunds,
        totalRefunds: action.payload.total,
        fetchingData: false
      };

    case REFUNDS_FETCHING:
      return {
        ...state,
        refunds: [],
        totalRefunds: 0,
        fetchingData: true
      };

    case REFUND_CONFIRMING:
      if (action.payload) {
        return {
          ...state,
          refunds: changeChargeStatus(state, chargeStatus.IN_PROGRESS, action.payload)
        };
      }
      return { ...state };

    case REFUND_CONFIRMED:
      if (action.payload) {
        return {
          ...state,
          refunds: changeChargeStatus(state, chargeStatus.COMPLETED, action.payload)
        };
      }
      return { ...state };

    case REFUND_CONFIRMED_ERROR:
      if (action.payload) {
        return {
          ...state,
          refunds: changeChargeStatus(
            state,
            chargeStatus.ERROR,
            action.payload
          )
        };
      }
      return { ...state };

    case SINGLE_REFUND_CONFIRMING:
      return {
        ...state,
        refunds: changeRefundStatus(state, chargeStatus.IN_PROGRESS, action.payload.refundID)
      };

    case SINGLE_REFUND_CONFIRMED:
      return {
        ...state,
        refunds: changeRefundStatus(state, chargeStatus.COMPLETED, action.payload.refundID)
      };

    case SINGLE_REFUND_CONFIRMED_ERROR:
      return {
        ...state,
        refunds: changeRefundStatus(state, chargeStatus.ERROR, action.payload.refundID)
      };

    case MANUAL_REFUND_SUCCESS:
      return {
        ...state,
        refunds: changeChargeStatus(state, chargeStatus.MANUAL_REFUND, action.payload)
      };

    case MANUAL_REFUND_ERROR:
      return {
        ...state,
        refunds: changeChargeStatus(state, chargeStatus.ERROR, action.payload)
      };

    case REFUND_DELETING:
      return {
        ...state,
        refunds: changeRefundStatus(state, 'deleting', action.payload)
      };

    case REFUND_DELETED:
      return {
        ...state,
        refunds: state.refunds.filter(refund => {
          return refund.id !== action.payload;
        })
      };

    case REFUND_DELETED_ERROR:
      return {
        ...state,
        refunds: changeRefundStatus(state, null, action.payload)
      };

    case REFUNDS_COUNTERS_SUCCESS:
      return { ...state, counters: action.payload.counters };

    case REFUNDS_COUNTERS_ERROR:
      return { ...state, counters: {} };
  }
  return state;
}
