import React, { Component } from 'react';
import { FieldArray, withFormik } from 'formik';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import Error from '@common/components/Error';
import { InputField, SelectField, CheckboxField } from '@common/form';
import { validateEmail } from '../../../common/helpers.jsx';

class UserListEditUserModal extends Component {
  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
  }

  updateServices(services) {
    this.setState({ services: services });
  }

  closeModal() {
    this.props.services.map(service => {
      service.checked = null;
      return service;
    });
    this.props.onModalClose();
  }

  render() {
    return (
      <Modal isOpen={true} toggle={this.closeModal}>
        {this.isResponseStatus('success') ? (
          <div>
            <ModalHeader toggle={this.closeModal}>User has been updated successfully!</ModalHeader>
            <ModalBody>{this.renderStepUserEdited()}</ModalBody>
          </div>
        ) : (
          <div>
            <ModalHeader toggle={this.closeModal}>Edit User</ModalHeader>
            <ModalBody>{this.renderStepCreateUser()}</ModalBody>
          </div>
        )}
      </Modal>
    );
  }

  isResponseStatus(status) {
    return this.props.userActionStatus === status;
  }

  renderStepCreateUser() {
    return (
      <div className="row justify-content-center">
        <div className="col-8">
          <form onSubmit={this.props.handleSubmit}>
            {this.isResponseStatus('error') ? <Error errors={this.props.userActionError} /> : null}
            <div className="form-group">
              <InputField
                type="text"
                name="email"
                placeholder="Type email address"
                label="Email address"
              />
            </div>
            <div className="row">
              <div className="col-12">
                <SelectField
                  label="User group"
                  name="user_group_id"
                  options={this.props.userGroups}
                  emptyOption="Select user group"
                />
              </div>
              <div className="col-12">
                <label>Products:</label>
                <FieldArray
                  name="service_ids"
                  render={arrayHelpers => (
                    <CheckboxField
                      data={this.props.services}
                      containerClass="d-inline-block col-6"
                      toggleAll={true}
                      {...arrayHelpers}
                    />
                  )}
                />
              </div>
            </div>
            <button
              type="submit"
              role="button"
              disabled={this.isResponseStatus('processing')}
              className="btn btn-primary btn-modal"
            >
              {this.isResponseStatus('processing') ? 'Updating user...' : 'Update user'}
            </button>
          </form>
        </div>
      </div>
    );
  }

  renderStepUserEdited() {
    return (
      <div className="row justify-content-center">
        <div className="col-8">
          <div className="text-center">
            <div className="text-with-label mb-3">
              <p className="text-with-label__text">User successfully updated:</p>
              <p className="text-with-label__label">{this.props.selectedUserData.email}</p>
            </div>
            <button
              onClick={this.closeModal}
              type="submit"
              role="button"
              className="btn btn-primary btn-modal"
            >
              Done
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const onValidate = values => {
  const errors = {};

  if (!validateEmail(values.email)) {
    errors.email = 'Invalid email address';
  }

  if (!values.user_group_id) {
    errors.user_group_id = 'User group is required';
  }

  return errors;
};

UserListEditUserModal = withFormik({
  mapPropsToValues: props => ({
    email: props.selectedUserData.email,
    user_group_id: props.selectedUserData.user_group?.id,
    service_ids: props.selectedUserData.services?.map(service => service.id)
  }),
  validate: onValidate,
  handleSubmit: (values, { props }) => {
    values.id = props.selectedUserData.id;
    values.user_group_id = parseInt(values.user_group_id, 10);
    props.onUserEdit(values);
  },
  displayName: 'edit_user'
})(UserListEditUserModal);

export default UserListEditUserModal;
