import { CHANGE_PAGE } from './types';
import {
  showNotificationSuccess,
  showNotificationInfo,
  showNotificationError
} from './../notifications/actions/NotificationsActions';

export function changePage(title) {
  return function(dispatch) {
    dispatch({ type: CHANGE_PAGE, payload: { title: title } });
  };
}

export function showErrorAlert(dispatch, message) {
  dispatch(showNotificationError(message));
}

export function showInfoAlert(dispatch, message) {
  dispatch(showNotificationInfo(message));
}

export function showSuccessAlert(dispatch, message) {
  dispatch(showNotificationSuccess(message));
}
