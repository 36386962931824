export const SUBSCRIPTIONS_SEARCH_URL = '/subscriptions/search';
export const SUBSCRIPTIONS_MOBILE_FIND_URL = '/subscriptions/mobile/find';
export const SUBSCRIPTIONS_CANCEL_URL = '/subscriptions/cancel';
export const SUBSCRIPTIONS_CANCEL_PAYMENT_URL = '/subscriptions/cancel/payment';
export const SUBSCRIPTIONS_CANCEL_MOBILE_URL = '/subscriptions/mobile/cancel';
export const SUBSCRIPTIONS_CLEAR_DATA = '/subscriptions/clear_data';

export function isSubscriptionChargeRefundable(charge) {
  return (
    charge.verification_fee ||
    charge.refunded ||
    charge.current_status === 'waiting' ||
    charge.current_status === 'error' ||
    charge.current_status === 'rejected'
  );
}

export const chargeStatus = {
  COMPLETED: 'completed',
  WAITING: 'waiting',
  ERROR: 'error',
  REJECTED: 'rejected',
  IN_PROGRESS: 'in_progress',
  MANUAL_REFUND: 'manual_refund',
  CAPTURE_FAILED: 'capture_failed',
  CAPTURE_SCHEDULED: 'capture_scheduled'
};

export const accountStatus = {
  ACTIVE: 'active',
  CANCELED: 'canceled',
  CANCELLED: 'cancelled',
  EXPIRED: 'expired'
};
