import React, { Component } from 'react';
import { withFormik } from 'formik';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { validateEmail } from './../../common/helpers';

import { InputField, RadioField } from '@common/form';
const NOTIFICATION_TYPE_VALUE_CANCEL = 'cancel';
const NOTIFICATION_TYPE_VALUE_REFUND = 'refund';
const NOTIFICATION_TYPE_OPTIONS = [
  { label: 'Cancel', value: NOTIFICATION_TYPE_VALUE_CANCEL },
  { label: 'Refund', value: NOTIFICATION_TYPE_VALUE_REFUND }
];
class SubscriptionsNotificationsModal extends Component {
  render() {
    return (
      <Modal isOpen={true} toggle={this.props.onModalClose}>
        <ModalHeader toggle={this.props.onModalClose}>
          Send notification to {this.props.itemData.user.name || this.props.itemData.user.email}
        </ModalHeader>
        <ModalBody>
          <div className="row justify-content-center">
            <div className="col-9">
              <form onSubmit={this.props.handleSubmit}>
                <div className="form-group row">
                  <div className="col-sm-4">Notification:</div>
                  <div className="col-sm-8">
                    <RadioField name="notification_type" options={NOTIFICATION_TYPE_OPTIONS} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-4">
                    Additional email
                    {this.props.values.notification_type === 'cancel' ? <span>*</span> : null}:
                  </div>
                  <div className="col-sm-8">
                    <InputField
                      type="text"
                      name="additional_email"
                      placeholder="Enter email address"
                    />
                  </div>
                  {this.props.values.notification_type === 'cancel' ? (
                    <div className="col-sm-12 text-right">* required</div>
                  ) : null}
                </div>
                <button
                  type="submit"
                  role="button"
                  disabled={
                    (this.props.values.notification_type === 'cancel' &&
                      !this.props.values.additional_email.length) ||
                    this.props.isInvalid ||
                    this.props.notificationStatus === 'processing'
                  }
                  className="btn btn-primary btn-modal"
                >
                  {this.props.notificationStatus === 'processing'
                    ? 'Sending...'
                    : 'Send Notification'}
                </button>
                {this.props.notificationStatus === 'success' ? (
                  <p className="form-response-success mt-1 mb-0 text-center">
                    Notification has been sent successfully.
                  </p>
                ) : null}
                {this.props.notificationStatus === 'error' ? (
                  <p className="form-response-error mt-1 mb-0 text-center">
                    Error! Notification has not been sent.
                  </p>
                ) : null}
              </form>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

const onValidate = values => {
  const errors = {};

  if (!values.notification_type) {
    errors.notification_type = 'Choose notification type';
  }

  if (values.notification_type === 'cancel') {
    if (!values.additional_email) {
      errors.additional_email = 'Email address is required';
    }
    if (values.additional_email && !validateEmail(values.additional_email)) {
      errors.additional_email = 'Invalid email address';
    }
  }

  if (
    values.notification_type === 'refund' &&
    values.additional_email &&
    !validateEmail(values.additional_email)
  ) {
    errors.additional_email = 'Invalid email address';
  }

  return errors;
};

function mapCancelNotificationValues(props) {
  return {
    service_id: props.itemData.service.id,
    email: props.itemData.user.email,
    locale: props.language && props.language.locale
  };
}

function mapRefundNotificationValues(props, values) {
  const data = {
    payment_uid: props.itemData.id,
    service_id: props.itemData.service.id,
    locale: props.language && props.language.locale
  };

  if (values.additional_email) {
    data.email = values.additional_email;
  }
  return data;
}

export default withFormik({
  displayName: 'send_notification',
  mapPropsToValues: () => ({
    notification_type: NOTIFICATION_TYPE_OPTIONS[0].value,
    additional_email: ''
  }),
  validate: onValidate,
  handleSubmit: (values, { props }) => {
    if (values.notification_type === NOTIFICATION_TYPE_VALUE_CANCEL) {
      const data = mapCancelNotificationValues(props);
      props.sendCancelNotification(data);
    } else if (values.notification_type === NOTIFICATION_TYPE_VALUE_REFUND) {
      const data = mapRefundNotificationValues(props, values);
      props.sendRefundNotification(data);
    }
  }
})(SubscriptionsNotificationsModal);
