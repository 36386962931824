import React from 'react';

export function getFlag(flag) {
  switch (flag) {
    case 'cs':
      return cs;
    case 'de':
      return de;
    case 'en':
      return en;
    case 'fr':
      return fr;
    case 'es':
      return es;
    case 'pt':
      return pt;
    case 'it':
      return it;
    case 'tr':
      return tr;
  }
}

const cs = (
  <svg xmlns="http://www.w3.org/2000/svg" height="512" width="512" viewBox="0 0 512 512">
    <defs>
      <clipPath id="a">
        <path fillOpacity=".67" d="M102.42 0h708.66v708.66H102.42z" />
      </clipPath>
    </defs>
    <g
      fillRule="evenodd"
      clipPath="url(#a)"
      transform="translate(-74) scale(.722)"
      strokeWidth="1pt"
    >
      <path fill="#e80000" d="M0 0h1063v708.66H0z" />
      <path fill="#fff" d="M0 0h1063v354.33H0z" />
      <path d="M0 0l529.732 353.88L0 707.3V0z" fill="#00006f" />
    </g>
  </svg>
);

const de = (
  <svg xmlns="http://www.w3.org/2000/svg" height="512" width="512" viewBox="0 0 512 512">
    <path fill="#ffce00" d="M0 341.338h512.005v170.67H0z" />
    <path d="M0 0h512.005v170.67H0z" />
    <path fill="#d00" d="M0 170.67h512.005v170.668H0z" />
  </svg>
);

const en = (
  <svg xmlns="http://www.w3.org/2000/svg" height="512" width="512" viewBox="0 0 512 512">
    <defs>
      <clipPath id="a">
        <path fillOpacity=".67" d="M250 0h500v500H250z" />
      </clipPath>
    </defs>
    <g clipPath="url(#a)" transform="translate(-256) scale(1.024)">
      <g strokeWidth="1pt">
        <path fill="#006" d="M0 0h1000.02v500.01H0z" />
        <path
          d="M0 0v55.903l888.218 444.11h111.802V444.11L111.802.003H0zm1000.02
              0v55.9L111.802 500.01H0v-55.9L888.218 0h111.802z"
          fill="#fff"
        />
        <path
          d="M416.675 0v500.01h166.67V0h-166.67zM0 166.67v166.67h1000.02V166.67H0z"
          fill="#fff"
        />
        <path
          d="M0 200.004v100.002h1000.02V200.004H0zM450.01 0v500.01h100V0h-100zM0
          500.01l333.34-166.67h74.535L74.535 500.01H0zM0 0l333.34 166.67h-74.535L0
          37.27V0zm592.145 166.67L925.485 0h74.535L666.68 166.67h-74.535zm407.875
          333.34L666.68 333.34h74.535l258.805 129.403v37.267z"
          fill="#c00"
        />
      </g>
    </g>
  </svg>
);

const fr = (
  <svg xmlns="http://www.w3.org/2000/svg" height="512" width="512" viewBox="0 0 512 512">
    <g fillRule="evenodd" strokeWidth="1pt">
      <path fill="#fff" d="M0 0h512.005v512H0z" />
      <path fill="#00267f" d="M0 0h170.667v512H0z" />
      <path fill="#f31830" d="M341.333 0H512v512H341.333z" />
    </g>
  </svg>
);

const it = (
  <svg xmlns="http://www.w3.org/2000/svg" height="512" width="512" viewBox="0 0 512 512">
    <g fillRule="evenodd" strokeWidth="1pt">
      <path fill="#fff" d="M0 0h512.005v512H0z" />
      <path fill="#009246" d="M0 0h170.667v512H0z" />
      <path fill="#ce2b37" d="M341.333 0H512v512H341.333z" />
    </g>
  </svg>
);

const tr = (
  <svg xmlns="http://www.w3.org/2000/svg" height="512" width="512" viewBox="0 0 512 512">
    <g fillRule="evenodd">
      <path fill="#f31930" d="M0 0h512v512H0z" />
      <path
        d="M348.772 263.97c0 70.63-58.247 127.883-130.1 127.883S88.572 334.6
        88.572 263.97s58.247-127.88 130.1-127.88 130.1 57.254 130.1 127.88z"
        fill="#fff"
      />
      <path
        d="M355.28 263.965c0 56.5-46.6 102.304-104.08 102.304s-104.08-45.804-104.08-102.305
        46.598-102.303 104.08-102.303 104.08 45.803 104.08 102.303z"
        fill="#f31830"
      />
      <path
        d="M374.104 204.23l-1.05 47.272-44.103 11.973 43.474 15.482-1.05 43.35
        28.352-33.853 42.842 14.863-24.782-36.332 30.242-36.125-46.413 12.798-27.512-39.428z"
        fill="#fff"
      />
    </g>
  </svg>
);

const pt = (
  <svg xmlns="http://www.w3.org/2000/svg" height="512" width="512" viewBox="0 0 512 512">
    <path fill="red" d="M204.79 0h307.26v512H204.79z" />
    <path fill="#060" d="M-.05 0h204.84v512H-.05z" />
    <g
      fill="#ff0"
      fillRule="evenodd"
      stroke="#000"
      strokeWidth=".573"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        d="M293.78 326.588c-34.37-1.036-191.988-99.42-193.07-115.086l8.705-14.515c15.634
        22.717 176.765 118.397 192.59 115.006l-8.225 14.59"
        strokeWidth=".652"
      />
      <path
        d="M107.583 195.015c-3.083 8.3 41.13 35.633 94.326 67.987 53.192 32.35 99.06
        52.344 102.46 49.477.207-.37 1.672-2.892 1.537-2.872-.637.96-2.188
        1.264-4.608.566-14.373-4.145-51.856-21.35-98.276-49.5-46.42-28.152-86.806-54.094
        -93.083-65.117-.435-.764-.746-2.158-.683-3.24l-.152-.004-1.337 2.337-.184.366zM294.59
        327.043c-.584 1.057-1.67 1.093-3.735.866-12.857-1.424-51.87-20.396-98.033-48.03-53.715
        -32.154-98.077-61.452-93.264-69.11l1.31-2.314.257.08c-4.327 12.977 87.55
        65.51292.96 68.86 53.16 32.936 97.98 52.17 101.945 47.17l-1.443 2.483v-.004z"
        strokeWidth=".652"
      />
      <path
        d="M204.954 220.99c34.403-.27 76.857-4.7 101.29-14.438L300.978 198c-14.442
        7.992-57.13 13.248-96.316 14.034-46.35-.426-79.064-4.742-95.456-15.74l-4.97
        9.106c30.134 12.75 61.012 15.46 100.717 15.59"
        strokeWidth=".652"
      />
      <path
        d="M307.662 206.746c-.84 1.344-16.775 6.84-40.247 10.894-15.915 2.426-36.675
        4.5-62.582 4.526-24.614.023-44.72-1.73-59.943-3.792-24.64-3.864-37.357
        -9.24-42.058-11.14.45-.893.74-1.52 1.172-2.355 13.534 5.388 26.31 8.637
        41.278 10.94 15.122 2.048 34.986 3.825 59.478 3.802 25.787-.026 46.318-2.258
        62.14-4.612 24.07-3.882 37.22-8.878 39.065-11.2l1.7 2.936h-.002zm-4.62-8.673c-2.61
        2.095-15.608 6.705-38.48 10.357-15.262 2.188-34.67 4.146-59.465 4.17-23.556.023
        -42.802-1.565-57.44-3.697-23.227-3.033-35.59-8.505-40.045-10.1.443-.767.895-1.528
        1.358-2.292 3.468 1.747 15.398 6.59 38.942 9.926 14.472 2.052 33.747 3.58 57.187
        3.557 24.677-.025 43.863-2.02 59.03-4.197 22.99-3.163 35.36-9.042 37.237-10.514l1.676
        2.79zM91.985 262.44c21.16 11.38 68.158 17.117 112.633 17.51 40.494.066 93.25-6.258
        112.978-16.713l-.543-11.39c-6.172 9.644-62.707 18.896-112.875 18.517-50.168
        -.378-96.754-8.127-112.284-18.157l.09 10.23"
        strokeWidth=".652"
      />
      <path
        d="M318.675 260.8v2.718c-2.964 3.545-21.555 8.903-44.87 12.677-17.743
        2.72-40.876 4.772-69.702 4.772-27.385 0-49.223-1.952-66.16-4.552-26.77
        -3.9-43.894-10.725-47.33-12.763l.014-3.17c10.325 6.866 38.3 11.888 47.692
        13.425 16.825 2.583 38.534 4.52 65.782 4.52 28.69 0 51.694-2.038 69.326-4.74
        16.727-2.416 40.555-8.7 45.246-12.884zm.012-9.66v2.716c-2.964 3.543-21.556
        8.9-44.87 12.674-17.743 2.72-40.877 4.773-69.702 4.773-27.386 0-49.224-1.95
        -66.16-4.553-26.77-3.896-43.894-10.723-47.33-12.76l.014-3.17c10.324 6.863
        38.297 11.886 47.69 13.42 16.826 2.586 38.535 4.525 65.784 4.525 28.69 0
        51.694-2.04 69.326-4.744 16.726-2.415 40.555-8.7 45.246-12.883v.002zm-114.165
        73.49c-48.665-.29-90.363-13.265-99.17-15.41l6.422 10.052c15.554 6.543 56.234
        16.293 93.215 15.213 36.98-1.078 69.3-3.943 92.077-15.03l6.583-10.418c-15.52
        7.31-68.344 15.52-99.128 15.594"
        strokeWidth=".652"
      />
      <path
        d="M299.538 317.12a152.554 152.554 0 0 1-2.954 4.358c-10.743 3.788-27.67
        7.765-34.813 8.925-14.59 3.007-37.164 5.227-57.2 5.237-43.11-.63-78.398-9.07-95
        -16.27l-1.34-2.302.22-.346 2.272.88c29.523 10.563 62.68 14.777 94.092 15.534
        19.954.07 39.93-2.287 56.083-5.176 24.77-4.963 34.786-8.704 37.86-10.4l.78
        -.438zm5.708-9.414c.025.03.05.057.075.088a301.968 301.968 0 0 1-2.232 3.712c
        -5.73 2.048-21.28 6.598-43.985 9.773-14.96 2.037-24.26 4.01-54.02 4.59-55.77
        -1.42-91.876-12.325-100.454-15.014l-1.272-2.44c32.32 8.438 65.333 14.328
        101.726 14.93 27.228-.58 38.812-2.585 53.65-4.608 26.476-4.117 39.816-8.476
        43.82-9.735a3.058 3.058 0 0 0-.176-.226l2.87-1.073v.002z"
        strokeWidth=".626"
      />
      <path
        d="M305.83 253.45c.158 32.015-16.225 60.743-29.42 73.416-18.67 17.93-43.422
        29.464-72.328 29.996-32.28.593-62.715-20.45-70.878-29.695-15.96-18.074-28.955
        -41.025-29.372-71.957 1.977-34.94 15.694-59.278 35.576-75.968 19.883-16.69
        46.357-24.818 68.405-24.237 25.438.67 55.153 13.15 75.686 37.925 13.45 16.23
        19.276 33.846 22.33 60.52zM204.356 143.837c61.992 0 112.977 50.446 112.977
        112.302 0 61.854-50.985 112.303-112.977 112.303-61.992 0-112.554-50.447
        -112.554-112.302 0-61.856 50.562-112.302 112.554-112.302"
        strokeWidth=".652"
      />
      <path
        d="M204.66 143.45c62.046 0 112.65 50.613 112.65 112.666 0 62.052-50.605
        112.663-112.65 112.663-62.05 0-112.654-50.61-112.654-112.664 0-62.053
        50.606-112.665 112.653-112.665zM94.484 256.117c0 60.6 49.762 110.185 110.174
        110.185 60.41 0 110.173-49.585 110.173-110.184 0-60.6-49.763-110.186-110.174
        -110.186-60.413 0-110.175 49.588-110.175 110.186z"
        strokeWidth=".652"
      />
      <path
        d="M204.75 152.815c56.582 0 103.188 46.445 103.188 103.2 0 56.758-46.607
        103.2-103.188 103.2-56.582 0-103.19-46.442-103.19-103.2 0-56.755 46.61-103.2
        103.19-103.2zm-100.71 103.2c0 55.393 45.486 100.72 100.71 100.72 55.223 0
        100.71-45.327 100.71-100.72 0-55.394-45.486-100.722-100.71-100.722S104.04
        200.62 104.04 256.015z"
        strokeWidth=".652"
      />
      <path d="M209.29 142.996h-9.655l.01 226.372h9.69z" strokeWidth=".652" />
      <path
        d="M208.322 141.706h2.458l.02 228.977h-2.46l-.017-228.977zm-9.58.002h2.475l.004
        228.977h-2.478V141.708z"
        strokeWidth=".652"
      />
      <path
        d="M317.39 260.477v-8.37l-6.816-6.347-38.684-10.24-55.75-5.69-67.13
        3.414-47.787 11.378-9.648 7.145v8.37l24.44-10.964 58.026-9.102h55.752l40.96
        4.552 28.445 6.827z"
        strokeWidth=".652"
      />
      <path
        d="M204.704 238.672c26.605-.05 52.418 2.52 72.892 6.507 21.127 4.223
        35.996 9.506 41.08 15.44l-.006 2.938c-6.13-7.384-26.138-12.798-41.55-15.893
        -20.318-3.952-45.958-6.504-72.416-6.455-27.92.052-53.916 2.696-73.937
        6.603C114.7 251 93.277 257.333 90.6 263.58v-3.06c1.47-4.323 17.424-10.784
        39.805-15.265 20.172-3.933 46.217-6.532 74.3-6.583zm.012-9.663c26.604-.05
        52.417 2.522 72.89 6.507 21.13 4.225 35.997 9.507 41.08 15.44l-.005
        2.94c-6.13-7.384-26.137-12.798-41.548-15.892-20.32-3.954-45.96-6.506
        -72.416-6.455-27.92.05-53.79 2.696-73.814 6.6-15.503 2.94-37.804 9.524
        -40.29 15.77v-3.06c1.468-4.278 17.745-10.97 39.804-15.267 20.172-3.933
        46.217-6.53 74.3-6.583zm-.546-49.286c41.925-.21 78.495 5.863 95.226 14.433l6.103
        10.558c-14.543-7.837-53.996-15.986-101.27-14.768-38.518.237-79.675 4.24-100.324
        15.258l7.286-12.185c16.947-8.79 56.914-13.248 92.98-13.294"
        strokeWidth=".652"
      />
      <path
        d="M204.727 188.44c23.913-.065 47.02 1.284 65.395 4.6 17.113 3.185 33.433
        7.965 35.767 10.536l1.808 3.197c-5.675-3.706-19.798-7.832-37.932-11.27-18.21
        -3.424-41.302-4.558-65.08-4.495-26.99-.093-47.96 1.332-65.957 4.47-19.034
        3.56-32.24 8.65-35.496 11.066l1.773-3.38c6.33-3.23 16.372-7.12 33.25-10.16
        18.61-3.4 39.802-4.42 66.472-4.565h-.002zm-.01-9.655c22.884-.06 45.48 1.216
        63.092 4.382 13.89 2.704 27.627 6.926 32.658 10.682l2.646 4.204c-4.5-5-21.432
        -9.74-36.38-12.393-17.477-3.013-39.134-4.206-62.018-4.397-24.016.067-46.21
        1.538-63.433 4.674-16.43 3.127-27.034 6.83-31.52 9.72l2.327-3.512c6.19-3.26
        16.193-6.253 28.79-8.7 17.35-3.16 39.68-4.592 63.837-4.66zm55.966 124.16c
        -20.728-3.87-41.494-4.432-55.998-4.26-69.868.818-92.438 14.345-95.192 18.44l
        -5.222-8.51c17.79-12.894 55.835-20.125 100.793-19.385 23.345.382 43.49
        1.932 60.438 5.215l-4.82 8.502"
        strokeWidth=".652"
      />
      <path
        d="M204.283 297.483c19.436.29 38.513 1.093 56.927 4.527l-1.334 2.354c
        -17.102-3.157-35.334-4.365-55.527-4.267-25.786-.2-51.866 2.207-74.572 8.723
        -7.164 1.994-19.025 6.6-20.234 10.406l-1.327-2.187c.383-2.25 7.55-6.92
        20.95-10.685 26.01-7.448 50.335-8.703 75.116-8.874v.004zm.882-9.78c20.137.376
        40.932 1.307 61.144 5.31l-1.39 2.45c-18.252-3.622-35.69-4.83-59.636-5.198
        -25.867.047-53.306 1.89-78.254 9.144-8.055 2.345-21.956 7.418-22.42 11.434l
        -1.325-2.35c.302-3.648 12.335-8.406 23.162-11.556 25.14-7.31 52.65-9.188 78.72-9.235z"
        strokeWidth=".626"
      />
      <path
        d="M304.408 309.908l-8.396 13.026-24.122-21.423-62.578-42.097-70.542-38.684-36.625-12.528
        7.805-14.473 2.65-1.446 22.757 5.69 75.093 38.684 43.235 27.306 36.41 26.17 14.79 17.066z"
        strokeWidth=".652"
      />
      <path
        d="M100.825 208.543c6.424-4.357 53.634 16.674 103.032 46.46 49.266 29.872
        96.343 63.626 92.094 69.943l-1.397 2.198-.64.506c.136-.098.844-.964-.07-3.306
        -2.1-6.907-35.494-33.554-90.902-67.01-54.015-32.21-99.033-51.622-103.534
        -46.073l1.42-2.717h-.002zm205.3 101.18c4.063-8.024-39.726-41.01-94.016
        -73.128-55.54-31.478-95.568-50.006-102.88-44.48l-1.624 2.956c-.014.164.06
        -.2.403-.466 1.328-1.16 3.532-1.08 4.526-1.1 12.59.19 48.546 16.736 98.994
        45.657 22.104 12.875 93.378 58.587 93.106 71.44.02 1.106.092 1.334-.324
        1.88l1.813-2.757v-.002z"
        strokeWidth=".652"
      />
    </g>
    <g transform="translate(-68.306 28.444) scale(1.13778)">
      <path
        d="M180.6 211.01c0 16.27 6.663 30.987 17.457 41.742 10.815 10.778 25.512
        17.58 41.81 17.58 16.38 0 31.246-6.654 42.015-17.39 10.77-10.735 17.443-25.552
        17.446-41.88h-.002v-79.19l-118.74-.14.012 79.278h.002z"
        fill="#fff"
        stroke="#000"
        strokeWidth=".67"
      />
      <path
        d="M182.82 211.12v.045c0 15.557 6.44 29.724 16.775 40.01 10.354 10.304
        24.614 16.71 40.214 16.71 15.68 0 29.91-6.36 40.22-16.625 10.31-10.265 16.698-24.433
        16.7-40.044h-.002V134.39l-113.84-.02-.07 76.75m91.022-53.748l.004 48.89-.04 5.173c0
        1.36-.082 2.912-.24 4.233-.926 7.73-4.48 14.467-9.746 19.708-6.164 6.136-14.67
        9.942-24.047 9.942-9.326 0-17.638-3.938-23.828-10.1-6.35-6.32-10.03-14.986-10.03
        -23.947l-.013-54.022 67.94.122v.002z"
        fill="red"
        stroke="#000"
        strokeWidth=".507"
      />
      <g id="e">
        <g id="d" fill="#ff0" stroke="#000" strokeWidth=".5">
          <path
            d="M190.19 154.43c.135-5.52 4.052-6.828 4.08-6.847.03-.02 4.232
            1.407 4.218 6.898l-8.298-.05"
            stroke="none"
          />
          <path
            d="M186.81 147.69l-.682 6.345 4.14.01c.04-5.25 3.975-6.124 4.07-6.104.09
            -.004 3.99 1.16 4.093 6.104h4.152l-.75-6.394-15.022.038v.002zm-.96 6.37h16.946c.357
            0 .65.353.65.784 0 .43-.293.78-.65.78H185.85c-.357 0-.65-.35-.65-.78 0-.43.293-.784.65-.784z"
          />
          <path
            d="M192.01 154.03c.018-3.313 2.262-4.25 2.274-4.248 0 0 2.342.966
            2.36 4.248h-4.634m-5.8-8.98h16.245c.342 0 .623.318.623.705 0 .387-.28.704
            -.623.704H186.21c-.342 0-.623-.316-.623-.705 0-.387.28-.705.623-.705zm.34
            1.42h15.538c.327 0 .595.317.595.704 0 .388-.268.704-.595.704H186.55c-.327
            0-.595-.316-.595-.704 0-.387.268-.704.595-.704zm5.02-10.59l1.227.002v.87h.895v
            -.89l1.257.005v.887h.896v-.89h1.258l-.002 2.01c0 .317-.254.52-.55.52h-4.41c
            -.296 0-.57-.236-.57-.525l-.004-1.99zm4.62 2.69l.277 6.45-4.303-.015.285-6.452 3.74.017"
          />
          <path id="a" d="M190.94 141.56l.13 3.478h-4.124l.116-3.478h3.88-.002z" />
          <use height="100%" width="100%" xlinkHref="#a" x="10.609" />
          <path
            id="b"
            d="M186.3 139.04l1.2.003v.872h.877v-.892l1.23.004v.89h.88v
            -.894l1.23.002-.003 2.012c0 .314-.25.518-.536.518h-4.317c-.29 0-.558-.235-.558-.525l-.003-1.99z"
          />
          <use height="100%" width="100%" xlinkHref="#b" x="10.609" />
          <path
            d="M193.9 140.61c-.026-.627.877-.634.866 0v1.536h-.866v-1.536"
            fill="#000"
            stroke="none"
          />
          <path
            id="c"
            d="M188.57 142.84c-.003-.606.837-.618.826 0v1.187h-.826v-1.187"
            fill="#000"
            stroke="none"
          />
          <use height="100%" width="100%" xlinkHref="#c" x="10.641" />
        </g>
        <use height="100%" width="100%" xlinkHref="#d" y="46.32" />
        <use
          height="100%"
          width="100%"
          xlinkHref="#d"
          transform="rotate(-45.202 312.766 180.004)"
        />
      </g>
      <use height="100%" width="100%" xlinkHref="#d" x="45.714" />
      <use height="100%" width="100%" xlinkHref="#e" transform="matrix(-1 0 0 1 479.792 0)" />
      <g id="f" fill="#fff">
        <path
          d="M232.636 202.406v.005a8.34 8.34 0 0 0 2.212 5.69c1.365 1.467 3.245
          2.378 5.302 2.378 2.067 0 3.944-.905 5.303-2.365 1.358-1.46 2.202-3.472
          2.202-5.693v-10.768l-14.992-.013-.028 10.765"
          fill="#039"
        />
        <circle cx="236.074" cy="195.735" r="1.486" />
        <circle cx="244.392" cy="195.742" r="1.486" />
        <circle cx="240.225" cy="199.735" r="1.486" />
        <circle cx="236.074" cy="203.916" r="1.486" />
        <circle cx="244.383" cy="203.905" r="1.486" />
      </g>
      <use height="100%" width="100%" xlinkHref="#f" y="-26.016" />
      <use height="100%" width="100%" xlinkHref="#f" x="-20.799" />
      <use height="100%" width="100%" xlinkHref="#f" x="20.745" />
      <use height="100%" width="100%" xlinkHref="#f" y="25.784" />
    </g>
  </svg>
);

const es = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="512"
    width="512"
    viewBox="0 0
  512 512"
  >
    <path fill="#c60b1e" d="M0 0h512v512H0z" />
    <path fill="#ffc400" d="M0 128h512v256H0z" />
    <path
      d="M171.727 227.575s-.523
  0-.81-.164c-.29-.166-1.156-.987-1.156-.987l-.694
  -.496-.633-.876s-.753-1.198-.408-2.133c.345-.93.925-1.262
  1.448-1.534.52-.274
  1.616-.603 1.616-.603s.87-.383 1.158-.44c.288-.055 1.333-.328 1.333-.328s.29
  -.163.577-.273c.292-.11.695-.11.93-.168.23-.055.81-.24 1.155-.26.538-.02
  1.39.096
  1.68.096.29 0 1.273.058 1.676.058s1.85-.113 2.256-.113c.404 0 .696-.05 1.16
  0 .46.055
  1.27.327 1.505.44.23.11 1.62.604 2.14.767.52.16 1.795.382
  2.376.656.574.276.93.738
  1.216 1.122.292.383.345.8.46 1.076.112.274.116.863.005
  1.136-.117.272-.526.837
  -.526.837l-.637
  1.04-.81.653s-.58.553-1.04.495c-.465-.047-5.153-.877-8.163-.877
  -3.01 0-7.817.877-7.817.877"
      fill="#ad1519"
    />
    <path
      d="M171.727 227.575s-.523
  0-.81-.164c-.29-.166-1.156-.987-1.156-.987l-.694-.496
  -.633-.876s-.753-1.198-.408-2.133c.345-.93.925-1.262 1.448-1.534.52-.274
  1.616-.603
  1.616-.603s.87-.383 1.158-.44c.288-.055 1.333-.328
  1.333-.328s.29-.163.577-.273c.292
  -.11.695-.11.93-.168.23-.055.81-.24 1.155-.26.538-.02 1.39.096 1.68.096.29 0
  1.273.058
  1.676.058s1.85-.113 2.256-.113c.404 0 .696-.05 1.16 0 .46.055 1.27.327
  1.505.44.23.11
  1.62.604 2.14.767.52.16 1.795.382 2.376.656.574.276.93.738 1.216
  1.122.292.383.345.8.46
  1.076.112.274.116.863.005 1.136-.117.272-.526.837-.526.837l-.637
  1.04-.81.653s-.58.553
  -1.04.495c-.465-.047-5.153-.877-8.163-.877-3.01 0-7.817.877-7.817.877h.004z"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
      strokeLinejoin="round"
    />
    <path
      d="M178.166 220.858c0-1.414.633-2.556 1.412-2.556.78 0 1.413 1.142 1.413
  2.556 0
  1.408-.633 2.553-1.412 2.553-.78 0-1.412-1.144-1.412-2.552"
      fill="#c8b100"
    />
    <path
      d="M178.166 220.858c0-1.414.633-2.556 1.412-2.556.78 0 1.413 1.142 1.413
  2.556 0 1.408-.633 2.553-1.412 2.553-.78 0-1.412-1.144-1.412-2.552z"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M178.957 220.858c0-1.3.29-2.355.648-2.355.36 0 .65 1.055.65 2.355 0
  1.292-.29
  2.346-.65 2.346-.357 0-.648-1.054-.648-2.346"
      fill="#c8b100"
    />
    <path
      d="M178.957 220.858c0-1.3.29-2.355.648-2.355.36 0 .65 1.055.65 2.355 0
  1.292-.29
  2.346-.65 2.346-.357 0-.648-1.054-.648-2.346z"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M178.652 218.157c0-.49.423-.898.946-.898.522 0 .945.407.945.897 0
  .495
  -.423.89-.945.89-.523 0-.946-.395-.946-.89"
      fill="#c8b100"
    />
    <path
      d="M180.265 217.84v.597h-1.462v-.596h.48v-1.34h-.634v-.594h.632v
  -.58h.622v.58h.63v.593h-.63v1.34h.36"
      fill="#c8b100"
    />
    <path
      d="M180.265 217.84v.597h-1.462v-.596h.48v-1.34h-.634v-.594h.632v
  -.58h.622v.58h.63v.593h-.63v1.34h.36"
      fill="none"
      stroke="#000"
      strokeWidth=".307"
    />
    <path
      d="M180.92 217.84v.597h-2.597v-.596h.96v-1.34h-.634v-.594h.632v
  -.58h.622v.58h.63v.593h-.63v1.34h1.015"
      fill="#c8b100"
    />
    <path
      d="M180.92 217.84v.597h-2.597v-.596h.96v-1.34h-.634v-.594h.632v
  -.58h.622v.58h.63v.593h-.63v1.34h1.015"
      fill="none"
      stroke="#000"
      strokeWidth=".307"
    />
    <path
      d="M179.87 217.296a.9.9 0 0 1 .673.86c0 .496-.423.892-.945.892
  -.523 0-.946-.396-.946-.89 0-.415.296-.768.7-.866"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M179.6 227.41h-4.922l-.113-1.206-.232-1.258-.245-1.57c-1.356
  -1.79-2.604-2.965-3.022-2.713.1-.33.22-.58.48-.73 1.206-.718 3.693 1.002
  5.564
  3.827.168.258.33.516.472.776h4.066c.146-.255.307-.513.476-.776 1.867-2.825
  4.357-4.545 5.56-3.828.26.152.38.4.483.73-.42-.247-1.666.923-3.03 2.713l-.24
  1.57-.232 1.26-.11 1.206h-4.95"
      fill="#c8b100"
    />
    <path
      d="M179.6
  227.41h-4.922l-.113-1.206-.232-1.258-.245-1.57c-1.356-1.79-2.604
  -2.965-3.022-2.713.1-.33.22-.58.48-.73 1.206-.718 3.693 1.002 5.564
  3.827.168.258.33.516.472.776h4.066c.146-.255.307-.513.476-.776 1.867-2.825
  4.357-4.545 5.56-3.828.26.152.38.4.483.73-.42-.247-1.666.923-3.03 2.713l-.24
  1.57-.232 1.26-.11 1.206H179.6z"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M171.282 220.616c.924-.546 3.086 1.167 4.84 3.827m11.826-3.827c-.928
  -.546-3.086 1.167-4.84 3.827"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M172.33 229.633a4.794 4.794 0 0 0-.592-1.11c1.993-.585 4.77-.952
  7.855
  -.96 3.084.008 5.883.375 7.872.96 0
  0-.223.393-.53.94-.174.3-.4.827-.384.827-1.8
  -.552-4.12-.835-6.972-.838-2.85.003-5.59.352-7.018.875.015
  0-.1-.33-.242-.694h.01"
      fill="#c8b100"
    />
    <path
      d="M172.33 229.633a4.794 4.794 0 0 0-.592-1.11c1.993-.585 4.77-.952
  7.855-.96 3.084.008 5.883.375 7.872.96 0
  0-.223.393-.53.94-.174.3-.4.827-.384.827-1.8
  -.552-4.12-.835-6.972-.838-2.85.003-5.59.352-7.018.875.015
  0-.1-.33-.242-.694h.01"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M179.593 232.167c2.49-.004 5.233-.382 6.244-.643.68-.197 1.074-.502
  1.002-.855-.036-.168-.18-.313-.378-.397-1.49-.476-4.166-.814-6.87-.818-2.698.004
  -5.393.342-6.88.818-.194.084-.34.23-.375.396-.073.352.323.657.998.854
  1.014.26 3.77.64 6.258.643"
      fill="#c8b100"
    />
    <path
      d="M179.593 232.167c2.49-.004 5.233-.382 6.244-.643.68-.197 1.074-.502
  1.002-.855-.036-.168-.18-.313-.378-.397-1.49-.476-4.166-.814-6.87-.818-2.698.004
  -5.393.342-6.88.818-.194.084-.34.23-.375.396-.073.352.323.657.998.854
  1.014.26
  3.77.64 6.258.643z"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M187.592
  227.41l-.61-.547s-.577.355-1.303.246c-.723-.11-.956-.985-.956
  -.985s-.81.68-1.475.63c-.665-.06-1.1-.63-1.1-.63s-.72.515-1.358.465c-.637-.06
  -1.245-.85-1.245-.85s-.637.82-1.273.878c-.638.052-1.16-.553-1.16-.553s-.288.605
  -1.1.74c-.808.137-1.5-.63-1.5-.63s-.464.74-1.013.934c-.548.19-1.274-.278-1.274
  -.278s-.116.277-.2.44c-.088.165-.318.194-.318.194l.18.49c1.984-.574
  4.672-.93
  7.713-.933 3.046.003 5.807.36 7.796.938l.203-.55"
      fill="#c8b100"
    />
    <path
      d="M187.592
  227.41l-.61-.547s-.577.355-1.303.246c-.723-.11-.956-.985-.956
  -.985s-.81.68-1.475.63c-.665-.06-1.1-.63-1.1-.63s-.72.515-1.358.465c-.637-.06
  -1.245-.85-1.245-.85s-.637.82-1.273.878c-.638.052-1.16-.553-1.16-.553s-.288.605
  -1.1.74c-.808.137-1.5-.63-1.5-.63s-.464.74-1.013.934c-.548.19-1.274-.278-1.274
  -.278s-.116.277-.2.44c-.088.165-.318.194-.318.194l.18.49c1.984-.574
  4.672-.93
  7.713-.933 3.046.003 5.807.36 7.796.938l.203-.55h-.008z"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M179.61 224.76l.287.052c-.047.12-.058.246-.058.38 0 .597.51 1.08 1.144
    1.08.506 0 .933-.312 1.082-.744.02.01.112-.393.158-.386.034 0 .03.42.05.41.07.542.568.914
    1.13.914.628 0 1.133-.483 1.133-1.08 0-.044 0-.088-.006-.13l.36-.358.194.455a.923.923 0 0
    0-.107.47c0 .57.488 1.032 1.086 1.032.38 0 .71-.185.906-.462l.23-.29-.004.36c0 .355.153.676.504.734
    0 0 .394.03.933-.393.53-.418.82-.767.82-.767l.035.43s-.522.857-.998 1.13c-.258.152-.658.313-.972.257
    -.33-.05-.567-.32-.69-.624a1.59 1.59 0 0 1-.815.22c-.645 0-1.225-.355-1.454-.882a1.59 1.59 0 0 1-1.192.512c
    -.515 0-.987-.23-1.282-.59a1.65 1.65 0 0 1-1.12.435c-.57 0-1.08-.285-1.368-.703-.29.418-.795.703-1.363.703a1.64
    1.64 0 0 1-1.12-.434c-.296.36-.77.59-1.285.59a1.59 1.59 0 0 1-1.19-.512c-.23.524-.81.883-1.454.883-.3 0-.58-.08
    -.815-.22-.12.305-.36.574-.69.624-.314.056-.71-.105-.97-.258-.477-.272-1.038-1.13-1.038-1.13l.073
    -.43s.296.35.822.768c.535.422.933.393.933.393.35-.058.504-.38.504-.734l-.004-.36.23.29c.195.277.527.462.905.462.6
    0 1.087-.462 1.087-1.032a.965.965 0 0 0-.103-.47l.19-.455.357.358a.808.808 0 0 0-.01.13c0 .597.507 1.08 1.14 1.08.56
    0 1.056-.372 1.13-.913.015.007.01-.41.048-.41.047-.008.143.396.158.385.15.432.58.745 1.087.745.628 0
    1.14-.484 1.14-1.08a.98.98 0 0 0-.058-.38l.3-.053"
      fill="#c8b100"
    />
    <path
      d="M179.61 224.76l.287.052c-.047.12-.058.246-.058.38 0 .597.51 1.08 1.144 1.08.506
    0 .933-.312 1.082-.744.02.01.112-.393.158-.386.034 0 .03.42.05.41.07.542.568.914 1.13.914.628
    0 1.133-.483 1.133-1.08 0-.044 0-.088-.006-.13l.36-.358.194.455a.923.923 0 0 0-.107.47c0
    .57.488 1.032 1.086 1.032.38 0 .71-.185.906-.462l.23-.29-.004.36c0 .355.153.676.504.734 0
    0 .394.03.933-.393.53-.418.82-.767.82-.767l.035.43s-.522.857-.998 1.13c-.258.152-.658.313
    -.972.257-.33-.05-.567-.32-.69-.624a1.59 1.59 0 0 1-.815.22c-.645 0-1.225-.355-1.454-.882a1.59
    1.59 0 0 1-1.192.512c-.515 0-.987-.23-1.282-.59a1.65 1.65 0 0 1-1.12.435c-.57 0-1.08-.285-1.368
    -.703-.29.418-.795.703-1.363.703a1.64 1.64 0 0 1-1.12-.434c-.296.36-.77.59-1.285.59a1.59 1.59
    0 0 1-1.19-.512c-.23.524-.81.883-1.454.883-.3 0-.58-.08-.815-.22-.12.305-.36.574-.69.624-.314.056
    -.71-.105-.97-.258-.477-.272-1.038-1.13-1.038-1.13l.073-.43s.296.35.822.768c.535.422.933.393.933.393.35
    -.058.504-.38.504-.734l-.004-.36.23.29c.195.277.527.462.905.462.6 0 1.087-.462 1.087-1.032a.965.965
    0 0 0-.103-.47l.19-.455.357.358a.808.808 0 0 0-.01.13c0 .597.507 1.08 1.14 1.08.56 0 1.056-.372 1.13
    -.913.015.007.01-.41.048-.41.047-.008.143.396.158.385.15.432.58.745 1.087.745.628 0 1.14-.484 1.14
    -1.08a.98.98 0 0 0-.058-.38l.3-.053h.008z"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M179.593
  227.568c-3.086.003-5.862.37-7.85.96-.136.043-.3-.06-.343-.18-.043-.125.054-.282.185-.326
  2-.61 4.844-.996 8.008-1 3.16.004 6.017.39 8.017 1
  .13.044.227.2.185.327-.038.12-.207.22-.338.178-1.992-.59-4.78-.957-7.864-.96"
      fill="#c8b100"
    />
    <path
      d="M179.593
  227.568c-3.086.003-5.862.37-7.85.96-.136.043-.3-.06-.343-.18-.043-.125.054-.282.185-.326
  2-.61 4.844-.996 8.008-1 3.16.004 6.017.39 8.017 1
  .13.044.227.2.185.327-.038.12-.207.22-.338.178-1.992-.59-4.78-.957-7.864-.96z"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
      strokeLinejoin="round"
    />
    <path
      d="M176.606 228.662c0-.232.2-.422.45-.422.246 0 .444.19.444.422 0
  .236-.198.42-.445.42-.25 0-.45-.184-.45-.42"
      fill="#fff"
    />
    <path
      d="M176.606 228.662c0-.232.2-.422.45-.422.246 0 .444.19.444.422 0
  .236-.198.42-.445.42-.25 0-.45-.184-.45-.42z"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M179.628 228.845h-.99c-.184 0-.338-.146-.338-.32
  0-.173.15-.313.33-.313h2.01a.32.32 0 0 1 .333.312.33.33 0 0 1-.335.32h-1.01"
      fill="#ad1519"
    />
    <path
      d="M179.628 228.845h-.99c-.184 0-.338-.146-.338-.32
  0-.173.15-.313.33-.313h2.01a.32.32 0 0 1 .333.312.33.33 0 0 1-.335.32h-1.01"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M174.656 229.186l-.71.105c-.18.027-.357-.09-.384-.264a.316.316 0 0 1
  .277-.357l.712-.106.73-.113c.18-.02.35.092.38.263.023.17-.1.334-.28.36l-.726.113"
      fill="#058e6e"
    />
    <path
      d="M174.656 229.186l-.71.105c-.18.027-.357-.09-.384-.264a.316.316 0 0 1
  .277-.357l.712-.106.73-.113c.18-.02.35.092.38.263.023.17-.1.334-.28.36l-.726.113"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path d="M171.788 229.683l.315-.508.672.127-.393.572-.594-.19" fill="#ad1519" />
    <path
      d="M171.788 229.683l.315-.508.672.127-.393.572-.594-.19"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M181.69 228.662c0-.232.2-.422.45-.422.245 0 .445.19.445.422 0
  .236-.2.42-.446.42-.25 0-.45-.184-.45-.42"
      fill="#fff"
    />
    <path
      d="M181.69 228.662c0-.232.2-.422.45-.422.245 0 .445.19.445.422 0
  .236-.2.42-.446.42-.25 0-.45-.184-.45-.42z"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M184.534 229.186l.715.105c.18.027.35-.09.38-.264a.313.313 0 0
  0-.273-.357l-.718-.106-.727-.113c-.185-.02-.353.092-.38.263-.03.17.096.334.28.36l.72.113"
      fill="#058e6e"
    />
    <path
      d="M184.534 229.186l.715.105c.18.027.35-.09.38-.264a.313.313 0 0
  0-.273-.357l-.718-.106-.727-.113c-.185-.02-.353.092-.38.263-.03.17.096.334.28.36l.72.113"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path d="M187.345 229.713l-.253-.542-.69.06.33.606.613-.123" fill="#ad1519" />
    <path
      d="M187.345 229.713l-.253-.542-.69.06.33.606.613-.123"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M179.585 231.59c-2.49-.004-4.744-.222-6.457-.666 1.713-.44 3.967-.712
  6.457-.72 2.493.003 4.758.277 6.475.72-1.717.444-3.982.662-6.475.665"
      fill="#ad1519"
    />
    <path
      d="M179.585 231.59c-2.49-.004-4.744-.222-6.457-.666 1.713-.44 3.967-.712
  6.457-.72 2.493.003 4.758.277 6.475.72-1.717.444-3.982.662-6.475.665z"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
      strokeLinejoin="round"
    />
    <path
      d="M187.446
  226.186c.064-.192.004-.383-.135-.43-.137-.04-.306.085-.37.27-.067.196-.01.39.133.433.138.04.303-.08.373-.274"
      fill="#c8b100"
    />
    <path
      d="M187.446
  226.186c.064-.192.004-.383-.135-.43-.137-.04-.306.085-.37.27-.067.196-.01.39.133.433.138.04.303-.08.373-.274z"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M182.485
  225.227c.023-.2-.073-.38-.22-.398-.145-.018-.284.135-.306.334-.028.2.07.378.214.397.146.015.285-.138.31-.333"
      fill="#c8b100"
    />
    <path
      d="M182.485
  225.227c.023-.2-.073-.38-.22-.398-.145-.018-.284.135-.306.334-.028.2.07.378.214.397.146.015.285-.138.31-.333z"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M176.72
  225.227c-.02-.2.075-.38.22-.398.147-.018.285.135.312.334.023.2-.073.378-.22.397-.142.015-.284-.138-.31-.333"
      fill="#c8b100"
    />
    <path
      d="M176.72
  225.227c-.02-.2.075-.38.22-.398.147-.018.285.135.312.334.023.2-.073.378-.22.397-.142.015-.284-.138-.31-.333z"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M171.76
  226.186c-.064-.192-.003-.383.136-.43.138-.04.306.085.372.27.065.196.007.39-.135.433-.138.04-.304-.08-.372-.274"
      fill="#c8b100"
    />
    <path
      d="M171.76
  226.186c-.064-.192-.003-.383.136-.43.138-.04.306.085.372.27.065.196.007.39-.135.433-.138.04-.304-.08-.372-.274z"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path d="M179.585 222.36l-.878.53.653 1.415.225.15.224-.15.655-1.414-.88-.53" fill="#c8b100" />
    <path
      d="M179.585 222.36l-.878.53.653 1.415.225.15.224-.15.655-1.414-.88-.53"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path d="M177.662 224.5l.4.58 1.374-.422.142-.19-.146-.2-1.37-.4-.4.63" fill="#c8b100" />
    <path
      d="M177.662 224.5l.4.58 1.374-.422.142-.19-.146-.2-1.37-.4-.4.63"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path d="M181.52 224.5l-.398.58-1.375-.422-.146-.19.15-.2 1.372-.4.4.63" fill="#c8b100" />
    <path
      d="M181.52 224.5l-.398.58-1.375-.422-.146-.19.15-.2 1.372-.4.4.63"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path d="M173.872 222.99l-.69.65.882 1.165.235.093.17-.178.31-1.406-.908-.324" fill="#c8b100" />
    <path
      d="M173.872 222.99l-.69.65.882 1.165.235.093.17-.178.31-1.406-.908-.324"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M172.424 225.324l.52.486
  1.25-.675.097-.217-.18-.165-1.432-.123-.254.694"
      fill="#c8b100"
    />
    <path
      d="M172.424 225.324l.52.486
  1.25-.675.097-.217-.18-.165-1.432-.123-.254.694"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M176.204 224.564l-.265.643-1.438-.13-.184-.165.1-.22
  1.256-.662.53.534"
      fill="#c8b100"
    />
    <path
      d="M176.204 224.564l-.265.643-1.438-.13-.184-.165.1-.22
  1.256-.662.53.534"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path d="M171.043 225.505l-.116.68-1.43.15-.216-.124.047-.23 1.08-.896.636.42" fill="#c8b100" />
    <path
      d="M171.043 225.505l-.116.68-1.43.15-.216-.124.047-.23 1.08-.896.636.42"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M173.808 224.92c0-.265.226-.48.503-.48s.503.215.503.48a.492.492 0 0
  1-.502.477.492.492 0 0 1-.502-.476"
      fill="#c8b100"
    />
    <path
      d="M173.808 224.92c0-.265.226-.48.503-.48s.503.215.503.48a.492.492 0 0
  1-.502.477.492.492 0 0 1-.502-.476z"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M185.327 222.99l.69.65-.884
  1.165-.237.093-.168-.178-.31-1.406.91-.324"
      fill="#c8b100"
    />
    <path
      d="M185.327 222.99l.69.65-.884
  1.165-.237.093-.168-.178-.31-1.406.91-.324"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M186.77 225.324l-.518.486-1.253-.675-.1-.217.188-.165
  1.433-.123.25.694"
      fill="#c8b100"
    />
    <path
      d="M186.77 225.324l-.518.486-1.253-.675-.1-.217.188-.165
  1.433-.123.25.694"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M182.995 224.564l.265.643
  1.428-.13.19-.165-.105-.22-1.25-.662-.528.534"
      fill="#c8b100"
    />
    <path
      d="M182.995 224.564l.265.643
  1.428-.13.19-.165-.105-.22-1.25-.662-.528.534"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path d="M187.955 225.505l.12.68 1.433.15.214-.124-.05-.23-1.078-.896-.64.42" fill="#c8b100" />
    <path
      d="M187.955 225.505l.12.68 1.433.15.214-.124-.05-.23-1.078-.896-.64.42"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M179.09 224.47c0-.27.228-.48.503-.48.28 0 .504.21.504.48a.49.49 0 0
  1-.504.476c-.275 0-.502-.215-.502-.476"
      fill="#c8b100"
    />
    <path
      d="M179.09 224.47c0-.27.228-.48.503-.48.28 0 .504.21.504.48a.49.49 0 0
  1-.504.476c-.275 0-.502-.215-.502-.476z"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M184.393 224.92c0-.265.226-.48.503-.48a.49.49 0 0 1 .503.48.49.49 0 0
  1-.504.477.492.492 0 0 1-.503-.476"
      fill="#c8b100"
    />
    <path
      d="M184.393 224.92c0-.265.226-.48.503-.48a.49.49 0 0 1 .503.48.49.49 0 0
  1-.504.477.492.492 0 0 1-.503-.476z"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M169.108
  226.302c-.016.01-.387-.495-.672-.75-.204-.177-.69-.33-.69-.33
  0-.09.283-.294.594-.294.184 0
  .357.075.456.204l.042-.197s.25.047.366.328c.116.29.042.73.042.73s-.046.203-.138.31"
      fill="#c8b100"
    />
    <path
      d="M169.108
  226.302c-.016.01-.387-.495-.672-.75-.204-.177-.69-.33-.69-.33
  0-.09.283-.294.594-.294.184 0
  .357.075.456.204l.042-.197s.25.047.366.328c.116.29.042.73.042.73s-.046.203-.138.31z"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M169.127
  226.05c.12-.123.365-.097.545.06.184.156.24.384.12.512-.12.127-.366.1-.546-.058-.184-.157-.238-.39-.12-.513"
      fill="#c8b100"
    />
    <path
      d="M169.127
  226.05c.12-.123.365-.097.545.06.184.156.24.384.12.512-.12.127-.366.1-.546-.058-.184-.157-.238-.39-.12-.513z"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M189.895 226.302c.01.01.388-.495.673-.75.194-.177.69-.33.69-.33
  0-.09-.288-.294-.6-.294a.59.59 0 0
  0-.456.204l-.042-.197s-.25.047-.364.328c-.112.29-.04.73-.04.73s.044.203.14.31"
      fill="#c8b100"
    />
    <path
      d="M189.895 226.302c.01.01.388-.495.673-.75.194-.177.69-.33.69-.33
  0-.09-.288-.294-.6-.294a.59.59 0 0
  0-.456.204l-.042-.197s-.25.047-.364.328c-.112.29-.04.73-.04.73s.044.203.14.31z"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M189.906
  226.05c-.118-.123-.364-.097-.548.06-.185.156-.238.384-.12.512.12.127.365.1.55-.058.184-.157.234-.39.118-.513"
      fill="#c8b100"
    />
    <path
      d="M189.906
  226.05c-.118-.123-.364-.097-.548.06-.185.156-.238.384-.12.512.12.127.365.1.55-.058.184-.157.234-.39.118-.513z"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path d="M168.236 237.946h22.822v-5.982h-22.822v5.982z" fill="#c8b100" />
    <path
      d="M168.236 237.946h22.822v-5.982h-22.822v5.982z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M170.63 241.926c.144-.057.248-.064.424-.064h17.09c.17 0
  .33.028.465.083-.585-.196-1.007-.726-1.007-1.353 0-.625.457-1.166
  1.048-1.37a1.798 1.798 0 0 1-.494.084h-17.102a1.51 1.51 0 0
  1-.48-.056l.093.015c.61.19.96.73.96 1.327 0 .574-.388 1.15-.996 1.334"
      fill="#c8b100"
    />
    <path
      d="M170.63 241.926c.144-.057.248-.064.424-.064h17.09c.17 0
  .33.028.465.083-.585-.196-1.007-.726-1.007-1.353 0-.625.457-1.166
  1.048-1.37a1.798 1.798 0 0 1-.494.084h-17.102a1.51 1.51 0 0
  1-.48-.056l.093.015c.61.19.96.73.96 1.327 0 .574-.388 1.15-.996 1.334z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
      strokeLinejoin="round"
    />
    <path
      d="M171.054 241.862h17.09c.58 0 1.048.36 1.048.8 0
  .443-.47.803-1.047.803h-17.09c-.58 0-1.05-.36-1.05-.804 0-.44.47-.798 1.05-.798"
      fill="#c8b100"
    />
    <path
      d="M171.054 241.862h17.09c.58 0 1.048.36 1.048.8 0
  .443-.47.803-1.047.803h-17.09c-.58 0-1.05-.36-1.05-.804 0-.44.47-.798
  1.05-.798z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M171.054 237.946h17.102c.576 0 1.05.305 1.05.676 0
  .378-.474.684-1.05.684h-17.102c-.58 0-1.048-.306-1.048-.684 0-.37.47-.676
  1.048-.676"
      fill="#c8b100"
    />
    <path
      d="M171.054 237.946h17.102c.576 0 1.05.305 1.05.676 0
  .378-.474.684-1.05.684h-17.102c-.58 0-1.048-.306-1.048-.684 0-.37.47-.676
  1.048-.676z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M195.577 338.612c-1.577
  0-2.983-.33-4.02-.883-1.03-.525-2.422-.845-3.958-.845-1.544
  0-2.973.324-4.002.85-1.033.543-2.46.877-4.02.877-1.578
  0-2.983-.367-4.02-.92-1.02-.502-2.388-.807-3.885-.807-1.547
  0-2.922.295-3.95.83-1.038.55-2.474.897-4.048.897v2.472c1.574 0 3.01-.36
  4.047-.905 1.03-.53 2.404-.833 3.95-.833 1.494 0 2.862.31 3.886.815 1.033.548
  2.442.922 4.02.922 1.56 0 2.987-.34 4.02-.878 1.03-.535 2.458-.86 4-.86 1.537 0
  2.93.325 3.96.852 1.036.552 2.42.886 4 .886l.02-2.472"
      fill="#005bbf"
    />
    <path
      d="M195.577 338.612c-1.577
  0-2.983-.33-4.02-.883-1.03-.525-2.422-.845-3.958-.845-1.544
  0-2.973.324-4.002.85-1.033.543-2.46.877-4.02.877-1.578
  0-2.983-.367-4.02-.92-1.02-.502-2.388-.807-3.885-.807-1.547
  0-2.922.295-3.95.83-1.038.55-2.474.897-4.048.897v2.472c1.574 0 3.01-.36
  4.047-.905 1.03-.53 2.404-.833 3.95-.833 1.494 0 2.862.31 3.886.815 1.033.548
  2.442.922 4.02.922 1.56 0 2.987-.34 4.02-.878 1.03-.535 2.458-.86 4-.86 1.537 0
  2.93.325 3.96.852 1.036.552 2.42.886 4 .886l.02-2.472z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M195.577 341.084c-1.577
  0-2.983-.334-4.02-.886-1.03-.527-2.422-.85-3.958-.85-1.544
  0-2.973.323-4.002.858-1.033.537-2.46.878-4.02.878-1.578
  0-2.983-.374-4.02-.922-1.02-.506-2.388-.815-3.885-.815-1.547
  0-2.922.302-3.95.832-1.038.545-2.474.904-4.048.904v2.466c1.574 0 3.01-.352
  4.047-.898 1.03-.54 2.404-.837 3.95-.837 1.494 0 2.862.31 3.886.815 1.033.55
  2.442.92 4.02.92 1.56 0 2.987-.34 4.02-.88 1.03-.53 2.458-.855 4-.855 1.537 0
  2.93.325 3.96.85 1.036.55 2.42.885 4 .885l.02-2.466"
      fill="#ccc"
    />
    <path
      d="M195.577 341.084c-1.577
  0-2.983-.334-4.02-.886-1.03-.527-2.422-.85-3.958-.85-1.544
  0-2.973.323-4.002.858-1.033.537-2.46.878-4.02.878-1.578
  0-2.983-.374-4.02-.922-1.02-.506-2.388-.815-3.885-.815-1.547
  0-2.922.302-3.95.832-1.038.545-2.474.904-4.048.904v2.466c1.574 0 3.01-.352
  4.047-.898 1.03-.54 2.404-.837 3.95-.837 1.494 0 2.862.31 3.886.815 1.033.55
  2.442.92 4.02.92 1.56 0 2.987-.34 4.02-.88 1.03-.53 2.458-.855 4-.855 1.537 0
  2.93.325 3.96.85 1.036.55 2.42.885 4 .885l.02-2.466"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M195.577 343.55c-1.577
  0-2.983-.334-4.02-.884-1.03-.526-2.422-.85-3.958-.85-1.544
  0-2.973.324-4.002.854-1.033.54-2.46.88-4.02.88-1.578
  0-2.983-.37-4.02-.92-1.02-.505-2.388-.815-3.885-.815-1.547
  0-2.922.298-3.95.837-1.038.546-2.474.898-4.048.898v2.465c1.574 0 3.01-.353
  4.047-.9 1.03-.532 2.404-.83 3.95-.83 1.494 0 2.862.31 3.886.81 1.033.553
  2.442.92 4.02.92 1.56 0 2.987-.337 4.02-.875 1.03-.53 2.458-.855 4-.855 1.537 0
  2.93.32 3.96.848 1.036.555 2.42.882 4 .882l.02-2.465"
      fill="#005bbf"
    />
    <path
      d="M195.577 343.55c-1.577
  0-2.983-.334-4.02-.884-1.03-.526-2.422-.85-3.958-.85-1.544
  0-2.973.324-4.002.854-1.033.54-2.46.88-4.02.88-1.578
  0-2.983-.37-4.02-.92-1.02-.505-2.388-.815-3.885-.815-1.547
  0-2.922.298-3.95.837-1.038.546-2.474.898-4.048.898v2.465c1.574 0 3.01-.353
  4.047-.9 1.03-.532 2.404-.83 3.95-.83 1.494 0 2.862.31 3.886.81 1.033.553
  2.442.92 4.02.92 1.56 0 2.987-.337 4.02-.875 1.03-.53 2.458-.855 4-.855 1.537 0
  2.93.32 3.96.848 1.036.555 2.42.882 4 .882l.02-2.465"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M195.56 348.485c-1.583
  0-2.966-.334-4.003-.887-1.03-.523-2.422-.845-3.958-.845-1.544
  0-2.973.322-4.002.852-1.033.54-2.46.88-4.02.88-1.578
  0-2.983-.372-4.02-.924-1.02-.5-2.388-.807-3.885-.807-1.547
  0-2.922.3-3.95.83-1.038.55-2.474.902-4.048.902v-2.454c1.574 0 3.01-.368
  4.047-.916 1.03-.53 2.404-.83 3.95-.83 1.494 0 2.862.31 3.886.81 1.033.554
  2.442.92 4.02.92 1.56 0 2.987-.336 4.02-.874 1.03-.53 2.458-.855 4-.855 1.537 0
  2.93.32 3.96.848 1.036.555 2.442.882 4.02.882l-.02 2.47"
      fill="#ccc"
    />
    <path
      d="M195.56 348.485c-1.583
  0-2.966-.334-4.003-.887-1.03-.523-2.422-.845-3.958-.845-1.544
  0-2.973.322-4.002.852-1.033.54-2.46.88-4.02.88-1.578
  0-2.983-.372-4.02-.924-1.02-.5-2.388-.807-3.885-.807-1.547
  0-2.922.3-3.95.83-1.038.55-2.474.902-4.048.902v-2.454c1.574 0 3.01-.368
  4.047-.916 1.03-.53 2.404-.83 3.95-.83 1.494 0 2.862.31 3.886.81 1.033.554
  2.442.92 4.02.92 1.56 0 2.987-.336 4.02-.874 1.03-.53 2.458-.855 4-.855 1.537 0
  2.93.32 3.96.848 1.036.555 2.442.882 4.02.882l-.02 2.47"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M195.56 350.953c-1.583
  0-2.966-.334-4.003-.882-1.03-.53-2.422-.854-3.958-.854-1.544
  0-2.973.326-4.002.858-1.033.537-2.46.88-4.02.88-1.578
  0-2.983-.375-4.02-.924-1.02-.505-2.388-.814-3.885-.814-1.547
  0-2.922.302-3.95.835-1.038.547-2.474.903-4.048.903v-2.447c1.574 0 3.01-.374
  4.047-.924 1.03-.53 2.404-.825 3.95-.825 1.494 0 2.862.305 3.886.808 1.033.548
  2.442.92 4.02.92 1.56 0 2.987-.34 4.02-.88 1.03-.53 2.458-.848 4-.848 1.537 0
  2.93.318 3.96.844 1.036.55 2.434.885 4.015.885l-.014 2.468"
      fill="#005bbf"
    />
    <path
      d="M195.56 350.953c-1.583
  0-2.966-.334-4.003-.882-1.03-.53-2.422-.854-3.958-.854-1.544
  0-2.973.326-4.002.858-1.033.537-2.46.88-4.02.88-1.578
  0-2.983-.375-4.02-.924-1.02-.505-2.388-.814-3.885-.814-1.547
  0-2.922.302-3.95.835-1.038.547-2.474.903-4.048.903v-2.447c1.574 0 3.01-.374
  4.047-.924 1.03-.53 2.404-.825 3.95-.825 1.494 0 2.862.305 3.886.808 1.033.548
  2.442.92 4.02.92 1.56 0 2.987-.34 4.02-.88 1.03-.53 2.458-.848 4-.848 1.537 0
  2.93.318 3.96.844 1.036.55 2.434.885 4.015.885l-.014 2.468z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M170.63 328.482c.057.208.132.41.132.633 0 1.498-1.293 2.69-2.875
  2.69h23.487c-1.583 0-2.877-1.192-2.877-2.69 0-.218.042-.425.097-.633a1.377 1.377
  0 0 1-.45.055h-17.09c-.138 0-.3-.015-.423-.055"
      fill="#c8b100"
    />
    <path
      d="M170.63 328.482c.057.208.132.41.132.633 0 1.498-1.293 2.69-2.875
  2.69h23.487c-1.583 0-2.877-1.192-2.877-2.69 0-.218.042-.425.097-.633a1.377 1.377
  0 0 1-.45.055h-17.09c-.138 0-.3-.015-.423-.055z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
      strokeLinejoin="round"
    />
    <path
      d="M171.054 326.93h17.09c.58 0 1.048.363 1.048.803 0
  .444-.47.804-1.047.804h-17.09c-.58 0-1.05-.36-1.05-.804 0-.44.47-.804 1.05-.804"
      fill="#c8b100"
    />
    <path
      d="M171.054 326.93h17.09c.58 0 1.048.363 1.048.803 0
  .444-.47.804-1.047.804h-17.09c-.58 0-1.05-.36-1.05-.804 0-.44.47-.804
  1.05-.804z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path d="M167.918 337.78h23.425v-5.975h-23.425v5.975z" fill="#c8b100" />
    <path
      d="M167.918 337.78h23.425v-5.975h-23.425v5.975z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M166.086 305.785c-2.318 1.338-3.89 2.708-3.635 3.392.128.63.864 1.098
  1.916 1.796 1.655 1.153 2.66 3.212 1.873 4.16 1.37-1.104 2.238-2.756 2.238-4.592
  0-1.92-.913-3.65-2.392-4.755"
      fill="#ad1519"
    />
    <path
      d="M166.086 305.785c-2.318 1.338-3.89 2.708-3.635 3.392.128.63.864 1.098
  1.916 1.796 1.655 1.153 2.66 3.212 1.873 4.16 1.37-1.104 2.238-2.756 2.238-4.592
  0-1.92-.913-3.65-2.392-4.755z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path d="M171.273 325.962h16.645v-81.548h-16.645v81.548z" fill="#ccc" />
    <path
      d="M183.138
  244.527v81.37m1.87-81.37v81.37m-13.735.065h16.645v-81.548h-16.645v81.548z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M204.92
  274.917c-3.633-1.5-9.807-2.614-16.89-2.846-2.443.02-5.165.248-7.976.717-9.955
  1.66-17.54 5.636-16.936 8.872.012.068.034.21.05.272 0
  0-3.73-8.41-3.793-8.73-.664-3.593 7.748-8.008 18.798-9.85a57.01 57.01 0 0 1
  9.787-.776c7.07 0 13.212.905 16.912 2.28l.047 10.06"
      fill="#ad1519"
    />
    <path
      d="M204.92
  274.917c-3.633-1.5-9.807-2.614-16.89-2.846-2.443.02-5.165.248-7.976.717-9.955
  1.66-17.54 5.636-16.936 8.872.012.068.034.21.05.272 0
  0-3.73-8.41-3.793-8.73-.664-3.593 7.748-8.008 18.798-9.85a57.01 57.01 0 0 1
  9.787-.776c7.07 0 13.212.905 16.912 2.28l.047 10.06"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
      strokeLinejoin="round"
    />
    <path
      d="M171.247 285.153c-4.615-.33-7.767-1.563-8.13-3.494-.283-1.54
  1.276-3.24 4.064-4.786 1.245.134 2.646.305 4.093.305l-.026 7.973"
      fill="#ad1519"
    />
    <path
      d="M171.247 285.153c-4.615-.33-7.767-1.563-8.13-3.494-.283-1.54
  1.276-3.24 4.064-4.786 1.245.134 2.646.305 4.093.305l-.026 7.973"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M187.948 278.92c2.884.437 5.05 1.153 6.128 2.034l.096.177c.514
  1.057-2.02 3.3-6.25 5.805l.026-8.014"
      fill="#ad1519"
    />
    <path
      d="M187.948 278.92c2.884.437 5.05 1.153 6.128 2.034l.096.177c.514
  1.057-2.02 3.3-6.25 5.805l.026-8.014"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M160.906 300.884c-.438-1.317 4.064-3.953 10.425-6.286 2.908-1.04
  5.307-2.125 8.282-3.437 8.836-3.905 15.363-8.39 14.56-10.024l-.088-.168c.472.382
  1.2 8.436 1.2 8.436.808 1.498-5.166 5.916-13.303 9.814-2.602 1.244-8.1
  3.276-10.695 4.185-4.642 1.61-9.257 4.648-8.834 5.774l-1.544-8.29"
      fill="#ad1519"
    />
    <path
      d="M160.906 300.884c-.438-1.317 4.064-3.953 10.425-6.286 2.908-1.04
  5.307-2.125 8.282-3.437 8.836-3.905 15.363-8.39 14.56-10.024l-.088-.168c.472.382
  1.2 8.436 1.2 8.436.808 1.498-5.166 5.916-13.303 9.814-2.602 1.244-8.1
  3.276-10.695 4.185-4.642 1.61-9.257 4.648-8.834 5.774l-1.544-8.29v-.003z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
      strokeLinejoin="round"
    />
    <path
      d="M170.126 271.006c2.035-.743 3.368-1.62
  2.714-3.223-.41-1.018-1.463-1.214-3.033-.64l-2.784 1.012 2.508
  6.178c.273-.124.545-.252.83-.357.28-.102.576-.18.863-.262l-1.097-2.705v-.004zm-1.21-2.982l.703-.255c.582-.215
  1.243.094 1.535.814.218.55.16 1.16-.51 1.588a4.69 4.69 0 0
  1-.72.335l-1.01-2.483m7.716-2.583c-.292.078-.584.166-.88.223-.295.06-.6.092-.898.132l1.443
  6.425
  4.488-.902c-.053-.127-.123-.262-.15-.393-.03-.134-.026-.284-.034-.418-.787.225-1.648.47-2.68.677l-1.286-5.745m8.987
  5.541c.847-2.33 1.872-4.564 2.88-6.825a5.394 5.394 0 0 1-1.109.072c-.535
  1.64-1.204 3.28-1.906
  4.913-.845-1.55-1.778-3.062-2.49-4.623-.348.045-.705.096-1.06.12-.347.023-.712.02-1.062.023
  1.295 2.108 2.542 4.21 3.725
  6.386.165-.03.334-.07.513-.08.166-.013.34.004.51.01m9.381-4.941c.156-.313.315-.62.49
  -.91-.24-.228-.982-.562-1.85-.65-1.83-.182-2.883.632-3.006
  1.738-.258 2.313 3.394 2.113 3.226
  3.647-.074.662-.776.93-1.52.858-.834-.084-1.44-.542-1.55-1.222l-.224-.02c-.12.402-.296.79-.486
  1.18.534.346 1.23.542 1.894.607 1.866.186 3.29-.562
  3.425-1.79.244-2.2-3.443-2.326-3.3-3.62.06-.545.48-.902 1.43-.807.684.07
  1.107.44 1.29.97l.182.02"
      fill="#c8b100"
    />
    <path
      d="M332.35
  225.713s-.754.793-1.304.905c-.548.11-1.243-.493-1.243-.493s-.495.515-1.102.653c
  -.605.138-1.448-.684-1.448-.684s-.578.822-1.097
  1.015c-.522.19-1.156-.247-1.156-.247s-.23.407-.665.632c-.184.087-.49-.058-.49
  -.058l-.613-.382-.695-.742-.636-.248s-.287-.93-.318-1.093c-.022-.164-.085-.58-.085-.58-.13-.663.895-1.435
  2.358-1.766.84-.196 1.573-.18 2.112-.015.584-.496 1.82-.842 3.275-.842 1.317 0
  2.473.28 3.11.712.63-.433 1.786-.712 3.107-.712 1.445 0 2.68.346
  3.266.843.54-.165 1.27-.177 2.116.016 1.458.33 2.487 1.103 2.356 1.767 0
  0-.06.415-.087.58-.03.162-.318 1.092-.318
  1.092l-.637.248-.696.742-.603.382s-.307.145-.49.058c-.436-.222-.67-.632-.67-.632s
  -.637.438-1.156.246c-.52-.194-1.1-1.016-1.1-1.016s-.838.822-1.45.684c-.605-.138
  -1.098-.653-1.098-.653s-.695.603-1.242.493c-.553-.112-1.297-.905-1.297-.905"
      fill="#ad1519"
    />
    <path
      d="M332.35
  225.713s-.754.793-1.304.905c-.548.11-1.243-.493-1.243-.493s-.495.515-1.102.653c
  -.605.138-1.448-.684-1.448-.684s-.578.822-1.097
  1.015c-.522.19-1.156-.247-1.156-.247s-.23.407-.665.632c-.184.087-.49-.058-.49
  -.058l-.613-.382-.695-.742-.636-.248s-.287-.93-.318-1.093c-.022-.164-.085-.58-.085-.58-.13-.663.895-1.435
  2.358-1.766.84-.196 1.573-.18 2.112-.015.584-.496 1.82-.842 3.275-.842 1.317 0
  2.473.28 3.11.712.63-.433 1.786-.712 3.107-.712 1.445 0 2.68.346
  3.266.843.54-.165 1.27-.177 2.116.016 1.458.33 2.487 1.103 2.356 1.767 0
  0-.06.415-.087.58-.03.162-.318 1.092-.318
  1.092l-.637.248-.696.742-.603.382s-.307.145-.49.058c-.436-.222-.67-.632-.67
  -.632s-.637.438-1.156.246c-.52-.194-1.1-1.016-1.1-1.016s-.838.822-1.45.684c-.605
  -.138-1.098-.653-1.098-.653s-.695.603-1.242.493c-.553-.112-1.297-.905-1.297-.905h-.004z"
      fill="none"
      stroke="#000"
      strokeWidth=".276"
    />
    <path
      d="M330.916 221.386c0-1.11.63-2.007 1.41-2.007.778 0 1.412.897 1.412
  2.006 0 1.113-.634 2.014-1.413 2.014-.778 0-1.41-.9-1.41-2.014"
      fill="#c8b100"
    />
    <path
      d="M330.916 221.386c0-1.11.63-2.007 1.41-2.007.778 0 1.412.897 1.412
  2.006 0 1.113-.634 2.014-1.413 2.014-.778 0-1.41-.9-1.41-2.014z"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M331.704 221.386c0-1.018.29-1.847.65-1.847.358 0 .647.828.647 1.846 0
  1.022-.288 1.85-.647 1.85-.358 0-.65-.828-.65-1.85"
      fill="#c8b100"
    />
    <path
      d="M331.704 221.386c0-1.018.29-1.847.65-1.847.358 0 .647.828.647 1.846 0
  1.022-.288 1.85-.647 1.85-.358 0-.65-.828-.65-1.85z"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M325.08 229.633a4.838 4.838 0 0 0-.597-1.11c1.995-.585 4.77-.952
  7.857-.96 3.088.008 5.882.375
  7.876.96l-.534.94c-.172.3-.4.827-.384.827-1.802-.552-4.12-.835-6.97-.838-2.852.003-5.594.352-7.022.875.02
  0-.1-.33-.242-.694h.015"
      fill="#c8b100"
    />
    <path
      d="M325.08 229.633a4.838 4.838 0 0 0-.597-1.11c1.995-.585 4.77-.952
  7.857-.96 3.088.008 5.882.375
  7.876.96l-.534.94c-.172.3-.4.827-.384.827-1.802-.552-4.12-.835-6.97-.838-2.852.003-5.594.352-7.022.875.02
  0-.1-.33-.242-.694h.015"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M332.34 232.167c2.492-.004 5.234-.382 6.248-.643.675-.197
  1.07-.502.997-.855-.034-.168-.18-.313-.377-.397-1.485-.476-4.164-.814-6.868
  -.818-2.698.004-5.39.342-6.88.818-.19.084-.342.23-.374.396-.075.352.32.657
  1 .854 1.01.26 3.765.64 6.254.643"
      fill="#c8b100"
    />
    <path
      d="M332.34 232.167c2.492-.004 5.234-.382 6.248-.643.675-.197
  1.07-.502.997-.855-.034-.168-.18-.313-.377-.397-1.485-.476-4.164-.814-6.868
  -.818-2.698.004-5.39.342-6.88.818-.19.084-.342.23-.374.396-.075.352.32.657
  1 .854 1.01.26 3.765.64 6.254.643z"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M338.38 222.285a.45.45 0 0 1 .46-.44c.258 0 .466.195.466.44 0
  .244-.208.436-.466.436a.448.448 0 0 1-.46-.435"
      fill="#fff"
    />
    <path
      d="M338.38 222.285a.45.45 0 0 1 .46-.44c.258 0 .466.195.466.44 0
  .244-.208.436-.466.436a.448.448 0 0 1-.46-.435zm-.26-1.535a.45.45 0 0 1
  .46-.44c.256 0 .465.196.465.44 0 .24-.21.437-.464.437a.45.45 0 0
  1-.46-.437zm-1.16-.96a.45.45 0 0 1 .465-.44c.252 0 .46.197.46.44 0
  .244-.208.44-.46.44a.45.45 0 0
  1-.465-.44zm-1.45-.463c0-.246.21-.443.466-.443a.45.45 0 0 1 .462.443.45.45 0 0
  1-.462.438c-.256 0-.465-.2-.465-.438zm-1.473.052a.45.45 0 0 1 .46-.44c.26 0
  .467.195.467.44 0 .242-.208.435-.466.435a.448.448 0 0 1-.46-.436z"
      fill="none"
      stroke="#000"
      strokeWidth=".215"
    />
    <path
      d="M342.957 225.266a2.92 2.92 0 0 0
  .225-1.102c0-1.628-1.292-2.952-2.893-2.952a2.82 2.82 0 0 0-1.41.378"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
      strokeLinecap="round"
    />
    <path
      d="M337.805 223.193c.15-.265.257-.59.257-.894
  0-1.175-1.214-2.128-2.707-2.128-.638 0-1.22.174-1.68.46"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M343.357 223.928c0-.24.21-.44.465-.44.256 0 .463.2.463.44 0
  .244-.207.437-.463.437-.254
  0-.465-.193-.465-.437zm-.175-1.615c0-.244.21-.436.465-.436.254 0
  .462.192.462.436 0 .24-.21.437-.463.437-.256
  0-.465-.196-.465-.437zm-1.04-1.235a.45.45 0 0 1 .462-.437c.257 0
  .464.197.464.438 0 .242-.207.44-.464.44a.45.45 0 0
  1-.46-.44zm-1.39-.66c0-.24.21-.435.465-.435.254 0 .46.196.46.435a.454.454 0 0
  1-.46.444.455.455 0 0
  1-.464-.444zm-1.477.06c0-.244.208-.44.464-.44s.465.196.465.44c0
  .24-.21.436-.466.436-.257 0-.465-.197-.465-.437z"
      fill="none"
      stroke="#000"
      strokeWidth=".215"
    />
    <path
      d="M340.337
  227.41l-.605-.547s-.58.355-1.305.246c-.722-.11-.953-.985-.953-.985s-.813.68-1.475.63c
  -.664-.06-1.098-.63-1.098-.63s-.727.515-1.363.465c-.638-.06-1.246-.85-1.246-.85s
  -.637.82-1.274.878c-.637.052-1.157-.553-1.157-.553s-.29.605-1.1.74c-.81.137-1.506
  -.63-1.506-.63s-.456.74-1.01.934c-.548.19-1.27-.278-1.27-.278s-.116.277-.204.44c
  -.088.165-.32.194-.32.194l.182.49c1.983-.574
  4.673-.93 7.714-.933 3.043.003 5.807.36 7.788.938l.204-.55"
      fill="#c8b100"
    />
    <path
      d="M340.337
  227.41l-.605-.547s-.58.355-1.305.246c-.722-.11-.953-.985-.953-.985s-.813.68-1.475.63c
  -.664-.06-1.098-.63-1.098-.63s-.727.515-1.363.465c-.638-.06-1.246-.85-1.246-.85s-.637.82
  -1.274.878c-.637.052-1.157-.553-1.157-.553s-.29.605-1.1.74c-.81.137-1.506-.63-1.506
  -.63s-.456.74-1.01.934c-.548.19-1.27-.278-1.27-.278s-.116.277-.204.44c-.088.165-.32.194-.32.194l.182.49c1.983-.574
  4.673-.93 7.714-.933 3.043.003 5.807.36 7.788.938l.204-.55h-.005z"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M325.315 222.285a.45.45 0 0 1 .464-.44.45.45 0 0 1 .46.44.448.448 0 0
  1-.46.436c-.26 0-.465-.19-.465-.435"
      fill="#fff"
    />
    <path
      d="M325.315 222.285a.45.45 0 0 1 .464-.44.45.45 0 0 1 .46.44.448.448 0 0
  1-.46.436c-.26 0-.465-.19-.465-.435zm.261-1.535a.45.45 0 0 1 .464-.44.45.45 0 0
  1 .46.44.45.45 0 0 1-.46.437c-.257
  0-.464-.198-.464-.437zm1.155-.96c0-.243.21-.44.466-.44.257 0 .465.197.465.44 0
  .244-.207.44-.464.44a.452.452 0 0
  1-.465-.44zm1.45-.463c0-.246.207-.443.463-.443.258 0 .465.197.465.443 0
  .238-.207.438-.465.438-.256 0-.464-.2-.464-.438zm1.477.052a.45.45 0 0 1
  .46-.44.45.45 0 0 1 .465.44c0 .242-.207.435-.465.435a.448.448 0 0 1-.46-.436z"
      fill="none"
      stroke="#000"
      strokeWidth=".215"
    />
    <path
      d="M321.66 225.266a2.98 2.98 0 0 1-.223-1.102c0-1.628 1.293-2.952
  2.893-2.952.512 0 .99.138 1.41.378"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
      strokeLinecap="round"
    />
    <path
      d="M326.85 223.157c-.15-.262-.293-.553-.293-.858 0-1.175 1.214-2.128
  2.707-2.128.634 0 1.222.174 1.683.46"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M320.335 223.928c0-.24.206-.44.465-.44.25 0 .46.2.46.44a.45.45 0 0
  1-.46.437c-.26 0-.465-.193-.465-.437zm.172-1.615c0-.244.21-.436.465-.436.258 0
  .465.192.465.436 0 .24-.207.437-.465.437-.254
  0-.465-.196-.465-.437zm1.045-1.235c0-.24.206-.437.465-.437a.45.45 0 0 1
  .46.438.45.45 0 0 1-.46.44.452.452 0 0 1-.465-.44zm1.39-.66a.45.45 0 0 1
  .46-.435c.258 0 .464.196.464.435a.454.454 0 0 1-.465.444c-.252
  0-.46-.2-.46-.444zm1.474.06c0-.244.207-.44.465-.44a.45.45 0 0 1 .46.44c0
  .24-.202.436-.46.436-.257 0-.464-.197-.464-.437z"
      fill="none"
      stroke="#000"
      strokeWidth=".215"
    />
    <path
      d="M332.356 224.76l.288.052c-.045.12-.055.246-.055.38 0 .597.51 1.08
  1.14 1.08.508 0 .938-.312 1.084-.744.018.01.11-.393.156-.386.043 0
  .036.42.046.41.076.542.572.914 1.133.914.63 0 1.138-.483 1.138-1.08a.737.737 0 0
  0-.01-.13l.36-.358.192.455a.955.955 0 0 0-.108.47c0 .57.49 1.032 1.088 1.032a1.1
  1.1 0 0 0 .906-.462l.23-.29-.004.36c0 .355.155.676.502.734 0 0
  .404.03.934-.393.534-.418.826-.767.826-.767l.03.43s-.522.857-.994
  1.13c-.26.152-.658.313-.97.257-.336-.05-.57-.32-.693-.624a1.59 1.59 0 0
  1-.817.22c-.645 0-1.224-.355-1.45-.882a1.6 1.6 0 0 1-1.196.512 1.67 1.67 0 0
  1-1.283-.59c-.29.27-.685.435-1.12.435a1.667 1.667 0 0
  1-1.366-.703c-.29.418-.79.703-1.364.703-.434 0-.828-.164-1.12-.434a1.66 1.66 0 0
  1-1.282.59c-.483 0-.895-.192-1.19-.512-.228.524-.81.883-1.452.883-.3
  0-.577-.08-.814-.22-.123.305-.36.574-.69.624-.316.056-.712-.105-.972-.258-.477
  -.272-1.04-1.13-1.04-1.13l.072-.43s.29.35.823.768c.532.422.935.393.935.393.35
  -.058.498-.38.498-.734v-.36l.23.29c.194.277.527.462.904.462.604
  0 1.09-.462 1.09-1.032a.955.955 0 0
  0-.107-.47l.192-.455.357.358c-.007.043-.007.086-.007.13 0 .597.507 1.08 1.13
  1.08.566 0 1.062-.372
  1.135-.913.015.007.01-.41.05-.41.05-.008.14.396.157.385.148.432.578.745
  1.085.745.63 0 1.14-.484 1.14-1.08 0-.135-.006-.26-.052-.38l.296-.053"
      fill="#c8b100"
    />
    <path
      d="M332.356 224.76l.288.052c-.045.12-.055.246-.055.38 0 .597.51 1.08
  1.14 1.08.508 0 .938-.312 1.084-.744.018.01.11-.393.156-.386.043 0
  .036.42.046.41.076.542.572.914 1.133.914.63 0 1.138-.483 1.138-1.08a.737.737 0 0
  0-.01-.13l.36-.358.192.455a.955.955 0 0 0-.108.47c0 .57.49 1.032 1.088 1.032a1.1
  1.1 0 0 0 .906-.462l.23-.29-.004.36c0 .355.155.676.502.734 0 0
  .404.03.934-.393.534-.418.826-.767.826-.767l.03.43s-.522.857-.994
  1.13c-.26.152-.658.313-.97.257-.336-.05-.57-.32-.693-.624a1.59 1.59 0 0
  1-.817.22c-.645 0-1.224-.355-1.45-.882a1.6 1.6 0 0 1-1.196.512 1.67 1.67 0 0
  1-1.283-.59c-.29.27-.685.435-1.12.435a1.667 1.667 0 0
  1-1.366-.703c-.29.418-.79.703-1.364.703-.434 0-.828-.164-1.12-.434a1.66 1.66 0 0
  1-1.282.59c-.483 0-.895-.192-1.19-.512-.228.524-.81.883-1.452.883-.3
  0-.577-.08-.814-.22-.123.305-.36.574-.69.624-.316.056-.712-.105-.972-.258-.477
  -.272-1.04-1.13-1.04-1.13l.072-.43s.29.35.823.768c.532.422.935.393.935.393.35
  -.058.498-.38.498-.734v-.36l.23.29c.194.277.527.462.904.462.604
  0 1.09-.462 1.09-1.032a.955.955 0 0
  0-.107-.47l.192-.455.357.358c-.007.043-.007.086-.007.13 0 .597.507 1.08 1.13
  1.08.566 0 1.062-.372
  1.135-.913.015.007.01-.41.05-.41.05-.008.14.396.157.385.148.432.578.745
  1.085.745.63 0 1.14-.484 1.14-1.08 0-.135-.006-.26-.052-.38l.296-.053h.006z"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M332.34
  227.568c-3.087.003-5.86.37-7.852.96-.133.043-.297-.06-.34-.18-.044-.125.054-.282.182-.326
  2.006-.61 4.847-.996 8.01-1 3.16.004 6.016.39 8.018 1
  .133.044.23.2.188.327-.042.12-.212.22-.34.178-1.995-.59-4.778-.957-7.866-.96"
      fill="#c8b100"
    />
    <path
      d="M332.34
  227.568c-3.087.003-5.86.37-7.852.96-.133.043-.297-.06-.34-.18-.044-.125.054-.282.182-.326
  2.006-.61 4.847-.996 8.01-1 3.16.004 6.016.39 8.018 1
  .133.044.23.2.188.327-.042.12-.212.22-.34.178-1.995-.59-4.778-.957-7.866-.96z"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M329.353 228.662c0-.232.2-.422.45-.422.246 0 .445.19.445.422 0
  .236-.2.42-.445.42-.25 0-.45-.184-.45-.42"
      fill="#fff"
    />
    <path
      d="M329.353 228.662c0-.232.2-.422.45-.422.246 0 .445.19.445.422 0
  .236-.2.42-.445.42-.25 0-.45-.184-.45-.42z"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M332.378 228.845h-.993c-.185 0-.34-.146-.34-.32
  0-.173.15-.313.336-.313h2.01a.32.32 0 0 1 .328.312c0 .175-.15.32-.333.32h-1.007"
      fill="#ad1519"
    />
    <path
      d="M332.378 228.845h-.993c-.185 0-.34-.146-.34-.32
  0-.173.15-.313.336-.313h2.01a.32.32 0 0 1 .328.312c0 .175-.15.32-.333.32h-1.007"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M327.404 229.186l-.71.105a.337.337 0 0 1-.385-.264.314.314 0 0 1
  .275-.357l.713-.106.73-.113c.182-.02.35.092.38.263.028.17-.1.334-.28.36l-.724.113"
      fill="#058e6e"
    />
    <path
      d="M327.404 229.186l-.71.105a.337.337 0 0 1-.385-.264.314.314 0 0 1
  .275-.357l.713-.106.73-.113c.182-.02.35.092.38.263.028.17-.1.334-.28.36l-.724.113"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path d="M324.535 229.683l.315-.508.676.127-.392.572-.6-.19" fill="#ad1519" />
    <path
      d="M324.535 229.683l.315-.508.676.127-.392.572-.6-.19"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M334.438 228.662c0-.232.202-.422.448-.422.247 0 .45.19.45.422 0
  .236-.203.42-.45.42-.246 0-.448-.184-.448-.42"
      fill="#fff"
    />
    <path
      d="M334.438 228.662c0-.232.202-.422.448-.422.247 0 .45.19.45.422 0
  .236-.203.42-.45.42-.246 0-.448-.184-.448-.42z"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M337.282 229.186l.715.105c.18.027.348-.09.38-.264a.312.312 0 0
  0-.272-.357l-.715-.106-.73-.113c-.184-.02-.354.092-.38.263-.03.17.1.334.28.36l.722.113"
      fill="#058e6e"
    />
    <path
      d="M337.282 229.186l.715.105c.18.027.348-.09.38-.264a.312.312 0 0
  0-.272-.357l-.715-.106-.73-.113c-.184-.02-.354.092-.38.263-.03.17.1.334.28.36l.722.113"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path d="M340.093 229.713l-.254-.542-.694.06.333.606.613-.123" fill="#ad1519" />
    <path
      d="M340.093 229.713l-.254-.542-.694.06.333.606.613-.123"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M332.336 231.59c-2.49-.004-4.745-.222-6.46-.666 1.715-.44 3.97-.712
  6.46-.72 2.493.003 4.758.277 6.47.72-1.712.444-3.977.662-6.47.665"
      fill="#ad1519"
    />
    <path
      d="M332.336 231.59c-2.49-.004-4.745-.222-6.46-.666 1.715-.44 3.97-.712
  6.46-.72 2.493.003 4.758.277 6.47.72-1.712.444-3.977.662-6.47.665z"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
      strokeLinejoin="round"
    />
    <path
      d="M340.193
  226.186c.065-.192.01-.383-.136-.43-.138-.04-.302.085-.367.27-.07.196-.01.39.13.433.138.04.307-.08.373-.274"
      fill="#c8b100"
    />
    <path
      d="M340.193
  226.186c.065-.192.01-.383-.136-.43-.138-.04-.302.085-.367.27-.07.196-.01.39.13.433.138.04.307-.08.373-.274z"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M335.23
  225.227c.028-.2-.07-.38-.217-.398-.142-.018-.285.135-.307.334-.027.2.07.378.215.397.146.015.285-.138.31-.333"
      fill="#c8b100"
    />
    <path
      d="M335.23
  225.227c.028-.2-.07-.38-.217-.398-.142-.018-.285.135-.307.334-.027.2.07.378.215.397.146.015.285-.138.31-.333z"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M329.473
  225.227c-.026-.2.068-.38.214-.398.147-.018.284.135.312.334.02.2-.074.378-.216.397-.146.015-.285-.138-.31-.333"
      fill="#c8b100"
    />
    <path
      d="M329.473
  225.227c-.026-.2.068-.38.214-.398.147-.018.284.135.312.334.02.2-.074.378-.216.397-.146.015-.285-.138-.31-.333z"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M324.512
  226.186c-.07-.192-.007-.383.13-.43.137-.04.307.085.373.27.064.196.007.39-.13.433-.143.04-.308-.08-.373-.274"
      fill="#c8b100"
    />
    <path
      d="M324.512
  226.186c-.07-.192-.007-.383.13-.43.137-.04.307.085.373.27.064.196.007.39-.13.433-.143.04-.308-.08-.373-.274z"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path d="M332.336 222.36l-.878.53.65 1.415.228.15.224-.15.656-1.414-.88-.53" fill="#c8b100" />
    <path
      d="M332.336 222.36l-.878.53.65 1.415.228.15.224-.15.656-1.414-.88-.53"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path d="M330.408 224.5l.402.58 1.37-.422.145-.19-.145-.2-1.37-.4-.402.63" fill="#c8b100" />
    <path
      d="M330.408 224.5l.402.58 1.37-.422.145-.19-.145-.2-1.37-.4-.402.63"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path d="M334.272 224.5l-.404.58-1.37-.422-.145-.19.145-.2 1.37-.4.404.63" fill="#c8b100" />
    <path
      d="M334.272 224.5l-.404.58-1.37-.422-.145-.19.145-.2 1.37-.4.404.63"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M326.618 222.99l-.69.65.884
  1.165.234.093.173-.178.31-1.406-.912-.324"
      fill="#c8b100"
    />
    <path
      d="M326.618 222.99l-.69.65.884
  1.165.234.093.173-.178.31-1.406-.912-.324"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M325.172 225.324l.518.486
  1.252-.675.095-.217-.18-.165-1.43-.123-.254.694"
      fill="#c8b100"
    />
    <path
      d="M325.172 225.324l.518.486
  1.252-.675.095-.217-.18-.165-1.43-.123-.254.694"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M328.95 224.564l-.264.643-1.434-.13-.186-.165.098-.22
  1.257-.662.53.534"
      fill="#c8b100"
    />
    <path
      d="M328.95 224.564l-.264.643-1.434-.13-.186-.165.098-.22
  1.257-.662.53.534"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M323.794 225.505l-.12.68-1.433.15-.216-.124.054-.23
  1.078-.896.638.42"
      fill="#c8b100"
    />
    <path
      d="M323.794 225.505l-.12.68-1.433.15-.216-.124.054-.23
  1.078-.896.638.42"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M326.555 224.92c0-.265.226-.48.503-.48a.49.49 0 0 1 .502.48.49.49 0 0
  1-.502.477.492.492 0 0 1-.503-.476"
      fill="#c8b100"
    />
    <path
      d="M326.555 224.92c0-.265.226-.48.503-.48a.49.49 0 0 1 .502.48.49.49 0 0
  1-.502.477.492.492 0 0 1-.503-.476z"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M338.073 222.99l.69.65-.883
  1.165-.234.093-.172-.178-.31-1.406.91-.324"
      fill="#c8b100"
    />
    <path
      d="M338.073 222.99l.69.65-.883
  1.165-.234.093-.172-.178-.31-1.406.91-.324"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M339.52 225.324l-.517.486-1.253-.675-.1-.217.185-.165
  1.432-.123.254.694"
      fill="#c8b100"
    />
    <path
      d="M339.52 225.324l-.517.486-1.253-.675-.1-.217.185-.165
  1.432-.123.254.694"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M335.742 224.564l.264.643
  1.434-.13.183-.165-.095-.22-1.255-.662-.53.534"
      fill="#c8b100"
    />
    <path
      d="M335.742 224.564l.264.643
  1.434-.13.183-.165-.095-.22-1.255-.662-.53.534"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M340.707 225.505l.115.68
  1.43.15.217-.124-.055-.23-1.075-.896-.633.42"
      fill="#c8b100"
    />
    <path
      d="M340.707 225.505l.115.68
  1.43.15.217-.124-.055-.23-1.075-.896-.633.42"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M331.842 224.47c0-.27.22-.48.503-.48.272 0 .502.21.502.48 0
  .26-.227.476-.502.476a.49.49 0 0 1-.503-.476"
      fill="#c8b100"
    />
    <path
      d="M331.842 224.47c0-.27.22-.48.503-.48.272 0 .502.21.502.48 0
  .26-.227.476-.502.476a.49.49 0 0 1-.503-.476z"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M337.14 224.92c0-.265.226-.48.503-.48.28 0 .508.215.508.48 0
  .262-.228.477-.507.477a.492.492 0 0 1-.503-.476"
      fill="#c8b100"
    />
    <path
      d="M337.14 224.92c0-.265.226-.48.503-.48.28 0 .508.215.508.48 0
  .262-.228.477-.507.477a.492.492 0 0 1-.503-.476z"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M331.4 219.09c0-.497.422-.896.945-.896.52 0 .948.4.948.895 0
  .492-.428.893-.948.893-.523 0-.945-.4-.945-.894"
      fill="#c8b100"
    />
    <path
      d="M333.012
  218.77v.594h-1.46v-.593h.478v-1.337h-.634v-.596h.634v-.585h.626v.585h.62v.596h-.62v1.338h.352"
      fill="#c8b100"
    />
    <path
      d="M333.012
  218.77v.594h-1.46v-.593h.478v-1.337h-.634v-.596h.634v-.585h.626v.585h.62v.596h-.62v1.338h.356z"
      fill="none"
      stroke="#000"
      strokeWidth=".307"
    />
    <path
      d="M333.666
  218.77v.594h-2.596v-.593h.96v-1.337h-.634v-.596h.634v-.585h.626v.585h.625v.596h-.624v1.338h1.01"
      fill="#c8b100"
    />
    <path
      d="M332.617 218.23a.9.9 0 0 1 .676.86c0 .492-.422.893-.948.893-.523
  0-.945-.4-.945-.894 0-.416.296-.762.7-.867"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M321.855 226.302c-.012.01-.388-.495-.672-.75-.2-.177-.69-.33-.69-.33
  0-.09.288-.294.6-.294.18 0
  .35.075.453.204l.036-.197s.254.047.365.328c.116.29.043.73.043.73s-.047.203-.135.31"
      fill="#c8b100"
    />
    <path
      d="M321.855 226.302c-.012.01-.388-.495-.672-.75-.2-.177-.69-.33-.69-.33
  0-.09.288-.294.6-.294.18 0
  .35.075.453.204l.036-.197s.254.047.365.328c.116.29.043.73.043.73s-.047.203-.135.31z"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M321.874
  226.05c.12-.123.365-.097.546.06.184.156.238.384.117.512-.117.127-.36.1-.543-.058-.185-.157-.24-.39-.12-.513"
      fill="#c8b100"
    />
    <path
      d="M321.874
  226.05c.12-.123.365-.097.546.06.184.156.238.384.117.512-.117.127-.36.1-.543-.058-.185-.157-.24-.39-.12-.513z"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M342.64 226.302c.016.01.388-.495.674-.75.196-.177.69-.33.69-.33
  0-.09-.287-.294-.598-.294a.59.59 0 0
  0-.457.204l-.04-.197s-.254.047-.364.328c-.115.29-.043.73-.043.73s.046.203.138.31"
      fill="#c8b100"
    />
    <path
      d="M342.64 226.302c.016.01.388-.495.674-.75.196-.177.69-.33.69-.33
  0-.09-.287-.294-.598-.294a.59.59 0 0
  0-.457.204l-.04-.197s-.254.047-.364.328c-.115.29-.043.73-.043.73s.046.203.138.31z"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M342.654
  226.05c-.12-.123-.364-.097-.55.06-.184.156-.233.384-.12.512.12.127.366.1.55-.058.184-.157.24-.39.12-.513"
      fill="#c8b100"
    />
    <path
      d="M342.654
  226.05c-.12-.123-.364-.097-.55.06-.184.156-.233.384-.12.512.12.127.366.1.55-.058.184-.157.24-.39.12-.513z"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path d="M320.95 237.946h22.82v-5.982h-22.82v5.982z" fill="#c8b100" />
    <path
      d="M320.95 237.946h22.82v-5.982h-22.82v5.982z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M341.375 241.926a1.007 1.007 0 0 0-.422-.064h-17.09c-.17
  0-.33.028-.472.083.59-.196 1.01-.726 1.01-1.353
  0-.625-.46-1.166-1.05-1.37.14.043.332.084.5.084h17.103c.17 0
  .334-.007.48-.056l-.097.015c-.61.19-.96.73-.96 1.327 0 .574.388 1.15 1 1.334"
      fill="#c8b100"
    />
    <path
      d="M341.375 241.926a1.007 1.007 0 0 0-.422-.064h-17.09c-.17
  0-.33.028-.472.083.59-.196 1.01-.726 1.01-1.353
  0-.625-.46-1.166-1.05-1.37.14.043.332.084.5.084h17.103c.17 0
  .334-.007.48-.056l-.097.015c-.61.19-.96.73-.96 1.327 0 .574.388 1.15 1 1.334z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
      strokeLinejoin="round"
    />
    <path
      d="M323.863 241.862h17.09c.575 0 1.045.36 1.045.8 0
  .443-.47.803-1.045.803h-17.09c-.58 0-1.05-.36-1.05-.804 0-.44.47-.798 1.05-.798"
      fill="#c8b100"
    />
    <path
      d="M323.863 241.862h17.09c.575 0 1.045.36 1.045.8 0
  .443-.47.803-1.045.803h-17.09c-.58 0-1.05-.36-1.05-.804 0-.44.47-.798
  1.05-.798z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M323.85 237.946h17.103c.575 0 1.045.305 1.045.676 0
  .378-.47.684-1.045.684H323.85c-.578 0-1.047-.306-1.047-.684 0-.37.47-.676
  1.048-.676"
      fill="#c8b100"
    />
    <path
      d="M323.85 237.946h17.103c.575 0 1.045.305 1.045.676 0
  .378-.47.684-1.045.684H323.85c-.578 0-1.047-.306-1.047-.684 0-.37.47-.676
  1.048-.676z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M316.426 338.612c1.582 0 2.985-.33 4.023-.883 1.03-.525 2.423-.845
  3.958-.845 1.54 0 2.972.324 4 .85 1.03.543 2.458.877 4.017.877 1.574 0
  2.987-.367 4.024-.92 1.02-.502 2.383-.807 3.884-.807 1.548 0 2.918.295 3.95.83
  1.034.55 2.473.897 4.047.897v2.472c-1.573
  0-3.012-.36-4.045-.905-1.033-.53-2.403-.833-3.95-.833-1.502
  0-2.865.31-3.886.815-1.033.548-2.45.922-4.025.922-1.555
  0-2.983-.34-4.016-.878-1.03-.535-2.458-.86-4.002-.86-1.535
  0-2.93.325-3.96.852-1.037.552-2.42.886-4 .886l-.02-2.472"
      fill="#005bbf"
    />
    <path
      d="M316.426 338.612c1.582 0 2.985-.33 4.023-.883 1.03-.525 2.423-.845
  3.958-.845 1.54 0 2.972.324 4 .85 1.03.543 2.458.877 4.017.877 1.574 0
  2.987-.367 4.024-.92 1.02-.502 2.383-.807 3.884-.807 1.548 0 2.918.295 3.95.83
  1.034.55 2.473.897 4.047.897v2.472c-1.573
  0-3.012-.36-4.045-.905-1.033-.53-2.403-.833-3.95-.833-1.502
  0-2.865.31-3.886.815-1.033.548-2.45.922-4.025.922-1.555
  0-2.983-.34-4.016-.878-1.03-.535-2.458-.86-4.002-.86-1.535
  0-2.93.325-3.96.852-1.037.552-2.42.886-4 .886l-.02-2.472z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M316.426 341.084c1.582 0 2.985-.334 4.023-.886 1.03-.527 2.423-.85
  3.958-.85 1.54 0 2.972.323 4 .858 1.03.537 2.458.878 4.017.878 1.574 0
  2.987-.374 4.024-.922 1.02-.506 2.383-.815 3.884-.815 1.548 0 2.918.302 3.95.832
  1.034.545 2.473.904 4.047.904v2.466c-1.573
  0-3.012-.352-4.045-.898-1.033-.54-2.403-.837-3.95-.837-1.502
  0-2.865.31-3.886.815-1.033.55-2.45.92-4.025.92-1.555
  0-2.983-.34-4.016-.88-1.03-.53-2.458-.855-4.002-.855-1.535
  0-2.93.325-3.96.85-1.037.55-2.42.885-4 .885l-.02-2.466"
      fill="#ccc"
    />
    <path
      d="M316.426 341.084c1.582 0 2.985-.334 4.023-.886 1.03-.527 2.423-.85
  3.958-.85 1.54 0 2.972.323 4 .858 1.03.537 2.458.878 4.017.878 1.574 0
  2.987-.374 4.024-.922 1.02-.506 2.383-.815 3.884-.815 1.548 0 2.918.302 3.95.832
  1.034.545 2.473.904 4.047.904v2.466c-1.573
  0-3.012-.352-4.045-.898-1.033-.54-2.403-.837-3.95-.837-1.502
  0-2.865.31-3.886.815-1.033.55-2.45.92-4.025.92-1.555
  0-2.983-.34-4.016-.88-1.03-.53-2.458-.855-4.002-.855-1.535
  0-2.93.325-3.96.85-1.037.55-2.42.885-4 .885l-.02-2.466"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M316.426 343.55c1.582 0 2.985-.334 4.023-.884 1.03-.526 2.423-.85
  3.958-.85 1.54 0 2.972.324 4 .854 1.03.54 2.458.88 4.017.88 1.574 0 2.987-.37
  4.024-.92 1.02-.505 2.383-.815 3.884-.815 1.548 0 2.918.298 3.95.837 1.034.546
  2.473.898 4.047.898v2.465c-1.573
  0-3.012-.353-4.045-.9-1.033-.532-2.403-.83-3.95-.83-1.502
  0-2.865.31-3.886.81-1.033.553-2.45.92-4.025.92-1.555
  0-2.983-.337-4.016-.875-1.03-.53-2.458-.855-4.002-.855-1.535
  0-2.93.32-3.96.848-1.037.555-2.42.882-4 .882l-.02-2.465"
      fill="#005bbf"
    />
    <path
      d="M316.426 343.55c1.582 0 2.985-.334 4.023-.884 1.03-.526 2.423-.85
  3.958-.85 1.54 0 2.972.324 4 .854 1.03.54 2.458.88 4.017.88 1.574 0 2.987-.37
  4.024-.92 1.02-.505 2.383-.815 3.884-.815 1.548 0 2.918.298 3.95.837 1.034.546
  2.473.898 4.047.898v2.465c-1.573
  0-3.012-.353-4.045-.9-1.033-.532-2.403-.83-3.95-.83-1.502
  0-2.865.31-3.886.81-1.033.553-2.45.92-4.025.92-1.555
  0-2.983-.337-4.016-.875-1.03-.53-2.458-.855-4.002-.855-1.535
  0-2.93.32-3.96.848-1.037.555-2.42.882-4 .882l-.02-2.465"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M316.45 348.485c1.577 0 2.96-.334 4-.887 1.03-.523 2.423-.845
  3.958-.845 1.54 0 2.972.322 4 .852 1.03.54 2.458.88 4.017.88 1.574 0 2.987-.372
  4.024-.924 1.02-.5 2.383-.807 3.884-.807 1.548 0 2.918.3 3.95.83 1.034.55
  2.473.902 4.047.902v-2.454c-1.573
  0-3.012-.368-4.045-.916-1.033-.53-2.403-.83-3.95-.83-1.502
  0-2.865.31-3.886.81-1.033.554-2.45.92-4.025.92-1.555
  0-2.983-.336-4.016-.874-1.03-.53-2.458-.855-4.002-.855-1.535
  0-2.93.32-3.96.848-1.037.555-2.44.882-4.022.882l.023 2.47"
      fill="#ccc"
    />
    <path
      d="M316.45 348.485c1.577 0 2.96-.334 4-.887 1.03-.523 2.423-.845
  3.958-.845 1.54 0 2.972.322 4 .852 1.03.54 2.458.88 4.017.88 1.574 0 2.987-.372
  4.024-.924 1.02-.5 2.383-.807 3.884-.807 1.548 0 2.918.3 3.95.83 1.034.55
  2.473.902 4.047.902v-2.454c-1.573
  0-3.012-.368-4.045-.916-1.033-.53-2.403-.83-3.95-.83-1.502
  0-2.865.31-3.886.81-1.033.554-2.45.92-4.025.92-1.555
  0-2.983-.336-4.016-.874-1.03-.53-2.458-.855-4.002-.855-1.535
  0-2.93.32-3.96.848-1.037.555-2.44.882-4.022.882l.023 2.47"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M316.45 350.953c1.577 0 2.96-.334 4-.882 1.03-.53 2.423-.854
  3.958-.854 1.54 0 2.972.326 4 .858 1.03.537 2.458.88 4.017.88 1.574 0 2.987-.375
  4.024-.924 1.02-.505 2.383-.814 3.884-.814 1.548 0 2.918.302 3.95.835 1.034.547
  2.473.903 4.047.903v-2.447c-1.573
  0-3.012-.374-4.045-.924-1.033-.53-2.403-.825-3.95-.825-1.502
  0-2.865.305-3.886.808-1.033.548-2.45.92-4.025.92-1.555
  0-2.983-.34-4.016-.88-1.03-.53-2.458-.848-4.002-.848-1.535
  0-2.93.318-3.96.844-1.037.55-2.436.885-4.014.885l.015 2.468"
      fill="#005bbf"
    />
    <path
      d="M316.45 350.953c1.577 0 2.96-.334 4-.882 1.03-.53 2.423-.854
  3.958-.854 1.54 0 2.972.326 4 .858 1.03.537 2.458.88 4.017.88 1.574 0 2.987-.375
  4.024-.924 1.02-.505 2.383-.814 3.884-.814 1.548 0 2.918.302 3.95.835 1.034.547
  2.473.903 4.047.903v-2.447c-1.573
  0-3.012-.374-4.045-.924-1.033-.53-2.403-.825-3.95-.825-1.502
  0-2.865.305-3.886.808-1.033.548-2.45.92-4.025.92-1.555
  0-2.983-.34-4.016-.88-1.03-.53-2.458-.848-4.002-.848-1.535
  0-2.93.318-3.96.844-1.037.55-2.436.885-4.014.885l.015 2.468z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M341.37 328.482c-.052.208-.125.41-.125.633 0 1.498 1.292 2.69 2.87
  2.69h-23.482c1.578 0 2.87-1.192 2.87-2.69a2.84 2.84 0 0
  0-.09-.633c.127.048.29.055.446.055h17.09c.136 0 .298-.015.417-.055"
      fill="#c8b100"
    />
    <path
      d="M341.37 328.482c-.052.208-.125.41-.125.633 0 1.498 1.292 2.69 2.87
  2.69h-23.482c1.578 0 2.87-1.192 2.87-2.69a2.84 2.84 0 0
  0-.09-.633c.127.048.29.055.446.055h17.09c.136 0 .298-.015.417-.055h.004z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
      strokeLinejoin="round"
    />
    <path
      d="M323.863 326.93h17.09c.575 0 1.045.363 1.045.803 0
  .444-.47.804-1.045.804h-17.09c-.58 0-1.05-.36-1.05-.804 0-.44.47-.804 1.05-.804"
      fill="#c8b100"
    />
    <path
      d="M323.863 326.93h17.09c.575 0 1.045.363 1.045.803 0
  .444-.47.804-1.045.804h-17.09c-.58 0-1.05-.36-1.05-.804 0-.44.47-.804
  1.05-.804z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path d="M320.66 337.78h23.427v-5.975H320.66v5.975z" fill="#c8b100" />
    <path
      d="M320.66 337.78h23.427v-5.975H320.66v5.975z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M345.917 305.785c2.32 1.338 3.894 2.708 3.64 3.392-.128.63-.864
  1.098-1.916 1.796-1.654 1.153-2.664 3.212-1.876
  4.16-1.367-1.104-2.23-2.756-2.23-4.592 0-1.92.91-3.65 2.383-4.755"
      fill="#ad1519"
    />
    <path
      d="M345.917 305.785c2.32 1.338 3.894 2.708 3.64 3.392-.128.63-.864
  1.098-1.916 1.796-1.654 1.153-2.664 3.212-1.876
  4.16-1.367-1.104-2.23-2.756-2.23-4.592 0-1.92.91-3.65 2.383-4.755z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path d="M324.086 325.962h16.644v-81.548h-16.644v81.548z" fill="#ccc" />
    <path
      d="M336.16
  244.385v81.373m1.87-81.373v81.373m-13.944.204h16.644v-81.548h-16.644v81.548z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M307.085 274.917c3.634-1.5 9.81-2.614 16.893-2.846 2.44.02 5.164.248
  7.974.717
  9.953 1.66 17.536 5.636 16.933 8.872-.012.068-.03.21-.05.272 0 0 3.735-8.41
  3.79-8.73.668
  -3.593-7.74-8.008-18.79-9.85a57.043 57.043 0 0 0-9.788-.776c-7.072
  0-13.216.905-16.916
  2.28l-.045 10.06"
      fill="#ad1519"
    />
    <path
      d="M307.085 274.917c3.634-1.5 9.81-2.614 16.893-2.846 2.44.02 5.164.248
  7.974.717
  9.953 1.66 17.536 5.636 16.933 8.872-.012.068-.03.21-.05.272 0 0 3.735-8.41
  3.79-8.73.668
  -3.593-7.74-8.008-18.79-9.85a57.043 57.043 0 0 0-9.788-.776c-7.072
  0-13.216.905-16.916
  2.28l-.045 10.06"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
      strokeLinejoin="round"
    />
    <path
      d="M340.76 285.153c4.616-.33 7.768-1.563
  8.125-3.494.29-1.54-1.275-3.24-4.06-4.786
  -1.246.134-2.648.305-4.095.305l.03 7.973"
      fill="#ad1519"
    />
    <path
      d="M340.76 285.153c4.616-.33 7.768-1.563
  8.125-3.494.29-1.54-1.275-3.24-4.06-4.786
  -1.246.134-2.648.305-4.095.305l.03 7.973"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M324.06 278.92c-2.888.437-5.05 1.153-6.133 2.034l-.096.177c-.518
  1.057 2.02 3.3
  6.252 5.805l-.023-8.014"
      fill="#ad1519"
    />
    <path
      d="M324.06 278.92c-2.888.437-5.05 1.153-6.133 2.034l-.096.177c-.518
  1.057 2.02 3.3
  6.252 5.805l-.023-8.014"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M351.096
  300.884c.437-1.317-4.063-3.953-10.423-6.286-2.907-1.04-5.308-2.125-8.282
  -3.437-8.834-3.905-15.36-8.39-14.555-10.024l.086-.168c-.465.382-1.193
  8.436-1.193 8.436
  -.807 1.498 5.167 5.916 13.293 9.814 2.605 1.244 8.106 3.276 10.702 4.185
  4.642 1.61 9.254
  4.648 8.835 5.774l1.54-8.29"
      fill="#ad1519"
    />
    <path
      d="M351.096
  300.884c.437-1.317-4.063-3.953-10.423-6.286-2.907-1.04-5.308-2.125-8.282
  -3.437-8.834-3.905-15.36-8.39-14.555-10.024l.086-.168c-.465.382-1.193
  8.436-1.193 8.436
  -.807 1.498 5.167 5.916 13.293 9.814 2.605 1.244 8.106 3.276 10.702 4.185
  4.642 1.61
  9.254 4.648 8.835 5.774l1.54-8.29v-.003z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
      strokeLinejoin="round"
    />
    <path
      d="M317.458 271.344c.63-2.39 1.444-4.698 2.242-7.036a5.675 5.675 0 0
  1-.545.112
  5.39 5.39 0 0 1-.556.05c-.38 1.68-.896 3.368-1.45
  5.055-.982-1.476-2.053-2.91-2.905-4.4
  -.34.07-.696.152-1.044.205-.34.05-.707.075-1.06.11a140.003 140.003 0 0 1
  4.302 6.052c.165

  -.043.324-.098.507-.124.164-.02.336-.017.508-.024m6.33-6.989c-.304.014-.607.037-.91.032
  -.303-.003-.607-.04-.907-.062l-.122 6.567
  4.59.078c-.022-.135-.056-.28-.052-.42 0-.134.042
  -.275.064-.41a31.97 31.97 0 0 1-2.77.088l.107-5.873m7.209 1.08c.734.062
  1.44.19 2.147.317

  -.013-.14-.034-.277-.023-.42.01-.133.062-.27.1-.4l-6.217-.515c.016.14.04.273.024.407-.012.145
  -.06.276-.097.41.634-.014 1.398-.02 2.255.052l-.542
  5.908c.303.007.606.004.906.03.3.02.602.076.9.12l.543
  -5.91m2.548
  6.473c.3.047.6.083.898.15.295.06.583.156.87.24l.738-3.02.08.02c.172.413.397.918.51
  1.21l.923 2.28c.36.058.72.105 1.074.182.364.08.713.184
  1.063.282l-.32-.684c-.495-1.03-1.016-2.054
  -1.446-3.096 1.145.05 2.032-.365
  2.254-1.284.158-.64-.096-1.143-.7-1.572-.443-.317-1.307-.484-1.864
  -.607l-2.506-.55-1.575 6.447m3.215-5.552c.723.16 1.625.276 1.625 1.097a2.032
  2.032 0 0 1-.057.484c
  -.235.964-.964 1.298-2.18.935l.612-2.515m8.624 7.52c-.055.713-.182 1.404-.32
  2.152.314.15.625.284.928.45.303.17.583.354.876.536l.614-7.403a3.632 3.632 0
  0
  1-.8-.436l-6.526 4.144c.172.084.35.16.52.25.168.096.31.196.48.292.547-.462
  1.123
  -.837 1.784-1.328l2.442 1.34.002.002zm-1.85-1.69l2.174-1.408-.253
  2.455-1.92-1.047"
      fill="#c8b100"
    />
    <path
      d="M230.31 205.26c0-1.165.994-2.106 2.22-2.106 1.22 0 2.218.94 2.218
  2.106 0
  1.16-.994 2.1-2.22 2.1-1.224 0-2.218-.94-2.218-2.1z"
      fill="none"
      stroke="#000"
      strokeWidth=".051"
    />
    <path
      d="M255.347 187.108c6.804 0 12.858 1.007 16.802 2.57 2.256 1.02 5.287
  1.772
  8.603 2.216 2.527.337 4.927.406 7.015.246 2.792-.054 6.83.763 10.87 2.542
  3.344 1.484
  6.135 3.287 7.99 5.033l-1.606 1.43-.464 4.056-4.4 5.04-2.2 1.867-5.21
  4.17-2.662.218-.81
  2.303-33.707-3.95-33.81
  3.95-.81-2.303-2.667-.217-5.21-4.17-2.198-1.867-4.4-5.04-.458
  -4.056-1.613-1.43c1.863-1.745 4.654-3.548 7.99-5.032 4.04-1.78 8.08-2.596
  10.87-2.542
  2.087.16 4.488.09 7.014-.246 3.32-.444 6.352-1.197 8.605-2.215 3.948-1.565
  9.665-2.572
  16.464-2.572z"
      fill="#ad1519"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M255.864
  231.607c-12.558-.018-23.808-1.505-31.835-3.923-.59-.178-.896-.716-.862
  -1.273-.01-.54.293-1.03.86-1.203 8.028-2.414 19.278-3.9 31.836-3.92
  12.556.02 23.8 1.506
  31.827 3.92.57.172.867.663.857 1.203.03.558-.272 1.096-.856 1.274-8.027
  2.418-19.27 3.905
  -31.826 3.923"
      fill="#c8b100"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M255.833 229.96c-11.333-.015-21.576-1.323-29.374-3.338 7.797-2.018
  18.04-3.25
  29.373-3.272 11.332.02 21.63 1.254 29.428 3.272-7.797 2.015-18.095
  3.323-29.427 3.338"
      fill="#ad1519"
    />
    <path d="M256.675 230.028v-6.724" fill="none" stroke="#000" strokeWidth=".092" />
    <path d="M254.848 230.028v-6.724" fill="none" stroke="#000" strokeWidth=".143" />
    <path d="M253.124 230.028v-6.724" fill="none" stroke="#000" strokeWidth=".184" />
    <path d="M251.415 230.028v-6.724" fill="none" stroke="#000" strokeWidth=".236" />
    <path d="M249.89 230.028v-6.724" fill="none" stroke="#000" strokeWidth=".287" />
    <path
      d="M246.986 229.666l-.04-6.12m1.417 6.2v-6.402"
      fill="none"
      stroke="#000"
      strokeWidth=".338"
    />
    <path
      d="M244.304 229.39v-5.64m1.36 5.802l-.04-6.002"
      fill="none"
      stroke="#000"
      strokeWidth=".379"
    />
    <path
      d="M240.707 229.07v-4.956m1.18 5.035v-5.197m1.216 5.357v-5.398"
      fill="none"
      stroke="#000"
      strokeWidth=".43"
    />
    <path d="M239.445 229.028v-4.793" fill="none" stroke="#000" strokeWidth=".471" />
    <path d="M238.27 228.786v-4.47" fill="none" stroke="#000" strokeWidth=".522" />
    <path d="M237.008 228.665v-4.15" fill="none" stroke="#000" strokeWidth=".573" />
    <path
      d="M234.427 228.304l-.04-3.304m1.363 3.465v-3.706"
      fill="none"
      stroke="#000"
      strokeWidth=".614"
    />
    <path d="M233.104 228.02v-2.9" fill="none" stroke="#000" strokeWidth=".645" />
    <path d="M231.89 227.786v-2.416" fill="none" stroke="#000" strokeWidth=".696" />
    <path d="M230.568 227.464v-1.892" fill="none" stroke="#000" strokeWidth=".748" />
    <path d="M229.208 227.303v-1.41" fill="none" stroke="#000" strokeWidth=".788" />
    <path d="M227.77 226.98v-.683" fill="none" stroke="#000" strokeWidth=".932" />
    <path
      d="M263.942 229.666v-6.16m-3.094 6.362l.04-6.524m-2.296 6.604v-6.644"
      fill="none"
      stroke="#000"
      strokeWidth=".051"
    />
    <path
      d="M255.777 221.277c-12.71.025-24.115 1.618-32.144
  4.09.666-.316.607-1.138-.224-3.275-1.002-2.586-2.565-2.473-2.565-2.473
  8.877-2.622 21.233-4.263 34.985-4.28 13.758.018 26.214 1.658 35.09 4.28 0
  0-1.563-.114-2.564 2.472-.83 2.137-.89 2.96-.224
  3.276-8.027-2.473-19.647-4.066-32.355-4.09"
      fill="#c8b100"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M255.83 215.342c-13.752.023-26.108 1.66-34.985
  4.285-.592.177-1.217-.055-1.41-.615-.19-.56.128-1.204.72-1.385 8.914-2.735
  21.59-4.452 35.678-4.48 14.094.025 26.817 1.745 35.732 4.48.592.18.91.824.72
  1.385-.194.56-.82.792-1.41.615-8.878-2.626-21.287-4.262-35.045-4.285"
      fill="#c8b100"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M255.833 229.96c-11.333-.015-21.576-1.323-29.374-3.338 7.797-2.018
  18.04-3.25 29.373-3.272 11.332.02 21.63 1.254 29.428 3.272-7.797 2.015-18.095
  3.323-29.427 3.338z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
      strokeLinejoin="round"
    />
    <path
      d="M246.02 218.466c0-.585.503-1.062 1.122-1.062.622 0 1.126.477 1.126
  1.062 0 .585-.504 1.065-1.126 1.065-.62 0-1.12-.48-1.12-1.064"
      fill="#fff"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M255.858 219.303h-3.365c-.62 0-1.136-.473-1.136-1.062
  0-.585.504-1.064 1.123-1.064h6.8c.62 0 1.124.48 1.124 1.065 0 .59-.515
  1.063-1.138 1.063h-3.408"
      fill="#ad1519"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M238.953
  220.23l-2.42.28c-.618.073-1.19-.342-1.268-.92-.075-.585.363-1.117.98-1.185l2.435-.284
  2.476-.283c.614-.072 1.175.334 1.252.92.072.582-.376 1.116-.99 1.185l-2.465.288"
      fill="#058e6e"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M229.116 220.442c0-.586.503-1.063 1.12-1.063.623 0 1.126.476 1.126
  1.062 0 .588-.503 1.065-1.126 1.065-.617 0-1.12-.477-1.12-1.065"
      fill="#fff"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M221.633 222.376l1.248-1.633 3.45.436-2.76 2.01-1.937-.814"
      fill="#ad1519"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M272.767 220.23l2.42.28c.612.073 1.19-.342
  1.266-.92.07-.585-.365-1.117-.98-1.185l-2.433-.284-2.476-.283c-.62-.072-1.18.334-1.253.92-.076.582.378
  1.116.99 1.185l2.467.288"
      fill="#058e6e"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M263.452 218.466c0-.585.504-1.062 1.122-1.062.622 0 1.125.477 1.125
  1.062 0 .585-.504 1.065-1.126 1.065-.618 0-1.122-.48-1.122-1.064m16.906
  1.975c0-.586.504-1.063 1.122-1.063.62 0 1.124.476 1.124 1.062 0 .588-.503
  1.065-1.125 1.065-.62 0-1.123-.477-1.123-1.065"
      fill="#fff"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M290.086 222.376l-1.247-1.633-3.45.436 2.758 2.01 1.938-.814"
      fill="#ad1519"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M225.104 226.982c7.94-2.23 18.736-3.61 30.73-3.632 11.99.02 22.842
  1.403 30.782 3.632"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M230.452 196.013l1.42 1.14
  2.13-3.48c-2.31-1.416-3.896-3.874-3.896-6.674 0-.318.02-.626.06-.932.22-4.44
  5.63-8.11 12.5-8.11 3.562 0 6.78.976 9.046
  2.54.06-.688.123-1.276.218-1.902-2.496-1.455-5.733-2.33-9.265-2.33-7.897
  0-14.075 4.485-14.375 9.8-.03.308-.046.62-.046.933 0 2.832 1.294 5.384 3.34
  7.16l-1.132 1.853"
      fill="#c8b100"
    />
    <path
      d="M230.452 196.013l1.42 1.14
  2.13-3.48c-2.31-1.416-3.896-3.874-3.896-6.674 0-.318.02-.626.06-.932.22-4.44
  5.63-8.11 12.5-8.11 3.562 0 6.78.976 9.046
  2.54.06-.688.123-1.276.218-1.902-2.496-1.455-5.733-2.33-9.265-2.33-7.897
  0-14.075 4.485-14.375 9.8-.03.308-.046.62-.046.933 0 2.832 1.294 5.384 3.34
  7.16l-1.132 1.853"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M230.544 196.052c-2.696-2.01-4.37-4.744-4.37-7.755 0-3.47 2.27-6.563
  5.717-8.614-2.126 1.708-3.416 3.916-3.6 6.382-.03.31-.046.62-.046.934 0 2.832
  1.294 5.384 3.34 7.16l-1.04 1.892"
      fill="#c8b100"
    />
    <path
      d="M230.544 196.052c-2.696-2.01-4.37-4.744-4.37-7.755 0-3.47 2.27-6.563
  5.717-8.614-2.126 1.708-3.416 3.916-3.6 6.382-.03.31-.046.62-.046.934 0 2.832
  1.294 5.384 3.34 7.16l-1.04 1.892"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M206.766 199.565c-1.51-1.69-2.434-3.876-2.434-6.265
  0-1.44.333-2.818.936-4.046 2.18-4.488 9.023-7.753 17.155-7.753 2.216 0 4.336.242
  6.294.688-.434.473-.775.992-1.108 1.517a27.215 27.215 0 0 0-5.187-.488c-7.444
  0-13.672 2.895-15.476 6.807a7.54 7.54 0 0 0-.75 3.276c0 2.378 1.114 4.51 2.857
  5.964l-2.695 4.402-1.444-1.147 1.85-2.95"
      fill="#c8b100"
    />
    <path
      d="M206.766 199.565c-1.51-1.69-2.434-3.876-2.434-6.265
  0-1.44.333-2.818.936-4.046 2.18-4.488 9.023-7.753 17.155-7.753 2.216 0 4.336.242
  6.294.688-.434.473-.775.992-1.108 1.517a27.215 27.215 0 0 0-5.187-.488c-7.444
  0-13.672 2.895-15.476 6.807a7.54 7.54 0 0 0-.75 3.276c0 2.378 1.114 4.51 2.857
  5.964l-2.695 4.402-1.444-1.147 1.85-2.95v-.005z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M209.527 184.814c-1.96 1.232-3.44 2.75-4.26 4.44a9.17 9.17 0 0 0-.935
  4.046c0 2.39.925 4.575 2.434 6.265l-1.636
  2.656c-1.566-2.012-2.476-4.36-2.476-6.858 0-4.302 2.737-8.06 6.873-10.548"
      fill="#c8b100"
    />
    <path
      d="M209.527 184.814c-1.96 1.232-3.44 2.75-4.26 4.44a9.17 9.17 0 0 0-.935
  4.046c0 2.39.925 4.575 2.434 6.265l-1.636
  2.656c-1.566-2.012-2.476-4.36-2.476-6.858 0-4.302 2.737-8.06 6.873-10.548z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M255.746 175.377c1.805 0 3.356 1.193 3.726 2.794.24 1.415.39
  3.026.425 4.742.003.178-.013.352-.013.527.005.202.044.42.047.628.063 3.6.578
  6.778 1.31 8.724l-5.497 5.258-5.557-5.258c.737-1.946 1.248-5.125
  1.317-8.724.004-.207.044-.426.044-.63
  0-.174-.012-.348-.01-.526.028-1.716.18-3.327.423-4.74.365-1.602 1.98-2.795
  3.782-2.795"
      fill="#c8b100"
    />
    <path
      d="M255.746 175.377c1.805 0 3.356 1.193 3.726 2.794.24 1.415.39
  3.026.425 4.742.003.178-.013.352-.013.527.005.202.044.42.047.628.063 3.6.578
  6.778 1.31 8.724l-5.497 5.258-5.557-5.258c.737-1.946 1.248-5.125
  1.317-8.724.004-.207.044-.426.044-.63
  0-.174-.012-.348-.01-.526.028-1.716.18-3.327.423-4.74.365-1.602 1.98-2.795
  3.782-2.795h.003z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M255.746 177.09c.937 0 1.727.598 1.92 1.425a30.65 30.65 0 0 1 .403
  4.48c0 .168-.01.335-.01.498 0 .2.035.4.04.604.057 3.4.544 6.396 1.243
  8.24l-3.625 3.432-3.628-3.433c.692-1.84 1.18-4.84
  1.24-8.24.005-.204.04-.404.043-.604
  0-.163-.01-.33-.01-.498.03-1.614.175-3.142.404-4.48.19-.827 1.045-1.424
  1.978-1.424"
      fill="#c8b100"
    />
    <path
      d="M255.746 177.09c.937 0 1.727.598 1.92 1.425a30.65 30.65 0 0 1 .403
  4.48c0 .168-.01.335-.01.498 0 .2.035.4.04.604.057 3.4.544 6.396 1.243
  8.24l-3.625 3.432-3.628-3.433c.692-1.84 1.18-4.84
  1.24-8.24.005-.204.04-.404.043-.604
  0-.163-.01-.33-.01-.498.03-1.614.175-3.142.404-4.48.19-.827 1.045-1.424
  1.978-1.424z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M281 196.013l-1.422 1.14-2.13-3.48c2.31-1.416 3.896-3.874 3.896-6.674
  0-.318-.018-.626-.056-.932-.22-4.44-5.637-8.11-12.5-8.11-3.57 0-6.784.976-9.052
  2.54a24.522 24.522 0 0 0-.22-1.902c2.497-1.455 5.733-2.33 9.273-2.33 7.894 0
  14.07 4.485 14.374 9.8.027.308.042.62.042.933 0 2.832-1.292 5.384-3.34
  7.16l1.133 1.853"
      fill="#c8b100"
    />
    <path
      d="M281 196.013l-1.422 1.14-2.13-3.48c2.31-1.416 3.896-3.874 3.896-6.674
  0-.318-.018-.626-.056-.932-.22-4.44-5.637-8.11-12.5-8.11-3.57 0-6.784.976-9.052
  2.54a24.522 24.522 0 0 0-.22-1.902c2.497-1.455 5.733-2.33 9.273-2.33 7.894 0
  14.07 4.485 14.374 9.8.027.308.042.62.042.933 0 2.832-1.292 5.384-3.34
  7.16l1.133 1.853"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M280.907 196.052c2.695-2.01 4.37-4.744 4.37-7.755
  0-3.47-2.27-6.563-5.713-8.614 2.122 1.708 3.41 3.916 3.6
  6.382.027.31.042.62.042.934 0 2.832-1.292 5.384-3.34 7.16l1.04 1.892"
      fill="#c8b100"
    />
    <path
      d="M280.907 196.052c2.695-2.01 4.37-4.744 4.37-7.755
  0-3.47-2.27-6.563-5.713-8.614 2.122 1.708 3.41 3.916 3.6
  6.382.027.31.042.62.042.934 0 2.832-1.292 5.384-3.34 7.16l1.04 1.892"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M304.688 199.565c1.506-1.69 2.43-3.876 2.43-6.265
  0-1.44-.333-2.818-.93-4.046-2.187-4.488-9.028-7.753-17.162-7.753a28.4 28.4 0 0
  0-6.292.688c.438.473.776.992 1.11 1.517 1.62-.317 3.37-.488 5.178-.488 7.447 0
  13.678 2.895 15.478 6.807.483.997.75 2.11.75 3.276 0 2.378-1.115 4.51-2.857
  5.964l2.695 4.402 1.447-1.147-1.85-2.95"
      fill="#c8b100"
    />
    <path
      d="M304.688 199.565c1.506-1.69 2.43-3.876 2.43-6.265
  0-1.44-.333-2.818-.93-4.046-2.187-4.488-9.028-7.753-17.162-7.753a28.4 28.4 0 0
  0-6.292.688c.438.473.776.992 1.11 1.517 1.62-.317 3.37-.488 5.178-.488 7.447 0
  13.678 2.895 15.478 6.807.483.997.75 2.11.75 3.276 0 2.378-1.115 4.51-2.857
  5.964l2.695 4.402 1.447-1.147-1.85-2.95.003-.005z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M301.925 184.814c1.957 1.232 3.44 2.75 4.26 4.44a9.28 9.28 0 0 1 .932
  4.046c0 2.39-.923 4.575-2.43 6.265l1.634 2.656c1.566-2.012 2.473-4.36
  2.473-6.858 0-4.302-2.733-8.06-6.868-10.548"
      fill="#c8b100"
    />
    <path
      d="M301.925 184.814c1.957 1.232 3.44 2.75 4.26 4.44a9.28 9.28 0 0 1 .932
  4.046c0 2.39-.923 4.575-2.43 6.265l1.634 2.656c1.566-2.012 2.473-4.36
  2.473-6.858 0-4.302-2.733-8.06-6.868-10.548z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M253.793 193.467c0-1.017.874-1.84 1.95-1.84 1.075 0 1.95.823 1.95
  1.84 0 1.022-.875 1.85-1.95 1.85-1.076 0-1.95-.828-1.95-1.85"
      fill="#fff"
    />
    <path
      d="M253.793 193.467c0-1.017.874-1.84 1.95-1.84 1.075 0 1.95.823 1.95
  1.84 0 1.022-.875 1.85-1.95 1.85-1.076 0-1.95-.828-1.95-1.85z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M253.793 189.85c0-1.018.874-1.848 1.95-1.848 1.075 0 1.95.83 1.95
  1.848 0 1.018-.875 1.843-1.95 1.843-1.076
  0-1.95-.825-1.95-1.843m.391-3.892c0-.815.7-1.472 1.56-1.472.854 0 1.554.657
  1.554 1.472 0 .814-.7 1.474-1.555 1.474-.86
  0-1.56-.66-1.56-1.474m.437-3.508c0-.585.505-1.062 1.123-1.062.622 0 1.12.477
  1.12 1.06 0 .59-.498 1.068-1.12 1.068-.618
  0-1.122-.477-1.122-1.067m.223-3.069c0-.472.402-.85.9-.85.494 0 .897.378.897.85 0
  .47-.402.852-.897.852-.497 0-.9-.382-.9-.85"
      fill="#fff"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M255.908 204.572l1.267.233c-.204.512-.246 1.072-.246 1.662 0 2.63
  2.257 4.767 5.036 4.767 2.23 0 4.132-1.382
  4.784-3.29.074.05.48-1.725.69-1.702.178.018.16 1.843.224 1.81.323 2.404 2.52
  4.03 4.992 4.03 2.775 0 5.025-2.128 5.025-4.76
  0-.196-.01-.393-.036-.583l1.577-1.564.848 1.993c-.337.624-.47 1.327-.47 2.08 0
  2.512 2.15 4.544 4.803 4.544 1.67 0 3.136-.803 3.998-2.022l1.01-1.284-.008
  1.575c0 1.58.668 3 2.212 3.25 0 0 1.772.11 4.12-1.733 2.35-1.844 3.643-3.37
  3.643-3.37l.203 1.845s-1.95 3.016-4.073 4.245c-1.16.675-2.922 1.384-4.323
  1.156-1.483-.24-2.54-1.43-3.083-2.8a7.167 7.167 0 0 1-3.642.984c-2.87
  0-5.452-1.574-6.473-3.944-1.32 1.425-3.16 2.3-5.317 2.3-2.293
  0-4.394-1.03-5.71-2.617a7.397 7.397 0 0 1-4.992 1.905c-2.538
  0-4.802-1.244-6.085-3.12-1.28 1.876-3.543 3.12-6.078 3.12-1.937
  0-3.7-.727-4.995-1.906-1.312 1.585-3.416 2.617-5.71 2.617-2.156
  0-3.995-.876-5.317-2.3-1.02 2.37-3.6 3.943-6.473 3.943a7.145 7.145 0 0
  1-3.635-.984c-.55 1.37-1.606 2.56-3.084
  2.8-1.4.228-3.164-.48-4.326-1.156-2.125-1.23-4.072-4.245-4.072-4.245l.204-1.846s1.295
  1.526 3.64 3.37c2.35 1.846 4.12 1.733 4.12 1.733 1.544-.25 2.21-1.67
  2.21-3.25l-.006-1.575 1.01 1.284c.86 1.22 2.33 2.022 3.997 2.022 2.654 0
  4.803-2.032 4.803-4.545 0-.753-.135-1.456-.472-2.08l.847-1.994 1.58
  1.563c-.024.19-.038.386-.038.58 0 2.634 2.248 4.762 5.025 4.762 2.477 0
  4.67-1.626 4.992-4.03.068.033.05-1.792.225-1.81.21-.023.618 1.752.688 1.702.656
  1.908 2.554 3.29 4.792 3.29 2.78 0 5.03-2.138 5.03-4.767
  0-.59-.035-1.15-.242-1.662l1.313-.233"
      fill="#c8b100"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M290.478
  210.855c.29-.853.03-1.698-.583-1.882-.614-.193-1.352.345-1.643 1.196-.293.85-.03
  1.69.584 1.882.613.185 1.35-.35
  1.642-1.197m-21.888-4.238c.114-.89-.312-1.67-.953-1.742-.64-.076-1.255.58-1.37
  1.47-.116.886.31 1.668.95 1.745.643.07 1.258-.587
  1.373-1.474m-25.414.001c-.115-.89.315-1.67.952-1.742.64-.076 1.255.58 1.37
  1.47.112.886-.31 1.668-.95 1.745-.643.07-1.258-.587-1.372-1.474m-21.886
  4.24c-.29-.853-.03-1.698.584-1.882.615-.193 1.348.345 1.64 1.196.288.85.027
  1.69-.584 1.882-.614.185-1.347-.35-1.64-1.197"
      fill="#fff"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M230.798 196.25c1.086.686 2.034 1.828 2.364 3.105 0 0
  .127-.262.725-.608.604-.338 1.114-.328 1.114-.328s-.17.993-.256
  1.35c-.087.348-.095 1.414-.326 2.374-.23.96-.65 1.727-.65 1.727a2.026 2.026 0 0
  0-1.612-.425c-.6.103-1.082.455-1.358.92 0
  0-.673-.585-1.233-1.41-.565-.827-.95-1.823-1.164-2.125-.21-.305-.73-1.185-.73-1.185s.478-.178
  1.166-.05c.686.127.902.334.902.334-.146-1.313.29-2.685 1.06-3.68"
      fill="#c8b100"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M231.258 206.704a1.862 1.862 0 0 1-.69-1.106 1.74 1.74 0 0 1
  .238-1.255s-.908-.457-1.88-.712c-.737-.192-2.033-.2-2.425-.206-.39-.012-1.174
  -.03-1.174-.03s.066.18.288.567c.268.47.507.764.507.764-1.297.3-2.403
  1.153-3.102 2.143 1.015.7 2.363 1.127 3.686.992 0
  0-.115.353-.2.886-.068.44-.064.623-.064.623l1.094-.404c.365-.136 1.582-.557
  2.208-.98.817-.558 1.516-1.28 1.516-1.28m2.91-.491a1.76 1.76 0 0 0 .248-1.257
  1.835 1.835 0 0 0-.675-1.105s.683-.724 1.5-1.28c.623-.422 1.843-.848
  2.208-.978.366-.134 1.096-.408
  1.096-.408s.003.186-.066.626c-.083.53-.2.884-.2.884 1.326-.14 2.676.305 3.69
  1.01-.698.985-1.812 1.825-3.105 2.124a7.094 7.094 0 0 0 .795
  1.331l-1.176-.023c-.39-.007-1.69-.01-2.426-.208-.97-.26-1.89-.71-1.89-.71"
      fill="#c8b100"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M230.31 205.26c0-1.165.994-2.106 2.22-2.106 1.22 0 2.218.94 2.218
  2.106 0 1.16-.994 2.1-2.22 2.1-1.224 0-2.218-.94-2.218-2.1"
      fill="#ad1519"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M255.823 192.886c1.067.967 1.89 2.413 1.994 3.9 0 0
  .19-.267.933-.536.74-.27 1.308-.164 1.308-.164s-.395 1.08-.568
  1.457c-.172.38-.395 1.565-.857 2.587a8.78 8.78 0 0 1-1.074 1.803 2.272 2.272 0 0
  0-1.714-.767c-.687 0-1.3.302-1.708.767 0
  0-.626-.774-1.08-1.803-.456-1.022-.683-2.208-.852-2.587-.172-.377-.572-1.457-.572-1.457s.572-.106
  1.31.164c.74.27.937.537.937.537.104-1.488.88-2.934 1.943-3.9"
      fill="#c8b100"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M254.168 204.627a2.044 2.044 0 0 1-.54-1.36c0-.513.195-.992.525-1.353 0
    0-.913-.684-1.943-1.15-.78-.355-2.227-.602-2.66-.683l-1.307-.245s.036.206.204.683c.204.578.408.95.408.95
    -1.504.09-2.923.84-3.906 1.81.982.963 2.395 1.694 3.905 1.793 0 0-.204.368-.408.95-.168.47-.204.68
    -.204.68l1.306-.24c.433-.088 1.88-.33 2.66-.69 1.03-.467 1.958-1.14 1.958-1.14m3.347-.005c.33
    -.368.54-.844.54-1.36a2 2 0 0 0-.525-1.353s.914-.684 1.944-1.15c.782-.355 2.23-.602 2.663-.683l1.297
    -.245s-.03.206-.202.683a7.6 7.6 0 0 1-.404.95c1.506.09 2.922.84 3.9 1.81-.978.963-2.39 1.694-3.9
    1.793 0 0 .2.368.404.95.17.47.202.68.202.68l-1.297-.24c-.434-.088-1.88-.33-2.663-.69a12.963 12.963
    0 0 1-1.96-1.14m23.455-8.382c-1.082.686-2.03 1.828-2.36 3.105 0 0-.132-.262-.73-.608-.598-.338-1.114
    -.328-1.114-.328s.173.993.262 1.35c.09.348.092 1.414.322 2.374.23.96.648 1.727.648 1.727a2.037 2.037
    0 0 1 1.614-.425 1.97 1.97 0 0 1 1.362.92s.668-.585 1.233-1.41c.56-.827.948-1.823 1.154-2.125.21-.305.734
    -1.185.734-1.185s-.48-.178-1.168-.05c-.687.127-.903.334-.903.334.154-1.313-.283-2.685-1.056-3.68"
      fill="#c8b100"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M280.51 206.704c.344-.274.608-.656.688-1.106a1.747 1.747 0 0
  0-.234-1.255s.902-.457 1.873-.712c.74-.192 2.04-.2 2.426-.206.393-.012 1.176-.03
  1.176-.03s-.063.18-.288.567c-.27.47-.507.764-.507.764 1.296.3 2.402 1.153 3.102
  2.143-1.01.7-2.357 1.127-3.686.992 0 0
  .12.353.2.886.066.44.06.623.06.623l-1.088-.404c-.365-.136-1.582-.557-2.21-.98a11.48
  11.48 0 0 1-1.51-1.28m-2.915-.491a1.785 1.785 0 0
  1-.25-1.257c.086-.45.334-.833.68-1.105 0
  0-.688-.724-1.505-1.28-.622-.422-1.844-.848-2.207-.978-.366-.134-1.09-.408-1.09
  -.408s-.004.186.063.626c.082.53.197.884.197.884-1.325-.14-2.677.305-3.686
  1.01.698.985 1.808 1.825 3.1 2.124 0 0-.232.295-.502.764a3.99 3.99 0 0
  0-.287.567l1.17-.023c.39-.007 1.695-.01 2.427-.208.97-.26 1.886-.71 1.886-.71"
      fill="#c8b100"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M277.018 205.26c0-1.165.993-2.106 2.22-2.106 1.228 0 2.222.94 2.222
  2.106 0 1.16-.994 2.1-2.223 2.1-1.226 0-2.22-.94-2.22-2.1m24.823
  4.668c-.524-.552-1.606-.436-2.415.255-.81.687-1.045 1.698-.523 2.247.523.553
  1.605.433 2.414-.254.81-.694 1.046-1.702.524-2.248"
      fill="#ad1519"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M298.688 211.267c.11-.378.364-.764.737-1.084.81-.69 1.89-.807
  2.415-.255.064.074.138.17.18.258 0 0 1.125-2.134 2.457-2.842 1.334-.717
  3.588-.536 3.588-.536 0-1.636-1.34-2.958-3.07-2.958-1.013 0-1.973.42-2.538
  1.133l-.232-1.097s-1.39.276-2.03 1.864c-.632 1.594.06 3.895.06 3.895-.002
  0-.347-.99-.868-1.648-.52-.653-1.85-1.37-2.545-1.697-.696-.323-1.406-.81-1.406
  -.81s-.03.177-.058.618c-.027.526.016.85.016.85-1.275-.167-2.762.04-3.92.49.494.975
  1.438 1.89 2.675 2.36 0
  0-.446.368-.854.77-.336.343-.444.503-.444.503l1.31.187c.432.058 1.88.286
  2.744.23a15.693 15.693 0 0 0
  1.782-.23m-85.609-.001c-.11-.378-.362-.764-.737-1.084-.81-.69-1.888-.807-2.415-.255a1.13
  1.13 0 0 0-.18.258s-1.13-2.134-2.46-2.842c-1.33-.717-3.582-.536-3.582-.536
  0-1.636 1.34-2.958 3.066-2.958 1.017 0 1.97.42 2.54 1.133l.232-1.097s1.39.276
  2.027 1.864c.635 1.594-.06 3.895-.06 3.895s.347-.99.87-1.648c.52-.653 1.853-1.37
  2.548-1.697.692-.323 1.405-.81
  1.405-.81s.027.177.055.618c.027.526-.016.85-.016.85 1.27-.167 2.76.04
  3.92.49-.495.975-1.436 1.89-2.675 2.36 0 0
  .445.368.85.77.34.343.45.503.45.503l-1.308.187c-.44.058-1.883.286-2.747.23a15.646
  15.646 0 0 1-1.785-.23"
      fill="#c8b100"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M209.925 209.928c.527-.552 1.606-.436 2.416.255.815.687 1.044
  1.698.523
  2.247-.522.553-1.605.433-2.414-.254-.812-.694-1.046-1.702-.525-2.248m43.703-6.661c0-1.163.993-2.1
  2.218-2.1s2.223.937 2.223 2.1-.995 2.105-2.224 2.105c-1.225
  0-2.218-.942-2.218-2.105"
      fill="#ad1519"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M251.226 171.316c0-2.38 2.033-4.3 4.536-4.3 2.503 0 4.534 1.92 4.534
  4.3 0 2.37-2.03 4.29-4.534 4.29-2.503 0-4.536-1.92-4.536-4.29"
      fill="#005bbf"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M254.574
  159.27v2.32h-2.48v2.35h2.48v6.77h-3.125c-.038.22-.227.382-.227.612 0 .593.124
  1.163.355 1.676.006.014.025.018.03.032h8.314c.006-.014.027-.018.033-.032a4.1 4.1
  0 0 0
  .354-1.676c0-.23-.19-.392-.226-.612h-3.03v-6.77h2.482v-2.35h-2.48v-2.32h-2.482z"
      fill="#c8b100"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M256.21 352.597c-13.395
  0-26.672-3.283-37.834-8.745-8.227-4.075-13.683-12.293-13.683-21.696v-34.1h102.833v34.1c0
  9.403-5.455 17.62-13.685 21.696-11.16 5.462-24.23 8.745-37.63 8.745"
      fill="#ccc"
    />
    <path
      d="M256.21 352.597c-13.395
  0-26.672-3.283-37.834-8.745-8.227-4.075-13.683-12.293-13.683-21.696v-34.1h102.833v34.1c0
  9.403-5.455 17.62-13.685 21.696-11.16 5.462-24.23 8.745-37.63 8.745z"
      fill="none"
      stroke="#000"
      strokeWidth=".532"
    />
    <path d="M255.992 287.997h51.53v-57.05h-51.53v57.05z" fill="#ccc" />
    <path
      d="M255.992 287.997h51.53v-57.05h-51.53v57.05z"
      fill="none"
      stroke="#000"
      strokeWidth=".532"
    />
    <path
      d="M256.03 322.116c0 13.475-11.42 24.398-25.643 24.398-14.23
  0-25.763-10.923-25.763-24.398v-34.152h51.407v34.152"
      fill="#ad1519"
    />
    <path
      d="M215.854 342.25c1.607.854 3.813 2.273 6.17
  2.84l-.15-58.33h-6.02v55.49z"
      fill="#c8b100"
      stroke="#000"
      strokeWidth=".532"
    />
    <path
      d="M204.54 321.656c.156 7.196 3.013 12.546 5.87
  16.05v-50.66h-5.798l-.073 34.61z"
      fill="#c8b100"
      stroke="#000"
      strokeWidth=".512"
      strokeLinejoin="round"
    />
    <path
      d="M227.316 346.37c2.355.237 4.112.19 6.02 0v-59.61h-6.02v59.61z"
      fill="#c7b500"
      stroke="#000"
      strokeWidth=".532"
    />
    <path
      d="M238.63 345.09c2.356-.472 5.014-1.94 6.17-2.697V286.76h-6.02l-.15
  58.33z"
      fill="#c8b100"
      stroke="#000"
      strokeWidth=".532"
    />
    <path d="M204.627 287.997h51.383v-57.05h-51.383v57.05z" fill="#ad1519" />
    <path
      d="M204.627 287.997h51.383v-57.05h-51.383v57.05z"
      fill="none"
      stroke="#000"
      strokeWidth=".532"
    />
    <path
      d="M250.395 337.138c2.507-2.225 4.863-7.292
  5.72-13.068l.15-37.31h-6.02l.15 50.378z"
      fill="#c8b100"
      stroke="#000"
      strokeWidth=".532"
    />
    <path
      d="M256.03 322.116c0 13.475-11.42 24.398-25.643 24.398-14.23
  0-25.763-10.923-25.763-24.398v-34.152h51.407v34.152"
      fill="none"
      stroke="#000"
      strokeWidth=".532"
    />
    <path
      d="M307.572 287.964v34.152c0 13.475-11.55 24.398-25.778 24.398-14.226
  0-25.763-10.923-25.763-24.398v-34.152h51.542"
      fill="#ad1519"
    />
    <path
      d="M307.572 287.964v34.152c0 13.475-11.55 24.398-25.778 24.398-14.226
  0-25.763-10.923-25.763-24.398v-34.152h51.542"
      fill="none"
      stroke="#000"
      strokeWidth=".532"
    />
    <path
      d="M265.406 313.74c.082.175.128.35.128.536 0 .63-.534 1.13-1.2 1.13-.66
  0-1.196-.5-1.196-1.13
  0-.185.046-.36.13-.513l-1.674-.022c-.034.172-.055.35-.055.536 0 1.185.818 2.19
  1.95 2.527l-.003 4.11 1.755.01.003-4.138a2.73 2.73 0 0 0
  1.737-1.655h4.713v-1.388h-6.29m23.123 0v1.388l-4.24.012a2.72 2.72 0 0
  1-.27.52l4.92 5.592-1.32 1.07-4.9-5.6c-.09.028-.134.064-.23.09l-.01
  9.254h-1.736l-.004-9.294c-.074-.018-.135-.054-.204-.087l-5.1 5.636-1.32-1.07
  5.096-5.657a2.27 2.27 0 0 1-.212-.466h-4.38v-1.388h13.914-.004zm2.823
  0v1.388h4.718c.28.78.915 1.39 1.736 1.655l.003 4.138
  1.752-.01-.005-4.11c1.133-.338 1.955-1.342 1.955-2.527
  0-.185-.02-.364-.063-.535h-1.666c.082.175.124.35.124.536 0 .63-.53 1.13-1.194
  1.13-.66 0-1.198-.5-1.198-1.13 0-.185.046-.36.13-.513l-6.29-.022m-7.125
  23.595a16.58 16.58 0 0 0 3.894-1.122l.86 1.465a18.773 18.773 0 0 1-4.607
  1.305c-.27 1.196-1.385 2.094-2.73 2.094-1.34 0-2.457-.887-2.734-2.075a18.662
  18.662 0 0 1-4.845-1.324l.864-1.465c1.316.556 2.72.927 4.18 1.13a2.66 2.66 0 0 1
  1.613-1.425l.015-7.174h1.73l.017 7.15c.768.228 1.416.75 1.74
  1.44h.002zm-11.77-2.406l-.853 1.47a17.764 17.764 0 0
  1-3.89-3.29c-.89.262-1.897.107-2.657-.512-1.175-.953-1.317-2.625-.31-3.734l.146-.156a16.29
  16.29 0 0 1-1.352-5.124l1.752.007a14.01 14.01 0 0 0 1.12 4.38 3.1 3.1 0 0 1
  1.49.163l4.38-4.847 1.32 1.066-4.352 4.84a2.506 2.506 0 0 1-.108 2.926 16.184
  16.184 0 0 0 3.316 2.81zm-6.494-5.084a1.243 1.243 0 0 1 1.69-.128c.503.407.565
  1.127.134 1.6a1.246 1.246 0 0 1-1.69.127 1.096 1.096 0 0
  1-.134-1.6zm-2.208-4.794l-1.8-.402-.254-4.56 1.787-.59.002 2.616c0 1.013.09
  1.974.265 2.936zm1.494-5.653l1.792.42s.092 2.93.054 2.273c-.047-.767.196
  2.292.196 2.292l-1.808.596c-.18-.93-.242-1.883-.242-2.864l.003-2.717h.004zm5.91
  14.587a16.71 16.71 0 0 0 5.163 2.74l.4-1.733a14.61 14.61 0 0
  1-4.275-2.145l-1.287 1.137m-.867 1.498a18.56 18.56 0 0 0 5.16 2.717l-1.34
  1.254a19.954 19.954 0 0 1-4.218-2.164l.398-1.807m2.34-10.05l1.712.727
  3.133-3.476-1.03-1.48-3.815 4.23m-1.328-1.074l-1.027-1.487 3.134-3.476
  1.707.73-3.815 4.232m19.285 10.567l.856 1.47a17.812 17.812 0 0 0
  3.89-3.29c.886.262 1.897.107 2.655-.512 1.177-.953
  1.312-2.625.312-3.734l-.15-.156c.712-1.6 1.192-3.32 1.35-5.124l-1.743.007a14.152
  14.152 0 0 1-1.123 4.38 3.11 3.11 0 0 0-1.493.163l-4.377-4.847-1.32 1.066 4.35
  4.84a2.518 2.518 0 0 0 .107 2.926 16.09 16.09 0 0 1-3.314
  2.81zm6.492-5.084a1.236 1.236 0 0 0-1.684-.128 1.093 1.093 0 0 0-.136
  1.6c.43.476 1.187.535
  1.69.127.504-.41.56-1.123.13-1.6zm2.21-4.794l1.8-.402.253-4.56-1.782-.59-.004
  2.616c0 1.013-.09 1.974-.27 2.936zm-1.49-5.653l-1.794.42s-.097 2.93-.058
  2.273c.05-.767-.196 2.292-.196
  2.292l1.808.596c.182-.93.242-1.883.242-2.864l-.003-2.717m-5.913 14.587a16.77
  16.77 0 0 1-5.16 2.74l-.4-1.733a14.586 14.586 0 0 0 4.27-2.145l1.29 1.137m.87
  1.498a18.616 18.616 0 0 1-5.162 2.717l1.34 1.254a19.962 19.962 0 0 0
  4.22-2.164l-.4-1.807m-2.338-10.05l-1.712.727-3.134-3.476 1.03-1.48 3.817
  4.23m1.327-1.074l1.027-1.487-3.132-3.476-1.708.73 3.814 4.232m-21.446-9.228l.527
  1.708h4.826l.52-1.708H270.3m22.545 0l-.53 1.708h-4.823l-.52-1.708h5.873m-12.397
  23.293c0-.63.537-1.138 1.198-1.138.66 0 1.193.507 1.193 1.137 0 .626-.533
  1.13-1.193
  1.13s-1.198-.504-1.198-1.13zm2.03-8.27l1.802-.504v-4.564l-1.8-.495v5.563m-1.747
  0l-1.796-.505v-4.564l1.797-.495v5.563"
      fill="#c8b100"
    />
    <path
      d="M261.594 313.78c.207-.95.95-1.722 1.91-2.01l-.015-5.635h1.737l.003
  5.665c.857.27 1.516.903 1.78 1.72l4.685.006v.255h-6.29a1.237 1.237 0 0
  0-1.07-.603 1.21 1.21 0 0 0-1.067.626l-1.674-.022m13.02
  0v-.254h4.35c.05-.142.114-.28.188-.412l-5.368-5.982 1.32-1.07 5.3
  5.884c.09-.045.19-.085.283-.118l.005-7.84h1.734v7.792c.078.023.196.044.274.067l5.176-5.894
  1.33 1.055-5.196 5.87c.134.197.225.416.3.648h4.214v.255H274.61h.003zm23.03
  0a1.23 1.23 0 0 1 1.066-.603c.47 0 .873.258
  1.07.626l1.667-.022c-.207-.95-.944-1.722-1.903-2.01l.01-5.632h-1.732l-.007
  5.665c-.852.27-1.512.9-1.777 1.716l-4.687.006v.255h6.293m-32.136-15.912l6.457
  7.24
  1.322-1.072-6.493-7.21c.13-.194.222-.404.3-.63h4.722v-1.648h-4.726c-.363-1.067-1.422-1.832-2.67-1.832-1.543
  0-2.8 1.188-2.8 2.653 0 1.16.787 2.152 1.885 2.51l-.01
  5.584h1.734v-5.548c.073-.02.206-.02.277-.048zm34.068.037l-.016
  5.56h-1.737v-5.582a2.61 2.61 0 0 1-.39-.158l-6.42 7.248-1.328-1.057
  6.544-7.39a2.42 2.42 0 0 1-.15-.33h-4.75v-1.648h4.735c.365-1.067 1.414-1.832
  2.657-1.832 1.543 0 2.8 1.188 2.8 2.653 0 1.19-.812 2.195-1.947
  2.535zm-17.13-.022l-.014 3.424h-1.734l.004-3.395a2.743 2.743 0 0
  1-1.83-1.717h-4.234v-1.647h4.235c.367-1.067 1.41-1.832 2.655-1.832 1.244 0
  2.295.765 2.66 1.832h4.32v1.647h-4.33a2.696 2.696 0 0 1-1.73 1.688zm-18.935
  4.156l-1.8.506v4.576l1.8.496v-5.58m1.747
  0l1.796.508v4.576l-1.796.496v-5.58m32.55
  0l-1.796.508v4.576l1.797.496v-5.58m1.748
  0l1.8.508v4.576l-1.8.496v-5.58m-27.25.904l1.714-.727 3.132 3.483-1.03
  1.485-3.817-4.24m-1.327 1.078l-1.025 1.492 3.133 3.48
  1.708-.733-3.816-4.24m19.674-1.212l-1.717-.714-3.102 3.508 1.04 1.476
  3.78-4.27m1.335 1.068l1.04 1.478-3.102 3.51-1.713-.72 3.775-4.268m-21.69
  9.646l.527-1.71h4.826l.52 1.71h-5.87m-7.077-18.15c0-.626.536-1.137
  1.198-1.137.66 0 1.195.51 1.195 1.137s-.535 1.133-1.194 1.133c-.66
  0-1.197-.506-1.197-1.133zm12.907.826l-.53
  1.714h-4.822l-.52-1.714h5.872m0-1.657l-.53-1.707h-4.822l-.52 1.708h5.872m16.713
  18.982l-.53-1.71h-4.822l-.52 1.71h5.873m4.682-18.15c0-.626.533-1.137
  1.197-1.137.66 0 1.195.51 1.195 1.137s-.534 1.133-1.195 1.133c-.664
  0-1.197-.506-1.197-1.133zm-17.197 0c0-.626.537-1.137 1.197-1.137s1.195.51 1.195
  1.137-.534 1.133-1.195 1.133c-.66 0-1.197-.506-1.197-1.133zm6.683.826l.532
  1.714h4.822l.522-1.714h-5.875m0-1.657l.532-1.707h4.822l.522 1.708h-5.875m-6.298
  5.346l-1.797.506v4.576l1.797.496v-5.578m1.727
  0l1.795.506v4.576l-1.796.496v-5.578"
      fill="#c8b100"
    />
    <path
      d="M284.227 337.335a16.58 16.58 0 0 0 3.894-1.122l.86 1.465a18.773
  18.773 0 0 1-4.607 1.305c-.27 1.196-1.385 2.094-2.73 2.094-1.34
  0-2.457-.887-2.734-2.075a18.662 18.662 0 0 1-4.845-1.324l.864-1.465c1.316.556
  2.72.927 4.18 1.13a2.66 2.66 0 0 1 1.613-1.425l.015-7.174h1.73l.017
  7.148c.768.23 1.416.753 1.74 1.443h.002zm-5.02-21.736a2.434 2.434 0 0
  1-.213-.47h-4.38v-1.644h4.35c.05-.138.12-.276.19-.407l-5.37-5.975 1.32-1.07
  5.304 5.873a2.16 2.16 0 0 1
  .284-.115l.004-7.826h1.73v7.778c.078.02.196.044.274.07l5.176-5.89 1.33
  1.06-5.196
  5.855c.134.196.225.414.3.646h4.214v1.644l-4.24.01c-.06.187-.168.358-.267.52l4.922
  5.598-1.322 1.07-4.9-5.605c-.09.033-.134.07-.233.095l-.012
  9.253h-1.735l-.005-9.292c-.073-.022-.133-.06-.203-.092l-5.1 5.64-1.318-1.068
  5.094-5.66m-13.7-17.74l6.457 7.226
  1.322-1.07-6.492-7.196c.13-.193.22-.403.3-.63h4.722v-1.642h-4.726c-.363-1.065-1.422-1.83-2.67-1.83-1.544
  0-2.8 1.186-2.8 2.65 0 1.158.786 2.147 1.885 2.502l-.01
  5.576h1.734v-5.538c.073-.02.206-.02.277-.048zm6.952 37.07l-.854 1.474a17.764
  17.764 0 0
  1-3.89-3.292c-.89.263-1.897.107-2.656-.512-1.176-.953-1.318-2.625-.312-3.734l.147-.156a16.29
  16.29 0 0 1-1.352-5.124l1.752.007c.137 1.54.52 3.006 1.12 4.38a3.1 3.1 0 0 1
  1.49.164l4.38-4.848 1.32 1.066-4.352 4.84a2.506 2.506 0 0 1-.108 2.926 16.184
  16.184 0 0 0 3.316 2.81zm-8.97-14.015v-4.11c-1.13-.335-1.952-1.342-1.952-2.527
  0-1.183.833-2.2 1.967-2.543l-.014-5.62h1.736l.003 5.653c.857.27 1.516.898 1.78
  1.713l4.685.006v1.644h-4.713a2.73 2.73 0 0 1-1.737 1.655l-.003
  4.138-1.755-.01m2.476 8.932a1.243 1.243 0 0 1 1.69-.128c.503.408.565 1.128.135
  1.6a1.246 1.246 0 0 1-1.69.128 1.095 1.095 0 0
  1-.135-1.6zm-2.208-4.793l-1.8-.403-.254-4.56 1.787-.59.002 2.615c0 1.014.09
  1.974.265 2.937zm1.494-5.654l1.792.422s.092 2.93.054 2.273c-.047-.767.196
  2.29.196 2.29l-1.808.597c-.18-.93-.242-1.884-.242-2.865l.003-2.717h.004zm5.91
  14.588a16.71 16.71 0 0 0 5.163 2.742l.4-1.734a14.61 14.61 0 0
  1-4.275-2.145l-1.287 1.136m-.867 1.498a18.56 18.56 0 0 0 5.16 2.717l-1.34
  1.256a19.954 19.954 0 0 1-4.218-2.164l.398-1.808"
      fill="none"
      stroke="#c8b100"
      strokeWidth=".266"
    />
    <path
      d="M272.633 325.435l1.712.727 3.133-3.476-1.03-1.48-3.815
  4.23m-1.328-1.074l-1.027-1.487 3.134-3.476 1.707.73-3.815
  4.232m-8.167-10.086c0-.63.536-1.138 1.197-1.138.66 0 1.195.508 1.195 1.138 0
  .63-.534 1.13-1.195 1.13-.66 0-1.197-.5-1.197-1.13zm27.452 20.653l.856
  1.47a17.812 17.812 0 0 0 3.89-3.29c.886.262 1.897.107 2.655-.512 1.177-.953
  1.312-2.625.312-3.734l-.15-.156c.712-1.6 1.192-3.32 1.35-5.124l-1.743.007a14.152
  14.152 0 0 1-1.123 4.38 3.11 3.11 0 0 0-1.493.163l-4.377-4.847-1.32 1.066 4.35
  4.84a2.518 2.518 0 0 0 .107 2.926 16.09 16.09 0 0 1-3.314
  2.81zm8.973-14.018l-.004-4.11c1.132-.334 1.953-1.34 1.953-2.526
  0-1.183-.832-2.2-1.965-2.543l.01-5.62h-1.735l-.007
  5.653c-.852.27-1.513.898-1.778 1.713l-4.687.006v1.644h4.72c.28.778.913 1.39
  1.734 1.654l.003 4.138 1.752-.01h.003zm-2.48 8.934a1.236 1.236 0 0 0-1.685-.128
  1.093 1.093 0 0 0-.136 1.6c.43.476 1.187.535
  1.69.127.504-.41.56-1.123.13-1.6zm2.208-4.794l1.8-.402.255-4.56-1.782-.59-.004
  2.616c0 1.013-.09 1.974-.27 2.936zm-1.49-5.653l-1.792.42s-.097 2.932-.057
  2.273c.05-.767-.196 2.292-.196
  2.292l1.808.596c.182-.93.242-1.883.242-2.864l-.003-2.717m1.775-21.505l-.016
  5.55h-1.738v-5.573a2.4 2.4 0 0 1-.388-.156l-6.42 7.233-1.33-1.055
  6.545-7.375a2.535 2.535 0 0 1-.15-.33h-4.75v-1.645h4.735c.365-1.065 1.413-1.828
  2.657-1.828 1.543 0 2.8 1.185 2.8 2.648 0 1.188-.812 2.19-1.947
  2.53zm-17.13-.023l-.014 3.42h-1.734l.004-3.39a2.74 2.74 0 0
  1-1.83-1.713h-4.234v-1.645h4.235c.367-1.065 1.41-1.828 2.654-1.828 1.245 0
  2.296.763 2.662 1.828h4.32v1.644h-4.332a2.69 2.69 0 0 1-1.728 1.682zm9.442
  36.115a16.77 16.77 0 0 1-5.16 2.74l-.4-1.733a14.586 14.586 0 0 0 4.27-2.145l1.29
  1.137m.87 1.498a18.617 18.617 0 0 1-5.162 2.717l1.34 1.254a19.962 19.962 0 0 0
  4.22-2.164l-.4-1.807m-29.245-33.463l-1.8.506v4.567l1.8.493v-5.566m1.747
  0l1.796.506v4.567l-1.796.493v-5.566m32.55 0l-1.796.506v4.567l1.797.493v-5.566"
      fill="none"
      stroke="#c8b100"
      strokeWidth=".266"
    />
    <path
      d="M299.555 302.022l1.8.505v4.568l-1.8.493v-5.566m-9.138
  23.413l-1.712.727-3.134-3.476 1.03-1.48 3.817
  4.23m1.327-1.074l1.027-1.487-3.132-3.476-1.708.73 3.814
  4.232m-19.44-21.44l1.715-.726 3.132 3.477-1.03 1.483-3.818-4.233M270.978
  304l-1.025 1.487 3.133 3.473 1.708-.73-3.816-4.23m19.673-1.21l-1.715-.713-3.103
  3.5 1.04 1.474 3.78-4.26m1.336 1.064l1.04 1.476-3.103 3.502-1.712-.717
  3.775-4.26m-21.69 9.628l.527-1.706h4.826l.52 1.706H270.3m0 1.65l.527
  1.71h4.826l.52-1.71H270.3m-7.075-19.765c0-.625.536-1.135 1.198-1.135.66 0
  1.195.51 1.195 1.135s-.535 1.13-1.194 1.13c-.66
  0-1.197-.505-1.197-1.13zm12.907.825l-.53
  1.71h-4.822l-.522-1.71h5.874m0-1.655l-.53-1.705h-4.822l-.522 1.705h5.874m21.382
  19.738c0-.63.54-1.138 1.198-1.138.665 0 1.194.508 1.194 1.138 0 .63-.53
  1.13-1.194 1.13-.66 0-1.198-.5-1.198-1.13zm-4.67-.793l-.53-1.706h-4.822l-.52
  1.706h5.873m0 1.65l-.53 1.71h-4.823l-.52-1.71h5.873m-12.397
  23.294c0-.63.537-1.138 1.198-1.138.66 0 1.193.507 1.193 1.137 0 .626-.533
  1.13-1.193
  1.13s-1.198-.504-1.198-1.13zm2.03-8.27l1.802-.504v-4.564l-1.8-.495v5.563m-1.747
  0l-1.796-.505v-4.564l1.797-.495v5.563m16.794-34.79c0-.625.534-1.135
  1.198-1.135.66 0 1.195.51 1.195 1.135s-.534 1.13-1.195 1.13c-.664
  0-1.198-.505-1.198-1.13zm-17.197 0c0-.625.538-1.135 1.197-1.135.662 0 1.195.51
  1.195 1.135s-.534 1.13-1.196 1.13c-.66 0-1.197-.505-1.197-1.13zm6.684.825l.53
  1.71h4.824l.522-1.71h-5.875m0-1.655l.53-1.705h4.824l.522 1.705h-5.875m-6.307
  5.336l-1.796.505v4.567l1.797.495v-5.567m1.73
  0l1.795.505v4.567l-1.796.495v-5.567"
      fill="none"
      stroke="#c8b100"
      strokeWidth=".266"
    />
    <path
      d="M278.84 314.305c0-1.47 1.257-2.658 2.804-2.658 1.543 0 2.8 1.19 2.8
  2.658 0 1.462-1.257 2.647-2.8 2.647-1.547 0-2.803-1.185-2.803-2.647"
      fill="#058e6e"
    />
    <path
      d="M282.258
  245.003l.057-.633.088-.353s-1.65.135-2.518-.108c-.868-.248-1.65-.606-2.462-1.29
  -.81-.687-1.128-1.116-1.708-1.202-1.39-.222-2.46.408-2.46.408s1.044.384
  1.828 1.34c.778.965 1.628 1.45 1.995 1.568.608.185 2.72.054 3.3.077.578.03
  1.88.196 1.88.196"
      fill="#db4446"
    />
    <path
      d="M282.258
  245.003l.057-.633.088-.353s-1.65.135-2.518-.108c-.868-.248-1.65-.606-2.462-1.29
  -.81-.687-1.128-1.116-1.708-1.202-1.39-.222-2.46.408-2.46.408s1.044.384
  1.828 1.34c.778.965 1.628 1.45 1.995 1.568.608.185 2.72.054 3.3.077.578.03
  1.88.196 1.88.196v-.003z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M289.972 242.683s.004.738.076 1.444c.07.677-.218 1.268-.107
  1.643.108.38.16.677.308.948.138.274.215.965.215.965s-.395-.28-.76-.553c-.36-.276-.62-.447-.62-.447s.074.738.108
  1.054c.04.317.225.913.524 1.266.296.345.888.912 1.07 1.367.19.46.152 1.47.152
  1.47s-.48-.77-.893-.906c-.405-.142-1.296-.637-1.296-.637s.817.81.817 1.582c0
  .77-.333 1.646-.333
  1.646s-.37-.698-.848-1.156c-.483-.455-1.152-.912-1.152-.912s.522 1.19.522
  1.995c0 .81-.145 2.53-.145
  2.53s-.408-.664-.815-.988c-.41-.312-.89-.592-1.04-.8-.146-.21.485.662.552
  1.19.073.527.323 2.406 1.967 4.806.96 1.405 2.447 3.86 5.63 3.052 3.186-.804
  2.003-5.086
  1.336-7.087-.67-1.996-1.003-4.21-.964-4.985.035-.767.59-3.047.518-3.473-.073
  -.417-.246-2.047.15-3.36.407-1.366.745-1.897.963-2.46.22-.56.403-.878.477-1.368.073-.492.073-1.41.073-1.41s.59
  1.093.74 1.478c.147.39.147 1.542.147 1.542s.11-1.153 1.003-1.713c.89-.568
  1.926-1.16 2.186-1.477.258-.32.334-.526.334-.526s-.076 1.967-.63
  2.74c-.368.503-1.82 2.14-1.82 2.14s.746-.285 1.263-.313c.52-.04.888 0 .888
  0s-.63.492-1.444 1.684c-.813 1.19-.48 1.294-1.073 2.276-.596.98-1.077
  1.018-1.818 1.615-1.114.894-.514 4.45-.37 4.978.15.523 2.075 4.878 2.113
  5.93.034 1.055.223 3.404-1.632 4.912-1.193.98-3.147.986-3.593
  1.266-.446.277-1.33 1.154-1.33 2.98 0 1.828.663 2.105 1.18 2.563.523.457
  1.188.21 1.336.564.15.348.223.56.447.77.223.207.372.455.296.835-.073.39-.926
  1.267-1.222 1.903-.3.625-.89 2.28-.89 2.523 0 .248-.07 1.02.183 1.407 0 0 .926
  1.082.295
  1.29-.406.14-.805-.254-.997-.208-.553.145-.842.486-1.002.457-.37-.07-.37-.25-.407-.77-.034-.525-.015-.74-.18-.74-.224
  0-.335.183-.373.46-.04.28-.04.908-.296.908-.26
  0-.63-.455-.852-.556-.223-.107-.852-.21-.888-.49-.038-.28.37-.877.776-.984.408-.105.78
  -.312.52-.522-.26-.212-.52-.212-.777
  0-.26.21-.814.032-.78-.284.04-.316.113-.7.073-.876-.035-.17-.48-.524.107-.845.595-.312.852.285
  1.444.178.596-.1.892-.32
  1.114-.664.224-.35.185-1.09-.222-1.542-.407-.46-.814-.532-.964-.812-.15-.28-.368-.946-.368-.946s.107
  1.226.034
  1.404c-.072.175-.034.91-.034.91s-.408-.456-.74-.804c-.33-.352-.666-1.403-.666-1.403s-.035.98-.035
  1.37c0
  .38.44.734.295.877-.15.138-.85-.74-1.036-.877-.19-.14-.78-.596-1.042-1.09-.257
  -.492-.446-1.19-.518-1.44-.075-.245-.195-1.34-.075-1.615.186-.42.483-1.157.483-1.157h-1.445c-.775
  0-1.332-.243-1.628.28-.295.528-.15 1.582.22 2.95.372 1.362.59 2.032.483
  2.28-.108.243-.59.807-.775.908-.19.11-.707.073-.93-.03-.22-.105-.587-.283-1.294-.283-.704
  0-1.15.033-1.406-.03-.26-.07-.89-.388-1.19-.318-.297.07-.808.333-.67.738.228.632
  -.218.774-.517.738-.296-.037-.55-.142-.925-.245-.368-.108-.926
  0-.85-.424.07-.423.22-.456.404-.768.188-.32.257-.524.05-.546-.26-.026-.522-.055
  -.725.112-.195.164-.514.518-.775.386-.262-.138-.465-.44-.465-1.102
  0-.658-.695-1.232-.057-1.204.637.03 1.448.496
  1.593.14.143-.36.055-.52-.29-.8-.35-.273-.78-.437-.317-.794.46-.353.576-.353.753
  -.55.173-.188.42-.805.745-.653.636.303.03.743.667
  1.45.638.714 1.04.966 2.112.852 1.07-.108 1.363-.247 1.363-.55
  0-.3-.09-.846-.12-1.068-.026-.218.148-1.015.148-1.015s-.493.305-.638.602c-.14.303
  -.43.82-.43.82s-.12-.615-.085-1.117c.02-.294.124-.807.112-.91-.028-.27-.23-.958-.23
  -.958s-.175.74-.29.96c-.114.217-.172 1.098-.172
  1.098s-.68-.593-.49-1.59c.14-.767-.117-1.78.113-2.112.228-.33.777-1.673
  2.11-1.73 1.33-.05 2.37.057 2.837.032.463-.033 2.112-.33
  2.112-.33s-3.04-1.56-3.733-2.03c-.695-.462-1.766-1.67-2.115-2.218-.348-.55-.666
  -1.615-.666-1.615s-.546.025-1.04.298a5.25
  5.25 0 0 0-1.27 1.01c-.294.332-.754 1.074-.754
  1.074s.084-.96.084-1.26c0-.296-.057-.88-.057-.88s-.346 1.315-1.04
  1.805c-.696.497-1.506 1.18-1.506
  1.18s.09-.733.09-.9c0-.164.172-1.015.172-1.015s-.49.734-1.244.88c-.752.135-1.854.106-1.942.575-.084.46.203
  1.094.03
  1.42-.174.33-.548.55-.548.55s-.434-.36-.81-.386c-.377-.028-.726.167-.726.167s
  -.32-.413-.204-.685c.12-.273.695-.684.553-.85-.145-.165-.61.058-.897.192
  -.288.138-.898.272-.84-.193.057-.466.202-.738.057-1.07-.144-.322-.058-.545.176-.628.23-.076
  1.155.025 1.244-.185.085-.22-.23-.495-.84-.633-.608-.134-.9-.493-.58-.795.32
  -.303.406-.382.55-.658.145-.276.202-.77.754-.52.546.244.43.847
  1.015 1.04.57.195 1.934-.08 2.22-.244.294-.168 1.218-.85 1.537-1.018.32-.16
  1.647-1.15
  1.647-1.15s-.78-.542-1.07-.82c-.29-.274-.807-.93-1.064-1.067-.262-.142-1.536
  -.63-1.97-.658-.434-.03-1.766-.49-1.766-.49s.607-.197.81-.36c.203-.165.66-.58.898
  -.55.227.03.283.03.283.03s-1.24-.06-1.5-.135c-.26-.088-1.014-.553-1.3-.553-.293
  0-.87.112-.87.112s.78-.494 1.418-.604c.637-.105 1.13-.084
  1.13-.084s-.984-.273-1.218-.6c-.23-.33-.46-.818-.638-1.04-.172-.217-.288-.578
  -.607-.603-.318-.028-.87.387-1.186.357-.315-.024-.55-.222-.58-.684-.023-.468
  0-.305-.11-.55-.116-.25-.58-.824-.147-.958.438-.138 1.363.08
  1.447-.084.09-.163-.49-.657-.866-.85-.377-.19-.984-.52-.666-.793.32-.272.636
  -.38.81-.628.174-.248.377-.932.754-.715.375.22.897
  1.29 1.186 1.208.292-.084.314-.85.26-1.177-.057-.332
  0-.906.285-.852.292.054.522.437.987.47.46.024 1.155-.11
  1.097.214-.056.327-.318.734-.636 1.094-.31.36-.457 1.07-.257 1.534.203.47.726
  1.212 1.187 1.51.46.3 1.328.524 1.88.876.55.36 1.825 1.37 2.26
  1.48.432.113.866.33.866.33s.49-.217 1.16-.217c.664 0 2.196.108 2.775-.14.58-.25
  1.333-.657 1.1-1.18-.227-.518-1.503-.987-1.387-1.393.115-.41.58-.44
  1.358-.47.78-.025 1.85.14
  2.052-.96.202-1.093.26-1.727-.834-1.97-1.102-.248-1.914-.272-2.11-1.07-.206-.792-.41-.984-.18-1.206.236-.215.638-.328
  1.45-.378.81-.058 1.734-.058 1.996-.255.26-.185.315-.71.633-.93.317-.215
  1.567-.412 1.567-.412s1.487.73 2.868 1.754c1.236.923 2.354 2.287 2.354 2.287"
      fill="#ed72aa"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M279.305
  241.912s-.173-.494-.202-.632c-.027-.135-.117-.302-.117-.302s.9 0
  .87.272c-.028.277-.29.277-.347.382-.058.113-.205.28-.205.28"
    />
    <path
      d="M279.305
  241.912s-.173-.494-.202-.632c-.027-.135-.117-.302-.117-.302s.9 0
  .87.272c-.028.277-.29.277-.347.382-.058.113-.205.28-.205.28z"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M283.418 240.483l-.062-.44s.784.008 1.16.273c.58.41.952 1.043.925
  1.07-.1.097-.548-.274-.867-.382 0 0-.23.054-.464.054-.23
  0-.347-.11-.378-.218-.025-.113.03-.302.03-.302l-.344-.05"
    />
    <path
      d="M283.418 240.483l-.062-.44s.784.008 1.16.273c.58.41.952 1.043.925
  1.07-.1.097-.548-.274-.867-.382 0 0-.23.054-.464.054-.23
  0-.347-.11-.378-.218-.025-.113.03-.302.03-.302l-.344-.05v-.004z"
      fill="none"
      stroke="#000"
      strokeWidth=".051"
    />
    <path
      d="M289.062 246.68s-.35-.495-.434-.662a7.966 7.966 0 0 1-.235-.49"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M267.87 241.745s.493.352.87.407c.376.058.783.058.84.058.058 0
  .177-.553.115-.93-.203-1.236-1.332-1.51-1.332-1.51s.337.75.173
  1.095c-.23.494-.665.88-.665.88"
      fill="#db4446"
    />
    <path
      d="M267.87 241.745s.493.352.87.407c.376.058.783.058.84.058.058 0
  .177-.553.115-.93-.203-1.236-1.332-1.51-1.332-1.51s.337.75.173
  1.095c-.23.494-.665.88-.665.88z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M265.526
  242.814s-.435-.796-1.36-.687c-.925.108-1.535.825-1.535.825s1.023-.032
  1.275.134c.377.248.492.88.492.88s.553-.33.725-.55c.173-.22.403-.602.403-.602"
      fill="#db4446"
    />
    <path
      d="M265.526
  242.814s-.435-.796-1.36-.687c-.925.108-1.535.825-1.535.825s1.023-.032
  1.275.134c.377.248.492.88.492.88s.553-.33.725-.55c.173-.22.403-.602.403-.602z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M264.397 246.018s-.78.112-1.213.607c-.44.494-.376 1.425-.376
  1.425s.517-.545.982-.545c.466 0 1.187.164
  1.187.164s-.23-.583-.23-.828-.35-.824-.35-.824"
      fill="#db4446"
    />
    <path
      d="M264.397 246.018s-.78.112-1.213.607c-.44.494-.376 1.425-.376
  1.425s.517-.545.982-.545c.466 0 1.187.164
  1.187.164s-.23-.583-.23-.828-.35-.824-.35-.824z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path d="M279.336 245.908l.345-.545.342.495-.686.05" />
    <path
      d="M279.336 245.908l.345-.545.342.495-.686.05"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path d="M280.173 245.883l.406-.546.435.49-.842.056" />
    <path
      d="M280.173 245.883l.406-.546.435.49-.842.056"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path d="M279.798 242.512l.84.302-.753.382-.087-.684" />
    <path
      d="M279.798 242.512l.84.302-.753.382-.087-.684"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path d="M280.81 242.788l.752.19-.606.465-.146-.655" />
    <path
      d="M280.81 242.788l.752.19-.606.465-.146-.655"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M275.165 249.228s-.824.24-1.128.684c-.376.548-.348 1.098-.348
  1.098s.693-.574 1.59-.33c.9.246.984.33 1.364.304.377-.028 1.303-.36
  1.303-.36s-.753.877-.668 1.484c.086.6.198.872.174 1.178-.062.742-.61 1.647-.61
  1.647s.32-.196 1.072-.36a4.884 4.884 0 0 0
  1.793-.822c.402-.304.924-1.042.924-1.042s-.168 1.015 0 1.454c.173.44.23 1.702.23
  1.702s.484-.428.867-.633c.205-.11.727-.384.932-.71.14-.234.32-1.092.32-1.092s.113.923.4
  1.37c.293.433.722 1.783.722
  1.783s.293-.876.61-1.235c.317-.357.697-.822.723-1.098.027-.273-.084-.874-.084
  -.874l.403.874m-11.69.63s.49-.85.955-1.126c.466-.274
  1.103-.766 1.275-.82.17-.055.926-.47.926-.47m1.014 5.295s1.115-.57
  1.447-.77c.697-.408 1.188-1.15 1.188-1.15"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M267.03
  256.38s-.433-.46-1.186-.327c-.753.14-1.244.99-1.244.99s.637-.17
  1.015-.085c.374.084.637.466.637.466s.345-.302.46-.466c.116-.162.315-.577.315-.577"
      fill="#db4446"
    />
    <path
      d="M267.03
  256.38s-.433-.46-1.186-.327c-.753.14-1.244.99-1.244.99s.637-.17
  1.015-.085c.374.084.637.466.637.466s.345-.302.46-.466c.116-.162.315-.577.315-.577h.003z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M266.162 259.423s-.637-.107-1.185.332c-.55.44-.58 1.287-.58
  1.287s.522-.44.93-.38c.402.05.894.27.894.27s.09-.523.115-.657c.09-.383-.173-.852-.173-.852"
      fill="#db4446"
    />
    <path
      d="M266.162 259.423s-.637-.107-1.185.332c-.55.44-.58 1.287-.58
  1.287s.522-.44.93-.38c.402.05.894.27.894.27s.09-.523.115-.657c.09-.383-.173-.852-.173-.852z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M267.64 262.224s-.04.814.347 1.313c.407.523 1.16.604
  1.16.604s-.254-.545-.292-.822c-.058-.41.346-.767.346-.767s-.372-.38-.748-.38c-.378
  0-.81.054-.81.054"
      fill="#db4446"
    />
    <path
      d="M267.64 262.224s-.04.814.347 1.313c.407.523 1.16.604
  1.16.604s-.254-.545-.292-.822c-.058-.41.346-.767.346-.767s-.372-.38-.748-.38c-.378
  0-.81.054-.81.054zm17.08 1.366s2.084 1.292 2.027 2.36c-.062 1.07-1.16 2.47-1.16
  2.47"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M275.165
  269.434s-.52-.658-1.243-.632c-.726.03-1.48.71-1.48.71s.9-.078
  1.13.22c.235.306.465.684.465.684s.404-.215.58-.353c.174-.134.548-.63.548-.63"
      fill="#db4446"
    />
    <path
      d="M275.165
  269.434s-.52-.658-1.243-.632c-.726.03-1.48.71-1.48.71s.9-.078
  1.13.22c.235.306.465.684.465.684s.404-.215.58-.353c.174-.134.548-.63.548-.63z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M272.936 272.31s-.952-.137-1.417.357c-.466.49-.435 1.396-.435
  1.396s.58-.628 1.1-.575c.52.055 1.1.332 1.1.332s-.09-.55-.146-.797a14.064 14.064
  0 0 0-.204-.713"
      fill="#db4446"
    />
    <path
      d="M272.936 272.31s-.952-.137-1.417.357c-.466.49-.435 1.396-.435
  1.396s.58-.628 1.1-.575c.52.055 1.1.332 1.1.332s-.09-.55-.146-.797a14.064 14.064
  0 0 0-.204-.713z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M274.993 275.355s-.464.657-.114 1.177c.344.523 1.067.77
  1.067.77s-.258-.38-.143-.825c.092-.348.695-.82.695-.82l-1.507-.3"
      fill="#db4446"
    />
    <path
      d="M274.993 275.355s-.464.657-.114 1.177c.344.523 1.067.77
  1.067.77s-.258-.38-.143-.825c.092-.348.695-.82.695-.82l-1.507-.3v-.002z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M287.698 276.59s-.835-.196-1.3.08c-.46.273-.838 1.426-.838
  1.426s.753-.63 1.303-.553c.547.087.955.306.955.306s.084-.47.026-.794a2.756 2.756
  0 0 0-.146-.465"
      fill="#db4446"
    />
    <path
      d="M287.698 276.59s-.835-.196-1.3.08c-.46.273-.838 1.426-.838
  1.426s.753-.63 1.303-.553c.547.087.955.306.955.306s.084-.47.026-.794a2.756 2.756
  0 0 0-.146-.465z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M288.106 279.656s-.637.658-.408 1.207a7.32 7.32 0 0 0 .638
  1.123s-.023-.817.235-1.04c.373-.327 1.068-.384
  1.068-.384s-.55-.492-.725-.546c-.17-.058-.807-.36-.807-.36"
      fill="#db4446"
    />
    <path
      d="M288.106 279.656s-.637.658-.408 1.207a7.32 7.32 0 0 0 .638
  1.123s-.023-.817.235-1.04c.373-.327 1.068-.384
  1.068-.384s-.55-.492-.725-.546c-.17-.058-.807-.36-.807-.36z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M291.288 280.62s-.318.792.292 1.312c.606.523 1.13.578
  1.13.578s-.465-.826-.32-1.262c.15-.455.55-.74.55-.74s-.754-.248-.868-.22c-.115.026-.784.33-.784.33"
      fill="#db4446"
    />
    <path
      d="M291.288 280.62s-.318.792.292 1.312c.606.523 1.13.578
  1.13.578s-.465-.826-.32-1.262c.15-.455.55-.74.55-.74s-.754-.248-.868-.22c-.115.026-.784.33-.784.33z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M258.683 337.46c2.143.644 3.23 2.24 3.23 4.11 0 2.432-2.362
  4.278-5.425 4.278-3.064 0-5.548-1.846-5.548-4.28 0-1.84 1.02-3.894 3.152-4.042 0
  0-.066-.194-.25-.51-.22-.236-.656-.676-.656-.676s.813-.153
  1.282.026c.472.178.783.476.783.476s.224-.436.535-.774c.315-.335.722-.546.722
  -.546s.47.396.626.66c.153.275.254.598.254.598s.433-.357.81-.502c.375-.15.86-.265.86
  -.265s-.14.47-.232.708-.142.74-.142.74"
      fill="#ffd691"
      stroke="#000"
      strokeWidth=".532"
    />
    <path
      d="M256.035
  348.49s-4.074-2.747-5.84-3.118c-2.26-.476-4.796-.09-5.894-.15.032.033 1.318.95
  1.882 1.517.564.563 2.446 1.69 3.51 1.956 3.305.83 6.343-.207
  6.343-.207m1.161.24s2.61-2.713 5.342-3.087c3.227-.447 5.34.267 6.593.594.03
  0-1.036.505-1.598.89-.567.386-2.02 1.604-4.244
  1.63-2.226.033-4.683-.234-5.09-.17-.408.053-1.003.144-1.003.144"
      fill="#058e6e"
      stroke="#000"
      strokeWidth=".532"
    />
    <path
      d="M256.415 345.372a5.175 5.175 0 0 1 .003-7.607 5.168 5.168 0 0 1 1.655
  3.8 5.19 5.19 0 0 1-1.658 3.807"
      fill="#ad1519"
      stroke="#000"
      strokeWidth=".532"
    />
    <path
      d="M255.378
  350.935s.63-1.555.69-2.894c.054-1.118-.157-2.224-.157-2.224h.815s.408 1.19.408
  2.225c0 1.04-.19 2.423-.19 2.423s-.563.087-.75.174c-.186.09-.815.298-.815.298"
      fill="#058e6e"
      stroke="#000"
      strokeWidth=".532"
    />
    <path
      d="M306.91 203.442c0-.59.504-1.06 1.123-1.06.622 0 1.12.47 1.12 1.06 0
  .59-.498 1.06-1.12 1.06-.62 0-1.122-.47-1.122-1.06"
      fill="#fff"
    />
    <path
      d="M306.91 203.442c0-.59.504-1.06 1.123-1.06.622 0 1.12.47 1.12 1.06 0
  .59-.498 1.06-1.12 1.06-.62 0-1.122-.47-1.122-1.06z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M308.446 200.722c0-.585.5-1.058 1.123-1.058.616 0 1.12.473 1.12 1.058
  0 .593-.504 1.065-1.12 1.065-.624 0-1.124-.472-1.124-1.065"
      fill="#fff"
    />
    <path
      d="M308.446 200.722c0-.585.5-1.058 1.123-1.058.616 0 1.12.473 1.12 1.058
  0 .593-.504 1.065-1.12 1.065-.624 0-1.124-.472-1.124-1.065z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M309.467 197.574c0-.586.504-1.062 1.128-1.062.617 0 1.12.476 1.12
  1.062 0 .588-.503 1.065-1.12 1.065-.624 0-1.128-.478-1.128-1.066"
      fill="#fff"
    />
    <path
      d="M309.467 197.574c0-.586.504-1.062 1.128-1.062.617 0 1.12.476 1.12
  1.062 0 .588-.503 1.065-1.12 1.065-.624 0-1.128-.478-1.128-1.066z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M309.6 194.194c0-.588.5-1.06 1.12-1.06.617 0 1.12.472 1.12 1.06 0
  .59-.503 1.062-1.12 1.062-.62 0-1.12-.472-1.12-1.062"
      fill="#fff"
    />
    <path
      d="M309.6 194.194c0-.588.5-1.06 1.12-1.06.617 0 1.12.472 1.12 1.06 0
  .59-.503 1.062-1.12 1.062-.62 0-1.12-.472-1.12-1.062z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M308.766 190.868c0-.585.504-1.058 1.12-1.058.623 0 1.126.473 1.126
  1.058 0 .592-.503 1.065-1.126 1.065-.616 0-1.12-.473-1.12-1.065"
      fill="#fff"
    />
    <path
      d="M308.766 190.868c0-.585.504-1.058 1.12-1.058.623 0 1.126.473 1.126
  1.058 0 .592-.503 1.065-1.126 1.065-.616 0-1.12-.473-1.12-1.065z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M307.038 187.85c0-.59.503-1.062 1.122-1.062.62 0 1.123.473 1.123
  1.062 0 .59-.502 1.066-1.123 1.066-.62 0-1.122-.476-1.122-1.066"
      fill="#fff"
    />
    <path
      d="M307.038 187.85c0-.59.503-1.062 1.122-1.062.62 0 1.123.473 1.123
  1.062 0 .59-.502 1.066-1.123 1.066-.62 0-1.122-.476-1.122-1.066z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M304.734 185.37c0-.59.503-1.065 1.122-1.065.622 0 1.124.476 1.124
  1.065 0 .585-.502 1.06-1.124 1.06-.62 0-1.122-.475-1.122-1.06"
      fill="#fff"
    />
    <path
      d="M304.734 185.37c0-.59.503-1.065 1.122-1.065.622 0 1.124.476 1.124
  1.065 0 .585-.502 1.06-1.124 1.06-.62 0-1.122-.475-1.122-1.06z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M302.058 183.308c0-.59.504-1.066 1.126-1.066.618 0 1.12.476 1.12
  1.066 0 .585-.502 1.06-1.12 1.06-.622 0-1.126-.475-1.126-1.06"
      fill="#fff"
    />
    <path
      d="M302.058 183.308c0-.59.504-1.066 1.126-1.066.618 0 1.12.476 1.12
  1.066 0 .585-.502 1.06-1.12 1.06-.622 0-1.126-.475-1.126-1.06z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M298.86 181.62c0-.588.503-1.057 1.122-1.057.622 0 1.124.47 1.124
  1.058 0 .59-.502 1.067-1.124 1.067-.62 0-1.12-.476-1.12-1.066"
      fill="#fff"
    />
    <path
      d="M298.86 181.62c0-.588.503-1.057 1.122-1.057.622 0 1.124.47 1.124
  1.058 0 .59-.502 1.067-1.124 1.067-.62 0-1.12-.476-1.12-1.066z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M295.543 180.418c0-.586.503-1.062 1.124-1.062.618 0 1.123.476 1.123
  1.062 0 .588-.505 1.064-1.123 1.064-.62 0-1.124-.476-1.124-1.064"
      fill="#fff"
    />
    <path
      d="M295.543 180.418c0-.586.503-1.062 1.124-1.062.618 0 1.123.476 1.123
  1.062 0 .588-.505 1.064-1.123 1.064-.62 0-1.124-.476-1.124-1.064z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M291.834 179.755c0-.585.502-1.065 1.12-1.065.624 0 1.125.48 1.125
  1.065 0 .586-.502 1.063-1.125 1.063-.62 0-1.12-.477-1.12-1.063"
      fill="#fff"
    />
    <path
      d="M291.834 179.755c0-.585.502-1.065 1.12-1.065.624 0 1.125.48 1.125
  1.065 0 .586-.502 1.063-1.125 1.063-.62 0-1.12-.477-1.12-1.063z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M288.313 179.57c0-.586.503-1.058 1.125-1.058.618 0 1.122.472 1.122
  1.058 0 .59-.504 1.066-1.122 1.066-.622 0-1.125-.477-1.125-1.066"
      fill="#fff"
    />
    <path
      d="M288.313 179.57c0-.586.503-1.058 1.125-1.058.618 0 1.122.472 1.122
  1.058 0 .59-.504 1.066-1.122 1.066-.622 0-1.125-.477-1.125-1.066z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M284.857 179.69c0-.585.504-1.06 1.125-1.06.623 0 1.12.475 1.12 1.06 0
  .59-.497 1.065-1.12 1.065-.62 0-1.125-.476-1.125-1.064"
      fill="#fff"
    />
    <path
      d="M284.857 179.69c0-.585.504-1.06 1.125-1.06.623 0 1.12.475 1.12 1.06 0
  .59-.497 1.065-1.12 1.065-.62 0-1.125-.476-1.125-1.064z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M281.406 179.69c0-.585.502-1.06 1.12-1.06.618 0 1.12.475 1.12 1.06 0
  .59-.502 1.065-1.12 1.065-.618 0-1.12-.476-1.12-1.064"
      fill="#fff"
    />
    <path
      d="M281.406 179.69c0-.585.502-1.06 1.12-1.06.618 0 1.12.475 1.12 1.06 0
  .59-.502 1.065-1.12 1.065-.618 0-1.12-.476-1.12-1.064z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M283.134 182.654c0-.59.503-1.065 1.12-1.065.623 0 1.122.475 1.122
  1.064 0 .586-.5 1.065-1.122 1.065-.617 0-1.12-.48-1.12-1.066m.703
  3.275c0-.59.502-1.066 1.12-1.066.623 0 1.126.478 1.126 1.067 0 .58-.503
  1.057-1.125 1.057-.62 0-1.12-.476-1.12-1.057m.125 3.26c0-.593.503-1.066
  1.125-1.066.62 0 1.12.473 1.12 1.066 0 .585-.5 1.062-1.12 1.062-.622
  0-1.125-.477-1.125-1.062m-1.021 2.967c0-.586.502-1.065 1.12-1.065.623 0 1.122.48
  1.122 1.065 0 .588-.5 1.062-1.122 1.062-.618 0-1.12-.474-1.12-1.062m-1.909
  2.728c0-.586.504-1.065 1.12-1.065.622 0 1.123.48 1.123 1.065s-.5 1.06-1.122
  1.06c-.617 0-1.12-.475-1.12-1.06"
      fill="#fff"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M278.79 177.632c0-.585.504-1.06 1.126-1.06.618 0 1.12.475 1.12 1.06 0
  .59-.502 1.065-1.12 1.065-.622 0-1.125-.476-1.125-1.065"
      fill="#fff"
    />
    <path
      d="M278.79 177.632c0-.585.504-1.06 1.126-1.06.618 0 1.12.475 1.12 1.06 0
  .59-.502 1.065-1.12 1.065-.622 0-1.125-.476-1.125-1.065z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M275.723 175.938c0-.586.5-1.065 1.122-1.065.618 0 1.12.48 1.12
  1.065s-.502 1.06-1.12 1.06c-.622 0-1.123-.475-1.123-1.06"
      fill="#fff"
    />
    <path
      d="M275.723 175.938c0-.586.5-1.065 1.122-1.065.618 0 1.12.48 1.12
  1.065s-.502 1.06-1.12 1.06c-.622 0-1.123-.475-1.123-1.06z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M272.34 174.916c0-.586.504-1.058 1.125-1.058.618 0 1.122.472 1.122
  1.058 0 .588-.504 1.066-1.122 1.066-.62 0-1.124-.478-1.124-1.066"
      fill="#fff"
    />
    <path
      d="M272.34 174.916c0-.586.504-1.058 1.125-1.058.618 0 1.122.472 1.122
  1.058 0 .588-.504 1.066-1.122 1.066-.62 0-1.124-.478-1.124-1.066z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M268.824 174.312c0-.585.504-1.06 1.12-1.06.623 0 1.122.475 1.122 1.06
  0 .585-.5 1.062-1.12 1.062-.618 0-1.122-.477-1.122-1.062"
      fill="#fff"
    />
    <path
      d="M268.824 174.312c0-.585.504-1.06 1.12-1.06.623 0 1.122.475 1.122 1.06
  0 .585-.5 1.062-1.12 1.062-.618 0-1.122-.477-1.122-1.062z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M265.38 174.37c0-.585.502-1.065 1.12-1.065.623 0 1.126.48 1.126 1.065
  0 .59-.503 1.063-1.125 1.063-.618 0-1.12-.473-1.12-1.063"
      fill="#fff"
    />
    <path
      d="M265.38 174.37c0-.585.502-1.065 1.12-1.065.623 0 1.126.48 1.126 1.065
  0 .59-.503 1.063-1.125 1.063-.618 0-1.12-.473-1.12-1.063z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M261.797 174.974c0-.585.504-1.06 1.122-1.06.62 0 1.124.475 1.124 1.06
  0 .592-.503 1.066-1.125 1.066-.62 0-1.123-.474-1.123-1.066"
      fill="#fff"
    />
    <path
      d="M261.797 174.974c0-.585.504-1.06 1.122-1.06.62 0 1.124.475 1.124 1.06
  0 .592-.503 1.066-1.125 1.066-.62 0-1.123-.474-1.123-1.066z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M258.48 176.12c0-.59.503-1.066 1.12-1.066.623 0 1.126.476 1.126 1.066
  0 .585-.503 1.065-1.125 1.065-.617 0-1.12-.48-1.12-1.065"
      fill="#fff"
    />
    <path
      d="M258.48 176.12c0-.59.503-1.066 1.12-1.066.623 0 1.126.476 1.126 1.066
  0 .585-.503 1.065-1.125 1.065-.617 0-1.12-.48-1.12-1.065z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M202.297 203.442c0-.59.503-1.06 1.12-1.06.623 0 1.122.47 1.122 1.06 0
  .59-.5 1.06-1.122 1.06-.618 0-1.12-.47-1.12-1.06"
      fill="#fff"
    />
    <path
      d="M202.297 203.442c0-.59.503-1.06 1.12-1.06.623 0 1.122.47 1.122 1.06 0
  .59-.5 1.06-1.122 1.06-.618 0-1.12-.47-1.12-1.06z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M200.76 200.722c0-.585.503-1.058 1.122-1.058.622 0 1.125.473 1.125
  1.058 0 .593-.503 1.065-1.125 1.065-.62 0-1.12-.472-1.12-1.065"
      fill="#fff"
    />
    <path
      d="M200.76 200.722c0-.585.503-1.058 1.122-1.058.622 0 1.125.473 1.125
  1.058 0 .593-.503 1.065-1.125 1.065-.62 0-1.12-.472-1.12-1.065z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M199.736 197.574c0-.586.502-1.062 1.125-1.062.62 0 1.122.476 1.122
  1.062 0 .588-.503 1.065-1.12 1.065-.624 0-1.126-.478-1.126-1.066"
      fill="#fff"
    />
    <path
      d="M199.736 197.574c0-.586.502-1.062 1.125-1.062.62 0 1.122.476 1.122
  1.062 0 .588-.503 1.065-1.12 1.065-.624 0-1.126-.478-1.126-1.066z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M199.61 194.194c0-.588.502-1.06 1.12-1.06.622 0 1.125.472 1.125 1.06
  0 .59-.503 1.062-1.124 1.062-.618 0-1.12-.472-1.12-1.062"
      fill="#fff"
    />
    <path
      d="M199.61 194.194c0-.588.502-1.06 1.12-1.06.622 0 1.125.472 1.125 1.06
  0 .59-.503 1.062-1.124 1.062-.618 0-1.12-.472-1.12-1.062z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M200.442 190.868c0-.585.503-1.058 1.12-1.058.62 0 1.123.473 1.123
  1.058 0 .592-.504 1.065-1.123 1.065-.617 0-1.12-.473-1.12-1.065"
      fill="#fff"
    />
    <path
      d="M200.442 190.868c0-.585.503-1.058 1.12-1.058.62 0 1.123.473 1.123
  1.058 0 .592-.504 1.065-1.123 1.065-.617 0-1.12-.473-1.12-1.065z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M202.166 187.85c0-.59.503-1.062 1.126-1.062.617 0 1.12.473 1.12 1.062
  0 .59-.503 1.066-1.12 1.066-.623 0-1.127-.476-1.127-1.066"
      fill="#fff"
    />
    <path
      d="M202.166 187.85c0-.59.503-1.062 1.126-1.062.617 0 1.12.473 1.12 1.062
  0 .59-.503 1.066-1.12 1.066-.623 0-1.127-.476-1.127-1.066z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M204.47 185.37c0-.59.503-1.065 1.125-1.065.617 0 1.12.476 1.12 1.065
  0 .585-.503 1.06-1.12 1.06-.622 0-1.124-.475-1.124-1.06"
      fill="#fff"
    />
    <path
      d="M204.47 185.37c0-.59.503-1.065 1.125-1.065.617 0 1.12.476 1.12 1.065
  0 .585-.503 1.06-1.12 1.06-.622 0-1.124-.475-1.124-1.06z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M207.146 183.308c0-.59.503-1.066 1.125-1.066.62 0 1.123.476 1.123
  1.066 0 .585-.504 1.06-1.122 1.06-.62 0-1.124-.475-1.124-1.06"
      fill="#fff"
    />
    <path
      d="M207.146 183.308c0-.59.503-1.066 1.125-1.066.62 0 1.123.476 1.123
  1.066 0 .585-.504 1.06-1.122 1.06-.62 0-1.124-.475-1.124-1.06z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M210.344 181.62c0-.588.503-1.057 1.126-1.057.618 0 1.12.47 1.12 1.058
  0 .59-.502 1.067-1.12 1.067-.623 0-1.126-.476-1.126-1.066"
      fill="#fff"
    />
    <path
      d="M210.344 181.62c0-.588.503-1.057 1.126-1.057.618 0 1.12.47 1.12 1.058
  0 .59-.502 1.067-1.12 1.067-.623 0-1.126-.476-1.126-1.066z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M213.662 180.418c0-.586.503-1.062 1.12-1.062.623 0 1.126.476 1.126
  1.062 0 .588-.503 1.064-1.125 1.064-.618 0-1.12-.476-1.12-1.064"
      fill="#fff"
    />
    <path
      d="M213.662 180.418c0-.586.503-1.062 1.12-1.062.623 0 1.126.476 1.126
  1.062 0 .588-.503 1.064-1.125 1.064-.618 0-1.12-.476-1.12-1.064z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M217.375 179.755c0-.585.502-1.065 1.12-1.065.62 0 1.122.48 1.122
  1.065 0 .586-.503 1.063-1.12 1.063-.62 0-1.122-.477-1.122-1.063"
      fill="#fff"
    />
    <path
      d="M217.375 179.755c0-.585.502-1.065 1.12-1.065.62 0 1.122.48 1.122
  1.065 0 .586-.503 1.063-1.12 1.063-.62 0-1.122-.477-1.122-1.063z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M220.892 179.57c0-.586.502-1.058 1.125-1.058.617 0 1.12.472 1.12
  1.058 0 .59-.503 1.066-1.12 1.066-.623 0-1.125-.477-1.125-1.066"
      fill="#fff"
    />
    <path
      d="M220.892 179.57c0-.586.502-1.058 1.125-1.058.617 0 1.12.472 1.12
  1.058 0 .59-.503 1.066-1.12 1.066-.623 0-1.125-.477-1.125-1.066z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M224.346 179.69c0-.585.504-1.06 1.122-1.06.622 0 1.125.475 1.125 1.06
  0 .59-.503 1.065-1.125 1.065-.618 0-1.122-.476-1.122-1.064"
      fill="#fff"
    />
    <path
      d="M224.346 179.69c0-.585.504-1.06 1.122-1.06.622 0 1.125.475 1.125 1.06
  0 .59-.503 1.065-1.125 1.065-.618 0-1.122-.476-1.122-1.064z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M227.802 179.69c0-.585.503-1.06 1.122-1.06.62 0 1.125.475 1.125 1.06
  0 .59-.505 1.065-1.126 1.065-.62 0-1.122-.476-1.122-1.064"
      fill="#fff"
    />
    <path
      d="M227.802 179.69c0-.585.503-1.06 1.122-1.06.62 0 1.125.475 1.125 1.06
  0 .59-.505 1.065-1.126 1.065-.62 0-1.122-.476-1.122-1.064z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M226.075 182.654c0-.59.503-1.065 1.12-1.065.623 0 1.126.475 1.126
  1.064 0 .586-.502 1.065-1.124 1.065-.618 0-1.12-.48-1.12-1.066m-.705
  3.275c0-.59.5-1.066 1.122-1.066.617 0 1.12.478 1.12 1.067 0 .58-.503 1.057-1.12
  1.057-.623 0-1.122-.476-1.122-1.057m-.13 3.26c0-.593.503-1.066 1.125-1.066.617 0
  1.12.473 1.12 1.066 0 .585-.503 1.062-1.12 1.062-.622
  0-1.125-.477-1.125-1.062m1.025 2.967c0-.586.503-1.065 1.12-1.065.623 0 1.126.48
  1.126 1.065 0 .588-.503 1.062-1.125 1.062-.618 0-1.12-.474-1.12-1.062m1.906
  2.728c0-.586.505-1.065 1.12-1.065.624 0 1.127.48 1.127 1.065s-.502 1.06-1.125
  1.06c-.616 0-1.12-.475-1.12-1.06"
      fill="#fff"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M230.414 177.632c0-.585.503-1.06 1.12-1.06.622 0 1.125.475 1.125 1.06
  0 .59-.504 1.065-1.125 1.065-.618 0-1.12-.476-1.12-1.065"
      fill="#fff"
    />
    <path
      d="M230.414 177.632c0-.585.503-1.06 1.12-1.06.622 0 1.125.475 1.125 1.06
  0 .59-.504 1.065-1.125 1.065-.618 0-1.12-.476-1.12-1.065z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M233.486 175.938c0-.586.502-1.065 1.12-1.065.62 0 1.125.48 1.125
  1.065s-.503 1.06-1.124 1.06c-.618 0-1.12-.475-1.12-1.06"
      fill="#fff"
    />
    <path
      d="M233.486 175.938c0-.586.502-1.065 1.12-1.065.62 0 1.125.48 1.125
  1.065s-.503 1.06-1.124 1.06c-.618 0-1.12-.475-1.12-1.06z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M236.863 174.916c0-.586.505-1.058 1.125-1.058s1.12.472 1.12 1.058c0
  .588-.5 1.066-1.12 1.066-.62 0-1.125-.478-1.125-1.066"
      fill="#fff"
    />
    <path
      d="M236.863 174.916c0-.586.505-1.058 1.125-1.058s1.12.472 1.12 1.058c0
  .588-.5 1.066-1.12 1.066-.62 0-1.125-.478-1.125-1.066z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M240.383 174.312c0-.585.504-1.06 1.122-1.06.623 0 1.126.475 1.126
  1.06 0 .585-.502 1.062-1.125 1.062-.618 0-1.122-.477-1.122-1.062"
      fill="#fff"
    />
    <path
      d="M240.383 174.312c0-.585.504-1.06 1.122-1.06.623 0 1.126.475 1.126
  1.06 0 .585-.502 1.062-1.125 1.062-.618 0-1.122-.477-1.122-1.062z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M243.83 174.37c0-.585.497-1.065 1.12-1.065.618 0 1.12.48 1.12 1.065 0
  .59-.502 1.063-1.12 1.063-.623 0-1.12-.473-1.12-1.063"
      fill="#fff"
    />
    <path
      d="M243.83 174.37c0-.585.497-1.065 1.12-1.065.618 0 1.12.48 1.12 1.065 0
  .59-.502 1.063-1.12 1.063-.623 0-1.12-.473-1.12-1.063z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M247.41 174.974c0-.585.504-1.06 1.12-1.06.625 0 1.124.475 1.124 1.06
  0 .592-.5 1.066-1.123 1.066-.616 0-1.12-.474-1.12-1.066"
      fill="#fff"
    />
    <path
      d="M247.41 174.974c0-.585.504-1.06 1.12-1.06.625 0 1.124.475 1.124 1.06
  0 .592-.5 1.066-1.123 1.066-.616 0-1.12-.474-1.12-1.066z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M250.725 176.12c0-.59.5-1.066 1.124-1.066.617 0 1.12.476 1.12 1.066 0
  .585-.503 1.065-1.12 1.065-.624 0-1.125-.48-1.125-1.065"
      fill="#fff"
    />
    <path
      d="M250.725 176.12c0-.59.5-1.066 1.124-1.066.617 0 1.12.476 1.12 1.066 0
  .585-.503 1.065-1.12 1.065-.624 0-1.125-.48-1.125-1.065z"
      fill="none"
      stroke="#000"
      strokeWidth=".399"
    />
    <path
      d="M222.264
  244.134h-.947v-.946h-1.656v3.785h1.657v2.602h-3.55v7.573h1.894v15.143h-3.785v7.81h29.104v
  -7.81h-3.787V257.15h1.893v-7.573h-3.55v-2.602h1.657v-3.785h-1.657v.946h-.946v-.946h
  -1.657v.946h-1.182v-.946h-1.656v3.785h1.657v2.602h-3.55v-8.28h1.894v-3.786H232.2v.945h
  -.945v-.946h-1.657v.945h-.946v-.946h-1.893v3.784h1.892v8.28h-3.55v-2.6h1.657v-3.786h
  -1.657v.946h-.947v-.946h-1.892v.946zm-6.39
  35.966h29.105m-29.105-1.894h29.104m-29.105-1.893h29.104m-29.105-1.892h29.104m-29.105
  -2.13h29.104m-25.32-1.655h21.533m-21.532-1.893h21.533m-21.532-2.13h21.533m-21.532
  -1.893h21.533m-21.532-1.894h21.533m-21.532-1.892h21.533m-21.532-1.894h21.533m-23.425
  -1.892h25.318m-25.318-1.893h25.318m-25.318-1.893h25.318m-25.318-1.893h25.318m-21.77
  -1.895h18.22m-10.884-1.892h3.55m-3.55-1.893h3.55m-3.55-1.893h3.55m-3.55-1.893h3.55m-5.443-2.365h7.334m-12.777
  8.043h3.786m-5.442-2.366h7.1m-7.1 34.783v-1.894m0-1.893v-1.892m-1.892
  1.893v1.893m3.55 0v-1.893m1.892
  3.787v-1.894m0-1.893v-1.892m0-2.13v-1.655m0-1.893v-2.13m-1.893 7.81v-2.13m-3.55
  2.13v-2.13m7.336
  0v2.13m1.656-2.13v-1.657m-5.443-1.893v1.893m3.786-1.893v1.893m3.55-1.893v1.893m-1.894
  -1.893v-2.13m1.892-1.893v1.893m0-5.68v1.893m-1.893-3.786v1.894m1.892-3.786v1.892m-3.55
  -1.892v1.892m-3.785-1.892v1.892m-1.656-3.785v1.893m3.55-1.893v1.893m3.55-1.893v1.893m1.892
  -3.786v1.893m-3.55-1.893v1.893m-3.785-1.893v1.893m-1.656-3.786v1.892m7.1-1.893v1.892m-3.55
  -5.68v1.893m16.326-1.892h-3.785m5.443-2.366h-7.1m7.1
  34.783v-1.894m0-1.893v-1.892m1.893 1.893v1.893m-3.55 0v-1.893m-1.893
  3.787v-1.894m0-1.893v-1.892m0-2.13v-1.655m0-1.893v-2.13m1.893 7.81v-2.13m3.55
  2.13v-2.13m-7.335
  0v2.13m-1.656-2.13v-1.657m5.442-1.893v1.893m-3.785-1.893v1.893m-3.55-1.893v1.893m1.894
  -1.893v-2.13m-1.893-1.893v1.893m0-5.68v1.893m1.894-3.786v1.894m-1.893-3.786v1.892m3.55
  -1.892v1.892m3.786-1.892v1.892m1.657-3.785v1.893m-3.55-1.893v1.893m-3.55-1.893v1.893m
  -1.892-3.786v1.893m3.55-1.893v1.893m3.786-1.893v1.893m1.657-3.786v1.892m-7.1-1.893v1.892m3.55-5.68v1.893m-7.335
  19.167v-2.13m0-5.678v-1.894m0
  5.68v-1.894m0-5.678v-1.893m0-1.893v-1.893m0-3.787v-1.893m0-1.893v-1.893m-8.99
  4.97h3.785m3.55-5.68h3.548m3.55 5.68h3.786"
      fill="#c8b100"
      stroke="#000"
      strokeWidth=".471"
    />
    <path
      d="M235.278 280.1v-4.97c0-.946-.474-3.785-4.97-3.785-4.26 0-4.732
  2.84-4.732 3.785v4.97h9.702z"
      fill="#c8b100"
      stroke="#000"
      strokeWidth=".471"
    />
    <path
      d="M227.232 275.367l-2.365-.237c0-.946.236-2.366.946-2.84l2.13
  1.658c-.237.236-.71.946-.71 1.42zm6.388
  0l2.367-.237c0-.946-.236-2.366-.946-2.84l-2.13 1.658c.238.236.71.946.71
  1.42zM231.255 273l1.183-2.128c-.473-.237-1.42-.474-2.13-.474-.473
  0-1.42.237-1.892.474l1.182
  2.128h1.657zm-4.495-5.914v-5.206c0-1.42-.947-2.603-2.604-2.603-1.656 0-2.602
  1.184-2.602 2.603v5.206h5.205zm7.334 0v-5.206c0-1.42.947-2.603 2.603-2.603 1.657
  0 2.603 1.184 2.603 2.603v5.206h-5.206zm-1.894-12.778l.475-4.733h-4.496l.236
  4.733h3.785zm3.55 0l-.472-4.733h4.73l-.472 4.733h-3.785zm-10.647
  0l.237-4.733h-4.497l.474 4.733h3.786z"
      fill="#c8b100"
      stroke="#000"
      strokeWidth=".471"
    />
    <path
      d="M233.62 280.1v-4.26c0-.71-.472-2.84-3.312-2.84-2.602 0-3.076
  2.13-3.076
  2.84v4.26h6.39zm-7.334-13.487v-4.497c0-1.182-.71-2.366-2.13-2.366s-2.13
  1.184-2.13 2.366v4.497h4.26zm8.282 0v-4.497c0-1.182.71-2.366 2.13-2.366s2.13
  1.184 2.13 2.366v4.497h-4.26z"
      fill="#0039f0"
    />
    <path
      d="M239.502 287.75c0-10.344 7.452-18.73 16.644-18.73 9.195 0 16.648
  8.386 16.648 18.73 0 10.345-7.453 18.73-16.648 18.73-9.192
  0-16.644-8.385-16.644-18.73"
      fill="#ad1519"
    />
    <path
      d="M239.502 287.75c0-10.344 7.452-18.73 16.644-18.73 9.195 0 16.648
  8.386 16.648 18.73 0 10.345-7.453 18.73-16.648 18.73-9.192
  0-16.644-8.385-16.644-18.73z"
      fill="none"
      stroke="#000"
      strokeWidth=".625"
    />
    <path
      d="M244.44 287.714c0-7.585 5.24-13.734 11.713-13.734 6.47 0 11.71 6.15
  11.71 13.734 0 7.588-5.24 13.74-11.71 13.74-6.473 0-11.714-6.152-11.714-13.74"
      fill="#005bbf"
    />
    <path
      d="M244.44 287.714c0-7.585 5.24-13.734 11.713-13.734 6.47 0 11.71 6.15
  11.71 13.734 0 7.588-5.24 13.74-11.71 13.74-6.473 0-11.714-6.152-11.714-13.74z"
      fill="none"
      stroke="#000"
      strokeWidth=".625"
    />
    <path
      d="M250.62 278.26s-1.39 1.522-1.39 2.933c0 1.42.587 2.596.587
  2.596-.21-.56-.778-.963-1.447-.963-.843 0-1.53.647-1.53 1.45 0
  .233.14.597.248.796l.5 1.008c.164-.37.553-.578 1.006-.578.605 0 1.097.462 1.097
  1.038 0 .086-.01.174-.032.253l-1.25.004v1.062h1.114l-.828 1.642
  1.098-.428.828.934.86-.934
  1.095.428-.825-1.643h1.113v-1.063l-1.252-.004a.936.936 0 0
  1-.025-.253c0-.576.483-1.038 1.09-1.038.452 0 .84.207
  1.01.58l.493-1.01c.11-.198.25-.562.25-.794 0-.804-.683-1.45-1.53-1.45-.67
  0-1.233.402-1.444.96 0 0 .583-1.176.583-2.595 0-1.41-1.42-2.934-1.42-2.934"
      fill="#c8b100"
    />
    <path
      d="M250.62 278.26s-1.39 1.522-1.39 2.933c0 1.42.587 2.596.587
  2.596-.21-.56-.778-.963-1.447-.963-.843 0-1.53.647-1.53 1.45 0
  .233.14.597.248.796l.5 1.008c.164-.37.553-.578 1.006-.578.605 0 1.097.462 1.097
  1.038 0 .086-.01.174-.032.253l-1.25.004v1.062h1.114l-.828 1.642
  1.098-.428.828.934.86-.934
  1.095.428-.825-1.643h1.113v-1.063l-1.252-.004a.936.936 0 0
  1-.025-.253c0-.576.483-1.038 1.09-1.038.452 0 .84.207
  1.01.58l.493-1.01c.11-.198.25-.562.25-.794 0-.804-.683-1.45-1.53-1.45-.67
  0-1.233.402-1.444.96 0 0 .583-1.176.583-2.595
  0-1.41-1.42-2.934-1.42-2.934h.003z"
      fill="none"
      stroke="#000"
      strokeWidth=".348"
      strokeLinejoin="round"
    />
    <path d="M248.41 287.86h4.456v-1.063h-4.457v1.062z" fill="#c8b100" />
    <path
      d="M248.41 287.86h4.456v-1.063h-4.457v1.062z"
      fill="none"
      stroke="#000"
      strokeWidth=".348"
    />
    <path
      d="M261.505 278.26s-1.39 1.522-1.39 2.933c0 1.42.584 2.596.584
  2.596-.208-.56-.776-.963-1.444-.963-.85 0-1.532.647-1.532 1.45 0
  .233.142.597.25.796l.494 1.008c.17-.37.558-.578 1.01-.578.607 0 1.094.462 1.094
  1.038a.93.93 0 0 1-.03.253l-1.25.004v1.062h1.116l-.83 1.642
  1.095-.428.832.934.857-.934
  1.098.428-.83-1.643h1.114v-1.063l-1.247-.004a.895.895 0 0
  1-.03-.253c0-.576.486-1.038 1.093-1.038.453 0 .84.207
  1.006.58l.5-1.01c.107-.198.248-.562.248-.794 0-.804-.687-1.45-1.532-1.45-.667
  0-1.237.402-1.446.96 0 0 .587-1.176.587-2.595 0-1.41-1.42-2.934-1.42-2.934"
      fill="#c8b100"
    />
    <path
      d="M261.505 278.26s-1.39 1.522-1.39 2.933c0 1.42.584 2.596.584
  2.596-.208-.56-.776-.963-1.444-.963-.85 0-1.532.647-1.532 1.45 0
  .233.142.597.25.796l.494 1.008c.17-.37.558-.578 1.01-.578.607 0 1.094.462 1.094
  1.038a.93.93 0 0 1-.03.253l-1.25.004v1.062h1.116l-.83 1.642
  1.095-.428.832.934.857-.934
  1.098.428-.83-1.643h1.114v-1.063l-1.247-.004a.895.895 0 0
  1-.03-.253c0-.576.486-1.038 1.093-1.038.453 0 .84.207
  1.006.58l.5-1.01c.107-.198.248-.562.248-.794 0-.804-.687-1.45-1.532-1.45-.667
  0-1.237.402-1.446.96 0 0 .587-1.176.587-2.595
  0-1.41-1.42-2.934-1.42-2.934h.002z"
      fill="none"
      stroke="#000"
      strokeWidth=".348"
      strokeLinejoin="round"
    />
    <path d="M259.293 287.86h4.455v-1.063h-4.455v1.062z" fill="#c8b100" />
    <path
      d="M259.293 287.86h4.455v-1.063h-4.455v1.062z"
      fill="none"
      stroke="#000"
      strokeWidth=".348"
    />
    <path
      d="M256.062 287.622s-1.39 1.524-1.39 2.94c0 1.413.587 2.59.587
  2.59-.212-.56-.777-.963-1.448-.963-.845 0-1.533.647-1.533 1.45 0
  .237.14.596.25.796l.498 1.007c.17-.37.553-.58 1.005-.58.607 0 1.1.464 1.1
  1.04a.92.92 0 0 1-.036.257l-1.247.003v1.062h1.117l-.83 1.644
  1.095-.433.83.933.86-.933 1.098.432-.834-1.645h1.117v-1.062l-1.248-.003a.96.96 0
  0 1-.03-.258c0-.575.488-1.04 1.095-1.04.452 0 .836.21
  1.006.58l.5-1.006c.105-.2.248-.56.248-.796 0-.803-.687-1.45-1.532-1.45a1.54 1.54
  0 0 0-1.448.963s.588-1.178.588-2.592c0-1.414-1.424-2.938-1.424-2.938"
      fill="#c8b100"
    />
    <path
      d="M256.062 287.622s-1.39 1.524-1.39 2.94c0 1.413.587 2.59.587
  2.59-.212-.56-.777-.963-1.448-.963-.845 0-1.533.647-1.533 1.45 0
  .237.14.596.25.796l.498 1.007c.17-.37.553-.58 1.005-.58.607 0 1.1.464 1.1
  1.04a.92.92 0 0 1-.036.257l-1.247.003v1.062h1.117l-.83 1.644
  1.095-.433.83.933.86-.933 1.098.432-.834-1.645h1.117v-1.062l-1.248-.003a.96.96 0
  0 1-.03-.258c0-.575.488-1.04 1.095-1.04.452 0 .836.21
  1.006.58l.5-1.006c.105-.2.248-.56.248-.796 0-.803-.687-1.45-1.532-1.45a1.54 1.54
  0 0 0-1.448.963s.588-1.178.588-2.592c0-1.414-1.424-2.938-1.424-2.938h.004z"
      fill="none"
      stroke="#000"
      strokeWidth=".348"
      strokeLinejoin="round"
    />
    <path d="M253.85 297.225h4.457v-1.062h-4.457v1.062z" fill="#c8b100" />
    <path
      d="M253.85 297.225h4.457v-1.062h-4.457v1.062z"
      fill="none"
      stroke="#000"
      strokeWidth=".348"
    />
    <path
      d="M289.378 238.29l-.296.022a1.58 1.58 0 0
  1-.273.365c-.263.25-.658.28-.88.07a.504.504 0 0 1-.143-.42.533.533 0 0
  1-.523-.007c-.264-.153-.33-.516-.137-.818.03-.06.06-.135.107-.18l-.02-.33-.356.085
  -.104.195c-.222.252-.553.317-.718.168a.592.592 0 0
  1-.134-.27c.003.01-.085.088-.177.11-.548.134-.767-1.073-.78-1.385l-.18.255s.162.722.08
  1.334a6.93 6.93 0 0 1-.296 1.222c.764.195 1.91.817 3.045 1.693 1.136.87 2.03
  1.814 2.4 2.476 0 0 .588-.328 1.205-.524.612-.2 1.39-.204
  1.39-.204l.222-.218c-.327.048-1.62.103-1.593-.44.004-.086.068-.18.08-.18a.72.72
  0 0 1-.308-.07c-.187-.124-.183-.44.025-.702l.18-.13.01-.35-.345.048c-.032.043
  -.11.094-.157.138-.272.236-.664.254-.88.032a.45.45
  0 0 1-.114-.477.584.584 0 0 1-.46-.048c-.265-.16-.315-.534-.11-.825a1.57 1.57 0
  0 1 .315-.327l-.07-.306"
      fill="#c8b100"
    />
    <path
      d="M289.378 238.29l-.296.022a1.58 1.58 0 0
  1-.273.365c-.263.25-.658.28-.88.07a.504.504 0 0 1-.143-.42.533.533 0 0
  1-.523-.007c-.264-.153-.33-.516-.137-.818.03-.06.06-.135.107-.18l-.02-.33
  -.356.085-.104.195c-.222.252-.553.317-.718.168a.592.592
  0 0 1-.134-.27c.003.01-.085.088-.177.11-.548.134-.767-1.073-.78-1.385l-.18.255s.162.722.08
  1.334a6.93 6.93 0 0 1-.296 1.222c.764.195 1.91.817 3.045 1.693 1.136.87 2.03
  1.814 2.4 2.476 0 0 .588-.328 1.205-.524.612-.2 1.39-.204
  1.39-.204l.222-.218c-.327.048-1.62.103-1.593-.44.004-.086.068-.18.08-.18a.72.72
  0 0 1-.308-.07c-.187-.124-.183-.44.025-.702l.18-.13.01-.35-.345.048c-.032.043
  -.11.094-.157.138-.272.236-.664.254-.88.032a.45.45
  0 0 1-.114-.477.584.584 0 0 1-.46-.048c-.265-.16-.315-.534-.11-.825a1.57 1.57 0
  0 1 .315-.327l-.07-.306-.007-.003z"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M287.073
  238.996c.05-.062.157-.06.238.003.08.06.104.16.054.218-.05.058-.153.058-.237-.007-.077-.057-.104-.156-.055-.213"
    />
    <path
      d="M287.073
  238.996c.05-.062.157-.06.238.003.08.06.104.16.054.218-.05.058-.153.058-.237-.007-.077-.057-.104-.156-.055-.213z"
      fill="none"
      stroke="#000"
      strokeWidth=".051"
    />
    <path
      d="M288.044 239.814l-.335-.258c-.06-.048-.08-.123-.042-.167.038-.04.12
  -.04.18.002l.334.262.342.26c.058.042.08.12.042.166-.042.04-.122.036-.184-.007l-.338-.26"
    />
    <path
      d="M288.044 239.814l-.335-.258c-.06-.048-.08-.123-.042-.167.038-.04.12
  -.04.18.002l.334.262.342.26c.058.042.08.12.042.166-.042.04-.122.036-.184
  -.007l-.338-.26"
      fill="none"
      stroke="#000"
      strokeWidth=".051"
    />
    <path
      d="M286.26 238.592l-.266-.156c-.065-.04-.1-.116-.068-.167.026-.056.106
  -.063.172-.023l.263.157.27.156c.067.036.097.113.07.167-.03.047-.107.058-.175.02l-.265-.155"
    />
    <path
      d="M286.26 238.592l-.266-.156c-.065-.04-.1-.116-.068-.167.026-.056.106
  -.063.172-.023l.263.157.27.156c.067.036.097.113.07.167-.03.047-.107.058
  -.175.02l-.265-.155"
      fill="none"
      stroke="#000"
      strokeWidth=".051"
    />
    <path
      d="M285.203 237.862c.05-.054.158-.054.238.007.082.06.105.16.056.217
  -.052.058-.158.054-.24-.003-.08-.067-.103-.16-.053-.222"
    />
    <path
      d="M285.203 237.862c.05-.054.158-.054.238.007.082.06.105.16.056.217
  -.052.058-.158.054-.24-.003-.08-.067-.103-.16-.053-.222z"
      fill="none"
      stroke="#000"
      strokeWidth=".051"
    />
    <path
      d="M289.12 240.563c.05-.058.025-.152-.054-.218-.08-.062-.188-.065-.238
  -.003-.05.058-.027.156.053.217.08.062.188.062.24.003"
    />
    <path
      d="M289.12 240.563c.05-.058.025-.152-.054-.218-.08-.062-.188-.065-.238
  -.003-.05.058-.027.156.053.217.08.062.188.062.24.003z"
      fill="none"
      stroke="#000"
      strokeWidth=".051"
    />
    <path
      d="M289.707 241.236l.218.21c.055.05.14.07.186.03.047-.036.04-.11-.012
  -.16l-.215-.214-.223-.22c-.053-.05-.137-.064-.184-.024-.05.032-.042.113.015.163l.217.216"
    />
    <path
      d="M289.707 241.236l.218.21c.055.05.14.07.186.03.047-.036.04-.11-.012
  -.16l-.215-.214-.223-.22c-.053-.05-.137-.064-.184-.024-.05.032
  -.042.113.015.163l.217.216"
      fill="none"
      stroke="#000"
      strokeWidth=".051"
    />
    <path
      d="M290.677 242.064c.05-.06.028-.155-.052-.22-.08-.063-.19-.066-.24-.004
  -.05.062-.026.156.055.22.08.06.19.064.237.004"
    />
    <path
      d="M290.677 242.064c.05-.06.028-.155-.052-.22-.08-.063-.19-.066-.24-.004
  -.05.062-.026.156.055.22.08.06.19.064.237.004z"
      fill="none"
      stroke="#000"
      strokeWidth=".051"
    />
    <path d="M287.862 235.884l-.605.018-.118.89.064.142.157-.01.776-.522-.274-.52" fill="#c8b100" />
    <path
      d="M287.862 235.884l-.605.018-.118.89.064.142.157-.01.776-.522-.274-.52"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M286.222
  236.378l-.018.556.942.117.147-.062-.013-.152-.548-.73-.51.272"
      fill="#c8b100"
    />
    <path
      d="M286.222
  236.378l-.018.556.942.117.147-.062-.013-.152-.548-.73-.51.272"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path d="M288.167 237.487l-.503.28-.55-.734-.01-.15.146-.062.945.114-.028.553" fill="#c8b100" />
    <path
      d="M288.167 237.487l-.503.28-.55-.734-.01-.15.146-.062.945.114-.028.553"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M286.95 236.8a.298.298 0 0 1 .402-.095.268.268 0 0 1 .1.38.306.306
  0 0 1-.402.097.272.272 0 0 1-.1-.382"
      fill="#c8b100"
    />
    <path
      d="M286.95 236.8a.298.298 0 0 1 .402-.095.268.268 0 0 1 .1.38.306.306
  0 0 1-.402.097.272.272 0 0 1-.1-.382z"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M284.747 235.88c-.015.003-.132-.47-.262-.73-.088-.19-.4-.437-.4
  -.437.03-.058.423-.2.89.094.38.314-.03.888-.03.888s-.1.138-.195.185"
      fill="#c8b100"
    />
    <path
      d="M284.747 235.88c-.015.003-.132-.47-.262-.73-.088-.19-.4-.437-.4
  -.437.03-.058.423-.2.89.094.38.314-.03.888-.03.888s-.1.138-.195.185h-.003z"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path d="M285.805 236.2l-.43.37-.7-.607.063-.087.024-.15.947-.07.095.544" fill="#c8b100" />
    <path
      d="M285.805 236.2l-.43.37-.7-.607.063-.087.024-.15.947-.07.095.544"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M284.62 235.82c.053-.152.187-.242.295-.21.106.04.15.19.1.34-.054.152
  -.188.24-.296.21-.112-.04-.154-.19-.1-.34"
      fill="#c8b100"
    />
    <path
      d="M284.62 235.82c.053-.152.187-.242.295-.21.106.04.15.19.1.34-.054.152
  -.188.24-.296.21-.112-.04-.154-.19-.1-.34z"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M290.182
  237.335l-.603-.066-.254.868.047.15.158.003.845-.41-.193-.546"
      fill="#c8b100"
    />
    <path
      d="M290.182
  237.335l-.603-.066-.254.868.047.15.158.003.845-.41-.193-.546"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M288.482
  237.603l-.096.55.914.24.153-.045.012-.148-.435-.804-.548.207"
      fill="#c8b100"
    />
    <path
      d="M288.482
  237.603l-.096.55.914.24.153-.045.012-.148-.435-.804-.548.207"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path d="M290.248 238.956l-.54.215-.435-.8.01-.153.155-.04.913.24-.102.54" fill="#c8b100" />
    <path
      d="M290.248 238.956l-.54.215-.435-.8.01-.153.155-.04.913.24-.102.54"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M289.142 238.12c.1-.124.29-.134.412-.04a.267.267 0 0 1 .046.39.31.31
  0 0 1-.416.038.266.266 0 0 1-.042-.388"
      fill="#c8b100"
    />
    <path
      d="M289.142 238.12c.1-.124.29-.134.412-.04a.267.267 0 0 1 .046.39.31.31
  0 0 1-.416.038.266.266 0 0 1-.042-.388z"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path d="M292.207 239.215l.11.57-.895.298-.157-.032-.02-.148.374-.827.587.14" fill="#c8b100" />
    <path
      d="M292.207 239.215l.11.57-.895.298-.157-.032-.02-.148.374-.827.587.14"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path d="M292.045 240.847l-.57.127-.318-.85.036-.147.158-.02.872.355-.177.535" fill="#c8b100" />
    <path
      d="M292.045 240.847l-.57.127-.318-.85.036-.147.158-.02.872.355-.177.535"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path d="M290.494 239.268l-.185.528.872.352.16-.02.03-.147-.31-.845-.568.133" fill="#c8b100" />
    <path
      d="M290.494 239.268l-.185.528.872.352.16-.02.03-.147-.31-.845-.568.133"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M291.458 240.258a.277.277 0 0 0 .016-.397.318.318 0 0
  0-.42-.014.274.274
  0 0 0-.01.398.308.308 0 0 0 .414.014"
      fill="#c8b100"
    />
    <path
      d="M291.458 240.258a.277.277 0 0 0 .016-.397.318.318 0 0
  0-.42-.014.274.274
  0 0 0-.01.398.308.308 0 0 0 .414.014z"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M292.866 242.104c-.003.016.51.027.807.09.21.045.538.282.538.282.058
  -.043.117-.433-.287-.807-.403-.29-.907.217-.907.217s-.122.12-.15.217"
      fill="#c8b100"
    />
    <path
      d="M292.866 242.104c-.003.016.51.027.807.09.21.045.538.282.538.282.058
  -.043.117-.433-.287-.807-.403-.29-.907.217-.907.217s-.122.12-.15.217z"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M292.313
  241.188l-.295.477.776.523.095-.084.126-.043-.12-.894-.583.022"
      fill="#c8b100"
    />
    <path
      d="M292.313
  241.188l-.295.477.776.523.095-.084.126-.043-.12-.894-.583.022"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M292.95 242.21c.143-.08.212-.224.155-.316-.063-.095-.226-.106-.37
  -.025-.14.082-.21.22-.148.318.056.09.222.103.364.022"
      fill="#c8b100"
    />
    <path
      d="M292.95 242.21c.143-.08.212-.224.155-.316-.063-.095-.226-.106-.37
  -.025-.14.082-.21.22-.148.318.056.09.222.103.364.022z"
      fill="none"
      stroke="#000"
      strokeWidth=".266"
    />
    <path
      d="M333.666 218.77v.594h-2.596v-.593h.96v-1.337h-.634v-.596h.634v
  -.585h.626v.585h.625v.596h-.624v1.338h1.01"
      fill="none"
      stroke="#000"
      strokeWidth=".307"
    />
    <path d="M179.352 231.578v-1.298" fill="none" stroke="#000" strokeWidth=".02" />
    <path d="M179 231.578v-1.298" fill="none" stroke="#000" strokeWidth=".031" />
    <path
      d="M178.667 231.578v-1.298m-.331 1.298v-1.298"
      fill="none"
      stroke="#000"
      strokeWidth=".041"
    />
    <path d="M178.042 231.578v-1.298" fill="none" stroke="#000" strokeWidth=".051" />
    <path
      d="M177.482 231.508l-.01-1.182m.274 1.197v-1.236"
      fill="none"
      stroke="#000"
      strokeWidth=".061"
    />
    <path
      d="M176.964 231.455v-1.09m.262 1.12l-.007-1.158"
      fill="none"
      stroke="#000"
      strokeWidth=".072"
    />
    <path
      d="M176.27 231.392v-.956m.228.972v-1.003m.234 1.034v-1.043"
      fill="none"
      stroke="#000"
      strokeWidth=".082"
    />
    <path d="M176.026 231.385v-.926" fill="none" stroke="#000" strokeWidth=".092" />
    <path d="M175.8 231.338v-.863" fill="none" stroke="#000" strokeWidth=".102" />
    <path d="M175.556 231.314v-.8" fill="none" stroke="#000" strokeWidth=".113" />
    <path
      d="M175.058 231.245l-.01-.638m.264.67v-.716m-.51.629v-.56"
      fill="none"
      stroke="#000"
      strokeWidth=".123"
    />
    <path d="M174.57 231.144v-.465" fill="none" stroke="#000" strokeWidth=".133" />
    <path d="M174.313 231.083v-.366" fill="none" stroke="#000" strokeWidth=".143" />
    <path d="M174.05 231.052v-.272" fill="none" stroke="#000" strokeWidth=".154" />
    <path d="M173.773 230.99v-.132" fill="none" stroke="#000" strokeWidth=".184" />
    <path
      d="M180.755 231.508v-1.19m-.598 1.23l.01-1.26m-.445 1.274v-1.282"
      fill="none"
      stroke="#000"
      strokeWidth=".01"
    />
    <path d="M332.27 231.6v-1.298" fill="none" stroke="#000" strokeWidth=".02" />
    <path d="M331.917 231.6v-1.298" fill="none" stroke="#000" strokeWidth=".031" />
    <path
      d="M331.584 231.6v-1.298m-.33 1.298v-1.298"
      fill="none"
      stroke="#000"
      strokeWidth=".041"
    />
    <path d="M330.96 231.6v-1.298" fill="none" stroke="#000" strokeWidth=".051" />
    <path
      d="M330.4 231.53l-.01-1.182m.274 1.197v-1.235"
      fill="none"
      stroke="#000"
      strokeWidth=".061"
    />
    <path
      d="M329.88 231.476v-1.088m.264 1.12l-.01-1.16"
      fill="none"
      stroke="#000"
      strokeWidth=".072"
    />
    <path
      d="M329.187 231.415v-.957m.228.972v-1.003m.234 1.034v-1.04"
      fill="none"
      stroke="#000"
      strokeWidth=".082"
    />
    <path d="M328.944 231.407v-.925" fill="none" stroke="#000" strokeWidth=".092" />
    <path d="M328.717 231.36v-.863" fill="none" stroke="#000" strokeWidth=".102" />
    <path d="M328.473 231.337v-.8" fill="none" stroke="#000" strokeWidth=".113" />
    <path
      d="M327.975 231.266l-.01-.637m.264.668v-.716m-.509.631v-.56"
      fill="none"
      stroke="#000"
      strokeWidth=".123"
    />
    <path d="M327.486 231.167v-.466" fill="none" stroke="#000" strokeWidth=".133" />
    <path d="M327.23 231.105v-.365" fill="none" stroke="#000" strokeWidth=".143" />
    <path d="M326.968 231.074v-.273" fill="none" stroke="#000" strokeWidth=".154" />
    <path d="M326.69 231.01v-.13" fill="none" stroke="#000" strokeWidth=".184" />
    <path
      d="M333.672 231.53v-1.19m-.597 1.228l.007-1.258m-.443 1.274v-1.282"
      fill="none"
      stroke="#000"
      strokeWidth=".01"
    />
  </svg>
);
