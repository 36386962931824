import React from 'react';
import { useField } from 'formik';

const InputField = React.forwardRef(
  ({ label, onParse = value => value, containerClass, ...props }, ref) => {
    const [field, meta, helpers] = useField(props);
    const { validate, ...restProps } = props;
    const hasError = meta.touched && meta.error;

    return (
      <div
        className={`form-group ${hasError ? 'has-error' : ''} ${
          containerClass ? containerClass : ''
        }`}
      >
        {label && <label htmlFor={field.name}>{label}</label>}
        <input
          className="form-control"
          {...field}
          {...restProps}
          ref={ref}
          onChange={event => {
            helpers.setValue(onParse(event.target.value));
          }}
        />
        {hasError && <div className="form-group-error">{meta.error}</div>}
      </div>
    );
  }
);

export default InputField;
