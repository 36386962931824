import { normalize, schema } from 'normalizr';
import * as TYPES from './types';
import { accountStatus, chargeStatus, isSubscriptionChargeRefundable } from '../../consts';
import { CLEAR_SUBSCRIPTIONS_DATA } from '../../types';
import { getTimestampFromUTC, sortDesc } from '../../../common/helpers';

const initialState = {
  allIds: [],
  byId: {},
  charges: {},
  logs: {},
  service: {},
  events: {},
  cardSubscriptionsTotal: 0,
  status: {},
  fetchDataStatus: null
};

function idAttribute(value, parent) {
  return parent.idUnique;
}

function processStrategy(value) {
  return value;
}

export function createUniqueId(item) {
  return `${item.id}${item.service ? '_' + item.service.id : ''}`;
}

export function cardsReducer(state = initialState, { type, payload, requestParams }) {
  switch (type) {
    case TYPES.FETCH_CARD_SUBSCRIPTIONS.REQUEST:
      return {
        ...initialState,
        fetchDataStatus: 'loading'
      };

    case TYPES.FETCH_CARD_SUBSCRIPTIONS.SUCCESS:
      const chargesSchema = new schema.Entity('charges', [], { idAttribute, processStrategy });
      const logsSchema = new schema.Entity('logs', [], { idAttribute, processStrategy });
      const serviceSchema = new schema.Entity('service', {}, { idAttribute });
      const subscriptionsSchema = new schema.Entity(
        'byId',
        {
          charges: chargesSchema,
          logs: logsSchema,
          service: serviceSchema
        },
        {
          idAttribute: createUniqueId,
          processStrategy: item => ({ ...item, idUnique: createUniqueId(item) })
        }
      );
      const normalizedData = normalize(payload.subscriptions, [subscriptionsSchema]);
      return {
        ...state,
        ...normalizedData.entities,
        allIds: normalizedData.result,
        cardSubscriptionsTotal: payload.total,
        fetchDataStatus: 'success'
      };

    case TYPES.FETCH_USER_SUBSCRIPTION_EVENTS.SUCCESS:
      return {
        ...state,
        events: {
          ...state.events,
          [payload.subscriptionId]: payload.events
        }
      };

    case TYPES.CANCEL_SUBSCRIPTION.SUCCESS:
    case TYPES.CANCEL_SUBSCRIPTION_PAYMENT.SUCCESS:
    case CLEAR_SUBSCRIPTIONS_DATA.SUCCESS:
      if (!state.byId[payload.subscriptionId]) {
        return state;
      }
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.subscriptionId]: {
            ...state.byId[payload.subscriptionId],
            active: false,
            cancelled: true
          }
        },
        service: {
          ...state.service,
          [payload.subscriptionId]: {
            ...state.service[payload.subscriptionId],
            status: accountStatus.CANCELED,
            active: false
          }
        },
        status: {
          ...state.status,
          [payload.subscriptionId]: { active: false }
        }
      };

    case TYPES.CLEAR_CARD_SUBSCRIPTIONS:
      return initialState;

    case TYPES.SUBSCRIPTION_MANUAL_REFUND.SUCCESS:
      return {
        ...state,
        charges: changeChargeStatus(
          state.charges,
          payload.subscriptionID,
          payload.chargeID,
          chargeStatus.MANUAL_REFUND
        )
      };

    case TYPES.SUBSCRIPTION_MANUAL_REFUND.ERROR:
      return {
        ...state,
        charges: changeChargeStatus(
          state.charges,
          payload.subscriptionID,
          payload.chargeID,
          chargeStatus.ERROR
        )
      };

    case TYPES.SUBSCRIPTION_REFUND_CREATED:
      return {
        ...state,
        charges: changeChargeStatus(
          state.charges,
          payload.subscriptionID,
          payload.chargeID,
          chargeStatus.WAITING
        )
      };

    case TYPES.SUBSCRIPTION_REFUND_CONFIRM.SUCCESS:
    case TYPES.SUBSCRIPTION_REFUND_CONFIRM.ERROR:
      const status = payload.status || (payload.manual ? 'manual_refund' : 'waiting');
      const otherChargeProperties =
        status === 'completed'
          ? {
              refunded_at: Math.floor(+new Date() / 1000),
              refunded: true
            }
          : {};

      return {
        ...state,
        charges: changeChargeStatus(
          state.charges,
          payload.subscriptionID,
          payload.chargeID,
          status,
          otherChargeProperties
        )
      };

    case TYPES.FETCH_CARD_SUBSCRIPTION_DETAILS.SUCCESS: {
      const subscriptionID = requestParams.subscriptionID;
      const { service, charges, logs } = payload;
      return {
        ...state,
        byId: {
          ...state.byId,
          [subscriptionID]: { ...state.byId[subscriptionID], loading: false }
        },
        service: { ...state.service, [subscriptionID]: service },
        charges: { ...state.charges, [subscriptionID]: charges },
        logs: { ...state.logs, [subscriptionID]: logs }
      };
    }

    case TYPES.FETCH_CARD_SUBSCRIPTION_DETAILS.REQUEST: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.subscriptionID]: { ...state.byId[payload.subscriptionID], loading: true }
        }
      };
    }

    case TYPES.FETCH_CARD_SUBSCRIPTION_DETAILS.ERROR: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.subscriptionID]: { ...state.byId[payload.subscriptionID], loading: false }
        }
      };
    }

    case TYPES.CARD_SUBSCRIPTION_STATUS.REQUEST: {
      const id = payload.id;
      return {
        ...state,
        status: { ...state.status, [id]: { loading: true } }
      };
    }

    case TYPES.CARD_SUBSCRIPTION_STATUS.SUCCESS: {
      const { id, active } = payload;
      return {
        ...state,
        status: { ...state.status, [id]: { active, loading: false } }
      };
    }
  }
  return state;
}

const changeChargeStatus = (
  charges,
  subscriptionID,
  chargeID,
  chargeStatus,
  otherChargeProps = {}
) => {
  return {
    ...charges,
    [subscriptionID]: charges[subscriptionID].map(charge => {
      if (charge.id === chargeID) {
        return { ...charge, current_status: chargeStatus, ...otherChargeProps };
      }
      return charge;
    })
  };
};

// selectors
export const getCardSubscriptionsSelector = state => {
  const cards = state.subscriptions.cards;
  return cards.allIds.map(id => {
    return {
      ...cards.byId[id],
      charges: sortCharges(cards.charges[id]),
      logs: cards.logs[id],
      service: cards.service[id],
      events: cards.events[id]
    };
  });
};

export const getCardSubscriptionsTotal = state => state.subscriptions.cards.cardSubscriptionsTotal;

export const getCardSubscriptionsFetchingStatus = state =>
  state.subscriptions.cards.fetchDataStatus;

export const getCardSubscriptionStatus = (state, subscriptionId) =>
  state.subscriptions.cards.status[subscriptionId] || {};

export const getAllChargesToRefund = state => {
  const allCharges = [];
  const cards = state.subscriptions.cards;
  cards.allIds.map(id => {
    cards.charges[id].filter(charge => {
      if (!isSubscriptionChargeRefundable(charge)) {
        allCharges.push(charge);
      }
    });
  });
  return allCharges;
};

const sortCharges = charges => {
  if (!charges) {
    return charges;
  }
  return [...charges].sort((chargeA, chargeB) => {
    chargeA =
      typeof chargeA.created === 'string' ? getTimestampFromUTC(chargeA.created) : chargeA.created;
    chargeB =
      typeof chargeB.created === 'string' ? getTimestampFromUTC(chargeB.created) : chargeB.created;
    return sortDesc(chargeA, chargeB);
  });
};
