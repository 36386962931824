import classNames from 'classnames';

import React, { Component } from 'react';

export default class BoxItems extends Component {
  constructor(props) {
    super(props);
    this.state = { selected: null };
  }

  componentDidMount() {
    if (this.props.options) {
      if (this.props.options.defaultSelected) {
        this.selectDefaultItem();
      }
    }
  }

  handleItemClick(item) {
    this.setState({ selected: item.id }, () => this.props.onClick(item));
  }

  selectDefaultItem() {
    if (this.props.items.length) {
      this.handleItemClick(this.props.items[0]);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.reset && prevProps.reset !== this.props.reset) {
      if (this.props.options && this.props.options.defaultSelected) {
        this.selectDefaultItem();
      } else {
        this.setState({ selected: null });
      }
    }
  }

  render() {
    return (
      <ul className="d-flex justify-content-center flex-wrap list-unstyled">
        {this.props.items.map(item => {
          const classes = {
            'box-item text-center cursor-pointer': true,
            active: item.id === this.state.selected
          };
          return (
            <li
              key={item.id}
              className={classNames(classes, this.props.customClasses)}
              onClick={() => this.handleItemClick(item)}
            >
              <div className="box-item--image-container rounded">
                <i className="box-item--image" style={{ background: `url(${item.image})` }}>
                  {item.image}
                </i>
              </div>
              <p className="box-item--text my-15">{item.name}</p>
            </li>
          );
        })}
      </ul>
    );
  }
}
