// services
export const SERVICES_FETCHED = 'services_fetched';
export const FILTER_SERVICES = 'filter_services';
export const RESET_SERVICES = 'reset_services';

// service
export const SERVICE_FETCHED = 'service_fetched';
export const SERVICE_PROCESSING = 'service_processing';
export const SERVICE_CREATED = 'service_created';
export const SERVICE_CREATED_ERROR = 'service_created_error';
export const SERVICE_UPDATED = 'service_updated';
export const SERVICE_UPDATED_ERROR = 'service_updated_error';
export const SERVICE_DELETED = 'service_deleted';
export const SERVICE_DELETED_ERROR = 'service_updated_error';
export const RESET_SERVICE = 'reset_service';
export const RESET_STATUS = 'reset_status';
export const SERVICE_UNSELECT = 'service_unselect';

//contactCaseReasons
export const REASONS_FETCHED = 'reasons_fetched';
export const REASON_DELETED = 'reason_deleted';
export const REASON_CREATED = 'reason_created';
export const REASON_UPDATED = 'reason_updated';

//contactCaseSolutions
export const SOLUTIONS_FETCHED = 'solutions_fetched';
export const SOLUTION_DELETED = 'solution_deleted';
export const SOLUTION_CREATED = 'solution_created';
export const SOLUTION_UPDATED = 'solution_updated';

//Communicators
export const COMMUNICATORS_FETCHED = 'communicator_fetched';
export const COMMUNICATOR_DELETED = 'communicator_deleted';
export const COMMUNICATOR_CREATED = 'communicator_created';
export const COMMUNICATOR_UPDATED = 'communicator_updated';

//Users
export const USERS_FETCHED = 'users_fetched';
