import axios from 'axios';

import {
  API_AUTH_TOKEN_KEY,
  API_RESPONSE_CREATED_VALID_CODE,
  API_RESPONSE_VALID_CODE,
  API_RESPONSE_VALIDATION_FAILED_CODE
} from './../common/consts';
import { showErrorAlert, showSuccessAlert } from './../common/actions';
import { mock } from './../common/mocks';
import { objectToQueryString } from './../common/helpers';

import { REFUNDS_LIST } from './mocks';
import { REFUNDS_CONFIRM_URL, REFUNDS_URL, CSV_URL, REFUNDS_COUNTERS_URL } from './consts';
import {
  REFUND_CONFIRMED,
  REFUND_CONFIRMED_ERROR,
  REFUND_CONFIRMING,
  REFUND_CREATED,
  REFUND_CREATED_ERROR,
  REFUND_CREATING,
  REFUND_DELETED,
  REFUND_DELETED_ERROR,
  REFUND_DELETING,
  REFUNDS_FETCHED,
  REFUNDS_FETCHING,
  SINGLE_REFUND_CONFIRMED,
  SINGLE_REFUND_CONFIRMED_ERROR,
  SINGLE_REFUND_CONFIRMING,
  CSV_GENERATING,
  REFUNDS_COUNTERS_ERROR,
  REFUNDS_COUNTERS_REQUEST,
  REFUNDS_COUNTERS_SUCCESS,
  MANUAL_REFUND_SUCCESS, MANUAL_REFUND_ERROR
} from './types';
import { API_RESPONSE_NOT_FOUND_CODE } from '../common/consts';

export function notifyConfirmSuccess(dispatch, refunds, refundsStatus) {
  if (refundsStatus) {
    showSuccessAlert(dispatch, 'Success! All refunds with this status confirmed.');
  } else {
    const identifiers = Array.isArray(refunds) ? refunds.join(', ') : refunds;
    showSuccessAlert(dispatch, `Success! Refund(s) with ID ${identifiers} confirmed.`);
  }
}

function notifyConfirmErrors(dispatch, errors, refunds, refundsStatus) {
  const errorMessage = errors && Array.isArray(errors) ?
    errors.map(e => e.msg || e.message).join('. ') : errors;
  if (refundsStatus) {
    showErrorAlert(
      dispatch,
      `Error! Cannot confirm all refunds with this status. ${errorMessage}`
    );
  } else {
    const identifiers = Array.isArray(refunds) ? refunds.join(', ') : refunds;
    showErrorAlert(
      dispatch,
      `Error! Cannot confirm refund(s) with ID: ${identifiers}. ${errorMessage}`
    );
  }
}

function notifyRemoveErrors(dispatch, refundID) {
  showErrorAlert(dispatch, `Error! Cannot remove refund  with ID: ${refundID}.`);
}

export function notifyCreateErrors(dispatch, subscriptionId, chargeId) {
  showErrorAlert(
    dispatch,
    `Error! Cannot mark refund charge ${chargeId} for payment ${subscriptionId}.`
  );
}

export function manualRefundRequest(refundID, successCallback, errorCallback) {
  if (MOCK_DATA) {
    return new Promise((resolve, reject) => {
      mock(
        'MANUAL_REFUND_MOCK_RESPONSE',
        function() {
          resolve();
          successCallback();
        },
        function() {
          reject(new Error());
          errorCallback();
        }
      );
    });
  }

  return axios
    .post(`/refunds/${refundID}/manual_refund`)
    .then(response => {
      successCallback();
      return response;
    })
    .catch(error => {
      errorCallback();
      return Promise.reject(error.response);
    });
}

export function manualRefund({refundID, chargeID, subscriptionID}) {
  return function(dispatch) {
    const payload = {chargeID, subscriptionID};

    const successCallback = () => {
      dispatch({ type: MANUAL_REFUND_SUCCESS, payload });
    };

    const errorCallback = () => {
      dispatch({ type: MANUAL_REFUND_ERROR, payload });
      notifyCreateErrors(dispatch, subscriptionID, chargeID);
    };

    return manualRefundRequest(refundID, successCallback, errorCallback);
  };
};

export function createRefund(refundData) {
  return function(dispatch) {
    const payload = {
      chargeId: refundData.charge_uid,
      paymentUid: refundData.payment_uid,
    };

    const successCallback = () => {
      dispatch({ type: REFUND_CREATED, payload });
    };

    const errorCallback = () => {
      dispatch({ type: REFUND_CREATED_ERROR, payload });
      notifyCreateErrors(dispatch, refundData.payment_uid, refundData.charge_uid);
    };

    return createRefundRequest(refundData, successCallback, errorCallback);
  };
}

export function createRefundRequest(refundData, successCallback, errorCallback) {
  if (MOCK_DATA) {
    return new Promise((resolve, reject) => {
      return mock(
        'CREATE_REFUND_MOCK_RESPONSE',
        function() {
          resolve({data: {id: 111}, status: API_RESPONSE_CREATED_VALID_CODE});
          successCallback();
        },
        function() {
          reject(new Error());
          errorCallback();
        }
      );
    });
  }

  return axios
    .post(REFUNDS_URL, refundData)
    .then(response => {
      successCallback();
      return response;
    })
    .catch(error => {
      errorCallback();
      return Promise.reject(error.response);
    });
}

export function confirmRefund(requestData) {
  return function(dispatch) {
    const refundIDs = requestData.refund_ids;
    const refundsStatus = requestData.status;
    dispatch({ type: REFUND_CONFIRMING, payload: refundIDs });

    if (MOCK_DATA) {
      return new Promise((resolve, reject) => {
        mock(
          'CONFIRM_REFUND_MOCK_RESPONSE',
          function() {
            resolve();
            dispatch({ type: REFUND_CONFIRMED, payload: refundIDs });
            notifyConfirmSuccess(dispatch, refundIDs, refundsStatus);
          },
          function() {
            reject(new Error());
            dispatch({ type: REFUND_CONFIRMED_ERROR, payload: refundIDs });
            notifyConfirmErrors(dispatch, refundIDs, refundsStatus);
          }
        );
      });
    }

    return axios
      .post(REFUNDS_CONFIRM_URL, requestData)
      .then(response => {
        dispatch({ type: REFUND_CONFIRMED, payload: refundIDs });
        notifyConfirmSuccess(dispatch, refundIDs, refundsStatus);
      })
      .catch(error => {
        if (error.response.status == API_RESPONSE_VALIDATION_FAILED_CODE) {
          dispatch({ type: REFUND_CONFIRMED_ERROR, payload: refundIDs });
          notifyConfirmErrors(dispatch, error.response.data.errors, refundIDs, refundsStatus);
          return Promise.reject(error.response);
        }
      });
  };
}

export function singleConfirmRefundRequest(data, successCallback, errorCallback) {
  const {refundID} = data;

  if (MOCK_DATA) {
    return mock(
      'SINGLE_CONFIRM_REFUND_MOCK_RESPONSE',
      function() {
        payload.status = 'completed';
        successCallback();
      },
      function() {
        errorCallback();
        notifyConfirmErrors(dispatch, refundID);
      }
    );
  }

  return axios
    .post(`/refunds/${refundID}/confirm/`)
    .then(response => {
      if (response.status === API_RESPONSE_VALID_CODE && response.data.errors) {
        // if request was rejected by Straal then response status is (200)
        // but includes list of errors
        errorCallback();
        showErrorAlert(dispatch, response.data.errors, { list: true });
      } else {
        successCallback(response.data);
      }
    })
    .catch(error => {
      if (error.response.status == API_RESPONSE_VALIDATION_FAILED_CODE) {
        errorCallback(error.response && error.response.data);
        notifyConfirmErrors(dispatch, error.response.data.errors, refundID);
      } else {
        notifyConfirmErrors(dispatch, error, refundID);
      }
    });
}

export function singleConfirmRefund(data) {
  return function(dispatch) {
    const payload = data;

    dispatch({ type: SINGLE_REFUND_CONFIRMING, payload });

    const successCallback = () => {
      dispatch({ type: SINGLE_REFUND_CONFIRMED, payload });
      notifyConfirmSuccess(dispatch, data.refundID);
    };

    const errorCallback = () => {
      dispatch({ type: SINGLE_REFUND_CONFIRMED_ERROR, payload });
    };

    return singleConfirmRefundRequest(data, successCallback, errorCallback);
  };
};

export function deleteRefund(refundID) {
  return function(dispatch) {
    dispatch({ type: REFUND_DELETING, payload: refundID });

    if (MOCK_DATA) {
      return mock(
        'DELETE_REFUND_MOCK_RESPONSE',
        function() {
          dispatch({ type: REFUND_DELETED, payload: refundID });
          showSuccessAlert(dispatch, `Success! Refund with ID ${refundID} was removed.`);
        },
        function() {
          dispatch({ type: REFUND_DELETED_ERROR, payload: refundID });
          notifyRemoveErrors(dispatch, refundID);
        }
      );
    }

    return axios
      .delete(`${REFUNDS_URL}/${refundID}`)
      .then(response => {
        dispatch({ type: REFUND_DELETED, payload: refundID });
        showSuccessAlert(dispatch, `Success! Refund with ID ${refundID} was removed.`);
      })
      .catch(error => {
        if (error.response.status === API_RESPONSE_VALIDATION_FAILED_CODE ||
          error.response.status === API_RESPONSE_NOT_FOUND_CODE) {
          dispatch({ type: REFUND_DELETED_ERROR, payload: refundID });
          notifyRemoveErrors(dispatch, refundID);
        }
      });
  };
}

export function getRefunds(requestData) {
  return function(dispatch) {
    dispatch({ type: REFUNDS_FETCHING });

    if (MOCK_DATA) {
      return mock(
        'FETCH_REFUNDS_MOCK_RESPONSE',
        function() {
          dispatch({ type: REFUNDS_FETCHED, payload: REFUNDS_LIST });
        },
        function() {
          showErrorAlert(dispatch, 'Error! Cannot fetch refunds list.');
        }
      );
    }

    return axios
      .get(REFUNDS_URL, requestData)
      .then(response => {
        dispatch({ type: REFUNDS_FETCHED, payload: response.data });
      })
      .catch(error => {
        showErrorAlert(dispatch, 'Error! Cannot fetch refunds list.');
      });
  };
}

export function generateCSV(requestData) {
  return function(dispatch) {
    dispatch({ type: CSV_GENERATING });

    const token = localStorage.getItem(API_AUTH_TOKEN_KEY);
    const queryString = objectToQueryString(requestData);
    let url = CSV_URL;

    if (queryString) {
      url = `${import.meta.env.VITE_API_URL}${url}?${queryString}&auth_token=${token}`;
    }
    window.open(url);
  };
}

export function getRefundsCounters(requestData) {
  return function(dispatch) {
    dispatch({ type: REFUNDS_COUNTERS_REQUEST });

    return axios
      .get(REFUNDS_COUNTERS_URL, requestData)
      .then(response => {
        dispatch({ type: REFUNDS_COUNTERS_SUCCESS, payload: response.data });
        return response.data;
      })
      .catch(() => {
        dispatch({ type: REFUNDS_COUNTERS_ERROR });
        showErrorAlert(dispatch, 'Error! Cannot fetch refunds counters.');
      });
  };
}
