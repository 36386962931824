import React from 'react';

import { displayYesNo } from '@common/helpers';

const getNameById = (values, id, key = 'name') => {
  if (id) {
    const name = values.find(item => item.id === id);
    if (name) {
      return name[key];
    }
  }
  return '';
};

const SummaryStep = props => (
  <div>
    <dl className="col-8 dl-list mx-auto text-left">
      <div className="dl-list--item">
        <dt className="dl-list--item-term">Product:</dt>
        <dd className="dl-list--item-description float-right">
          {getNameById(props.initialData.services, props.stepsData.service)}
        </dd>
      </div>

      <div className="dl-list--item">
        <dt className="dl-list--item-term">Communicator:</dt>
        <dd className="dl-list--item-description float-right">
          {getNameById(
            props.serviceRelatedData.communicators,
            props.stepsData.communicator,
            'description'
          )}
        </dd>
      </div>

      <div className="dl-list--item">
        <dt className="dl-list--item-term">Language:</dt>
        <dd className="dl-list--item-description float-right">
          {getNameById(props.serviceRelatedData.languages, props.stepsData.language)}
        </dd>
      </div>

      <div className="dl-list--item">
        <dt className="dl-list--item-term">Customer e-mail:</dt>
        <dd className="dl-list--item-description float-right">{props.stepsData.customerEmail}</dd>
      </div>

      <div className="dl-list--item">
        <dt className="dl-list--item-term">Customer is satisfied:</dt>
        <dd className="dl-list--item-description float-right">
          {props.stepsData.caseSolved !== undefined ? displayYesNo(props.stepsData.caseSolved) : ''}
        </dd>
      </div>

      <div className="dl-list--item">
        <dt className="dl-list--item-term">Contact reason:</dt>
        <dd className="dl-list--item-description float-right">
          {getNameById(
            props.serviceRelatedData.contactReasons,
            props.stepsData.contactReason,
            'description'
          )}
        </dd>
      </div>

      <div className="dl-list--item">
        <dt className="dl-list--item-term">Contact description:</dt>
        <dd className="dl-list--item-description float-right">
          {props.stepsData.reasonDescription}{' '}
        </dd>
      </div>

      <div className="dl-list--item">
        <dt className="dl-list--item-term">Contact solution:</dt>
        <dd className="dl-list--item-description float-right">
          {getNameById(
            props.serviceRelatedData.contactSolutions,
            props.stepsData.contactSolution,
            'description'
          )}
        </dd>
      </div>

      <div className="dl-list--item">
        <dt className="dl-list--item-term">Solution description:</dt>
        <dd className="dl-list--item-description float-right">
          {props.stepsData.solutionDescription}
        </dd>
      </div>

    </dl>
    <div className="text-center">
      <button type="button" className="btn btn-primary mt-6" onClick={() => props.handleNext()}>
        Submit
      </button>
    </div>
  </div>
);

export default SummaryStep;
