import React from 'react';
import { connect } from 'react-redux';
import { deleteModal } from '../actions/ModalActions';
import ReactDOM from 'react-dom';

class ModalDialog extends React.Component {
  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  handleClickOutside = event => {
    const domNode = ReactDOM.findDOMNode(this);
    if (!domNode || !domNode.contains(event.target)) {
      this.props.close(this.props.id);
    }
  };

  render() {
    return (
      <div className="modal-content">
        <div className="modal-close crossicon" onClick={() => this.props.close(this.props.id)} />
        {this.props.content}
      </div>
    );
  }
}

const Modal = props => {
  const showModal = props.modal.includes(props.id);
  return (
    <div id={props.id} className={`modal fade ${showModal ? 'show' : ''}`} role="dialog">
      <div className="modal-dialog">
        {showModal && (
          <ModalDialog content={props.children} close={props.deleteModal} id={props.id} />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ modal }) => {
  return {
    modal: modal
  };
};

const mapDispatchToProps = {
  deleteModal
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
