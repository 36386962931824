import React, { Component } from 'react';
import { connect } from 'react-redux';

import { changePage } from '@common/actions';

import {
  createContactCaseReason,
  createContactCaseSolution,
  createService,
  createCommunicators,
  resetStatus,
  getCommunicators,
  getContactCaseSolutions,
  getContactCaseReasons,
  getUsers
} from '../actions';
import Loading from '@common/components/Loading';
import { WIZARD_STEPS } from './steps/steps';
import EntityWizard from '../../wizard/EntityWizard';
import { getLanguages } from '../../resources/actions';
import ProductItemModal, { MORE_INFO_OPTIONS } from '../productItems/ProductItemModal';
import { CREATE_SELECTABLE_ITEM } from '../productItems/ProductItemContainer';
import { deleteModal, showModal } from '../../modal/actions/ModalActions';
import Modal from '../../modal/components/Modal';
import { AddServiceSuccessModal } from './AddServiceSuccessModal';
export const ADD_SERVICE_SUCCESS = 'addServiceSuccess';

class AddServiceContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productItemName: ''
    };

    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleNextStep = this.handleNextStep.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAdditionalAction = this.handleAdditionalAction.bind(this);
    this.createItem = this.createItem.bind(this);
    this.getServiceRelatedData = this.getServiceRelatedData.bind(this);
  }

  componentDidMount() {
    this.props.changePage('Add new product');
    this.props.getLanguages();
    this.getServiceRelatedData();
  }

  getServiceRelatedData() {
    this.props.getContactCaseReasons();
    this.props.getContactCaseSolutions();
    this.props.getCommunicators();
    this.props.getUsers();
  }

  handleBackClick() {
    this.props.resetStatus();
  }

  handleNextStep(step) {}

  handleSubmit(service) {
    this.props.createService(service);
  }

  createItem(props) {
    switch (this.state.productItemName) {
      case 'reason':
        this.props.createContactCaseReason(props);
        break;
      case 'solution':
        this.props.createContactCaseSolution(props);
        break;
      case 'communicator':
        this.props.createCommunicators(props);
    }
  }

  handleAdditionalAction(productItemName) {
    this.setState(
      {
        productItemName
      },
      () => {
        this.props.showModal(CREATE_SELECTABLE_ITEM);
      }
    );
  }

  hasInitialData() {
    return (
      this.props.languages &&
      this.props.contactReasons.length &&
      this.props.contactSolutions.length &&
      this.props.communicators.length &&
      this.props.users.length
    );
  }

  renderAddContactCaseWizard() {
    const initialData = {
      languages: this.props.languages || [],
      contactReasons: this.props.contactReasons || [],
      contactSolutions: this.props.contactSolutions || [],
      communicators: this.props.communicators || [],
      users: this.props.users || []
    };
    return (
      <EntityWizard
        steps={WIZARD_STEPS}
        initialData={initialData}
        onNextStep={this.handleNextStep}
        onSubmit={this.handleSubmit}
        submitStatus={this.props.submitStatus}
        errors={this.props.errors}
        onAdditionalAction={this.handleAdditionalAction}
      />
    );
  }

  render() {
    return (
      <div className="container contact-cases-wizard">
        <div className="row">
          <div className="col-8 mx-auto">
            <h2 className="text-center my-6">Add new product</h2>
            {this.hasInitialData() ? (
              this.renderAddContactCaseWizard()
            ) : (
              <Loading loadingClass="throbber--center" />
            )}
            <Modal id={ADD_SERVICE_SUCCESS}>
              <AddServiceSuccessModal
                onModalClose={() => this.props.deleteModal(ADD_SERVICE_SUCCESS)}
              />
            </Modal>
          </div>
        </div>
        <Modal id={CREATE_SELECTABLE_ITEM}>
          <ProductItemModal
            name={this.state.productItemName}
            status={this.props.submitStatus}
            onSubmit={this.createItem}
            initialValues={{ more_info: MORE_INFO_OPTIONS[0].value }}
            onModalClose={() => this.props.deleteModal(CREATE_SELECTABLE_ITEM)}
            resetStatus={this.props.resetStatus}
          />
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    submitStatus: state.services.serviceActionStatus,
    services: state.services.all,
    languages: state.resources.languages,
    users: state.services.users,
    contactReasons: state.services.contactCaseReasons,
    contactSolutions: state.services.contactCaseSolutions,
    communicators: state.services.communicators,
    errors: state.services.errors
  };
}

export default connect(mapStateToProps, {
  createService,
  getLanguages,
  getContactCaseReasons,
  getContactCaseSolutions,
  getCommunicators,
  getUsers,
  changePage,
  resetStatus,
  showModal,
  deleteModal,
  createContactCaseReason,
  createContactCaseSolution,
  createCommunicators
})(AddServiceContainer);
