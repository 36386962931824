import React, { PureComponent } from 'react';

import BoxItems from '@common/components/BoxItems';
import { getFlag } from './../../../icons/flags';

export default class LanguagesStep extends PureComponent {
  addFlag(item) {
    return {...item, image: getFlag(item.locale)};
  }

  render() {
    return (
      <div>
        <BoxItems
          items={this.props.items.map(this.addFlag)}
          customClasses={'mx-15 languages-step'}
          onClick={itemData => this.props.handleNext({ language: itemData.id })}
          reset={this.props.reset}
        />
      </div>
    );
  }
}
