import React, { Component } from 'react';
import { Card, CardBody } from 'reactstrap';
import { connect } from 'react-redux';
import qs from 'qs';
import ReactDatetimeClass from 'react-datetime';

import { changePage } from '@common/actions';
import Loading from '@common/components/Loading';
import TablePagination from './../../common/components/TablePagination';
import { API_AUTH_TOKEN_KEY } from './../../common/consts';

import {
  getRefunds,
  confirmRefund,
  deleteRefund,
  createRefund,
  generateCSV,
  getRefundsCounters,
  singleConfirmRefund
} from '../actions';
import { getServices } from './../../services/actions';
import RefundsData from './RefundsData';
import NavTabs from './../../common/components/NavTabs';

import { SERVICE_TYPE_PAYCARD } from './../../services/consts';
import { getIcon } from '../../icons/icons';
import { REFUNDS_PER_PAGE } from './../consts';

import AsyncButton from 'react-async-button';
import { chargeStatus } from '../../subscriptions/consts';

//fix for vite issue https://github.com/vitejs/vite/issues/2139
const Datetime = ReactDatetimeClass.default ?? ReactDatetimeClass;

const REFUND_ALL_BUTTON = ['waiting', 'error'];
const REMOVE_ACTION = ['waiting', 'error'];
const ALL_SERVICES_OPTION = { id: 'all', name: 'All' };
const ALL_STATUS_FILTER_OPTIONS = [
  { id: 'waiting', name: 'Waiting' },
  { id: 'in_progress', name: 'In progress' },
  { id: 'manual_refund', name: 'Manual refund' },
  { id: 'rejected', name: 'Rejected' },
  { id: 'error', name: 'Error' },
  { id: 'completed', name: 'Completed' }
];

const FIELD_DATE_FORMAT = 'DD-MM-YYYY';

class RefundsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      service_id: ALL_SERVICES_OPTION.id,
      status: ALL_STATUS_FILTER_OPTIONS[0].id,
      date_since: null,
      date_until: null,
      currentPage: 1,
      limit: REFUNDS_PER_PAGE,
      offset: null,
      socketJobs: ''
    };
    this.handleRefundAllClick = this.handleRefundAllClick.bind(this);
    this.handleConfirmRefund = this.handleConfirmRefund.bind(this);
    this.handleCreateRefund = this.handleCreateRefund.bind(this);
    this.handleItemRemoveClick = this.handleItemRemoveClick.bind(this);
    const token = localStorage.getItem(API_AUTH_TOKEN_KEY);
    this.ws = new WebSocket(import.meta.env.VITE_SOCKET_URL + '/cable?auth_token=' + token);
  }

  componentDidMount() {
    this.props.changePage('Refunds');
    const queryObj = qs.parse(this.props.location.search, {
      arrayFormat: 'bracket',
      ignoreQueryPrefix: true
    });
    this.setState({ ...queryObj }, this.refreshData);

    if (!this.props.services.length) {
      this.props.getServices('card', true);
    }
    this.handleSocket();
  }

  componentWillUnmount() {
    this.ws.close();
  }

  setSocketJobs = socketJobs => {
    this.setState({ socketJobs });
  };

  handleSocket = () => {
    const ws = this.ws;
    const setSocketJobs = this.setSocketJobs;
    ws.onopen = function() {
      //Subscribe to the channel
      ws.send(
        JSON.stringify({ command: 'subscribe', identifier: '{"channel":"RefundsCountChannel"}' })
      );
    };

    ws.onmessage = function(msg) {
      const parsedMsg = JSON.parse(msg.data);
      if (
        parsedMsg &&
        parsedMsg.message &&
        typeof parsedMsg.message.refund_jobs_count !== 'undefined'
      ) {
        setSocketJobs(parsedMsg.message.refund_jobs_count);
      }
    };
  };

  getParams = pagination => {
    const params = {
      status: this.state.status
    };
    if (pagination) {
      params.limit = REFUNDS_PER_PAGE;
      if (this.state.offset) {
        params.offset = this.state.offset;
      }
    }
    if (this.state.service_id && this.state.service_id !== 'all') {
      params.service_id = this.state.service_id;
    }
    if (this.state.date_since) {
      params.date_since = this.state.date_since;
    }
    if (this.state.date_until) {
      params.date_until = this.state.date_until;
    }
    return { params };
  };

  handleRefundAllClick() {
    return this.props.confirmRefund({ status: this.state.status })
      .then(() => this.refreshData());
  }

  handleConfirmRefund(refundID) {
    return this.props.singleConfirmRefund({ refundID })
      .then(() => this.refreshData());
  }

  handleCreateRefund(data) {
    return this.props.createRefund(data)
      .then(() => this.refreshData());
  }

  handleItemRemoveClick(refundID) {
    return this.props.deleteRefund(refundID);
  }

  renderSelectOptions = () => {
    const options = [ALL_SERVICES_OPTION];
    this.props.services
      .filter(item => {
        return item.paymentType === SERVICE_TYPE_PAYCARD;
      })
      .forEach(item => {
        options.push({
          id: item.id,
          name: item.name
        });
      });
    return options.map(item => {
      return (
        <option key={JSON.stringify(item)} value={item.id}>
          {item.name}
        </option>
      );
    });
  };

  dateRangeAvailable = status => {
    return status !== 'waiting' && status !== 'in_progress';
  };

  handleSelect = (selectValue, type) => {
    const dateRangeAvailable = this.dateRangeAvailable(
      (type === 'status' && selectValue) || this.state.status
    );
    const queryObj = qs.parse(this.props.location.search, {
      arrayFormat: 'bracket',
      ignoreQueryPrefix: true
    });
    if (!dateRangeAvailable) {
      delete queryObj.date_since;
      delete queryObj.date_until;
    }
    let params;
    params = {
      ...queryObj,
      [type]: selectValue === 'all' ? undefined : selectValue,
      offset: null,
      currentPage: 1
    };

    const queryURL = qs.stringify(
      { ...queryObj, ...params },
      { arrayFormat: 'brackets', encode: false }
    );
    this.props.history.push({ pathname: this.props.location.pathname, search: `?${queryURL}`});
    if (!dateRangeAvailable) {
      params = {
        ...params,
        date_since: null,
        date_until: null
      };
    }
    this.setState({ ...params }, this.refreshData);
  };

  renderSelect = () => {
    return (
      <select
        name="service_id"
        className="form-control"
        value={this.state.service_id}
        onChange={e => this.handleSelect(e.target.value, 'service_id')}
      >
        {this.renderSelectOptions()}
      </select>
    );
  };

  renderActions() {
    const refundCounter = this.props.counters[this.state.status];
    if (
      !this.props.fetchingData &&
      this.props.refunds.length &&
      (!this.state.status || REFUND_ALL_BUTTON.includes(this.state.status))
    ) {
      return (
        <AsyncButton
          className="btn btn-primary btn-padding-x-35 d-block"
          text="Refund all"
          pendingText="Processing.."
          onClick={this.handleRefundAllClick}
          rejectedClass="btn btn-danger btn-padding-x-35 d-block"
        >
          {
            ({ buttonText }) => (
              <span>{buttonText} ({refundCounter})</span>
            )
          }
        </AsyncButton>
      );
    }
    return null;
  }

  handleCSV = () => {
    this.props.generateCSV(this.getParams().params);
  };

  renderCSVButton() {
    if (!this.props.fetchingData && this.props.refunds.length) {
      const refundAllButtonRender =
        !this.state.status || REFUND_ALL_BUTTON.includes(this.state.status);
      return (
        <div
          className={
            'btn btn-secondary d-block' + (refundAllButtonRender ? ' ml-1' : ' ml-auto')
          }
          onClick={this.handleCSV}
        >
          <span className="d-inline-block align-middle mr-1">{getIcon('exportIcon')}</span>
          Export CSV
        </div>
      );
    }
    return null;
  }

  renderNoDataMessage() {
    return (
      <Card>
        <CardBody>
          <span className="ui-heading ui-heading-secondary">Refund list is empty.</span>
        </CardBody>
      </Card>
    );
  }

  refreshData = () => {
    this.props.getRefunds(this.getParams(true));
    this.props.getRefundsCounters(this.getParams()).then(res => {
      if (res && res.counters && typeof res.counters.in_progress !== 'undefined') {
        this.setState({
          socketJobs: res.counters.in_progress
        });
      }
    });
  };

  renderRemoveButton() {
    return REMOVE_ACTION.includes(this.state.status);
  }

  renderRefundButton() {
    return this.state.status === chargeStatus.WAITING ||
      this.state.status === chargeStatus.ERROR;
  }

  handleDatePicker = (date, type) => {
    const queryObj = qs.parse(this.props.location.search, {
      arrayFormat: 'bracket',
      ignoreQueryPrefix: true
    });
    const params = {
      ...queryObj,
      [type]: date.unix(),
      offset: null,
      currentPage: 1
    };

    const queryURL = qs.stringify(params, { arrayFormat: 'brackets', encode: false });
    this.props.history.push({ pathname: this.props.location.pathname, search: `?${queryURL}`});
    this.setState({ ...params }, this.refreshData);
  };

  getOffset(page) {
    return page > 1 ? Math.ceil((page - 1) * REFUNDS_PER_PAGE) : null;
  }

  handlePaginationClick = page => {
    const offset = this.getOffset(page);
    this.setState(
      {
        offset: offset,
        currentPage: page
      },
      this.refreshData
    );
  };

  renderDatepicker = () => {
    return (
      <React.Fragment>
        <Datetime
          dateFormat={FIELD_DATE_FORMAT}
          timeFormat={false}
          utc={true}
          inputProps={{
            className: 'form-control form-control-white input-sm',
            placeholder: 'Select date from',
            readOnly: true
          }}
          onChange={date => this.handleDatePicker(date, 'date_since')}
          value={this.state.date_since ? this.state.date_since * 1000 : null}
        />
        <span className="mx-1">-</span>
        <Datetime
          dateFormat={FIELD_DATE_FORMAT}
          timeFormat={false}
          utc={true}
          inputProps={{
            className: 'form-control form-control-white input-sm',
            placeholder: 'Select date to',
            readOnly: true
          }}
          onChange={date => this.handleDatePicker(date, 'date_until')}
          value={this.state.date_until ? this.state.date_until * 1000 : null}
        />
      </React.Fragment>
    );
  };

  renderTable = () => {
    return (
      <Card className="mb-3">
        <CardBody>
          <table className="table table-hover mb-0">
            <thead className="thead-default">
              <tr>
                <th className="table-column-width-auto">Id</th>
                <th className="table-column-width-auto">E-mail</th>
                <th className="table-column-width-auto">Product</th>
                <th className="table-column-width-auto">Amount</th>
                <th className="table-column-width-auto">Charge date</th>
                <th className="table-column-width-auto">Created at</th>
                <th>Messages</th>
                <th className="table-column-width-auto" />
              </tr>
            </thead>
            <tbody>
              {this.props.refunds.map((refund, index) => {
                return (
                  <RefundsData
                    key={index}
                    refund={refund}
                    fetchingData={this.props.fetchingData}
                    refundAction={this.handleConfirmRefund}
                    createRefund={this.handleCreateRefund}
                    removeAction={this.handleItemRemoveClick}
                    renderRemoveButton={this.renderRemoveButton()}
                    renderRefundButton={this.renderRefundButton()}
                  />
                );
              })}
            </tbody>
          </table>
        </CardBody>
      </Card>
    );
  };

  renderRefunds() {
    const totalPages = Math.ceil(this.props.totalRefunds / REFUNDS_PER_PAGE);
    return (
      <div className="row">
        <div className="col-12 mt-3">
          <div className="d-flex actions-wrapper align-items-center my-3">
            {this.renderSelect()}
            {this.dateRangeAvailable(this.state.status) && this.renderDatepicker()}
            {this.renderActions()}
            {this.renderCSVButton()}
          </div>
          <NavTabs
            tabs={ALL_STATUS_FILTER_OPTIONS}
            activeTab={this.state.status}
            onChange={value => this.handleSelect(value, 'status')}
            counters={this.props.counters}
          />
          {this.props.refunds.length ?
            this.renderTable() : this.renderNoDataMessage()}
          {totalPages > 1 ? (
            <TablePagination
              currentPage={this.state.currentPage}
              totalPages={totalPages}
              onPageChange={this.handlePaginationClick}
            />
          ) : null}
        </div>
      </div>
    );
  }
  render() {
    return (
      <React.Fragment>
        <nav className="sub-navbar">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <h2 className="m-1">In progress: {this.state.socketJobs} jobs</h2>
            </div>
          </div>
        </nav>
        <div className="refunds container">
          {this.props.fetchingData ? (
            <Loading loadingClass="throbber--middle" />
          ) : (
            this.renderRefunds()
          )}
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    refunds: state.refunds.refunds,
    counters: state.refunds.counters,
    fetchingData: state.refunds.fetchingData,
    services: state.services.paycard || [],
    totalRefunds: state.refunds.totalRefunds || 0
  };
}

export default connect(mapStateToProps, {
  getRefunds,
  getServices,
  confirmRefund,
  singleConfirmRefund,
  deleteRefund,
  createRefund,
  generateCSV,
  getRefundsCounters,
  changePage
})(RefundsContainer);
