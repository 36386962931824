import { combineReducers } from 'redux';

import authReducer from './users/reducers';
import commonReducer from './common/reducers';
import contactCasesReducer from './contact_cases/reducers';
import refundsReducer from './refunds/reducers';
import resourcesReducers from './resources/reducers';
import subscriptions from './subscriptions/reducers';
import servicesReducers from './services/reducers';
import modal from './modal/reducers/ModalReducer';
import nethoneAlertsReducer from './alerts/nethone/reducer';
import notifications from './notifications/reducers/NotificationsReducer';

const rootReducer = combineReducers({
  modal,
  auth: authReducer,
  common: commonReducer,
  contactCases: contactCasesReducer,
  refunds: refundsReducer,
  resources: resourcesReducers,
  services: servicesReducers,
  subscriptions,
  nethoneAlerts: nethoneAlertsReducer,
  notifications
});

export default rootReducer;
