import { CHANGE_PAGE } from '@common/types';

import {
  CONTACT_CASE_ADDING,
  CONTACT_CASE_ADDED,
  CONTACT_CASE_ADD_ERROR,
  CONTACT_CASE_DELETED,
  CONTACT_CASE_FETCHED,
  CONTACT_CASE_FETCHING,
  CONTACT_CASE_CSV_FETCHING,
  CONTACT_CASE_CSV_FETCHED,
  CONTACT_CASE_DELETING
} from './types';

export default function(state = { offset: 0, processingItems: [] }, action) {
  switch (action.type) {
    case CONTACT_CASE_FETCHING:
      return {
        ...state,
        fetchingData: true
      };

    case CONTACT_CASE_FETCHED:
      return {
        ...state,
        contactCases: action.payload.contact_cases,
        fetchingData: false,
        total: action.payload.total
      };

    case CONTACT_CASE_ADDING:
      return { ...state, contactCaseSubmitStatus: 'processing' };

    case CONTACT_CASE_ADDED:
      return { ...state, contactCaseSubmitStatus: 'success' };

    case CONTACT_CASE_ADD_ERROR:
      return { ...state, contactCaseSubmitStatus: 'error' };

    case CONTACT_CASE_DELETING:
      const newProcessingItems = [...state.processingItems];
      newProcessingItems.push(action.payload);
      return { ...state, processingItems: newProcessingItems };

    case CONTACT_CASE_DELETED:
      const index = state.contactCases.findIndex(item => item.id === action.payload);
      if (index !== -1) {
        return {
          ...state,
          contactCases: [
            ...state.contactCases.slice(0, index),
            ...state.contactCases.slice(index + 1)
          ],
          processingItems: state.processingItems.filter(item => item !== action.payload),
          total: state.total - 1
        };
      }
      return { ...state, fetchingData: false };

    case CONTACT_CASE_CSV_FETCHING:
      return { ...state, fetchingFile: true };

    case CONTACT_CASE_CSV_FETCHED:
      return { ...state, fetchingFile: null };

    case CHANGE_PAGE:
      return { ...state, fetchingData: false, contactCaseSubmitStatus: null, total: 0 };
  }
  return state;
}
