import React from 'react';

const Error = ({ errors }) => {
  const isErrorList = Array.isArray(errors);
  return (
    <ul className="form-response-error list-unstyled">
      {isErrorList ? (
        errors.map((error, index) => {
          return <li key={index}>Error: {error.msg}</li>;
        })
      ) : (
        <li>Error: {errors}</li>
      )}
    </ul>
  );
};

export default Error;
