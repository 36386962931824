import axios from 'axios';

import { showErrorAlert, showSuccessAlert } from '@common/actions';
import {
  API_AUTH_TOKEN_KEY,
  API_RESPONSE_VALIDATION_FAILED_CODE
} from '@common/consts';
import { objectToQueryString } from '@common/helpers';
import { mock } from '@common/mocks';

import { CONTACT_CASES_MOCKS } from './mocks';
import { CONTACT_CASE_CSV_URL, CONTACT_CASE_URL } from './consts';
import {
  CONTACT_CASE_ADD_ERROR,
  CONTACT_CASE_ADDED,
  CONTACT_CASE_ADDING,
  CONTACT_CASE_CSV_FETCHING,
  CONTACT_CASE_DELETED,
  CONTACT_CASE_DELETING,
  CONTACT_CASE_FETCHED,
  CONTACT_CASE_FETCHING
} from './types';

export function getContactCases(requestData) {
  return function(dispatch) {
    dispatch({ type: CONTACT_CASE_FETCHING });

    const queryString = objectToQueryString(requestData);
    let url = CONTACT_CASE_URL;

    if (queryString) {
      url = `${url}?${queryString}`;
    }

    if (MOCK_DATA) {
      return mock(
        'FETCH_CONTACT_CASES_MOCK_RESPONSE',
        function() {
          dispatch({ type: CONTACT_CASE_FETCHED, payload: CONTACT_CASES_MOCKS });
        },
        function() {
          showErrorAlert(dispatch, 'Server error. Cannot load contact case.');
        }
      );
    }

    return axios
      .get(url)
      .then(response => {
        dispatch({ type: CONTACT_CASE_FETCHED, payload: response.data });
      })
      .catch(error => {
        showErrorAlert(dispatch, 'Server error. Cannot load contact case.');
      });
  };
}

export function addContactCase(requestData) {
  return function(dispatch) {
    dispatch({ type: CONTACT_CASE_ADDING });

    if (MOCK_DATA) {
      return mock(
        'ADD_CONTACT_CASES_MOCK_RESPONSE',
        function() {
          dispatch({ type: CONTACT_CASE_ADDED });
          showSuccessAlert(dispatch, 'Contact case has been added.');
        },
        function() {
          dispatch({ type: CONTACT_CASE_ADD_ERROR });
          showErrorAlert(dispatch, [{ code: 401, msg: 'Service id is invalid' }], { list: true });
        }
      );
    }

    return axios
      .post(CONTACT_CASE_URL, requestData)
      .then(response => {
        dispatch({ type: CONTACT_CASE_ADDED });
        showSuccessAlert(dispatch, 'Contact case has been added.');
      })
      .catch(error => {
        if (error.response.status === API_RESPONSE_VALIDATION_FAILED_CODE) {
          showErrorAlert(dispatch, error.response.data.errors, { list: true });
        } else {
          showErrorAlert(dispatch, error.response.data.error);
        }
        dispatch({ type: CONTACT_CASE_ADD_ERROR });
      });
  };
}

export function deleteContactCase(contactCaseId) {
  return function(dispatch) {
    dispatch({ type: CONTACT_CASE_DELETING, payload: contactCaseId });

    if (MOCK_DATA) {
      return mock(
        'DELETE_CONTACT_CASES_MOCK_RESPONSE',
        function() {
          dispatch({ type: CONTACT_CASE_DELETED, payload: contactCaseId });
          showSuccessAlert(dispatch, 'Contact case has been deleted.');
        },
        function() {
          showErrorAlert(dispatch, [{ code: 401, msg: 'Contact case id is invalid' }], {
            list: true
          });
        }
      );
    }

    return axios
      .delete(`${CONTACT_CASE_URL}/${contactCaseId}`)
      .then(response => {
        dispatch({ type: CONTACT_CASE_DELETED, payload: contactCaseId });
        showSuccessAlert(dispatch, 'Contact case has been deleted.');
      })
      .catch(error => {
        if (error.response.status === API_RESPONSE_VALIDATION_FAILED_CODE) {
          showErrorAlert(dispatch, error.response.data.errors, { list: true });
        } else {
          showErrorAlert(dispatch, error.response.data.error);
        }
      });
  };
}

export function exportContactCases(requestData) {
  return function(dispatch) {
    dispatch({ type: CONTACT_CASE_CSV_FETCHING });
    const token = localStorage.getItem(API_AUTH_TOKEN_KEY);
    const queryString = objectToQueryString(requestData);
    let url = CONTACT_CASE_CSV_URL;

    if (queryString) {
      url = `${import.meta.env.VITE_API_URL}${url}?${queryString}&auth_token=${token}`;
    }
    window.open(url);
  };
}
