import React from 'react';

import Loading from '@common/components/Loading';
import AsyncButton from 'react-async-button';
import { hasAccountManagementPermissionTo } from './../permissions';
import { Table, TableColumn } from '@common/components/Table';

class UserListData extends React.Component {
  onDeactivateUser(user) {
    this.props.deactivateUser(user);
  }

  onActivateUser(user) {
    this.props.activateUser(user);
  }

  onResetPassword(user) {
    this.props.resetPassword(user);
  }

  onUserEdit(user) {
    this.props.editUser(user);
  }

  render() {
    return (
      <div className="mt-6 mb-3">
        {this.props.fetchingData ? (
          <Loading loadingClass="throbber--middle" />
        ) : (
          <div className="table-striped table-sm table-with-action-btns">
            <Table data={this.props.fetchedData}>
              <TableColumn
                dataKey="email"
                headerLabel="Email"
                dataSort={true}
                dataFormat={this.renderLinkField.bind(this)}
              />
              <TableColumn dataKey="role" headerLabel="Role" dataSort={true} />
              <TableColumn dataKey="user_group" headerLabel="Group" dataSort={true} />
              <TableColumn
                dataKey="active"
                headerLabel="Status"
                dataFormat={this.renderStatusField}
                dataSort={true}
              />
              <TableColumn
                headerLabel="Action"
                width="200px"
                dataFormat={this.renderActionFields.bind(this)}
              />
            </Table>
          </div>
        )}
      </div>
    );
  }

  renderActionFields(cell, row) {
    if (hasAccountManagementPermissionTo('actions').indexOf(row.role) > -1) {
      return row.active ? (
        <div>
          <AsyncButton
            className="btn btn-sm btn-danger mr-15 mb-1 btn-width-140"
            text="Deactivate"
            pendingText="Processing.."
            rejectedText="Cancel account (!)"
            onClick={() => this.onDeactivateUser(row)}
            loadingClass="btn btn-danger"
            rejectedClass="btn btn-danger"
            fulFilledClass="btn btn-danger"
          />
          <AsyncButton
            className="btn btn-sm btn-secondary btn-width-140"
            text="Reset password"
            pendingText="Processing.."
            rejectedText="Cancel payment (!)"
            onClick={() => this.onResetPassword(row)}
            loadingClass="btn btn-danger"
            rejectedClass="btn btn-danger"
            fulFilledClass="btn btn-danger"
          />
        </div>
      ) : null;
    }
  }

  renderStatusField(cell, row) {
    return (
      <span>
        <span
          className={
            row.active ? 'status-dot status-dot-active' : 'status-dot status-dot-deactivated'
          }
        />
        {row.active ? 'Active' : 'Deactivated'}
      </span>
    );
  }

  renderLinkField(cell, row) {
    return (
      <a className="td-link" onClick={() => this.onUserEdit(row)}>
        {row.email}
      </a>
    );
  }
}

export default UserListData;
