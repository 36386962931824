import {
  CONTACT_REASONS_FETCHED,
  CONTACT_SOLUTIONS_FETCHED,
  COMMUNICATORS_FETCHED,
  LANGUAGES_FETCHED,
  TEST_CARDS_FETCHED
} from './types';

export default function(state = { services: {} }, action) {
  switch (action.type) {
    case LANGUAGES_FETCHED:
      return { ...state, languages: action.payload.languages, languagesFetched: true };
    case CONTACT_REASONS_FETCHED:
      return {
        ...state,
        contactReasons: action.payload.contact_case_reasons,
        contactReasonsFetched: true
      };
    case CONTACT_SOLUTIONS_FETCHED:
      return {
        ...state,
        contactSolutions: action.payload.contact_case_solutions,
        contactSolutionsFetched: true
      };
    case COMMUNICATORS_FETCHED:
      return { ...state, communicators: action.payload.communicators, communicatorsFetched: true };
    case TEST_CARDS_FETCHED:
      return {
        ...state,
        testCards: action.payload.test_cards.map(testCard => {
          return { id: testCard.id, name: testCard.card_number };
        }),
        testCardsFetched: true
      };
  }

  return state;
}
