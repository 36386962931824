import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { DateTimePickerComponent } from '@common/form';

export const FIELD_DATE_FORMAT = 'MM-DD-YYYY';

class DateRangeLimitFilter extends Component {
  constructor(props) {
    super(props);
    this.handleResetButtonClick = this.handleResetButtonClick.bind(this);
  }

  handleResetButtonClick() {
    this.props.resetForm();
    this.props.onResetClick();
  }

  render() {
    return (
      <form className="form-inline contact-cases-period-filters" onSubmit={this.props.handleSubmit}>
        <span className="mr-1">Period:</span>
        <DateTimePickerComponent
          id="date_since"
          name="date_since"
          fieldClass="form-control form-control-white input-sm"
          dateFormat={FIELD_DATE_FORMAT}
          timeFormat={false}
          placeholder="Select date from"
          disabled={this.props.disabled}
        />
        <span className="mx-1">-</span>
        <DateTimePickerComponent
          id="date_until"
          name="date_until"
          fieldClass="form-control form-control-white input-sm"
          dateFormat={FIELD_DATE_FORMAT}
          timeFormat={false}
          placeholder="Select date to"
          disabled={this.props.disabled}
        />
        <button
          type="submit"
          disabled={this.props.disabled}
          className="btn btn-primary ml-3 btn-padding-x-35"
        >
          Search
        </button>
        <button
          type="button"
          disabled={this.props.disabled}
          className="btn btn-outline-none ml-1"
          onClick={this.handleResetButtonClick}
        >
          Clear all
        </button>
      </form>
    );
  }
}

const onValidate = values => {
  const errors = {};

  if (!values.date_since || !values.date_until) {
    errors.date_since = 'empty';
    errors.date_until = 'empty';
    errors.msg = 'Period must be selected';
  }

  if (values.date_since && values.date_until && !values.date_until.isAfter(values.date_since)) {
    errors.date_until = 'invalid';
    errors.msg = 'Start date must be prior to end date';
  }

  return errors;
};

const mapStateToProps = (state, props) => ({
  initialValues: props.initialValues
});

DateRangeLimitFilter = withFormik({
  mapPropsToValues: props => props.initialValues,
  validate: onValidate,
  handleSubmit: (values, { props }) => {
    props.onSearchClick(values.date_since, values.date_until);
  },
  displayName: 'DateRangeLimitFilter'
})(DateRangeLimitFilter);

export default connect(mapStateToProps)(DateRangeLimitFilter);
