import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { withFormik } from 'formik';
import { Card, CardBody } from 'reactstrap';

import { validateEmail, isNumberType } from '@common/helpers';
import { InputField } from '@common/form';
import MobileServicesFilter from './filters/MobileServicesFilter.jsx';
import Loading from '@common/components/Loading.jsx';

const email = 'email';
const msisdn = 'msisdn';

function MobileSubscriptionsFilters(props) {
  const emailRef = useRef();
  const msisdnRef = useRef();
  const [emailDisabled, setEmailDisabled] = useState(false);
  const [msdinDisabled, setMsdinDisabled] = useState(false);
  const inputDisabled = useMemo(() => {
    return !props.values.service_ids?.length;
  }, [props.values.service_ids]);

  useEffect(() => {
    if (props.filtersData) {
      props.setFieldValue(
        'service_ids',
        props.filtersData?.map(item => item.id)
      );
    }
  }, [props.filtersData]);

  const toggleDisabledInputs = (fieldName, event) => {
    const value = event.target.value;
    if (value.length === 0) {
      setEmailDisabled(false);
      setMsdinDisabled(false);
    } else {
      if (fieldName === email) {
        props.setFieldTouched(msisdn, false);
        setMsdinDisabled(true);
      } else if (fieldName === msisdn) {
        props.setFieldTouched(email, false);
        setEmailDisabled(true);
      }
    }
  };

  const resetDisabledInputs = fieldName => {
    if (fieldName === email && !emailDisabled) return;
    if (fieldName === msisdn && !msdinDisabled) return;

    setEmailDisabled(false);
    setMsdinDisabled(false);
    props.setFieldValue(email, '');
    props.setFieldValue(msisdn, '');
    const currentInputRef =
      fieldName === email ? emailRef : fieldName === msisdn ? msisdnRef : null;
    if (currentInputRef) {
      setTimeout(() => {
        currentInputRef.current.focus();
      }, 0);
    }
  };

  const onKeyUpEmail = useCallback(event => toggleDisabledInputs(email, event), []);
  const onKeyUpMsisdn = useCallback(event => toggleDisabledInputs(msisdn, event), []);

  if (!props.filtersData) {
    return <Loading loadingClass="throbber--middle" />;
  }

  return (
    <Card>
      <CardBody>
        <form onSubmit={props.handleSubmit}>
          <MobileServicesFilter
            services={props.filtersData}
            disabled={props.isDataFetching}
            initialCheck={props.initialCheck}
          />
          <div className="row">
            <div className="col-5">
              <div onClick={() => resetDisabledInputs(email)}>
                <InputField
                  id={email}
                  type="email"
                  name={email}
                  placeholder="Search by email"
                  disabled={inputDisabled || emailDisabled}
                  onKeyUp={onKeyUpEmail}
                  ref={emailRef}
                />
              </div>
            </div>
            <div className="col-5">
              <div onClick={() => resetDisabledInputs(msisdn)}>
                <InputField
                  id={msisdn}
                  type="text"
                  name={msisdn}
                  placeholder="Enter phone number"
                  disabled={inputDisabled || msdinDisabled}
                  onKeyUp={onKeyUpMsisdn}
                  ref={msisdnRef}
                />
              </div>
            </div>
            <div className="col-2">
              <button
                type="submit"
                disabled={inputDisabled || props.isDataFetching}
                className="btn btn-primary btn-block"
              >
                Search
              </button>
            </div>
          </div>
        </form>
      </CardBody>
    </Card>
  );
}

const onValidate = values => {
  const errors = {};
  if (!values[email] && !values[msisdn]) {
    errors[email] = 'Fill at least one field';
    errors[msisdn] = 'Fill at least one field';
  }
  if (values[email]) {
    if (!validateEmail(values[email])) {
      errors[email] = 'Invalid email address';
    }
  }
  if (values[msisdn]) {
    if (values[msisdn].length < 7) {
      errors[msisdn] = 'invalid';
    } else if (!isNumberType(values[msisdn])) {
      errors[msisdn] = 'invalid';
    }
  }
  return errors;
};

export default withFormik({
  displayName: 'MobileSubscriptionsFilters',
  mapPropsToValues: props => ({
    [email]: '',
    [msisdn]: '',
    service_ids: []
  }),
  validate: onValidate,
  handleSubmit: (values, { props }) => {
    values = Object.fromEntries(Object.entries(values).filter(([_, v]) => v !== ''));
    props.onSearch(values);
  }
})(MobileSubscriptionsFilters);
