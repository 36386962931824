import axios from 'axios';
import humps from 'humps';
import {
  API_AUTH_TOKEN_KEY,
  API_RESPONSE_VALID_CODE,
  API_RESPONSE_CREATED_VALID_CODE,
  API_RESPONSE_ACTION_VALID_CODE,
  API_RESPONSE_VALIDATION_FAILED_CODE,
  API_RESPONSE_UNATHORIZED_CODE
} from '@common/consts';
import { objectToQueryString } from './../common/helpers';
import { showErrorAlert, showSuccessAlert } from './../common/actions';
import {
  SERVICES_URL,
  CONTACT_CASE_REASONS_URL,
  CONTACT_CASE_SOLUTIONS_URL,
  COMMUNICATORS_URL
} from './consts';
import {
  SERVICES_FETCHED,
  SERVICE_FETCHED,
  RESET_SERVICES,
  RESET_SERVICE,
  SERVICE_PROCESSING,
  SERVICE_CREATED,
  SERVICE_CREATED_ERROR,
  SERVICE_UPDATED,
  SERVICE_UPDATED_ERROR,
  FILTER_SERVICES,
  RESET_STATUS,
  REASONS_FETCHED,
  REASON_CREATED,
  REASON_DELETED,
  REASON_UPDATED,
  SOLUTIONS_FETCHED,
  SOLUTION_CREATED,
  SOLUTION_DELETED,
  SOLUTION_UPDATED,
  COMMUNICATORS_FETCHED,
  COMMUNICATOR_CREATED,
  COMMUNICATOR_DELETED,
  COMMUNICATOR_UPDATED,
  SERVICE_DELETED_ERROR,
  SERVICE_DELETED,
  USERS_FETCHED,
  SERVICE_UNSELECT
} from './types';
import { deleteModal } from './../modal/actions/ModalActions';
import { CREATE_SELECTABLE_ITEM } from './productItems/ProductItemContainer';
import { showModal } from '../modal/actions/ModalActions';
import { ADD_SERVICE_SUCCESS } from './add/AddServiceContainer';
import { USERS_URL } from '../users/consts';
import { FILTER_USERS } from '../users/types';

const REASON_DELETED_MSG = 'Contact case reason has been deleted.';
const SOLUTION_DELETED_MSG = 'Contact case solution has been deleted.';
const COMMUNICATOR_DELETED_MSG = 'Communicator has been deleted.';

function handleAction(dispatch, url, actionType, actionPayload) {
  return axios
    .get(url)
    .then(response => {
      const res = actionPayload ? { ...response.data, ...actionPayload } : response.data;
      dispatch({ type: actionType, payload: humps.camelizeKeys(res) });
    })
    .catch(error => {});
}

export function getServices(paymentType, isActive) {
  let servicesUrl = SERVICES_URL;
  const requestData = {};
  let typePayment = null;

  if (paymentType) {
    requestData.payment_type = paymentType;
    typePayment = { paymentType: paymentType };
  }

  if (isActive !== undefined) {
    requestData.active = isActive ? 1 : 0;
  }
  if (requestData) {
    servicesUrl = `${servicesUrl}?${objectToQueryString(requestData)}`;
  }

  return function(dispatch) {
    handleAction(dispatch, servicesUrl, SERVICES_FETCHED, typePayment);
  };
}

export function getService({ id }) {
  const URL = `${SERVICES_URL}/${id}`;

  return function(dispatch) {
    handleAction(dispatch, URL, SERVICE_FETCHED);
  };
}

export function resetServices() {
  return function(dispatch) {
    dispatch({ type: RESET_SERVICES });
  };
}

export function resetService() {
  return function(dispatch) {
    dispatch({ type: RESET_SERVICE });
  };
}

export function resetStatus() {
  return function(dispatch) {
    dispatch({ type: RESET_STATUS });
  };
}

export function createService(service) {
  return function(dispatch) {
    dispatch({ type: SERVICE_PROCESSING });

    axios
      .post(SERVICES_URL, { service: humps.decamelizeKeys(service) })
      .then(response => {
        dispatch({ type: SERVICE_CREATED });
        dispatch(showModal(ADD_SERVICE_SUCCESS));
      })
      .catch(error => {
        if (error.response.status === API_RESPONSE_VALIDATION_FAILED_CODE) {
          showErrorAlert(dispatch, error.response.data.errors, { list: true });
          dispatch({ type: SERVICE_CREATED_ERROR, payload: error.response.data.errors });
        } else {
          showErrorAlert(dispatch, error.response.data.error);
        }
      });
  };
}

export function updateService(data) {
  let { id, ...params } = data;
  params = humps.decamelizeKeys(params);

  return function(dispatch) {
    dispatch({ type: SERVICE_PROCESSING });

    axios
      .put(`${SERVICES_URL}/${id}`, { service: params })
      .then(response => {
        dispatch(showModal(ADD_SERVICE_SUCCESS));
        dispatch({ type: SERVICE_UPDATED, payload: { id, ...params } });
      })
      .catch(error => {
        if (error.response.status === API_RESPONSE_VALIDATION_FAILED_CODE) {
          showErrorAlert(dispatch, error.response.data.errors, { list: true });
          dispatch({ type: SERVICE_UPDATED_ERROR, payload: error.response.data.errors });
        } else {
          showErrorAlert(dispatch, error.response.data.error);
        }
      });
  };
}

export function deleteService(serviceId) {
  return function(dispatch) {
    dispatch({ type: SERVICE_PROCESSING });

    axios
      .delete(`${SERVICES_URL}/${serviceId}`)
      .then(response => {
        dispatch({ type: SERVICE_DELETED, payload: serviceId });
        showSuccessAlert(dispatch, 'Service has been deleted');
      })
      .catch(error => {
        if (error.response.status === API_RESPONSE_VALIDATION_FAILED_CODE) {
          showErrorAlert(dispatch, error.response.data.errors, { list: true });
        } else {
          showErrorAlert(dispatch, error.response.data.error);
        }
        dispatch({ type: SERVICE_DELETED_ERROR });
      });
  };
}

export function filterServices(term) {
  return function(dispatch) {
    dispatch({ type: FILTER_SERVICES, payload: term });
  };
}

const getSelectableItems = (url, actionType) => {
  return function(dispatch) {
    return axios
      .get(`${url}`, { params: { active: 1 } })
      .then(res => {
        dispatch({ type: actionType, payload: res.data });
      })
      .catch(error => {});
  };
};

export function getContactCaseReasons() {
  return getSelectableItems(CONTACT_CASE_REASONS_URL, REASONS_FETCHED);
}

export function getContactCaseSolutions() {
  return getSelectableItems(CONTACT_CASE_SOLUTIONS_URL, SOLUTIONS_FETCHED);
}

export function getCommunicators() {
  return getSelectableItems(COMMUNICATORS_URL, COMMUNICATORS_FETCHED);
}

export function getUsers() {
  return getSelectableItems(USERS_URL, USERS_FETCHED);
}

const deleteSelectableItems = (contactCaseId, url, actionType, msg) => {
  return function(dispatch) {
    return axios
      .delete(`${url}/${contactCaseId}`)
      .then(() => {
        dispatch({ type: actionType, payload: contactCaseId });
        showSuccessAlert(dispatch, msg);
      })
      .catch(error => {
        if (error.response.status === API_RESPONSE_VALIDATION_FAILED_CODE) {
          showErrorAlert(dispatch, error.response.data.errors, { list: true });
        } else {
          showErrorAlert(dispatch, error.response.data.error);
        }
      });
  };
};

export function deleteContactCaseReason(contactCaseId) {
  return deleteSelectableItems(
    contactCaseId,
    CONTACT_CASE_REASONS_URL,
    REASON_DELETED,
    REASON_DELETED_MSG
  );
}

export function deleteContactCaseSolution(contactCaseId) {
  return deleteSelectableItems(
    contactCaseId,
    CONTACT_CASE_SOLUTIONS_URL,
    SOLUTION_DELETED,
    SOLUTION_DELETED_MSG
  );
}

export function deleteCommunicator(contactCaseId) {
  return deleteSelectableItems(
    contactCaseId,
    COMMUNICATORS_URL,
    COMMUNICATOR_DELETED,
    COMMUNICATOR_DELETED_MSG
  );
}

const createSelectableItems = (params, url, actionType) => {
  return function(dispatch) {
    dispatch({ type: SERVICE_PROCESSING });
    return axios
      .post(`${url}`, params)
      .then(response => {
        dispatch({ type: actionType, payload: response.data });
        dispatch({ type: SERVICE_CREATED });
      })
      .catch(error => {
        dispatch({ type: RESET_STATUS });
        dispatch(deleteModal(CREATE_SELECTABLE_ITEM));
        if (error.response.status === API_RESPONSE_VALIDATION_FAILED_CODE) {
          showErrorAlert(dispatch, error.response.data.errors, { list: true });
        } else {
          showErrorAlert(dispatch, error.response.data.error);
        }
      });
  };
};

export function createContactCaseReason(params) {
  return createSelectableItems(params, CONTACT_CASE_REASONS_URL, REASON_CREATED);
}

export function createContactCaseSolution(params) {
  return createSelectableItems(params, CONTACT_CASE_SOLUTIONS_URL, SOLUTION_CREATED);
}

export function createCommunicators(params) {
  return createSelectableItems(params, COMMUNICATORS_URL, COMMUNICATOR_CREATED);
}

const editSelectableItems = (params, url, actionType) => {
  return function(dispatch) {
    dispatch({ type: SERVICE_PROCESSING });
    const copyParams = { ...params };
    delete copyParams.id;
    return axios
      .put(`${url}/${params.id}`, copyParams)
      .then(() => {
        dispatch({ type: actionType, payload: params });
        dispatch({ type: SERVICE_CREATED });
      })
      .catch(error => {
        dispatch({ type: RESET_STATUS });
        dispatch(deleteModal(EDIT_REASON_MODAL));
        if (error.response.status === API_RESPONSE_VALIDATION_FAILED_CODE) {
          showErrorAlert(dispatch, error.response.data.errors, { list: true });
        } else {
          showErrorAlert(dispatch, error.response.data.error);
        }
      });
  };
};

export function editContactCaseReason(params) {
  return editSelectableItems(params, CONTACT_CASE_REASONS_URL, REASON_UPDATED);
}

export function editContactCaseSolution(params) {
  return editSelectableItems(params, CONTACT_CASE_SOLUTIONS_URL, SOLUTION_UPDATED);
}

export function editCommunicator(params) {
  return editSelectableItems(params, COMMUNICATORS_URL, COMMUNICATOR_UPDATED);
}

export function serviceUnselect() {
  return function(dispatch) {
    dispatch({ type: SERVICE_UNSELECT });
  };
}
