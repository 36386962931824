import { normalize, schema } from 'normalizr';
import * as TYPES from './types';
import { CLEAR_SUBSCRIPTIONS_DATA } from '../../types';

const initialState = {
  allIds: [],
  byId: [],
  status: null
};

export function mobileReducer(state = initialState, {type, payload }) {
  switch (type) {
    case TYPES.MOBILE_SUBSCRIPTIONS.REQUEST:
      return {
        ...state,
        status: 'loading'
      };

    case TYPES.MOBILE_SUBSCRIPTIONS.SUCCESS:
      const subscriptionSchema = new schema.Entity('subscriptions');
      const normalizedData = normalize(payload.subscriptions, [subscriptionSchema]);
      return {
        allIds: normalizedData.result,
        byId: normalizedData.entities.subscriptions,
        status: 'success'
      };

    case CLEAR_SUBSCRIPTIONS_DATA.SUCCESS:
      if (!state.byId[payload.subscriptionId]) {
        return state;
      }
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.subscriptionId]: {
            ...state.byId[payload.subscriptionId],
            active: false,
            cancelled: true,
            service: {
              ...state.byId[payload.subscriptionId].service,
              status: 'cancelled',
              active: false
            }
          }
        },
      };

    case TYPES.CANCEL_SUBSCRIPTION_MOBILE.SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload]: {
            ...state.byId[payload],
            service: {
              ...state.byId[payload].service,
              status: 'inactive'
            }
          }
        }
      };

    default:
      return state;
  }
}

export const getMobileSubscriptionsSelector = state => {
  const mobile = state.subscriptions.mobile;
  return mobile.allIds.map(id => mobile.byId[id]);
};

export const getMobileSubscriptionStatus = state => state.subscriptions.mobile.status;
