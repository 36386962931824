import React, { Component } from 'react';

const PAYMENT_TYPES = [
  {id: 'mobile', name: 'mobile'},
  {id: 'card', name: 'card'},
  {id: 'hybrid', name: 'hybrid'}
];

export const ADDITIONAL_INFO = {
  securityHash: {id: 'securityHash', label: 'Security hash'},
  apiUrl: {id: 'apiUrl', label: 'Api url'},
  paymentType: {id: 'paymentType', label: 'Payment type'},
  serviceUid: {id: 'serviceUid', label: 'Service uid'},
  darkApiVersion: {id: 'darkApiVersion', label: 'Dark api version'},
  straalV1: {id: 'straalV1', label: 'Straal v1', type: 'checkbox'},
  newSearch: {id: 'newSearch', label: 'New search', type: 'checkbox'}
};

export default class AdditionalInfoStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      securityHash: props.item.securityHash || '',
      apiUrl: props.item.apiUrl || '',
      paymentType: props.item.paymentType || 'card',
      serviceUid: props.item.serviceUid || '',
      darkApiVersion: props.item.darkApiVersion || '1.1',
      straalV1: props.item.straalV1 === undefined ? true : props.item.straalV1,
      newSearch: props.item.newSearch === undefined ? true : props.item.newSearch
    };
  }

  handleInputChange = e => {
    this.setState({[e.target.name]: e.target.value});
  };

  handleCheckbox = e => {
    this.setState({[e.target.name]: e.target.checked});
  };

  renderInput = (item, type = 'text') => (
    <div className="form-group">
      <label className="control-label">{item.label}</label>
      <input
        name={item.id}
        type={type}
        className="form-control input-sm"
        onChange={this.handleInputChange}
        value={this.state[item.id]}
      />
    </div>
  );

  renderCheckbox = (item, customClass) => (
    <div className={`custom-control custom-checkbox ${customClass}`}>
      <input
        id={item.id}
        name={item.id}
        checked={this.state[item.id]}
        onChange={this.handleCheckbox}
        className="form-check-input custom-control-input"
        type="checkbox"
      />
      <label className="custom-control-label mr-1" htmlFor={item.id}>{item.label}</label>
    </div>
  );

  handleNext = () => {
    const data = {...this.state};
    if (!this.state.securityHash.length) {
      delete data.securityHash;
    }
    this.props.handleNext(data);
  };

  render() {
    return (
      <div className="row">
        <div className='col-6'>
          {this.renderInput(ADDITIONAL_INFO.securityHash, 'password')}
          {this.renderInput(ADDITIONAL_INFO.apiUrl)}
          <div className="form-group">
            <label className="control-label">Payment type</label>
            <select className="form-control input-sm"
              name="paymentType"
              onChange={this.handleInputChange}
              defaultValue={this.state.paymentType}
            >
              {PAYMENT_TYPES.map(item => {
                return (<option key={item.id} value={item.id}>{item.name}</option>);
              })}
            </select>
          </div>
        </div>
        <div className='col-6'>
          {this.renderInput(ADDITIONAL_INFO.serviceUid)}
          {this.renderInput(ADDITIONAL_INFO.darkApiVersion)}
          <div className="form-group mt-5">
            {this.renderCheckbox(ADDITIONAL_INFO.straalV1, 'mb-1')}
            {this.renderCheckbox(ADDITIONAL_INFO.newSearch)}
          </div>
        </div>
        <div className='text-center w-100'>
          <button
            type="button"
            className={'btn btn-primary mt-6'}
            onClick={this.handleNext}
          >
            Next
          </button>
        </div>
      </div>
    );
  }
}
