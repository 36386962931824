import React, { Component } from 'react';
import { withFormik } from 'formik';
import { connect } from 'react-redux';

import { validateEmail } from '@common/helpers.jsx';
import { loginUser } from '@users/actions';
import { InputField } from '@common/form';

class LoginPage extends Component {
  render() {
    return (
      <div className="container">
        <div className="row justify-content-center">
          <div className="card mt-3 login-container">
            <div className="card-body">
              <h2 className="text-center">Dark Templar</h2>
              <form onSubmit={this.props.handleSubmit}>
                {this.props.loginError ? (
                  <div className="alert alert-danger alert-login" role="alert" type="danger">
                    {this.props.loginError}
                  </div>
                ) : (
                  ''
                )}
                <InputField type="email" name="email" placeholder="Email address" />
                <InputField type="password" name="password" placeholder="Password" />
                <div className="checkbox"></div>

                <button className="btn btn-sm btn-primary btn-block" type="submit">
                  Sign in
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const onValidate = values => {
  const errors = {};

  if (!values.password) {
    errors.password = 'This field is required';
  }

  if (!values.email) {
    errors.email = 'This field is required';
  } else if (!validateEmail(values.email)) {
    errors.email = 'Invalid email address';
  }
  return errors;
};

const LoginFormEnhancer = withFormik({
  displayName: 'LoginForm',
  mapPropsToValues: () => ({ email: '', password: '', remember: true }),
  validate: onValidate,
  handleSubmit: (values, { props }) => {
    props.loginUser(values, props.history);
  }
})(LoginPage);

const mapStateToProps = state => {
  return { loginError: state.auth.loginError };
};

export default connect(mapStateToProps, { loginUser })(LoginFormEnhancer);
