import React from 'react';
import { useField } from 'formik';

function SelectField({ label, options, emptyOption, ...props }) {
  const [field, meta] = useField(props);
  const { validate, ...restProps } = props;
  const hasError = meta.touched && meta.error;
  return (
    <>
      <label htmlFor={field.name}>{label}</label>
      <select className="form-control custom-select" {...field} {...restProps}>
        {emptyOption && <option value="">{emptyOption || ''} </option>}
        {options.map((option, index) => {
          return (
            <option key={index} value={option.id || option.id === 0 ? option.id : option.name}>
              {option.name}
            </option>
          );
        })}
      </select>
      {hasError && <div className="form-group-error">{meta.error}</div>}
    </>
  );
}

export default SelectField;
