import React from 'react';
import { connect } from 'react-redux';

import { changePage } from './../../common/actions';
import {
  getServices,
  filterServices,
  getService,
  createService,
  updateService,
  resetService
} from './../../services/actions';
import ServicesListData from './ServicesListData';
import { Link } from 'react-router-dom';
import { deleteService } from '../actions';

class ServicesListContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedService: null
    };

    this.handleServiceEdit = this.handleServiceEdit.bind(this);
    this.createService = this.createService.bind(this);
    this.filterServices = this.filterServices.bind(this);
  }

  componentDidMount() {
    this.props.changePage('Services list');
    this.props.getServices();
  }

  createService(service) {
    this.props.createService(service);
  }

  filterServices(e) {
    let term = e.target.value;
    term = term.toLowerCase();
    this.props.filterServices(term);
  }

  handleServiceEdit(service) {
    this.props.updateService(service);
  }

  render() {
    return (
      <div>
        <nav className="sub-navbar">
          <div className="container">
            <div className="row align-items-center text-right">
              <div className="col-12">
                <Link to="/admin_gate/services/reasons" className="text-secondary mr-3">
                  Manage reasons list
                </Link>
                <Link to="/admin_gate/services/solutions" className="text-secondary mr-3">
                  Manage solutions list
                </Link>
                <Link to="/admin_gate/services/communicators" className="text-secondary mr-3">
                  Manage comunicators list
                </Link>
                <Link to="/admin_gate/services/add" className="btn btn-primary">
                  Add new product
                </Link>
              </div>
            </div>
          </div>
        </nav>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 mt-2">
              <p>
                Total items:{' '}
                <span className="ui-heading-info">
                  {' '}
                  {this.props.services ? this.props.services.length : null}
                </span>
              </p>
              <ServicesListData
                fetchingData={this.props.fetchingData}
                fetchedData={this.props.services}
                deleteService={this.props.deleteService}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const services = state.services;

  return {
    services: services.all,
    serviceSelected: services.serviceSelected,
    serviceActionStatus: services.serviceActionStatus
  };
};

export default connect(mapStateToProps, {
  changePage,
  getServices,
  filterServices,
  getService,
  createService,
  updateService,
  resetService,
  deleteService
})(ServicesListContainer);
