import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';

import { getServices } from '@services/actions';
import { getLanguages, getTestCards } from '../../resources/actions';
import { SERVICE_TYPE_PAYCARD } from '@services/consts';
import {
  actionOnMultipleSubscriptions,
  clearCardSubscriptions,
  getCardSubscriptions,
  resetMultipleSubscriptionAction
} from './store/actions';
import SubscriptionsData from '../common/SubscriptionsData';
import { hasGroupPermissionTo } from '../../users/groups';
import TablePagination from '../../common/components/TablePagination';
import { Card, CardBody } from 'reactstrap';
import Loading from '../../common/components/Loading';
import {
  getCardSubscriptionsFetchingStatus,
  getCardSubscriptionsSelector,
  getCardSubscriptionsTotal
} from './store/cardReducer';
import CardSubscriptionsFilters from './_CardSubscriptionsFilters.jsx';
import { mapValuesToSubmit } from './mapValues';

const SELECT_ALL_PRODUCTS_LABEL = 'All products';

class CardSubscriptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      services: [{ name: SELECT_ALL_PRODUCTS_LABEL, id: [] }],
      pagination: {
        currentPage: 1,
        limit: 10
      },
      currentFilters: null
    };

    this.updateServices = this.updateServices.bind(this);
    this.getCardSubscriptions = this.getCardSubscriptions.bind(this);
  }

  componentDidMount() {
    if (!this.props.services) {
      this.props.getServices(SERVICE_TYPE_PAYCARD, true);
    }
    this.props.getLanguages();
    if (hasGroupPermissionTo('creditCardSearchEnhancement')) {
      this.props.getTestCards();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.services && this.props.services && this.props.services.length) {
      this.setState({
        services: [this.props.services[0]]
      });
    }
  }

  componentWillUnmount() {
    this.props.clearCardSubscriptions();
  }

  updateServices(services) {
    this.setState({ services });
    this.props.clearCardSubscriptions();
  }

  handleSearch = requestData => {
    this.setState(
      prevState => ({
        currentFilters: requestData,
        pagination: { ...prevState.pagination, currentPage: 1 }
      }),
      () => {
        this.getCardSubscriptions();
      }
    );
  };

  getCardSubscriptions() {
    const { queryParams = {}, ...params } = this.state.currentFilters;
    params.service_ids = [];
    this.state.services.forEach(service => {
      if (service.name === SELECT_ALL_PRODUCTS_LABEL) {
        params.service_ids = this.props.services
          .filter(item => typeof item.id === 'number')
          .map(item => item.id);
      } else {
        params.service_ids.push(service.id);
      }
    });
    queryParams.limit = this.state.pagination.limit;
    const offset = (this.state.pagination.currentPage - 1) * this.state.pagination.limit;
    if (offset) {
      queryParams.offset = offset;
    } else if (offset === 0) {
      delete queryParams.offset;
    }
    return this.props.getCardSubscriptions(params, queryParams);
  }

  get isSearchDisabled() {
    return this.state.services.length === 0;
  }

  get paginationTotalPages() {
    return Math.ceil(this.props.subscriptionsTotal / this.state.pagination.limit);
  }

  onPaginationPageChange = currentPage => {
    this.setState(
      prevState => ({ pagination: { ...prevState.pagination, currentPage } }),
      () => {
        this.getCardSubscriptions();
      }
    );
  };

  disableSelectOption = option => {
    const isAllOptionSelected =
      this.state.services[0] && this.state.services[0].name === SELECT_ALL_PRODUCTS_LABEL;
    if (
      isAllOptionSelected ||
      (this.state.services.length && option.name === SELECT_ALL_PRODUCTS_LABEL)
    ) {
      return true;
    } else if (!isAllOptionSelected && option.name === SELECT_ALL_PRODUCTS_LABEL) {
      return false;
    }
  };

  render() {
    return (
      <div className="container mt-15 subscriptions">
        {this.props.services ? (
          <Card>
            <CardBody>
              <div className="mb-2">
                <p className="text-secondary">Select products:</p>
                <Select
                  defaultValue={this.props.services[0]}
                  isMulti
                  name="service_id"
                  options={this.props.services}
                  classNamePrefix="select"
                  onChange={this.updateServices}
                  isOptionDisabled={this.disableSelectOption}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.id}
                />
              </div>
              <CardSubscriptionsFilters
                onSearchClick={this.handleSearch}
                testCards={this.props.testCards}
                disabled={this.isSearchDisabled}
                resetData={this.props.clearCardSubscriptions}
                queryParams={this.props.location.search}
                onSubmit={this.handleSearch}
              />
            </CardBody>
          </Card>
        ) : (
          <Loading loadingClass="throbber--middle" />
        )}

        {this.props.subscriptions ? (
          <div>
            <SubscriptionsData
              fetchDataStatus={this.props.fetchDataStatus}
              fetchedData={this.props.subscriptions}
              languages={this.props.languages}
              modalActionStatus={this.props.modalActionStatus}
            />
            {this.props.subscriptionsTotal ? (
              <TablePagination
                currentPage={this.state.pagination.currentPage}
                totalPages={this.paginationTotalPages}
                onPageChange={this.onPaginationPageChange}
              />
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }
}

const addOption = services => {
  const allOption = { name: SELECT_ALL_PRODUCTS_LABEL, id: [] };
  services.forEach(item => allOption.id.push(item.id));
  return [allOption, ...services];
};

function mapStateToProps(state) {
  return {
    fetchDataStatus: getCardSubscriptionsFetchingStatus(state),
    services: state.services.paycard && addOption(state.services.paycard),
    subscriptions: getCardSubscriptionsSelector(state),
    subscriptionsTotal: getCardSubscriptionsTotal(state),
    modalActionStatus: state.subscriptions.notifications,
    multipleSubscriptionsCancelStatus: state.multipleSubscriptionsCancelStatus,
    languages: state.resources.languages,
    testCards: state.resources.testCards
  };
}

export default connect(mapStateToProps, {
  getCardSubscriptions,
  clearCardSubscriptions,
  getServices,
  getLanguages,
  getTestCards,
  actionOnMultipleSubscriptions,
  resetMultipleSubscriptionAction
})(CardSubscriptions);
