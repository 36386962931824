import React, { Component } from 'react';
import { Route } from 'react-router-dom';

class AuthorizedRoute extends Component {
  render() {
    return this.props.hasPermission ? (
      <Route path={this.props.path} component={this.props.component} />
    ) : (
      <div className="container mt-15">
        <div className="row">
          <p className="mx-auto">
            Access denied. You don't have permission to display this page.
          </p>
        </div>
      </div>
    );
  }
}

export default AuthorizedRoute;
