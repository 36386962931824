import React, { PureComponent } from 'react';

import LinkedItems from '@common/components/LinkedItems';
import { rename } from '@common/helpers';

export default class CommunicatorStep extends PureComponent {
  render() {
    return (
      <div className="col-8 mx-auto">
        <LinkedItems
          items={this.props.items.map(item => {
            return { id: item.id, name: rename(item.description) };
          })}
          options={{ itemsPerRow: this.props.items.length }}
          onClick={itemData => {
            return this.props.handleNext({ communicator: itemData.id });
          }}
          reset={this.props.reset}
        />
      </div>
    );
  }
}
