import React from 'react';
import { displayTimestamp } from '../../common/helpers';

export function SubscriptionsItemHistoryEvents(props) {
  return (
    <div className={'card card--absolute active'}>
      {props.events && props.events.length ? (
        <div className="card-body">
          <div className="row">
            <div className="col-6">
              <p className="ui-heading ui-heading-dark">Events:</p>
            </div>
            <div className="col-6">
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={props.toggleEvents}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
          </div>
          <table className="table table-hover table-bordered">
            <thead className="thead-default">
              <tr>
                <th>Date</th>
                <th>Communicator</th>
                <th>Case</th>
                <th>Solution and comment</th>
                <th>User</th>
              </tr>
            </thead>
            <tbody>
              {props.events.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{displayTimestamp(item.created_at)}</td>
                    <td>{item.communicator.description}</td>
                    <td>{item.contact_case_reason.description}</td>
                    <td>{item.solution_description}</td>
                    <td>{item.user.email}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="card-body">
          <div className="row">
            <div className="col-6">
              <p className="mb-0">No events for this subscription</p>
            </div>
            <div className="col-6">
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={props.toggleEvents}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
