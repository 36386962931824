export const NETHONE_ALERTS_PER_PAGE = 25;
export const SORT_ORDER_NEWEST = '-';
export const SORT_ORDER_OLDEST = '+';
export const SORT_KEYS = {
  transactionDate: 'transaction_date',
  alertDate: 'alert_date'
};
export const DEFAULT_SORT_ORDER = SORT_ORDER_NEWEST;
export const DEFAULT_SORT_KEY = SORT_KEYS.alertDate;

export const ALL_STATUS_FILTER_OPTIONS = [
  { id: '', name: 'All' },
  { id: 'multiple_matched', name: 'Multiple matched' },
  { id: 'not_found', name: 'Not found' },
  { id: 'pending', name: 'Pending' },
  { id: 'processing', name: 'Processing' },
  { id: 'error', name: 'Error' },
  { id: 'completed', name: 'Completed' }
];

export const NETHONE_ALERT_MODAL = 'nethone_modal';

export const RESULT_STATUS_MAP = {
  fullRefund: 'full_refund',
  alreadyRefunded: 'already_refunded',
  transactionNotfound: 'transaction_notfound'
};

export const RESULT_STATUS = [
  {id: RESULT_STATUS_MAP.fullRefund, name: 'Full refund'},
  {id: RESULT_STATUS_MAP.alreadyRefunded, name: 'Already refunded'},
  {id: RESULT_STATUS_MAP.transactionNotfound, name: 'Transaction not found'}
];

export const EDITABLE_ALERT_STATUS = 'unresolved';
