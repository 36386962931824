import axios from 'axios';

import { objectToQueryString } from '@common/helpers';

import {
  CONTACT_CASE_REASONS_URL,
  CONTACT_CASE_SOLUTIONS_URL,
  COMMUNICATORS_URL,
  LANGUAGES_URL, TEST_CARDS_URL
} from './consts';
import {
  CONTACT_REASONS_FETCHED,
  CONTACT_SOLUTIONS_FETCHED,
  COMMUNICATORS_FETCHED,
  LANGUAGES_FETCHED, TEST_CARDS_FETCHED
} from './types';

function handleAction(dispatch, url, actionType, params) {
  if (params) {
    const queryString = objectToQueryString(params);
    url = `${url}?${queryString}`;
  }

  return axios
    .get(url)
    .then(response => {
      dispatch({ type: actionType, payload: response.data });
    })
    .catch(error => {});
}

export function getLanguages(params) {
  return function(dispatch) {
    handleAction(dispatch, LANGUAGES_URL, LANGUAGES_FETCHED, params);
  };
}

export function getTestCards() {
  return function(dispatch) {
    handleAction(dispatch, TEST_CARDS_URL, TEST_CARDS_FETCHED);
  };
}

export function getContactReasons(params) {
  return function(dispatch) {
    handleAction(dispatch, CONTACT_CASE_REASONS_URL, CONTACT_REASONS_FETCHED, params);
  };
}

export function getContactSolutions(params) {
  return function(dispatch) {
    handleAction(dispatch, CONTACT_CASE_SOLUTIONS_URL, CONTACT_SOLUTIONS_FETCHED, params);
  };
}

export function getCommunicators(params) {
  return function(dispatch) {
    handleAction(dispatch, COMMUNICATORS_URL, COMMUNICATORS_FETCHED, params);
  };
}

export function getContactCaseServiceRelatedData(params) {
  return function(dispatch) {
    dispatch(getContactReasons(params));
    dispatch(getContactSolutions(params));
    dispatch(getCommunicators(params));
    dispatch(getLanguages(params));
  };
}
