import React, { useEffect, useState } from 'react';

const CheckboxField = ({ data, push, remove, form, name, containerClass, toggleAll }) => {
  const [allSelected, setAllSelected] = useState(data.length === form.values[name].length);

  useEffect(() => {
    setAllSelected(form.values[name].length === data.length);
  }, [form.values[name].length]);

  const onToggleAll = () => {
    // always remove all
    data.forEach(item => {
      const index = data.indexOf(item.id);
      remove(index);
    });

    if (!allSelected) {
      // select all
      data.forEach(item => push(item.id));
    }
    setAllSelected(!allSelected);
  };

  const onChange = (e, id) => {
    if (e.target.checked) {
      push(id);
    } else {
      const idx = form.values[name].indexOf(id);
      remove(idx);
    }
  };

  return (
    <ul className="custom-checkboxes-list">
      {toggleAll && (
        <li className={'custom-control custom-checkbox ' + containerClass}>
          <input
            id="selectAll"
            type="checkbox"
            checked={allSelected}
            onChange={onToggleAll}
            className="form-check-input custom-control-input"
          />
          <label htmlFor="selectAll" className="custom-control-label mr-2 font-weight-black">
            {allSelected ? 'Clear all' : 'Select all'}
          </label>
        </li>
      )}
      {data.map(tag => (
        <li key={tag.id} className={'custom-control custom-checkbox ' + containerClass}>
          <input
            id={tag.id}
            name={name}
            type="checkbox"
            value={tag.id}
            checked={form.values[name].includes(tag.id)}
            onChange={e => onChange(e, tag.id)}
            className="form-check-input custom-control-input"
          />
          <label htmlFor={tag.id} className="custom-control-label mr-2 font-weight-black">
            {tag.name}
          </label>
        </li>
      ))}
    </ul>
  );
};

export default CheckboxField;
