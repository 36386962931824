import { CHANGE_PAGE } from './types';

export default function(state = {}, action) {
  switch (action.type) {
    case CHANGE_PAGE:
      return { ...state, pageTitle: action.payload.title, alert: null };
  }

  return state;
}
