import React from 'react';
import { FieldArray } from 'formik';

import CheckboxField from '@common/form/CheckboxField.jsx';

const MobileServicesFilter = props => (
  <div className="mb-2">
    <p className="text-secondary">Select products:</p>
    <FieldArray
      name="service_ids"
      render={arrayHelpers => (
        <CheckboxField
          data={props.services}
          containerClass="d-inline"
          toggleAll={true}
          {...arrayHelpers}
        />
      )}
    />
  </div>
);

export default MobileServicesFilter;
