import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import qs from 'qs';

import { changePage } from '@common/actions';
import { hasContactCasesPermissionTo, action } from '@users/permissions';
import { sortOrderRename, removeEmptyObjectKeys } from '@common/helpers';

import { getContactCases, deleteContactCase, exportContactCases } from '../actions';
import { CONTACT_CASE_PER_PAGE } from '../consts';
import ContactCasesTable from './ContactCasesTable';
import ContactCasesFilters from './ContactCasesFilters';
import { showNotificationError } from './../../notifications/actions/NotificationsActions';
import { FIELD_DATE_FORMAT } from './filters/DateRangeLimitFilter';
import { getTimestamp } from '../../common/helpers';

const defaultState = {
  currentPage: 1,
  currentFilters: {
    limit: CONTACT_CASE_PER_PAGE,
    order: 'newest',
    date_since: moment().startOf('month'),
    date_until: moment().endOf('month')
  }
};

class ContactCasesContainer extends PureComponent {
  constructor(props) {
    super(props);
    const queryParams = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    if (queryParams.date_since) {
      queryParams.date_since = moment.unix(queryParams.date_since);
    }
    if (queryParams.date_until) {
      queryParams.date_until = moment.unix(queryParams.date_until);
    }
    this.state = {
      ...defaultState,
      currentFilters: {
        ...defaultState.currentFilters,
        ...queryParams
      }
    };
  }

  componentDidMount() {
    this.getContactCases();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.contactCases &&
      this.props.contactCases.length === 0 &&
      this.state.currentPage > 1
    ) {
      this.setState(
        prevState => ({
          currentPage: 1,
          currentFilters: {
            ...prevState.currentFilters,
            offset: this.getOffset(1)
          }
        }),
        () => this.getContactCases()
      );
    }
  }

  handleExportClick = () => {
    const requestData = this.convertDateToTimestamp(this.state.currentFilters);
    Object.keys(requestData).forEach(filter => {
      (filter === 'limit' || filter === 'offset') && delete requestData[filter];
    });
    this.props.exportContactCases(requestData);
  };

  handleFiltersClick = filters => {
    this.setState(
      prevState => {
        const updatedFilters = removeEmptyObjectKeys({ ...prevState.currentFilters, ...filters });
        return {
          currentFilters: {
            ...updatedFilters,
            offset: null
          },
          currentPage: 1
        };
      },
      () => this.getContactCases()
    );
  };

  handleSortClick = sortOrder => {
    this.setState(
      prevState => ({
        currentFilters: {
          ...prevState.currentFilters,
          order: sortOrderRename(sortOrder)
        }
      }),
      () => this.getContactCases()
    );
  };

  handlePaginationClick = page => {
    const offset = this.getOffset(page);
    this.setState(
      prevState => ({
        currentFilters: {
          ...prevState.currentFilters,
          offset: offset
        },
        currentPage: page
      }),
      () => this.getContactCases()
    );
  };

  getOffset(page) {
    return page > 1 ? Math.ceil((page - 1) * CONTACT_CASE_PER_PAGE) : null;
  }

  handleSearchClick = (date_since, date_until) => {
    this.setState(
      prevState => ({
        currentFilters: {
          ...prevState.currentFilters,
          date_since,
          date_until
        }
      }),
      () => this.getContactCases()
    );
  };

  handleSearchError = error => {
    this.props.showNotificationError(error.msg);
  };

  handleResetClick = () => {
    this.setState(defaultState, () => {
      this.getContactCases();
    });
  };

  getContactCases() {
    let requestData = { ...this.state.currentFilters };
    removeEmptyObjectKeys(requestData);
    requestData = this.convertDateToTimestamp(requestData);

    const queryParams = qs.stringify(requestData, { arrayFormat: 'brackets', encode: false });
    this.props.history.push({ search: queryParams });
    this.props.getContactCases(requestData, this.props.history);
  }

  handleDeleteClick = id => {
    return hasContactCasesPermissionTo(action.delete) ? this.props.deleteContactCase(id) : null;
  };

  renderTotal() {
    if (!this.props.fetchingCases && this.props.totalContactCases) {
      return (
        <p>
          Total items: <span className="ui-heading-info">{this.props.totalContactCases}</span>
        </p>
      );
    }
  }

  convertDateToTimestamp(data) {
    const updatedData = { ...data };
    if (data.date_since) {
      updatedData.date_since = getTimestamp(data.date_since, FIELD_DATE_FORMAT);
    }
    if (data.date_until) {
      updatedData.date_until = getTimestamp(data.date_until, FIELD_DATE_FORMAT);
    }
    return updatedData;
  }

  render() {
    const { limit, order, offset, date_since, date_until, ...modalFilters } =
      this.state.currentFilters;
    const dateFilter = { date_since, date_until };
    return (
      <div>
        <ContactCasesFilters
          onSearchClick={this.handleSearchClick}
          onSearchError={this.handleSearchError}
          onExportClick={this.handleExportClick}
          onFiltersClick={this.handleFiltersClick}
          onResetClick={this.handleResetClick}
          isDataFetching={this.props.fetchingCases}
          isFetchingFile={this.props.fetchingFile}
          currentFiltersModal={modalFilters}
          currentFiltersDate={dateFilter}
        />
        <div className="container">
          <div className="row">
            <div className="col-12 mt-2">
              {this.renderTotal()}
              <ContactCasesTable
                fetchingData={this.props.fetchingCases}
                fetchedData={this.props.contactCases}
                onPaginationClick={this.handlePaginationClick}
                onSortClick={this.handleSortClick}
                onDeleteClick={this.handleDeleteClick}
                onDeleting={this.props.processingItems}
                pages={{
                  total: Math.ceil(this.props.totalContactCases / CONTACT_CASE_PER_PAGE),
                  current: this.state.currentPage
                }}
                sort={sortOrderRename(this.state.currentFilters.order)}
                showAction={hasContactCasesPermissionTo(action.delete)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    contactCases: state.contactCases.contactCases,
    fetchingFile: state.contactCases.fetchingFile,
    fetchingCases: state.contactCases.fetchingData,
    totalContactCases: state.contactCases.total,
    processingItems: state.contactCases.processingItems
  };
}

export default connect(mapStateToProps, {
  getContactCases,
  changePage,
  deleteContactCase,
  showNotificationError,
  exportContactCases
})(ContactCasesContainer);
