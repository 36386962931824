import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Collapse } from 'reactstrap';

import { SubscriptionsItemHeader } from './SubscriptionsItemHeader';
import SubscriptionsNotificationsModal from './SubscriptionsNotificationsModal';
import {
  clearData,
  resetNotificationStatus,
  sendCancelNotification,
  sendRefundNotification
} from '../actions';
import {
  cancelSubscription,
  cancelSubscriptionPayment,
  createSubscriptionRefund,
  fetchEvents,
  confirmSubscriptionRefund,
  subscriptionManualRefund,
  cancelTransaction
} from '../cards/store/actions';
import { cancelSubscriptionMobile } from '../mobile/store/actions';
import { COUNTRIES } from '../../common/consts/countries';
import { API_RESPONSE_CREATED_VALID_CODE } from '../../common/consts';
import { action, hasRefundsPermissionTo } from '../../users/permissions';
import { getCardSubscriptionStatus } from '../cards/store/cardReducer';
import CardSubscriptionsItem from '../cards/CardSubscriptionsItem.jsx';
import MobileSubscriptionsItem from '../mobile/MobileSubscriptionsItem';

const ACTIVE_STATUS = 'active';

class SubscriptionItemContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      showEvents: false,
      language: this.getDefaultLanguage()
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.item && this.props.item.service.country !== prevProps.item.service.country) {
      this.setState({ language: this.getDefaultLanguage() });
    }
  }

  handleItemClick = () => {
    this.setState({ show: !this.state.show });
  };

  getDefaultLanguage = () => {
    const serviceCountry = this.props.item.service.country
      ? this.props.item.service.country
      : 'United Kingdom';
    const country = COUNTRIES.find(
      country => country.name.toLowerCase() === serviceCountry.toLowerCase()
    );
    let code = country ? country.code.toLowerCase() : 'en';

    if (code === 'br') {
      code = 'pt';
    }

    return (
      this.props.languages.find(language => language.locale === code) ||
      this.props.languages.find(language => language.name === 'English')
    );
  };

  // get subscription status depends on user/mobile type
  subscriptionStatus = () => {
    return this.props.mobile
      ? this.props.item.service.status
      : this.returnUserSubsciptionStatusName(this.props.item);
  };

  // payment status for card subscriptions
  returnUserSubsciptionStatusName = itemData => {
    if (itemData.cancelled) {
      return 'cancelled';
    } else if (itemData.suspended) {
      return 'suspended';
    } else {
      return ACTIVE_STATUS;
    }
  };

  handleRefundActions = data => {
    const hasPermissionToConfirmRefund = hasRefundsPermissionTo(action.confirm);
    const params = {
      refundData: data,
      subscriptionID: this.props.item.idUnique
    };
    if (hasPermissionToConfirmRefund) {
      return this.props
        .createSubscriptionRefund(params)
        .then(res => {
          if (res.status === API_RESPONSE_CREATED_VALID_CODE) {
            this.props.confirmSubscriptionRefund({
              refundID: res.data.id,
              subscriptionID: this.props.item.idUnique,
              ...data
            });
          }
        })
        .catch(err => console.log(err));
    } else {
      return this.props.createSubscriptionRefund(params);
    }
  };

  handleManualRefund = (refundID, chargeID) => {
    return this.props.subscriptionManualRefund({
      refundID,
      chargeID,
      subscriptionID: this.props.item.idUnique
    });
  };

  handleChargeCancel = chargeID => {
    return this.props.cancelTransaction({ chargeID, subscriptionID: this.props.item.idUnique });
  };

  handleShowNotificationModal = () => {
    this.setState({ isNotificationModalOpen: true });
  };

  handleCloseNotificationModal = () => {
    this.props.resetNotificationStatus();
    this.setState({ isNotificationModalOpen: false });
  };

  toggleEvents = data => {
    !this.state.showEvents ? this.props.fetchEvents(data) : null;

    this.setState({
      showEvents: !this.state.showEvents
    });
  };

  handleChangeLanguage = language => {
    this.setState({ language });
  };

  // cancel payment
  cancelSubscriptionPayment = () => {
    const paymentUID = this.props.mobile ? this.props.item.id : this.props.item.uid;
    return this.props.cancelSubscriptionPayment({
      service_id: this.props.item.service.id,
      payment_uid: paymentUID,
      subscriptionId: this.props.item.idUnique,
      locale: this.state.language && this.state.language.locale
    });
  };

  // cancel account in service and cancel payment
  cancelSubscription = () => {
    const paymentUID = this.props.mobile ? this.props.item.id : this.props.item.uid;
    return this.props.cancelSubscription({
      service_id: this.props.item.service.id,
      payment_uid: paymentUID,
      subscriptionId: this.props.item.idUnique,
      locale: this.state.language && this.state.language.locale
    });
  };

  cancelSubscriptionMobile = () => {
    return this.props.cancelSubscriptionMobile({
      service_id: this.props.item.service.id,
      payment_uid: this.props.item.id,
      locale: this.state.language
    });
  };

  handleClearData = () => {
    const paymentUID = this.props.mobile ? this.props.item.id : this.props.item.uid;
    return this.props.clearData({
      service_id: this.props.item.service.id,
      payment_uid: paymentUID,
      subscriptionId: this.props.item.idUnique
    });
  };

  render() {
    return (
      <li className="mb-15">
        <div className="card">
          <div className="card-header">
            <SubscriptionsItemHeader
              item={this.props.item}
              mobile={this.props.mobile}
              show={this.state.show}
              handleItemClick={this.handleItemClick}
              status={this.props.subscriptionStatus}
              subscriptionStatus={this.subscriptionStatus}
            />
          </div>
          {this.state.show && (
            <Collapse isOpen={this.state.show}>
              {this.props.mobile ? (
                <MobileSubscriptionsItem
                  item={this.props.item}
                  language={this.state.language}
                  languages={this.props.languages}
                  showEvents={this.state.showEvents}
                  handleChangeLanguage={this.handleChangeLanguage}
                  handleRefundActions={this.handleRefundActions}
                  handleManualRefund={this.handleManualRefund}
                  cancelSubscription={this.cancelSubscription}
                  cancelSubscriptionPayment={this.cancelSubscriptionPayment}
                  cancelSubscriptionMobile={this.cancelSubscriptionMobile}
                  handleClearData={this.handleClearData}
                  toggleEvents={this.toggleEvents}
                  handleShowNotificationModal={this.handleShowNotificationModal}
                />
              ) : (
                <CardSubscriptionsItem
                  item={this.props.item}
                  itemStatusActive={this.props.subscriptionStatus.active}
                  language={this.state.language}
                  languages={this.props.languages}
                  showEvents={this.state.showEvents}
                  handleChangeLanguage={this.handleChangeLanguage}
                  handleRefundActions={this.handleRefundActions}
                  handleManualRefund={this.handleManualRefund}
                  handleChargeCancel={this.handleChargeCancel}
                  cancelSubscription={this.cancelSubscription}
                  cancelSubscriptionPayment={this.cancelSubscriptionPayment}
                  cancelSubscriptionMobile={this.cancelSubscriptionMobile}
                  handleClearData={this.handleClearData}
                  toggleEvents={this.toggleEvents}
                  handleShowNotificationModal={this.handleShowNotificationModal}
                />
              )}
            </Collapse>
          )}
        </div>
        {this.state.isNotificationModalOpen && (
          <SubscriptionsNotificationsModal
            itemData={this.props.item}
            language={this.state.language}
            onModalClose={this.handleCloseNotificationModal}
            sendCancelNotification={this.props.sendCancelNotification}
            sendRefundNotification={this.props.sendRefundNotification}
            notificationStatus={this.props.modalActionStatus && this.props.modalActionStatus.status}
          />
        )}
      </li>
    );
  }
}

const mapStateToProps = (state, props) => ({
  subscriptionStatus: getCardSubscriptionStatus(state, props.item.idUnique)
});

export default connect(mapStateToProps, {
  cancelSubscription,
  cancelSubscriptionPayment,
  cancelSubscriptionMobile,
  sendCancelNotification,
  sendRefundNotification,
  resetNotificationStatus,
  fetchEvents,
  clearData,
  createSubscriptionRefund,
  confirmSubscriptionRefund,
  subscriptionManualRefund,
  cancelTransaction
})(SubscriptionItemContainer);
