import React from 'react';
import { hasCurrentUserPermission } from '../users/permissions';

export default function hasStepPermission(WrappedComponent) {
  class HasStepPermission extends React.Component {
    notAllowedSteps = [];
    returnAllowedSteps() {
      return this.props.steps.filter(step => {
        if (!step.hasPermission ||
          (step.hasPermission && hasCurrentUserPermission(step.hasPermission))) {
          return true;
        } else {
          this.notAllowedSteps.push(step.name);
          return false;
        }
      });
    }

    render() {
      return <WrappedComponent
        {...this.props}
        steps={this.returnAllowedSteps()}
        notAllowedSteps={this.notAllowedSteps}
      />;
    }
  }
  HasStepPermission.displayName = `HasStepPermission(${getDisplayName(WrappedComponent)})`;
  return HasStepPermission;
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}
