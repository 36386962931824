import React, { Component } from 'react';
import MultiSelectBox from '../../../../lib/msb/react/msb';

export default class UsersStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userIds: []
    };
    this.handleNext = this.handleNext.bind(this);
    this.handleMultiSelectChange = this.handleMultiSelectChange.bind(this);
  }

  get users() {
    return this.props.items.map(item => {
      return {
        id: item.id,
        name: item.email,
        value: item.id,
        selected: item.selected,
        group: item.user_group
      };
    });
  }

  handleNext() {
    if (!this.state.userIds.length) {
      return;
    }

    this.props.handleNext(this.state);
  }

  handleMultiSelectChange(users) {
    const userIds = users.map(users => users.value);

    this.setState({
      userIds
    });
  }

  render() {
    return (
      <div className="col-10 mx-auto">
        <MultiSelectBox
          data={this.users}
          options={{ selectableLabel: 'Users', groupBy: 'group' }}
          onChange={this.handleMultiSelectChange}
          reset={this.props.reset}
        />
        <div className={'text-center'}>
          <button
            type="button"
            className={`btn btn-primary mt-6 ${!this.state.userIds.length ? 'disabled' : ''}`}
            onClick={() => this.handleNext()}
          >
            Next
          </button>
        </div>
      </div>
    );
  }
}
