import 'raf/polyfill';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';

import App from './app/App';
import LoginPage from './app/users/login/LoginPage';
import './stylesheets/react-datetime.css';
import './stylesheets/main.sass';
import { API } from './api';
import AuthenticatedRoute from './app/common/components/AuthenticatedRoute';
import configureStore from './configureStore';

console.log('Check App Version ', __APP_VERSION__);

export const store = configureStore();

API.init();

const root = ReactDOM.createRoot(document.getElementById('app'));

if (!import.meta.env.DEV) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.MODE,
    release: __APP_VERSION__
  });
}

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Route path="/login" component={LoginPage} />
      <Sentry.ErrorBoundary showDialog>
        <AuthenticatedRoute path="/" component={App} />
      </Sentry.ErrorBoundary>
    </BrowserRouter>
  </Provider>
);
