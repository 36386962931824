import React from 'react';

export function AddServiceSuccessModal(props) {
  return (
    <div>
      <div className="modal-header">
        <h4 className="modal-title">
          {props.edit ? (
            <p className="text-center mb-0">Product was succesfully updated!</p>
          ) : (
            <p className="text-center mb-0">New product was succesfully added!</p>
          )}
        </h4>
      </div>
      <div className="modal-body">
        <div className="row justify-content-center">
          <div className="col-8">
            <button
              role="button"
              className="btn btn-primary btn-modal mt-0"
              onClick={props.onModalClose}
            >
              Done
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
