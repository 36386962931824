import React from 'react';
import { connect } from 'react-redux';
import {
  getContactCaseReasons,
  deleteContactCaseReason,
  createContactCaseReason,
  editContactCaseReason
} from './../actions';
import ProductItemContainer from './ProductItemContainer';

const ContactCaseReasons = props => {
  return (
    <ProductItemContainer
      name="reason"
      data={props.contactCaseReasons}
      getItems={props.getContactCaseReasons}
      deleteItem={props.deleteContactCaseReason}
      createItem={props.createContactCaseReason}
      editItem={props.editContactCaseReason}
    />
  );
};

const mapStateToProps = state => {
  return {
    contactCaseReasons: state.services.contactCaseReasons
  };
};

export default connect(mapStateToProps, {
  getContactCaseReasons,
  deleteContactCaseReason,
  createContactCaseReason,
  editContactCaseReason
})(ContactCaseReasons);
