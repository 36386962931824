import { proceedLogout } from './app/users/actions';
import { API_AUTH_TOKEN_KEY, API_AUTH_VALID_KEY } from './app/common/consts';
import axios from 'axios';
import { isTimestampValid } from './app/common/helpers';
import { LOGIN_USER } from './app/users/types';
import { store } from './index';

export const API = {
  init() {
    const token = localStorage.getItem(API_AUTH_TOKEN_KEY);
    const tokenTimestamp = localStorage.getItem(API_AUTH_VALID_KEY);

    axios.defaults.baseURL = import.meta.env.VITE_API_URL;
    axios.defaults.headers.post['Content-Type'] = 'application/json';

    axios.interceptors.request.use(
      config => {
        const token = localStorage.getItem(API_AUTH_TOKEN_KEY);
        if (token !== null) {
          config.headers['Auth-Token'] = token;
        }

        return config;
      },
      error => Promise.reject(error)
    );

    axios.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        console.log('Response error: ', error);
        if (error.response.status === 401) {
          proceedLogout(store.dispatch);
        }
        return Promise.reject(error);
      }
    );

    // For staging
    if (import.meta.env.VITE_API_CREDENTIALS_USERNAME) {
      axios.defaults.withCredentials = true;
      axios.defaults.auth = {
        username: import.meta.env.VITE_API_CREDENTIALS_USERNAME,
        password: import.meta.env.VITE_API_CREDENTIALS_PASSWORD
      };
    }

    // Login authenticated user or clear storage if token expired
    if (token) {
      if (tokenTimestamp && isTimestampValid(tokenTimestamp)) {
        store.dispatch({ type: LOGIN_USER, payload: { role: 'TODO', email: 'TODO' } });
      } else {
        localStorage.clear();
      }
    }
  }
};
