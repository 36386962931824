import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { changePage } from '@common/actions';
import { getContactCaseServiceRelatedData } from '@resources/actions';

import { addContactCase } from '../actions';
import Loading from '@common/components/Loading';
import { WIZARD_STEPS } from './steps/steps';
import EntityWizard from '../../wizard/EntityWizard';
import { getServices } from '../../services/actions';

class AddContactCaseWizardContainer extends Component {
  constructor(props) {
    super(props);
    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleNextStep = this.handleNextStep.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.changePage('Add contact case');
    this.props.getServices(null, true);
  }

  handleBackClick() {
    this.props.changePage('Add contact case');
  }

  initialData() {
    const requestedData = this.props.services;
    if (requestedData) {
      return {
        services: this.props.services
      };
    }
  }

  handleNextStep(step, data) {
    switch (step) {
      case 1:
        this.props.getContactCaseServiceRelatedData({ service_id: data.service, active: 1 });
        break;
    }
  }

  handleSubmit(contactCase) {
    const requestData = {
      service_id: contactCase.service,
      contact_case_reason_id: contactCase.contactReason,
      reason_description: contactCase.reasonDescription,
      contact_case_solution_id: contactCase.contactSolution,
      solution_description: contactCase.solutionDescription,
      communicator_id: contactCase.communicator,
      case_solved: contactCase.caseSolved ? 1 : 0,
      language_id: contactCase.language
    };

    if (contactCase.customerEmail) {
      requestData.customer_email = contactCase.customerEmail;
    }

    this.props.addContactCase(requestData);
  }

  renderAddContactCaseWizard() {
    const customData = {
      languages: this.props.languages || [],
      contactReasons: this.props.contactReasons || [],
      contactSolutions: this.props.contactSolutions || [],
      communicators: this.props.communicators || []
    };
    return (
      <EntityWizard
        steps={WIZARD_STEPS}
        initialData={this.initialData()}
        serviceRelatedData={customData}
        getServiceRelatedData={this.props.getContactCaseServiceRelatedData}
        onNextStep={this.handleNextStep}
        submitStatus={this.props.submitStatus}
        onSubmit={this.handleSubmit}
      />
    );
  }

  renderProcessingModal() {
    return (
      <Modal isOpen size="size--medium">
        <ModalHeader>Processing</ModalHeader>
        <ModalBody>
          <Loading loadingClass="throbber--center throbber--relative" />
          <p className="text-center mt-2">Processing... Please wait</p>
        </ModalBody>
      </Modal>
    );
  }

  renderSuccessModal() {
    return (
      <Modal isOpen size="size--medium">
        <ModalHeader>Success</ModalHeader>
        <ModalBody>
          <p className="text-center mb-0">Contact case was succesfully added!</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" className="btn-block" onClick={this.handleBackClick}>
            Go back to form
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  render() {
    return (
      <div className="container contact-cases-wizard mt-1">
        {this.initialData() ? (
          this.renderAddContactCaseWizard()
        ) : (
          <Loading loadingClass="throbber--center" />
        )}
        {this.props.submitStatus === 'processing' ? this.renderProcessingModal() : null}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    submitStatus: state.contactCases.contactCaseSubmitStatus,
    services: state.services.all,
    languages: state.resources.languages,
    contactReasons: state.resources.contactReasons,
    contactSolutions: state.resources.contactSolutions,
    communicators: state.resources.communicators
  };
}

export default connect(mapStateToProps, {
  getServices,
  addContactCase,
  getContactCaseServiceRelatedData,
  changePage
})(AddContactCaseWizardContainer);
