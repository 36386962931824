import React from 'react';
import { Field } from 'formik';

function RadioField({ options, name }) {
  return (
    <div role="group">
      {options.map(option => (
        <div key={option.value} className="custom-control custom-radio d-inline-block">
          <Field
            id={option.value}
            type="radio"
            name={name}
            value={option.value}
            className="custom-control-input"
          />
          <label className="custom-control-label mr-2" htmlFor={option.value}>
            {option.label}
          </label>
        </div>
      ))}
    </div>
  );
}

export default RadioField;
