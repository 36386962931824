import React from 'react';
import { displayUTCDate, displayTimestamp, displayYesNo } from '../../common/helpers';
import { getIcon } from '../../icons/icons';
import {
  action,
  hasRefundsPermissionTo,
  hasSubscriptionsPermissionTo
} from '../../users/permissions';
import { UncontrolledTooltip } from 'reactstrap';
import AsyncButton from 'react-async-button';
import { chargeStatus } from '../consts';

const dateFormat = 'YYYY-MM-DD hh:mm a';
const renderRefundedTimeTooltip = (item, index) => (
  <span>
    <i
      id={`refunded${item.id}${index}`}
      className="icon icon--secondary ml-1 align-middle d-inline-block"
    >
      {getIcon('tooltipIcon')}
    </i>
    <UncontrolledTooltip
      target={`refunded${item.id}${index}`}
      className="custom-tooltip"
      placement="top"
    >
      {displayTimestamp(item.refunded_at)}
    </UncontrolledTooltip>
  </span>
);

const displayChargeStatus = status => {
  switch (status) {
    case chargeStatus.WAITING:
    case chargeStatus.ERROR:
      return 'Waiting';
    case chargeStatus.IN_PROGRESS:
      return 'In progress';
    case chargeStatus.MANUAL_REFUND:
      return 'Manual refund';
    case chargeStatus.CAPTURE_FAILED:
      return 'Capture failed';
  }
};

const renderRefundButton = (props, charge) => {
  const hasPermissionToConfirmRefund = hasRefundsPermissionTo(action.confirm);

  const buttonText = hasPermissionToConfirmRefund ? 'Refund' : 'Mark refund';
  const buttonClassName = hasPermissionToConfirmRefund ? 'btn-danger' : 'btn-secondary';

  return (
    <AsyncButton
      className={`btn btn-sm ${buttonClassName}`}
      text={buttonText}
      pendingText="Processing.."
      rejectedText={`${buttonText} (!)`}
      onClick={() => props.handleRefundActions(charge)}
      loadingClass="btn btn-danger btn-xs"
      rejectedClass="btn btn-danger btn-xs"
      fulFilledClass="btn btn-danger btn-xs"
    />
  );
};

const renderManualRefundButton = (props, charge) => (
  <AsyncButton
    className="btn btn-secondary btn-sm"
    text="Manual refund"
    pendingText="Processing.."
    rejectedText="Manual refund (!)"
    onClick={() => props.handleManualRefund(charge.refund_id, charge.id)}
    loadingClass="btn btn-danger btn-xs"
    rejectedClass="btn btn-danger btn-xs"
    fulFilledClass="btn btn-danger btn-xs"
  />
);

const renderCancelChargeButton = (props, charge) => (
  <AsyncButton
    className="btn btn-secondary btn-sm"
    text="Cancel"
    pendingText="Cancelling.."
    rejectedText="Cancel error (!)"
    onClick={() => props.handleChargeCancel(charge.id)}
    loadingClass="btn btn-danger btn-xs"
    rejectedClass="btn btn-danger btn-xs"
    fulFilledClass="btn btn-danger btn-xs"
  />
);

export function SubscriptionsItemBillings(props) {
  const hasCharges = props.itemData.charges && props.itemData.charges.length;
  const canShowId = hasSubscriptionsPermissionTo('showCardBillingIds');

  return (
    <div className="col-6">
      <p className="ui-heading ui-heading-dark">Billings:</p>
      <table className={`table table-bordered ${hasCharges ? 'table-hover' : ''}`}>
        <thead className="thead-default">
          <tr>
            {canShowId ? <th>ID</th> : null}
            <th>Date</th>
            <th>Amount</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {hasCharges ? (
            props.itemData.charges.map((charge, id) => {
              const isChargeRefunded = charge.refunded || charge.verification_fee;
              const isChargeRejected = charge.current_status === chargeStatus.REJECTED;
              const isChargeScheduled = charge.current_status === chargeStatus.CAPTURE_SCHEDULED;
              const isChargeStatusInfo = displayChargeStatus(charge.current_status);
              const isChargeProcessing =
                !charge.refunded && charge.current_status === chargeStatus.COMPLETED;
              const price =
                charge.value && charge.value.amount_decimal
                  ? `${charge.value.amount_decimal} ${charge.value.currency}`
                  : null;
              return (
                <tr key={id}>
                  {canShowId ? <td>{charge.id}</td> : null}
                  <td>
                    {typeof charge.created === 'string'
                      ? displayUTCDate(charge.created, dateFormat)
                      : displayTimestamp(charge.created, dateFormat)}
                  </td>
                  <td className="text-nowrap">
                    {price}
                    <span className="d-block">3ds: {displayYesNo(charge.with_3ds)}</span>
                  </td>
                  <td className={!charge.current_status ? 'td-with-btn' : ''}>
                    {isChargeProcessing ? (
                      'Processing'
                    ) : isChargeRefunded ? (
                      <span className="text-nowrap">
                        Refunded
                        {charge.refunded_at ? renderRefundedTimeTooltip(charge, id) : null}
                      </span>
                    ) : isChargeRejected ? (
                      renderManualRefundButton(props, charge)
                    ) : isChargeStatusInfo ? (
                      isChargeStatusInfo
                    ) : isChargeScheduled ? (
                      renderCancelChargeButton(props, charge)
                    ) : (
                      renderRefundButton(props, charge)
                    )}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td>No billings to show</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}
