import React, { Component } from 'react';
import { withFormik } from 'formik';

import { InputField, RadioField } from '@common/form';

export const MORE_INFO_OPTIONS = [
  { label: 'No', value: 'false' },
  { label: 'Yes', value: 'true' }
];

class ProductItemModal extends Component {
  componentWillUnmount() {
    this.props.resetStatus();
  }

  createForm = () => {
    return (
      <div>
        <div className="modal-header">
          <h4 className="modal-title">
            {this.props.initialValues && this.props.initialValues.description ? 'Update' : 'Add'}{' '}
            new {this.props.name}
          </h4>
        </div>
        <div className="modal-body">
          <div className="row justify-content-center">
            <div className="col-8">
              <form onSubmit={this.props.handleSubmit}>
                <div className="form-group">
                  <InputField
                    type="text"
                    name="description"
                    placeholder={'Type ' + this.props.name + ' description'}
                    label="Description*"
                  />
                  {this.props.name !== 'communicator' ? (
                    <div>
                      <label>Additional info</label>
                      <RadioField name="more_info" options={MORE_INFO_OPTIONS} />
                    </div>
                  ) : null}
                  <p className="col-sm-12 text-right">* required</p>
                </div>
                <button
                  type="submit"
                  role="button"
                  disabled={this.props.invalid || this.props.submitting}
                  className="btn btn-primary btn-modal"
                >
                  Create {this.props.name}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  };

  createdData = () => {
    let moreInfoLabel = '';
    if (this.props.name !== 'communicator') {
      moreInfoLabel = MORE_INFO_OPTIONS.find(
        item => item.value === this.props.values.more_info
      ).label;
    }

    return (
      <div>
        <div className="modal-header">
          <h4 className="modal-title">
            {this.props.name} has been{' '}
            {this.props.initialValues && this.props.initialValues.description
              ? 'updated'
              : 'created'}{' '}
            successfully!
          </h4>
        </div>
        <div className="modal-body">
          <div className="row justify-content-center">
            <div className="col-8">
              <div className="text-center">
                <div className="text-with-label">
                  <p className="text-with-label__text">
                    Description:{' '}
                    <span className="text-with-label__label">{this.props.values.description}</span>
                  </p>
                  {this.props.name !== 'communicator' ? (
                    <p className="text-with-label__text">
                      Additional info:{' '}
                      <span className="text-with-label__label">{moreInfoLabel}</span>
                    </p>
                  ) : null}
                </div>
                <button
                  onClick={this.props.onModalClose}
                  type="submit"
                  role="button"
                  className="btn btn-primary btn-modal"
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const template =
      this.props.responseStatus === 'success' ? this.createdData() : this.createForm();
    return <div>{template}</div>;
  }
}

const onValidate = values => {
  const errors = {};

  if (!values.description) {
    errors.description = 'Name cannot be empty';
  }

  return errors;
};

export default withFormik({
  displayName: 'productItem',
  mapPropsToValues: props => props.initialValues,
  validate: onValidate,
  handleSubmit: (values, { props }) => {
    if (props.name === 'communicator') {
      delete values.more_info;
    }
    props.onSubmit(values);
  }
})(ProductItemModal);
