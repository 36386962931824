import axios from 'axios';
import { CLEAR_ALERT_RESOLVE_ERROR, NETHONE_ALERT_RESOLVE, NETHONE_ALERTS_FETCH } from './types';
import { showErrorAlert, showSuccessAlert } from '../../common/actions';
import { API_AUTH_TOKEN_KEY } from '../../common/consts';
import { objectToQueryString } from '../../common/helpers';
import { USER_UPDATED, USER_UPDATED_ERROR } from '../../users/types.jsx';
import { mock } from '@common/mocks';

export function getNethoneAlerts(requestData) {
  return function (dispatch) {
    dispatch({ type: NETHONE_ALERTS_FETCH.PENDING });

    return axios
      .get('/transaction_alerts', requestData)
      .then(response => {
        dispatch({ type: NETHONE_ALERTS_FETCH.SUCCESS, payload: response.data });
      })
      .catch(error => {
        dispatch({ type: NETHONE_ALERTS_FETCH.ERROR });
        showErrorAlert(dispatch, 'Error! Cannot fetch nethone alerts list.');
      });
  };
}

export function resolveNethoneAlert(id, requestData, successCallback) {
  return function (dispatch) {
    dispatch({ type: NETHONE_ALERT_RESOLVE.PENDING });

    if (MOCK_DATA) {
      return mock(
        'NETHONE_ALERT_RESOLVE_MOCK_RESPONSE',
        function () {
          dispatch({ type: NETHONE_ALERT_RESOLVE.SUCCESS });
          showSuccessAlert(dispatch, `${id} alert resolve request successful`);
          successCallback();
        },
        function () {
          dispatch({
            type: USER_UPDATED_ERROR,
            payload: [{ code: 1001, msg: 'Email already exists' }]
          });
        }
      );
    }

    return axios
      .post(`/transaction_alerts/${id}/resolve`, requestData)
      .then(() => {
        dispatch({ type: NETHONE_ALERT_RESOLVE.SUCCESS });
        showSuccessAlert(dispatch, `${id} alert resolve request successful`);
        successCallback();
      })
      .catch(error => {
        dispatch({
          type: NETHONE_ALERT_RESOLVE.ERROR,
          payload:
            error && error.response
              ? (error.response.data && error.response.data.errors) || error.response.statusText
              : 'Error!'
        });
        showErrorAlert(dispatch, `Error! Cannot resolve ${id} alert`);
      });
  };
}

export function clearResolveAlertError() {
  return function (dispatch) {
    dispatch({ type: CLEAR_ALERT_RESOLVE_ERROR });
  };
}

export function exportNethoneAlertCSV(requestData) {
  const token = localStorage.getItem(API_AUTH_TOKEN_KEY);
  const queryString = objectToQueryString(requestData);
  let url = 'transaction_alerts.csv';

  if (queryString) {
    url = `${import.meta.env.VITE_API_URL}/${url}?${queryString}&auth_token=${token}`;
  }
  window.open(url);
}
