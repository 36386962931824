import React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';

class NavTabs extends React.Component {
  toggle = tab => {
    if (this.props.activeTab !== tab) {
      this.props.onChange(tab);
    }
  };

  render() {
    return (
      <Nav tabs>
        {this.props.tabs &&
          this.props.tabs.map((tab, i) => {
            return (
              <NavItem key={tab.id}>
                <NavLink
                  className={
                    this.props.activeTab === tab.id || (!this.props.activeTab && i === 0) ?
                      'active bg-white' :
                      ''
                  }
                  onClick={() => {
                    this.toggle(tab.id);
                  }}
                >
                  {tab.name}
                  {typeof this.props.counters[tab.id] !== 'undefined' ?
                    ` (${this.props.counters[tab.id]})` :
                    ''}
                </NavLink>
              </NavItem>
            );
          })}
      </Nav>
    );
  }
}

export default NavTabs;
