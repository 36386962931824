import { applyMiddleware, compose, createStore } from 'redux';
import reducers from './app/reducers';
import ReduxThunk from 'redux-thunk';

const crashReporter = store => next => action => {
  try {
    return next(action);
  } catch (err) {
    console.error('Caught an exception!', err);
    throw err;
  }
};

const configureStore = () => {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  return createStore(
    reducers,
    composeEnhancers(applyMiddleware(crashReporter, ReduxThunk))
  );
};

export default configureStore;
