import React, { Component } from 'react';

import LinkedItems from '@common/components/LinkedItems';

export default class ContactReasonStep extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleNext = this.handleNext.bind(this);
    this.handleItemSelect = this.handleItemSelect.bind(this);
  }

  handleItemSelect(itemData) {
    this.setState({ itemId: itemData.id, showTextArea: itemData.more_info }, () => {
      if (!this.state.showTextArea) {
        this.handleNext();
      } else if (this.additionalInfoTextArea) {
        this.additionalInfoTextArea.focus();
      }
    });
  }

  handleNext() {
    if (!this.state.itemId) {
      return;
    }

    if (this.state.showTextArea && !this.state.reasonDescription) {
      this.setState({ requiredTextArea: true });
      return;
    }

    const data = { contactReason: this.state.itemId };
    if (this.state.showTextArea) {
      data['reasonDescription'] = this.state.reasonDescription;
    }

    this.props.handleNext(data);
  }

  handleInfoChange = event => {
    this.setState({ reasonDescription: event.target.value });
  };

  componentDidUpdate(prevProps) {
    if (this.props.reset && prevProps.reset !== this.props.reset) {
      this.setState({ reasonDescription: '' });
    }
  }

  renderAdditionalInfo() {
    if (this.state.showTextArea) {
      return (
        <div className={`form-group mx-15 ${this.state.requiredTextArea ? 'has-error' : ''}`}>
          <label className="col-xs-2 control-label"> Additional info: </label>
          <div className="col-xs-10">
            <textarea
              ref={textarea => (this.additionalInfoTextArea = textarea)}
              className="form-control"
              onChange={this.handleInfoChange}
              value={this.state.reasonDescription}
            />
          </div>
        </div>
      );
    }
    return null;
  }

  get reasonOptions() {
    return this.props.items.map(item => {
      return { id: item.id, name: item.description, more_info: item.more_info };
    });
  }

  render() {
    const buttonDisabled =
      !this.state.itemId || (this.state.showTextArea && !this.state.reasonDescription);
    return (
      <div className="col-12 mx-auto">
        <LinkedItems
          items={this.reasonOptions}
          options={{ itemsPerRow: 2 }}
          onClick={this.handleItemSelect}
          reset={this.props.reset}
        />
        {this.renderAdditionalInfo()}
        {this.state.showTextArea ? (
          <div className={'text-center'}>
            <button
              type="button"
              className={`btn btn-primary mt-6 ${buttonDisabled ? 'disabled' : ''}`}
              onClick={() => this.handleNext()}
            >
              Next
            </button>
          </div>
        ) : null}
      </div>
    );
  }
}
