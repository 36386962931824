import React, { PureComponent } from 'react';

import { SubscriptionsItemHistory } from '../common/SubscriptionsItemHistory.jsx';
import { SubscriptionsItemHistoryEvents } from '../common/SubscriptionsItemEvents.jsx';
import { SubscriptionsItemBillings } from '../common/SubscriptionsItemBillings.jsx';
import SubscriptionsItemActions from '../common/SubscriptionsItemActions.jsx';
import { connect } from 'react-redux';
import { getCardSubscriptionDetails } from './store/actions.jsx';
import Loading from '../../common/components/Loading.jsx';
import { SubscriptionsItemDetails } from '../common/SubscriptionsItemDetails.jsx';
import { accountStatus } from '../consts.jsx';

class CardSubscriptionsItem extends PureComponent {
  componentDidMount() {
    if (!this.props.item.charges) {
      this.props.getCardSubscriptionDetails({
        subscriptionID: this.props.item.id,
        subscriptionIDUnique: this.props.item.idUnique
      });
    }
  }

  canCancelOnlyPayment = () => {
    // cancel payment directly in Straal only
    // if subscription status active and account/service error
    return (
      this.props.itemStatusActive &&
      (this.props.item.service.status === accountStatus.CANCELED ||
        this.props.item.service.status === accountStatus.CANCELLED ||
        this.props.item.service.status === accountStatus.EXPIRED ||
        !this.props.item.service.status)
    );
  };

  canCancelSubscription = () => {
    return this.props.item.service.status === accountStatus.ACTIVE;
  };

  isCanceled = () => {
    return (
      !this.props.itemStatusActive &&
      (this.props.item.service.status === accountStatus.CANCELED ||
        this.props.item.service.status === accountStatus.CANCELLED ||
        this.props.item.service.status === accountStatus.EXPIRED ||
        !this.props.item.service.status)
    );
  };

  isSEPACanceled = () => {
    return (
      this.props.item.service.status === accountStatus.CANCELED ||
      this.props.item.service.status === accountStatus.CANCELLED ||
      this.props.item.service.status === accountStatus.EXPIRED ||
      this.props.item.service.error === 'not_found'
    );
  };

  handleRefundActions = charge => {
    const data = {
      service_id: this.props.item.service.id,
      user_email: this.props.item.user.email,
      user_masked_cc: this.props.item.card && this.props.item.card.num_masked,
      payment_uid: this.props.item.uid,
      charge_uid: charge.id,
      charge_value: charge.value && charge.value.amount_decimal,
      charge_currency: charge.value && charge.value.currency,
      charge_date: charge.created
    };
    return this.props.handleRefundActions(data);
  };

  canClearData = () => {
    return (
      this.props.item.service.status === accountStatus.CANCELED ||
      this.props.item.service.status === accountStatus.CANCELLED ||
      this.props.item.service.status === accountStatus.EXPIRED
    );
  };

  handleCancelSubscriptionClick = () => {
    if (this.canCancelOnlyPayment()) {
      return this.props.cancelSubscriptionPayment();
    } else if (this.canCancelSubscription()) {
      return this.props.cancelSubscription();
    }
  };

  render() {
    const SEPA = this.props.item && this.props.item.bank_transfer;
    return !this.props.item.loading && this.props.item.charges ? (
      <div className="card-body">
        <SubscriptionsItemDetails
          itemData={{
            ...this.props.item.service,
            isStatusActive: SEPA && this.isSEPACanceled() ? false : this.props.itemStatusActive // SEPA hack
          }}
        >
          <SubscriptionsItemActions
            language={this.props.language}
            languages={this.props.languages}
            handleChangeLanguage={this.props.handleChangeLanguage}
            handleShowNotificationModalClick={this.props.handleShowNotificationModal}
            canCancelSubscription={SEPA ? !this.isSEPACanceled() : !this.isCanceled()}
            showCancelSubscriptionBtn={true}
            handleCancelSubscriptionClick={this.handleCancelSubscriptionClick}
            handleClearData={this.props.handleClearData}
            canClearData={this.canClearData()}
          />
        </SubscriptionsItemDetails>
        <div className="row">
          <SubscriptionsItemBillings
            itemData={this.props.item}
            handleRefundActions={this.handleRefundActions}
            handleManualRefund={this.props.handleManualRefund}
            handleChargeCancel={this.props.handleChargeCancel}
          />
          <SubscriptionsItemHistory
            itemData={this.props.item}
            toggleEvents={this.props.toggleEvents}
          >
            {this.props.showEvents ? (
              <SubscriptionsItemHistoryEvents
                events={this.props.item.events}
                toggleEvents={this.props.toggleEvents}
              />
            ) : null}
          </SubscriptionsItemHistory>
        </div>
      </div>
    ) : (
      <Loading loadingClass="throbber--center throbber--relative my-2" />
    );
  }
}

export default connect(null, {
  getCardSubscriptionDetails
})(CardSubscriptionsItem);
