import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

class AuthenticatedRoute extends Component {
  render() {
    return this.props.authenticated ?
      <Route path={this.props.path} component={this.props.component} /> :
      <Redirect to="/login" />;
  }
}

function mapStateToProps(state) {
  return { authenticated: state.auth.authenticated };
}

export default connect(mapStateToProps)(AuthenticatedRoute);
