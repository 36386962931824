import React, { Component } from 'react';
import moment from 'moment';
import { withFormik, ErrorMessage } from 'formik';
import qs from 'qs';

import { isNumberType, validateEmail } from '@common/helpers';
import { InputField, SelectField, DateTimePickerComponent } from '@common/form';
import { mapValuesToSubmit } from './mapValues.jsx';

const formName = 'CardSubscriptionsBetaFilterInputs';
const expirationDateType = 'expirationDate';
const queryParamsType = 'queryParams';

const emailName = 'email';
const binName = 'credit_card_bin_number';
const creditCardLastDigitsName = 'credit_card_last_digits';
const creditCardExpiryMonthName = 'credit_card_expiry_month';
const creditCardExpiryYearName = 'credit_card_expiry_year';
const dateSinceName = 'date_since';
const dateUntilName = 'date_until';
const createdName = 'date_field';
const orderName = 'order';
const ibanName = 'iban';

const fields = new Map([
  [
    emailName,
    {
      name: emailName,
      label: 'Email',
      placeholder: 'Enter email address',
      maxLength: null
    }
  ],
  [
    binName,
    {
      name: binName,
      label: 'BIN number',
      placeholder: 'BIN',
      maxLength: 6
    }
  ],
  [
    creditCardLastDigitsName,
    {
      name: creditCardLastDigitsName,
      label: 'Last 4 digits',
      placeholder: '4 digits',
      maxLength: 4
    }
  ],
  [
    creditCardExpiryMonthName,
    {
      name: expirationDateType + '.' + creditCardExpiryMonthName,
      placeholder: 'mm',
      maxLength: 2,
      object: expirationDateType
    }
  ],
  [
    creditCardExpiryYearName,
    {
      name: expirationDateType + '.' + creditCardExpiryYearName,
      placeholder: 'yy',
      maxLength: 2,
      object: expirationDateType
    }
  ],
  [
    dateSinceName,
    {
      name: queryParamsType + '.' + dateSinceName,
      label: 'From',
      placeholder: 'Select date',
      dateFormat: 'DD-MM-YYYY',
      object: queryParamsType
    }
  ],
  [
    dateUntilName,
    {
      name: queryParamsType + '.' + dateUntilName,
      label: 'Until',
      placeholder: 'Now',
      dateFormat: 'DD-MM-YYYY',
      object: queryParamsType
    }
  ],
  [
    createdName,
    {
      name: queryParamsType + '.' + createdName,
      label: 'Date',
      object: queryParamsType
    }
  ],
  [
    orderName,
    {
      name: queryParamsType + '.' + orderName,
      label: 'Sort by',
      object: queryParamsType
    }
  ],
  [
    ibanName,
    {
      name: ibanName,
      label: 'IBAN'
    }
  ]
]);

const email = fields.get(emailName);
const bin = fields.get(binName);
const creditCardLastDigits = fields.get(creditCardLastDigitsName);
const creditCardExpiryMonth = fields.get(creditCardExpiryMonthName);
const creditCardExpiryYear = fields.get(creditCardExpiryYearName);
const dateSince = fields.get(dateSinceName);
const dateUntil = fields.get(dateUntilName);
const create = fields.get(createdName);
const order = fields.get(orderName);
const iban = fields.get(ibanName);

const queryParamsToInputNameMap = {
  bin: binName,
  last4: creditCardLastDigitsName
};

const CREATE_OPTIONS = [
  { id: 'created', name: 'Accounts created' },
  { id: 'billed', name: 'Accounts charged' }
];

const SORT_OPTIONS = [
  { id: 'newest', name: 'recently created' },
  { id: 'recently_billed', name: 'recently charged' }
];

class CardSubscriptionsFilters extends Component {
  componentDidMount() {
    if (this.props.queryParams) {
      // wait for initial values to load
      setTimeout(() => {
        this.props.submitForm();
      }, 0);
    }
  }

  componentWillUnmount() {
    this.resetForm();
  }

  resetForm() {
    this.props.resetForm(); // redux form prop
    this.props.resetData(); // parent component prop
  }

  parseBINNumber = val => {
    if (val && val.length > bin.maxLength) {
      if (val.length > 10) {
        const lastDigits = val.slice(-4);
        this.props.setFieldValue(creditCardLastDigitsName, lastDigits);
      }
      return val.slice(0, bin.maxLength);
    }
    return val;
  };

  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <p className="d-block">Filter by:</p>
        <div className="row">
          <div className="col-9">
            <div className="d-flex">
              <div className="d-flex pr-2" style={{ flexBasis: '45%' }}>
                <div className="mr-1 flex-grow-1">
                  <SelectField label={create.label} name={create.name} options={CREATE_OPTIONS} />
                </div>
                <div className="d-flex align-items-center" style={{ flexBasis: '238px' }}>
                  <DateTimePickerComponent
                    name={dateSince.name}
                    label={dateSince.label}
                    dateFormat={dateSince.dateFormat}
                    timeFormat={false}
                    readOnly={false}
                    highlight="true"
                    placeholder={dateSince.placeholder}
                    disabled={this.props.disabled}
                  />
                  <span className="mt-15" style={{ marginLeft: '5px', marginRight: '5px' }}>
                    -
                  </span>
                  <DateTimePickerComponent
                    name={dateUntil.name}
                    label={dateUntil.label}
                    dateFormat={dateUntil.dateFormat}
                    timeFormat={false}
                    readOnly={false}
                    highlight="true"
                    placeholder={dateUntil.placeholder}
                    disabled={this.props.disabled}
                  />
                </div>
              </div>
              <div className="form-group">
                <SelectField label={order.label} name={order.name} options={SORT_OPTIONS} />
              </div>
            </div>
            <div className="d-flex">
              <div className="pr-2" style={{ flexBasis: '25%' }}>
                <InputField
                  type="text"
                  name={email.name}
                  placeholder={email.placeholder}
                  label={email.label}
                  maxLength={email.maxLength ? email.maxLength : ''}
                  disabled={this.props.disabled}
                  autoFocus={true}
                  highlight="true"
                />
              </div>
              <div className="mr-2">
                <InputField
                  type="text"
                  name={bin.name}
                  placeholder={bin.placeholder}
                  label={bin.label}
                  highlight="true"
                  disabled={this.props.disabled}
                  onParse={this.parseBINNumber}
                />
              </div>
              <div className="mr-2" style={{ flexBasis: '10%' }}>
                <InputField
                  type="text"
                  name={creditCardLastDigits.name}
                  placeholder={creditCardLastDigits.placeholder}
                  label={creditCardLastDigits.label}
                  disabled={this.props.disabled}
                  highlight="true"
                />
              </div>
              <div className="mr-2" style={{ flexBasis: '15%' }}>
                <label>Expiration date</label>
                <div className="d-flex">
                  <InputField
                    type="text"
                    name={creditCardExpiryMonth.name}
                    placeholder={creditCardExpiryMonth.placeholder}
                    label={creditCardExpiryMonth.label}
                    maxLength={
                      creditCardExpiryMonth.maxLength ? creditCardExpiryMonth.maxLength : ''
                    }
                    disabled={this.props.disabled}
                    highlight="true"
                    containerClass="mb-0"
                  />
                  <div className="form-group mx-1 mt-15">/</div>
                  <InputField
                    type="text"
                    name={creditCardExpiryYear.name}
                    placeholder={creditCardExpiryYear.placeholder}
                    label={creditCardExpiryYear.label}
                    maxLength={creditCardExpiryYear.maxLength ? creditCardExpiryYear.maxLength : ''}
                    disabled={this.props.disabled}
                    highlight="true"
                    containerClass="mb-0"
                  />
                </div>
                <ErrorMessage
                  name={expirationDateType}
                  component="span"
                  className="form-group-error"
                />
              </div>
              <div style={{ flex: 1 }}>
                <InputField
                  type="text"
                  name={iban.name}
                  placeholder={iban.placeholder}
                  label={iban.label}
                  highlight="true"
                  disabled={this.props.disabled}
                />
              </div>
            </div>
          </div>
          <div className="col-3">
            <div
              className="position-absolute w-100"
              style={{ top: '124px', left: 0, padding: 'inherit' }}
            >
              <button
                type="submit"
                disabled={this.props.disabled}
                className="btn btn-primary btn-block"
              >
                Search
              </button>
              <p className="text-center cursor-pointer mb-0" onClick={this.props.resetForm}>
                Clear all
              </p>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

// errors validation

const ERROR_INVALID = 'invalid';
const ERROR_EMAIL = 'Invalid email address';
const ERROR_EMPTY = 'Choose at least one field';
const ERROR_REQUIRED_WITH = 'Input is required with';
const ERROR_IBAN = 'Specify credit card fields or iban number, not both';

const createError = (input, invalid, customError) => {
  const errors = {};

  if (invalid) {
    errors[input] = customError || ERROR_INVALID;
  }
  return errors;
};

const onValidate = values => {
  let errors = {};
  let invalid = false;
  const { expirationDate, queryParams, ...restValues } = values;
  const expirationDateValues = Object.values(expirationDate).filter(value => value && value.length);
  const restFields = Object.values(restValues).filter(value => value && value.length);

  if (!restFields.length && !expirationDateValues.length) {
    return (errors = {
      [emailName]: ERROR_EMPTY,
      [binName]: ERROR_EMPTY,
      [creditCardLastDigitsName]: ERROR_EMPTY,
      [expirationDateType]: ERROR_EMPTY,
      [ibanName]: ERROR_EMPTY
    });
  }
  if (expirationDateValues.length) {
    if (expirationDateValues.length !== 2) {
      errors = {
        ...errors,
        [expirationDateType]: 'month and year are required'
      };
    } else if (!values[creditCardLastDigitsName]) {
      const errorMsg = `${ERROR_REQUIRED_WITH} expiration date`;
      errors = {
        ...errors,
        ...createError(creditCardLastDigitsName, true, errorMsg)
      };
    }
  }
  Object.keys(restValues).forEach(key => {
    switch (key) {
      case emailName:
        invalid = !restValues[emailName] ? null : !validateEmail(restValues[emailName]);
        return (errors = { ...errors, ...createError(emailName, invalid, ERROR_EMAIL) });

      case binName:
        invalid = !restValues[binName]
          ? null
          : restValues[binName].length > bin.maxLength || !isNumberType(restValues[binName]);
        errors = { ...errors, ...createError(binName, invalid) };
        if (restValues[binName].length && !restValues[creditCardLastDigitsName].length) {
          const errorMsg = `${ERROR_REQUIRED_WITH} ${bin.label}`;
          errors = {
            ...errors,
            ...createError(creditCardLastDigitsName, true, errorMsg)
          };
        }
        return;

      case creditCardLastDigitsName:
        invalid = !restValues[creditCardLastDigitsName].length
          ? null
          : restValues[creditCardLastDigitsName].length != creditCardLastDigits.maxLength ||
            !isNumberType(restValues[creditCardLastDigitsName]);
        errors = { ...errors, ...createError(creditCardLastDigitsName, invalid) };
        if (
          restValues[creditCardLastDigitsName].length &&
          !expirationDateValues.length &&
          !restValues[binName].length
        ) {
          const errorMsg = `${ERROR_REQUIRED_WITH} ${creditCardLastDigits.label.toLowerCase()}`;
          errors = {
            ...errors,
            ...createError(expirationDateType, true, errorMsg)
          };
        }
        return;

      case ibanName:
        invalid = !restValues[ibanName].length && null;
        errors = { ...errors, ...createError(ibanName, invalid) };
        if (
          restValues[ibanName].length &&
          (
            restValues[binName].length ||
            restValues[creditCardLastDigitsName].length ||
            restValues[expirationDateType]
          ).length
        ) {
          errors = {
            ...errors,
            ...createError(ibanName, true, ERROR_IBAN)
          };
        }
        return;
    }
  });
  return errors;
};

CardSubscriptionsFilters = withFormik({
  displayName: formName,
  mapPropsToValues: props => {
    const initialValues = {
      [emailName]: '',
      [binName]: '',
      [creditCardLastDigitsName]: '',
      [ibanName]: '',
      [expirationDateType]: {
        [creditCardExpiryMonthName]: '',
        [creditCardExpiryYearName]: ''
      },
      [queryParamsType]: {
        [createdName]: CREATE_OPTIONS[0].id,
        [dateSinceName]: moment().subtract(3, 'year'),
        [orderName]: SORT_OPTIONS[0].id
      }
    };
    if (props.queryParams) {
      const params = qs.parse(props.queryParams, { ignoreQueryPrefix: true });
      Object.keys(params).map(key => {
        const inputName = queryParamsToInputNameMap[key];
        initialValues[inputName] = params[key];
      });
    }
    return initialValues;
  },
  validate: onValidate,
  handleSubmit: (values, { props }) => {
    let { expirationDate, queryParams, ...restValues } = values;
    expirationDate = Object.fromEntries(
      Object.entries(expirationDate).filter(([_, v]) => v !== '')
    );
    restValues = Object.fromEntries(Object.entries(restValues).filter(([_, v]) => v !== ''));
    const params = mapValuesToSubmit({ expirationDate, queryParams, ...restValues });
    props.onSearchClick(params);
  }
})(CardSubscriptionsFilters);

export default CardSubscriptionsFilters;
