export const REFUND_CREATING = 'refund_create';
export const REFUND_CREATED = 'refund_created';
export const REFUND_CREATED_ERROR = 'refund_created_error';
export const REFUND_CONFIRMING = 'refund_confirming';
export const REFUND_CONFIRMED = 'refund_confirmed';
export const REFUND_CONFIRMED_ERROR = 'refund_confirmed_error';
export const REFUND_DELETING = 'refund_deleting';
export const REFUND_DELETED = 'refund_deleted';
export const REFUND_DELETED_ERROR = 'refund_deleted_error';
export const REFUND_NOTIFICATION_SENT = 'refund_notification_sent';
export const REFUNDS_FETCHED = 'refunds_fetched';
export const REFUNDS_FETCHING = 'refunds_fetching';
export const SINGLE_REFUND_CONFIRMING = 'single_refund_confirming';
export const SINGLE_REFUND_CONFIRMED = 'single_refund_confirmed';
export const SINGLE_REFUND_CONFIRMED_ERROR = 'single_refund_confirmed_error';
export const CSV_GENERATING = 'csv_generating';
export const REFUNDS_COUNTERS_REQUEST = 'Refunds counters request';
export const REFUNDS_COUNTERS_SUCCESS = 'Refunds counters success';
export const REFUNDS_COUNTERS_ERROR = 'Refunds counters error';
export const MANUAL_REFUND_SUCCESS = 'manual refund success';
export const MANUAL_REFUND_ERROR = 'manual refund error';

