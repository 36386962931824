import React, { Component } from 'react';
import { connect } from 'react-redux';

import DateRangeLimitFilter from './filters/DateRangeLimitFilter';
import FiltersModal from './filters/FiltersModal';
import { fetchUsers } from './../../users/actions';
import { getContactCaseServiceRelatedData } from './../../resources/actions';
import { getIcon } from './../../icons/icons';
import { hasAccountManagementPermissionTo, action } from '../../users/permissions';
import { getServices } from '../../services/actions';

const caseSolved = [0, 1];
class ContactCasesFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filtersModal: false
    };

    this.toggleFiltersModal = this.toggleFiltersModal.bind(this);
    this.applyFilters = this.applyFilters.bind(this);
  }

  componentDidMount() {
    if (hasAccountManagementPermissionTo(action.list)) {
      this.props.fetchUsers();
    }

    this.props.getServices();
    this.props.getContactCaseServiceRelatedData();
  }

  toggleFiltersModal() {
    this.setState({
      filtersModal: !this.state.filtersModal
    });
  }

  applyFilters(filters) {
    this.props.onFiltersClick(filters);
    this.toggleFiltersModal();
  }

  isDataLoaded() {
    const isDataLoaded =
      this.props.services &&
      this.props.contactReasons &&
      this.props.communicators &&
      this.props.contactSolutions &&
      this.props.languages;
    return isDataLoaded;
  }

  renderFiltersModal() {
    if (this.state.filtersModal && this.isDataLoaded()) {
      return (
        <FiltersModal
          filters={{
            users: this.props.users || [],
            services: this.props.services,
            contactReasons: this.props.contactReasons,
            communicators: this.props.communicators,
            contactSolutions: this.props.contactSolutions,
            languages: this.props.languages,
            caseSolved: caseSolved
          }}
          initialValues={this.props.currentFiltersModal}
          onSubmit={this.applyFilters}
          onModalClose={() => this.toggleFiltersModal()}
        />
      );
    }
  }

  render() {
    const currentFiltersTotal = Object.keys(this.props.currentFiltersModal).length;

    return (
      <div className="sub-navbar">
        <div className="container-fluid">
          <div className="row">
            <div className="col-auto mr-auto d-flex align-items-center">
              <DateRangeLimitFilter
                onSearchClick={this.props.onSearchClick}
                onResetClick={this.props.onResetClick}
                disabled={this.props.isDataFetching}
                onSubmitFail={this.props.onSearchError}
                initialValues={this.props.currentFiltersDate}
              />
            </div>
            <div className="col-auto">
              <span onClick={this.toggleFiltersModal} className="cursor-pointer">
                Filters {`(${currentFiltersTotal})`}
                <span className="ml-1">{getIcon('arrowIcon')}</span>
              </span>
              {this.renderFiltersModal()}

              <div
                className="btn btn-secondary ml-3"
                onClick={this.props.onExportClick}
                disabled={this.props.isDataFetching || this.props.isFetchingFile}
              >
                <span className="d-inline-block align-middle mr-1">{getIcon('exportIcon')}</span>
                Export CSV
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    users: state.auth.usersList,
    services: state.services.all,
    languages: state.resources.languages,
    contactReasons: state.resources.contactReasons,
    contactSolutions: state.resources.contactSolutions,
    communicators: state.resources.communicators
  };
};

ContactCasesFilters = connect(mapStateToProps, {
  fetchUsers,
  getServices,
  getContactCaseServiceRelatedData
})(ContactCasesFilters);

export default ContactCasesFilters;
