// 0 = ERROR
// 1 = SUCCESS

const mockResponseType = new Map();

// SUBSCRIPTIONS mock response
mockResponseType.set('FETCH_USER_SUBSCRIPTIONS_MOCK_RESPONSE', 1);
mockResponseType.set('FETCH_MOBILE_SUBSCRIPTIONS_MOCK_RESPONSE', 1);
mockResponseType.set('SEND_CANCEL_NOTIFICATION_MOCK_RESPONSE', 1);
mockResponseType.set('SEND_REFUND_NOTIFICATION_MOCK_RESPONSE', 1);
mockResponseType.set('CANCEL_SUBSCRIPTION_MOCK_RESPONSE', 1);
mockResponseType.set('CANCEL_SUBSCRIPTION_PAYMENT_MOCK_RESPONSE', 1);
mockResponseType.set('CANCEL_SUBSCRIPTION_MOBILE_MOCK_RESPONSE', 1);
mockResponseType.set('SUBSCRIPTION_FETCH_EVENTS_MOCK_RESPONSE', 1);
mockResponseType.set('SUBSCRIPTIONS_CLEAR_DATA_MOCK_RESPONSE', 1);
mockResponseType.set('CANCEL_MULTIPLE_SUBSCRIPTIONS_MOCK_RESPONSE', 1);
mockResponseType.set('REFUND_MULTIPLE_SUBSCRIPTIONS_MOCK_RESPONSE', 1);

// CONTACT CASES mock response
mockResponseType.set('FETCH_CONTACT_CASES_MOCK_RESPONSE', 1);
mockResponseType.set('ADD_CONTACT_CASES_MOCK_RESPONSE', 1);
mockResponseType.set('DELETE_CONTACT_CASES_MOCK_RESPONSE', 1);

// REFUNDS mock response
mockResponseType.set('FETCH_REFUNDS_MOCK_RESPONSE', 1);
mockResponseType.set('CONFIRM_REFUND_MOCK_RESPONSE', 1);
mockResponseType.set('SINGLE_CONFIRM_REFUND_MOCK_RESPONSE', 1);
mockResponseType.set('CREATE_REFUND_MOCK_RESPONSE', 1);
mockResponseType.set('DELETE_REFUND_MOCK_RESPONSE', 1);
mockResponseType.set('MANUAL_REFUND_MOCK_RESPONSE', 1);
mockResponseType.set('CANCEL_TRANSACTION_MOCK_RESPONSE', 1);

// USERS mock response
mockResponseType.set('FETCH_USERS_MOCK_RESPONSE', 1);
mockResponseType.set('FETCH_USER_MOCK_RESPONSE', 1);
mockResponseType.set('CREATE_USER_MOCK_RESPONSE', 1);
mockResponseType.set('UPDATE_USER_MOCK_RESPONSE', 1);
mockResponseType.set('RESET_PASSWORD_MOCK_RESPONSE', 1);
mockResponseType.set('DEACTIVATE_USER_MOCK_RESPONSE', 1);

// ALERTS mock response
mockResponseType.set('NETHONE_ALERT_RESOLVE_MOCK_RESPONSE', 1);

const loadingTime = 200;
export function mock(response, successCallback, errorCallback) {
  const res = mockResponseType.get(response);
  setTimeout(() => {
    if (res === 1) {
      successCallback();
    } else if (res === 0) {
      errorCallback();
    }
  }, loadingTime);
}
