import React from 'react';
import ReactDatetimeClass from 'react-datetime';

const FIELD_DATE_FORMAT = 'DD-MM-YYYY';
//fix for vite issue https://github.com/vitejs/vite/issues/2139
const Datetime = ReactDatetimeClass.default ?? ReactDatetimeClass;

const renderInput = props => {
  return (
    <div className="position-relative">
      <input {...props} />
      <button type="button" className="close h-100" aria-label="Delete" onClick={() => props.onChange({target: {value: ''}})}>
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  );
};

const DatePicker = ({placeholder, onChange, value}) => {
  return (
    <Datetime
      dateFormat={FIELD_DATE_FORMAT}
      timeFormat={false}
      utc={true}
      inputProps={{
        className: 'form-control form-control-white input-sm',
        placeholder,
      }}
      renderInput={renderInput}
      onChange={onChange}
      value={value}
    />
  );
};

export default DatePicker;
