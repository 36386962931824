import React, { Component } from 'react';
import { Collapse } from 'reactstrap';

import { getIcon } from './../icons/icons';
import { scrollTo } from './../common/helpers';
import { hasCurrentUserPermission } from '../users/permissions';
import hasStepPermission from './hasStepPermission';
import Error from '../common/components/Error';

class EntityWizard extends Component {
  firstStep = 0;
  reset = false;
  initialState = {
    visited: [],
    currentStep: this.firstStep,
    data: {},
    stepCollapseState: Array(this.props.steps.length)
      .fill(false)
      .fill(true, this.firstStep, this.firstStep + 1),
  };

  constructor(props) {
    super(props);

    this.state = this.initialState;

    this.handleNextStep = this.handleNextStep.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.editData) {
      this.setState({
        visited: this.props.steps.map((e, i) => i),
        currentStep: 0,
        data: { ...this.props.editData },
        stepCollapseState: this.handleCollapseStep(0),
      });
    }
  }


  componentDidUpdate(prevProps) {
    if (prevProps.submitStatus !== this.props.submitStatus) {
      if (this.props.submitStatus === 'success') {
        this.resetWizard();
      } else if (this.props.submitStatus === 'error') {
        this.setState({currentStep: 0, stepCollapseState: this.handleCollapseStep(0)});
      }
    }
  }


  handleNextStep(stepData) {
    const nextStep = this.state.currentStep + 1;
    this.animateCurrentStepToTop(this.state.currentStep);
    this.props.onNextStep(nextStep, stepData);

    if (stepData.service && stepData.service !== this.state.data.service) {
      stepData = {
        ...stepData,
        contactReason: null,
        reasonDescription: null,
        contactSolution: null,
        solutionDescription: null
      };
    }

    this.setState(
      {
        visited: [...this.state.visited, this.state.currentStep],
        currentStep: nextStep,
        data: { ...this.state.data, ...stepData },
        stepCollapseState: this.handleCollapseStep(nextStep),
      },
      () => {
        if (nextStep === this.props.steps.length - 1) {
          this.setState({ visited: [...this.state.visited, nextStep] });
        }
      }
    );
  }

  resetWizard() {
    this.setState(this.initialState);
    this.reset = true;
  }

  handleSubmit() {
    this.props.onSubmit(this.state.data);
    document.documentElement.scrollTop = 0;
  }

  handleShowStepClick(num) {
    if (this.state.visited.includes(num)) {
      this.setState(
        {
          currentStep: num,
          stepCollapseState: this.handleCollapseStep(num)
        },
        () => {
          const previousStep = this.state.currentStep - 1 > -1 ? this.state.currentStep - 1 : 0;
          this.animateCurrentStepToTop(previousStep);
        }
      );
    }
  }

  handleCollapseStep(currentStep) {
    return this.state.stepCollapseState.map((step, index) => {
      return index === currentStep;
    });
  }

  animateCurrentStepToTop(id) {
    const element = document.getElementById(id);
    scrollTo(document.documentElement, element.offsetTop + 160, 50);
  }

  render() {
    return (
      <section>
        {this.props.errors && <Error errors={this.props.errors}/>}
        {this.props.steps.map((step, index) => {
          const summaryStep = index === this.props.steps.length - 1;
          const visitedStep = this.state.visited.indexOf(index) > -1;
          return (
            <section className="card mb-1" key={index} id={index}>
              <div
                className={`card-header ${visitedStep ? 'cursor-pointer' : ''}`}
                onClick={() => this.handleShowStepClick(index)}
              >
                <div className="row card-header-content">
                  <div className="col-11">
                    <p className="card-header-title mb-0">
                      {summaryStep ? (
                        this.props.steps[index].name
                      ) : (
                        <span>
                          <span className="card-header-title-strong">Step {index + 1}</span>
                          {' '} - {this.props.steps[index].name}
                        </span>
                      )}
                    </p>
                  </div>
                  <div className="col-1">
                    <span className="card-header-icon">
                      {visitedStep ? getIcon('tickIcon') : null}
                    </span>
                  </div>
                </div>
              </div>
              <Collapse
                isOpen={this.state.stepCollapseState[index] && this.state.currentStep === index}
              >
                <div className="card-body">{this.props.steps[index].component(this)}</div>
              </Collapse>
            </section>
          );
        })}
      </section>
    );
  }
}

export default hasStepPermission(EntityWizard);
