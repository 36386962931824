import { combineReducers } from 'redux';
import { notificationsReducer } from './notificationsReducer';
import { mobileReducer } from './mobile/store/mobileReducer';
import { cardsReducer } from './cards/store/cardReducer';

const subscriptions = combineReducers({
  cards: cardsReducer,
  mobile: mobileReducer,
  notifications: notificationsReducer
});

export default subscriptions;
