import React from 'react';
import { displayTimestamp, howManyDaysLeft } from '../../common/helpers';

export function SubscriptionsItemDetails(props) {
  const pricing = props.itemData.payment_pricing;
  return (
    <div className="card-body-content-full">
      <div className="row">
        <div className="col-9">
          <div className="row">
            <div className="col-4 subscription-detail">
              {props.mobile ? createdInfo(props) : subscriptionInfo(props)}
              <div className="subscription-details">
                <span className="ui-heading">Country:</span>
                <span className="ui-heading ui-heading-dark">
                  {props.itemData.country ? props.itemData.country : '-'}
                </span>
              </div>
              {props.mobile ? null : (
                <div className="subscription-details">
                  <span className="ui-heading">Account:</span>
                  <span className="ui-heading ui-heading-dark">
                    {props.itemData.error ? 'not found' : props.itemData.status}
                    {!props.itemData.error && props.itemData.expired ?
                      howManyDaysLeft(props.itemData.expired) === 1 ?
                        ` (${howManyDaysLeft(props.itemData.expired)} day left)` :
                        ` (${howManyDaysLeft(props.itemData.expired)} days left)` :
                      null}
                  </span>
                </div>
              )}
            </div>
            <div className="col-4 subscription-detail">
              <div className="subscription-details">
                <span className="ui-heading">Last login:</span>
                <span className="ui-heading ui-heading-dark">
                  {props.itemData.last_sign_in_at ?
                    displayTimestamp(props.itemData.last_sign_in_at, 'YYYY-MM-DD hh:mm a') :
                    '-'}
                </span>
              </div>
              <div className="subscription-details">
                <span className="ui-heading">Last IP:</span>
                <span className="ui-heading ui-heading-dark">
                  {props.itemData.last_sign_in_ip ? props.itemData.last_sign_in_ip : '-'}
                </span>
              </div>
              <div className="subscription-details">
                <span className="ui-heading">Registration:</span>
                <span className="ui-heading ui-heading-dark">
                  {props.itemData.register_domain ? props.itemData.register_domain : '-'}
                </span>
              </div>
              {props.itemData.deactivation_reason ? (
                <div className="subscription-details">
                  <span className="ui-heading">Deactivation reason:</span>
                  <span className="ui-heading ui-heading-dark">
                    {props.itemData.deactivation_reason}
                  </span>
                </div>
              ) : null}
            </div>
            <div className="col-4 subscription-detail">
              <div className="subscription-details">
                <span className="ui-heading">Sign in count:</span>
                <span className="ui-heading ui-heading-dark">{props.itemData.sign_in_count}</span>
              </div>
              {props.itemData.next_billing_date ? (
                <div className="subscription-details">
                  <span className="ui-heading">Next billing:</span>
                  <span className="ui-heading ui-heading-dark">
                    {displayTimestamp(props.itemData.next_billing_date)}
                  </span>
                </div>
              ) : null}
              <div className="subscription-details">
                <span className="ui-heading">Payment price:</span>
                <span className="ui-heading ui-heading-dark">
                  {pricing && pricing.amount_decimal ?
                    `${pricing.amount_decimal} ${pricing.currency}` :
                    '-'}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-3 px-0">{props.children}</div>
        {props.mobile && migrationInfo(props)}
        {props.itemData.additional_access ? (
          <div className="col-12">
            <div className="ui-heading mt-1">
              User has additional access to services:
              {props.itemData.additional_access.map((service, index) => {
                return (
                  <span key={index} className="ui-heading ui-heading-dark ml-1">
                    {service}
                  </span>
                );
              })}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

function createdInfo(props) {
  return (
    <div className="subscription-details">
      <span className="ui-heading">Created:</span>
      <span className="ui-heading ui-heading-dark">{displayTimestamp(props.itemData.created)}</span>
    </div>
  );
}

function subscriptionInfo(props) {
  return (
    <div className="subscription-details">
      <span className="ui-heading">Subscription:</span>
      <span className="ui-heading ui-heading-dark">
        {props.itemData.isStatusActive !== null ?
          props.itemData.isStatusActive ? 'Active' : 'Cancelled' :
          props.itemData.error}
      </span>
    </div>
  );
}

function migrationInfo(props) {
  return props.itemData.migration_info &&
    (props.itemData.migration_info.from || props.itemData.migration_info.to) ? (
      <div className="col-12">
        <div className="ui-heading mt-1">
        Subscription migrated {props.itemData.migration_info.from ? 'from' : 'to'} service:
          <span className="ui-heading ui-heading-dark ml-1">
            {props.itemData.migration_info.from || props.itemData.migration_info.to}
          </span>
        </div>
      </div>
    ) : null;
}
