import React from 'react';
import { connect } from 'react-redux';
import { resetStatus } from './../actions';
import Modal from '../../modal/components/Modal';
import { showModal, deleteModal } from '../../modal/actions/ModalActions';
import { getIcon } from '../../icons/icons';
import { NavLink as Link } from 'react-router-dom';
import { NavLink } from 'reactstrap';
import { MORE_INFO_OPTIONS } from './ProductItemModal';
import ProductItemModal from './ProductItemModal';
import ProductItemData from './ProductItemData';

export const CREATE_SELECTABLE_ITEM = 'createSelectableItem';
const EDIT_SELECTABLE_ITEM = 'editSelectableItem';

class ProductItemContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editItem: null
    };
  }

  componentDidMount() {
    this.props.getItems();
  }

  editItem = item => {
    const editItem = {
      id: item.id,
      description: item.description
    };
    if (item.more_info !== undefined) {
      editItem.more_info = item.more_info.toString();
    }
    this.setState({ editItem });
    this.props.showModal(EDIT_SELECTABLE_ITEM);
  };

  render() {
    return (
      <div>
        <nav className="sub-navbar">
          <div className="container">
            <div className="row">
              <div className="col-auto ml-auto d-flex align-items-center">
                <NavLink
                  activeClassName="active"
                  className="back-link"
                  tag={Link}
                  to="/admin_gate/services/list"
                >
                  {getIcon('arrowIcon')}
                  <span>Go back to products list</span>
                </NavLink>
              </div>
              <div className="col-auto">
                <button
                  onClick={() => this.props.showModal(CREATE_SELECTABLE_ITEM)}
                  className="btn btn-primary"
                >
                  Add new {this.props.name}
                </button>
              </div>
            </div>
          </div>
        </nav>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 mt-2">
              <p>
                Total items: <span className="ui-heading-info">{this.props.data.length}</span>
              </p>
              <ProductItemData
                name={this.props.name}
                fetchingData={false}
                fetchedData={this.props.data}
                deleteItem={this.props.deleteItem}
                editItem={this.editItem}
              />
            </div>
          </div>
        </div>
        <Modal id={CREATE_SELECTABLE_ITEM}>
          <ProductItemModal
            name={this.props.name}
            responseStatus={this.props.status}
            onSubmit={this.props.createItem}
            initialValues={{ description: '', more_info: MORE_INFO_OPTIONS[0].value }}
            onModalClose={() => this.props.deleteModal(CREATE_SELECTABLE_ITEM)}
            resetStatus={this.props.resetStatus}
          />
        </Modal>
        <Modal id={EDIT_SELECTABLE_ITEM}>
          <ProductItemModal
            name={this.props.name}
            responseStatus={this.props.status}
            onSubmit={this.props.editItem}
            initialValues={this.state.editItem}
            onModalClose={() => this.props.deleteModal(EDIT_SELECTABLE_ITEM)}
            resetStatus={this.props.resetStatus}
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    status: state.services.serviceActionStatus
  };
};

export default connect(mapStateToProps, {
  showModal,
  deleteModal,
  resetStatus
})(ProductItemContainer);
