import React, { useState } from 'react';
import { withFormik } from 'formik';
import { ModalBody, ModalHeader } from 'reactstrap';

import { RESULT_STATUS, RESULT_STATUS_MAP } from '../consts';
import moment from 'moment';
import { InputField, SelectField, DateTimePickerComponent } from '@common/form';

const ERROR_MESSAGE = {
  required: 'Required',
  conditionalRequired: 'Required if any other input has a value',
  timestamp: 'Date is invalid'
};

const onValidate = values => {
  const errors = {};
  const amount = values.amount && values.amount.length;
  const currency = values.currency && values.currency.length;
  const timestamp = values.timestamp;

  if (values.result === RESULT_STATUS_MAP.transactionNotfound) {
    return errors;
  }

  if (values.timestamp) {
    const isValid = moment(values.timestamp, 'DD-MM-YYYY HH:mm', true).isValid();
    if (!isValid) {
      errors.timestamp = ERROR_MESSAGE.timestamp;
    }
  }

  if (values.result === RESULT_STATUS_MAP.fullRefund) {
    if (!amount) {
      errors.amount = ERROR_MESSAGE.required;
    }
    if (!currency) {
      errors.currency = ERROR_MESSAGE.required;
    }
    if (!timestamp) {
      errors.timestamp = ERROR_MESSAGE.required;
    }
  } else if (values.result === RESULT_STATUS_MAP.alreadyRefunded) {
    if (amount || currency || timestamp) {
      if (!amount) {
        errors.amount = ERROR_MESSAGE.conditionalRequired;
      }
      if (!currency) {
        errors.currency = ERROR_MESSAGE.conditionalRequired;
      }
      if (!timestamp) {
        errors.timestamp = ERROR_MESSAGE.conditionalRequired;
      }
    }
  }
  return errors;
};

const Modal = ({
  additionalValues,
  closeModal,
  isSubmitting,
  handleSubmit,
  resetForm,
  setFieldValue,
  isValid,
  setValues
}) => {
  const [disabled, setDisabled] = useState(false);
  const readOnly = additionalValues && additionalValues.readOnly;

  const handleSubmitExtend = values => {
    onSubmit(additionalValues.id, values);
  };

  const onChange = e => {
    setFieldValue('result', e.target.value);
    setDisabled(e.target.value === RESULT_STATUS_MAP.transactionNotfound);
    if (disabled && e.target.value !== RESULT_STATUS_MAP.transactionNotfound) {
      resetForm();
    } else if (e.target.value === RESULT_STATUS_MAP.transactionNotfound) {
      setValues({ result: e.target.value, amount: '', currency: '' });
    }
  };
  return (
    <>
      <ModalHeader toggle={closeModal} cssModule={{ close: 'close text-white' }}>
        Alert
      </ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit}>
          <SelectField
            label="Message"
            name="result"
            disabled={readOnly}
            options={RESULT_STATUS}
            onChange={onChange}
          />
          <InputField
            type="text"
            name="amount"
            placeholder="Amount"
            label="Amount"
            disabled={readOnly || disabled}
          />
          <InputField
            type="text"
            name="currency"
            placeholder="Currency"
            label="Currency"
            disabled={readOnly || disabled}
          />
          <DateTimePickerComponent
            name="timestamp"
            label="Timestamp"
            disabled={readOnly || disabled}
            containerClass="rdt--top"
            dateFormat="DD-MM-YYYY"
            timeFormat="HH:mm"
          />
          {additionalValues && additionalValues.error ? (
            <p>
              Error: <span>{additionalValues && additionalValues.error}</span>
            </p>
          ) : null}
          {readOnly ? null : (
            <button
              type="submit"
              role="button"
              disabled={!isValid || isSubmitting}
              className="btn btn-primary btn-modal"
            >
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </button>
          )}
        </form>
      </ModalBody>
    </>
  );
};

const AlertResponseModal = withFormik({
  displayName: 'nethone_alerts_modal',
  mapPropsToValues: props => {
    return props.initialValues;
  },
  validate: onValidate,
  handleSubmit: (values, { props }) => {
    const { result } = values;
    props.onSubmit(
      props.additionalValues.id,
      result === RESULT_STATUS_MAP.transactionNotfound ? { result } : values
    );
  }
})(Modal);

export default AlertResponseModal;
