import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';

import { EDITABLE_ALERT_STATUS, SORT_KEYS, SORT_ORDER_OLDEST } from '../consts';
import { displayTimestamp } from '../../../common/helpers';

const SortIcon = ({ order }) => (
  <span className={'order' + (order === SORT_ORDER_OLDEST ? ' dropup' : '')}>
    <span className="caret" />
  </span>
);

const TableHeader = ({handleSort, sortOrder}) => (
  <thead className="thead-default">
    <tr>
      <th
        className="table-column-width-auto cursor-pointer"
        onClick={() => handleSort(SORT_KEYS.alertDate)}
      >
        Alert date{' '}
        <SortIcon order={sortOrder} />
      </th>
      <th className="table-column-width-auto">Card number</th>
      <th
        className="table-column-width-auto cursor-pointer"
        onClick={() => handleSort(SORT_KEYS.transactionDate)}
      >
        Transaction date{' '}
        <SortIcon order={sortOrder} />
      </th>
      <th className="table-column-width-auto">Transaction value</th>
      <th className="table-column-width-auto">Refund value</th>
      <th className="table-column-width-auto">Descriptor</th>
      <th className="table-column-width-auto">Alert response</th>
      <th className="table-column-width-auto">Status</th>
      <th className="table-column-width-auto">Error</th>
    </tr>
  </thead>
);

const TableRow = ({alert, onAlertResponseClick}) => (
  <tr>
    <td className="text-nowrap">
      {displayTimestamp(alert.alert_date, 'DD-MM-YYYY HH:mm')}
    </td>
    <td>
      <Link
        target="_blank"
        to={{
          pathname: '/subscription/find/paycard',
          search:
            `?bin=${alert.card && alert.card.num_bin}&last4=${alert.card && alert.card.num_last_4}`
        }}
      >
        {alert.card && alert.card.num_masked}
      </Link>
    </td>
    <td className="text-nowrap">
      {displayTimestamp(alert.transaction_date, 'DD-MM-YYYY HH:mm')}
    </td>
    <td>{alert.transaction_value.amount_decimal} {alert.transaction_value.currency}</td>
    <td>{alert.refund_value.amount_decimal} {alert.refund_value.currency}</td>
    <td>{alert.descriptor}</td>
    <td>
      <span className="btn btn-link py-0" onClick={() => onAlertResponseClick(alert)}>
        {alert.status === EDITABLE_ALERT_STATUS ?
          <button className="btn btn-sm btn-outline-primary">Set alert</button> :
          alert.resolution && alert.resolution.result
        }
      </span>
    </td>
    <td>{alert.status}</td>
    <td className="text-nowrap">{alert.error}</td>
  </tr>
);

function List({data, sortOrder, handleSort, onCardClick, onAlertResponseClick}) {
  return (
    <Card className="mb-3">
      <CardBody>
        <table className="table table-hover mb-0 text-center">
          <TableHeader sortOrder={sortOrder} handleSort={handleSort} />
          <tbody>
            {data.map(alert => (
              <TableRow
                key={alert.id}
                alert={alert}
                onAlertResponseClick={onAlertResponseClick}
              />
            ))}
          </tbody>
        </table>
      </CardBody>
    </Card>
  );
}

export default List;
