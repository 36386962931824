import React, { Component } from 'react';

import { SubscriptionsItemHistory } from '../common/SubscriptionsItemHistory';
import { SubscriptionsItemHistoryEvents } from '../common/SubscriptionsItemEvents';
import { SubscriptionsItemDetails } from '../common/SubscriptionsItemDetails';
import SubscriptionsItemActions from '../common/SubscriptionsItemActions';

/**
 CANCEL SUBSCRIPTION LOGIC DESCRIPTION

 MOBILE SUBSCRIPTION
 if able_to_cancel === false => hide 'cancel account' button

 if service.active === true AND service.status === 'active' AND service.deactivating === false =>
 cancel mobile (cancelSubscriptionMobile action)

 CARD SUBSCRIPTION
 if cancelled === false AND service.status !== 'active' =>
 cancel payment (cancelSubscriptionPayment action)
 else if service.status === 'active' =>
 cancel (cancelSubscription action)
 */

const ACTIVE_STATUS = 'active';

export default class MobileSubscriptionsItem extends Component {
  get isStatusActive() {
    return this.props.item.service.status === ACTIVE_STATUS;
  }

  get isActiveInStraal() {
    return this.props.item.service.active;
  }

  get isDeactivating() {
    return this.props.item.service['deactivating'];
  }

  canCancelSubscription = () => {
    return this.props.item['able_to_cancel'] &&
      this.isStatusActive && this.isActiveInStraal && !this.isDeactivating;
  };

  showCancelSubscriptionBtn = () => {
    return this.props.mobile ? this.props.item.able_to_cancel : true;
  };

  handleRefundActions = charge => {
    const data = {
      service_id: this.props.item.service.id,
      user_email: this.props.item.user.email,
      user_masked_cc: this.props.item.card.num_masked,
      payment_uid: this.props.item.id,
      charge_uid: charge.id,
      charge_value: charge.value && charge.value.amount_decimal,
      charge_currency: charge.value && charge.value.currency,
      charge_date: charge.created
    };
    return this.props.handleRefundActions(data);
  };

  canClearData = () => {
    return (
      (this.props.item.cancelled || this.props.item.suspended) &&
      this.props.item.service.status !== 'active'
    );
  };

  handleCancelSubscriptionClick = () => {
    return this.props.cancelSubscriptionMobile();
  };

  render() {
    return (
      <div className="card-body">
        <SubscriptionsItemDetails
          itemData={{
            ...this.props.item.service,
            migration_info: this.props.item.migration_info,
            created: this.props.item.created
          }}
          mobile={true}
        >
          <SubscriptionsItemActions
            mobile={true}
            language={this.props.language}
            languages={this.props.languages}
            handleChangeLanguage={this.props.handleChangeLanguage}
            handleShowNotificationModalClick={this.props.handleShowNotificationModal}
            canCancelSubscription={this.canCancelSubscription()}
            showCancelSubscriptionBtn={this.showCancelSubscriptionBtn()}
            handleCancelSubscriptionClick={this.handleCancelSubscriptionClick}
            handleClearData={this.props.handleClearData}
            canClearData={this.canClearData()}
          />
        </SubscriptionsItemDetails>
        <div className="row">
          <SubscriptionsItemHistory
            itemData={this.props.item}
            mobile={true}
            toggleEvents={this.props.toggleEvents}
          >
            {this.props.showEvents ? (
              <SubscriptionsItemHistoryEvents
                events={this.props.item.events}
                toggleEvents={this.props.toggleEvents}
              />
            ) : null}
          </SubscriptionsItemHistory>
        </div>
      </div>
    );
  }
}
