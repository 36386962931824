import React from 'react';

export function getIcon(icon) {
  switch (icon) {
    case 'arrowIcon':
      return arrowIcon;
    case 'exportIcon':
      return exportIcon;
    case 'deleteIcon':
      return deleteIcon;
    case 'tooltipIcon':
      return tooltipIcon;
    case 'tickIcon':
      return tickIcon;
    case 'editIcon':
      return editIcon;
  }
}

const arrowIcon = (
  <svg width="10" height="5" viewBox="0 0 10 5">
    <path d="M 0,0 5,5 10,0 Z" />
  </svg>
);

const exportIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20">
    <defs>
      <path
        id="a"
        d="M1309 109l6 6v12a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2v-16a2 2 0
        0 1 2-2zm-1 7h5.5l-5.5-5.5zm-1.95 5.35l-2.83 2.82 2.83 2.83 2.83-2.83 2.12 2.12v-7.07h-7.07z"
      />
    </defs>
    <g transform="translate(-1299 -109)">
      <use fill="#fff" xlinkHref="#a" />
    </g>
  </svg>
);

const deleteIcon = (
  <svg width="14" height="18" viewBox="0 0 14 18">
    <path
      d="M 14,1 10.5,1 9.5,0 4.5,0 3.5,1 0,1 0,3 14,3 Z
      M 1,16 c 0,1.1 0.9,2 2,2 l 8,0 a 2,2 0 0 0 2,-2 L 13,4 1,4 Z"
    />
  </svg>
);

const tooltipIcon = (
  <svg width="18" height="18" viewBox="0 0 18 18">
    <path d="m 9.9,6.3 -1.8,0 0,-1.8 1.8,0 z m 0,7.2 -1.8,0 0,-5.4 1.8,0 z M 9,0 A 9,9 0 1 0 9,18 9,9 0 0 0 9,0 Z" />
  </svg>
);

const tickIcon = (
  <svg width="40" height="40" viewBox="0 0 40 40">
    <defs>
      <path id="a" d="M1125 273a20 20 0 1 1 40 0 20 20 0 0 1-40 0z" />
      <path id="b" d="M1150.8 267l-6.4 6.22-3.2-3.11-3.2 3.11 6.4 6.23 9.6-9.34z" />
    </defs>
    <g transform="translate(-1125 -253)">
      <use fill="#35d14c" xlinkHref="#a" />
    </g>
    <g transform="translate(-1125 -253)">
      <use fill="#fff" xlinkHref="#b" />
    </g>
  </svg>
);

const editIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
    <path d="M20.71 7.04c.39-.39.39-1.04 0-1.41l-2.34-2.34c-.37-.39-1.02-.39-1.41 0l-1.84 1.83 3.75 3.75M3 17.25V21h3.75L17.81 9.93l-3.75-3.75L3 17.25z" />
  </svg>
);
