import React from 'react';
import { connect } from 'react-redux';
import {
  getCommunicators,
  deleteCommunicator,
  createCommunicators,
  editCommunicator
} from './../actions';
import ProductItemContainer from './ProductItemContainer';

const Communicators = props => {
  return (
    <ProductItemContainer
      name="communicator"
      data={props.communicators}
      getItems={props.getCommunicators}
      deleteItem={props.deleteCommunicator}
      createItem={props.createSolution}
      editItem={props.editCommunicator}
    />
  );
};

const mapStateToProps = state => {
  return {
    communicators: state.services.communicators
  };
};

export default connect(mapStateToProps, {
  getCommunicators,
  deleteCommunicator,
  createSolution: createCommunicators,
  editCommunicator
})(Communicators);
