import { createActionTypes } from '../../../../actionCreator';

export const FETCH_CARD_SUBSCRIPTIONS = createActionTypes('fetch_card_subscriptions');
export const FETCH_USER_SUBSCRIPTION_EVENTS = createActionTypes('fetch_user_subscription_events');

export const CANCEL_SUBSCRIPTION = createActionTypes('cancel_subscription');
export const CANCEL_SUBSCRIPTION_PAYMENT = createActionTypes('cancel_subscription_payment');

// Single card subscription details
export const FETCH_CARD_SUBSCRIPTION_DETAILS = createActionTypes('fetch_card_subscription_details');

// Single card subscription status
export const CARD_SUBSCRIPTION_STATUS = createActionTypes('card_subscription_status');

// Subscription refund
export const SUBSCRIPTION_REFUND_CREATED = 'subscription_refund_success';
export const SUBSCRIPTION_REFUND_ERROR = 'subscription_refund_error';

export const SUBSCRIPTION_REFUND_CONFIRM = createActionTypes('card_subscription_refund_confirm');
export const SUBSCRIPTION_MANUAL_REFUND = createActionTypes('card_subscription_manual_refund');

export const CANCEL_TRANSACTION = createActionTypes('cancel_transaction');

export const CLEAR_CARD_SUBSCRIPTIONS = 'clear_subscriptions';

// Multiple subscriptions
export const CANCEL_MULTIPLE_SUBSCRIPTIONS = createActionTypes('cancel_multiple_subscription');
export const REFUND_MULTIPLE_SUBSCRIPTIONS = createActionTypes('refund_multiple_subscription');
export const RESET_MULTIPLE_SUBSCRIPTIONS = 'reset_multiple_subscription';
