import React from 'react';
import { displayTimestamp } from '../../common/helpers';

export function SubscriptionsItemHistory(props) {
  const hasLogs = props.itemData.logs && props.itemData.logs.length;
  return (
    <div className={props.mobile ? 'col-12' : 'col-6'}>
      <div className="row">
        <div className="col-6">
          <p className="ui-heading ui-heading-dark">History:</p>
        </div>
        {props.mobile ? null : (
          <div className="col-6 text-right">
            <a onClick={() => props.toggleEvents(props.itemData)} className="double-dashed-link">
              Show events
            </a>
          </div>
        )}
      </div>
      <table className={`table table-bordered ${hasLogs ? 'table-hover' : ''}`}>
        <thead className="thead-default">
          <tr>
            <th>Action</th>
            <th>User</th>
            <th>Date</th>
            <th>Additional info</th>
          </tr>
        </thead>
        <tbody>
          {hasLogs ? (
            props.itemData.logs.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.action}</td>
                  <td>{item.user}</td>
                  <td>{displayTimestamp(item.created_at, 'YYYY-MM-DD hh:mm a')}</td>
                  <td>{item.refund_reason}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={4}>No history to show</td>
            </tr>
          )}
        </tbody>
      </table>
      {props.children}
    </div>
  );
}
