import React, { Component } from 'react';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';

import { displayTimestamp } from '@common/helpers';
import { getIcon } from '../../icons/icons';
import Loading from '../../common/components/Loading';
import AsyncButton from 'react-async-button';
import { chargeStatus } from '../../subscriptions/consts';

export default class RefundsData extends Component {
  constructor(props) {
    super(props);
    this.state = {popoverOpen: false};
    this.handleRemoveClick = this.handleRemoveClick.bind(this);
    this.handleRefund = this.handleRefund.bind(this);
    this.togglePopover = this.togglePopover.bind(this);
  }

  handleRemoveClick() {
    this.props.removeAction(this.props.refund.id);
  }

  togglePopover() {
    this.setState({ popoverOpen: !this.state.popoverOpen }, () => {
      if (this.state.popoverOpen) {
        document.addEventListener('mousedown', this.togglePopover);
      } else {
        document.removeEventListener('mousedown', this.togglePopover);
      }
    });
  }

  handleRefund(charge) {
    const currentStatus = charge.status;

    if (currentStatus === chargeStatus.COMPLETED || currentStatus === chargeStatus.IN_PROGRESS) {
      return;
    }

    if (currentStatus === chargeStatus.REJECTED || currentStatus === chargeStatus.MANUAL_REFUND) {
      const refund = this.props.refund;
      return this.props.createRefund({
        service_id: refund.service.id,
        user_email: refund.user_email,
        user_reference: refund.user_reference,
        user_masked_cc: refund.user_masked_cc,
        payment_uid: refund.payment_uid,
        charge_uid: charge.charge_uid,
        charge_value: charge.charge_value,
        charge_date: charge.charge_date
      });
    }
    //confirm refund
    return this.props.refundAction(this.props.refund.id);
  }

  renderRemoveButton() {
    const isRemoving = this.props.refund && this.props.refund.status === 'deleting';
    return (
      <span className="remove-btn">
        {
          isRemoving ?
            <Loading loadingClass="throbber--relative" /> :
            <i
              className="icon--secondary icon-hover--danger"
              disabled={isRemoving}
              onClick={() => this.handleRemoveClick()}
            >
              {getIcon('deleteIcon')}
            </i>
        }
      </span>
    );
  }

  renderMakeRefundButton(item) {
    if (item.status === 'manual_refund') {
      return 'Manual refund';
    }

    const buttonText = item.status === 'rejected' || item.status === 'manual_error' ?
      'Manual refund' : 'Refund';

    return (
      <AsyncButton
        className="btn btn-sm btn-secondary"
        text={buttonText}
        pendingText="Processing.."
        rejectedText={`${buttonText} (!)`}
        onClick={() => this.handleRefund(item)}
        loadingClass="btn btn-danger btn-xs"
        rejectedClass="btn btn-danger btn-xs"
        fulFilledClass="btn btn-danger btn-xs"
      />
    );
  }

  render() {
    const charge = this.props.refund.charge;
    const firstMsg = charge.messages && charge.messages[0];
    const price = charge.charge_value;
    return (
      <tr>
        <td>{charge.charge_uid}</td>
        <td>{this.props.refund.user_email}</td>
        <td className="text-center">{this.props.refund.service.name}</td>
        <td className="text-nowrap text-center">
          {price && price.amount_decimal && `${price.amount_decimal} ${price.currency}` }
        </td>
        <td className="text-nowrap">
          {charge.charge_date && displayTimestamp(charge.charge_date, 'DD-MM-YYYY')}
        </td>
        <td className="text-nowrap">
          {this.props.refund.created_at && displayTimestamp(this.props.refund.created_at, 'DD-MM-YYYY')}
        </td>
        <td className="position-relative">
          {
            firstMsg ?
              firstMsg.code + ' ' + firstMsg.message + ' ' +
              displayTimestamp(firstMsg.created_at, 'DD-MM-YYYY') : null
          }
          {charge.messages.length > 1 ?
            <React.Fragment>
              <span
                id={`messagesPopover${charge.charge_uid}`} role="link"
                className="cursor-pointer ui-heading-info font-weight-normal all-label"
                onClick={this.togglePopover}
              > all</span>
            </React.Fragment> :
            null
          }
        </td>
        <td className="text-nowrap text-right">
          {this.props.renderRefundButton ? this.renderMakeRefundButton(charge) : null}
          {this.props.renderRemoveButton ? this.renderRemoveButton() : null}
        </td>
        {charge.messages.length > 1 ?
          <Popover placement="auto" isOpen={this.state.popoverOpen}
            target={`messagesPopover${charge.charge_uid}`}>
            <PopoverHeader>Messages</PopoverHeader>
            <PopoverBody>
              {charge.messages.map((item, index) => {
                return (
                  <p key={index}>
                    {item.code} {item.message} {displayTimestamp(item.created_at, 'DD-MM-YYYY')}
                  </p>
                );
              })}
            </PopoverBody>
          </Popover> :
          null
        }
      </tr>
    );
  }
}
