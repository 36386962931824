import React, { Component } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import classNames from 'classnames';

import Loading from '@common/components/Loading';
import TablePagination from '@common/components/TablePagination';
import { getFlag } from './../../icons/flags';
import { getIcon } from './../../icons/icons';
import { displayYesNo, displayTimestamp, sortAsc, sortDesc } from '../../common/helpers';
import { Table, TableColumn } from '@common/components/Table';

export default class ContactCasesTable extends Component {
  constructor(props) {
    super(props);
  }

  action = (cell, row) => {
    return (
      <i
        className="icon icon--secondary icon-hover--danger icon-action cursor-pointer"
        onClick={() => this.props.onDeleteClick(row.id)}
      >
        {this.props.onDeleting.indexOf(row.id) > -1 ? (
          <Loading loadingClass="throbber--middle" />
        ) : (
          getIcon('deleteIcon')
        )}
      </i>
    );
  };

  onPageChange = page => this.props.onPaginationClick(page);

  onSortChange = (sortName, sortOrder) => this.props.onSortClick(sortOrder);

  renderTable() {
    return (
      <div className="table-striped table-sm table-with-action-btns contact-cases-list">
        <Table
          data={this.props.fetchedData}
          options={{
            defaultSortName: 'created_at',
            defaultSortOrder: this.props.sort
          }}
          onSort={this.onSortChange}
        >
          <TableColumn dataKey="id" headerLabel="Id" hidden />
          <TableColumn
            dataKey="customer_email"
            headerLabel="Customer email"
            dataFormat={customerEmail}
          />
          <TableColumn dataKey="user.email" headerLabel="User email" dataFormat={userEmail} />
          <TableColumn
            dataKey="contact_case_reason.description"
            headerLabel="Case"
            dataFormat={caseReason}
          />
          <TableColumn
            dataKey="contact_case_solution.description"
            headerLabel="Case result"
            dataFormat={caseResult}
            width="50px"
          />
          <TableColumn
            dataKey="language.locale"
            headerLabel="Language"
            dataFormat={language}
            width="80px"
          />
          <TableColumn dataKey="service.name" headerLabel="Product" dataFormat={product} />
          <TableColumn
            dataKey="communicator.description"
            headerLabel="Communicator"
            width="100px"
            dataFormat={communicator}
          />
          <TableColumn
            headerLabel="Case solved"
            dataFormat={satisfaction}
            dataAlign="center"
            width="100px"
          />
          <TableColumn
            dataKey="created_at"
            headerLabel="Date"
            dataFormat={date}
            dataSort={true}
            width="200px"
            cellCustomClass="text-nowrap"
          />
          {this.props.showAction && (
            <TableColumn
              headerLabel="Action"
              dataFormat={this.action}
              dataAlign="center"
              width="80px"
              cellCustomClass="text-center"
            />
          )}
        </Table>
        {this.props.pages.total ? (
          <TablePagination
            currentPage={this.props.pages.current}
            totalPages={this.props.pages.total}
            onPageChange={this.onPageChange}
          />
        ) : null}
      </div>
    );
  }

  render() {
    return this.props.fetchingData ? (
      <Loading loadingClass="throbber--middle mt-6" />
    ) : (
      this.renderTable()
    );
  }
}

const customerEmail = (cell, row) => <span className="ui-heading-info">{row.customer_email}</span>;

const userEmail = (cell, row) => row.user.email;

const caseReason = (cell, row, formatExtraData, rowIdx) => {
  return (
    <span>
      {row.contact_case_reason.description}
      {row.reason_description ? (
        <span>
          <i id={`contactReasonDesc${rowIdx}`} className="table-icon icon--secondary">
            {getIcon('tooltipIcon')}
          </i>
          <UncontrolledTooltip
            target={`contactReasonDesc${rowIdx}`}
            className="custom-tooltip"
            placement="top"
          >
            {row.reason_description}
          </UncontrolledTooltip>
        </span>
      ) : null}
    </span>
  );
};

const caseResult = (cell, row) => (
  <div className="table-column-width-cut" style={{ width: '100px' }}>
    {row.contact_case_solution.description}
  </div>
);
const communicator = (cell, row) => row.communicator.description;

const language = (cell, row) => (
  <span>
    <i className="icon icon-flag-small mr-1">{getFlag(row.language.locale)}</i>
    {row.language.locale}
  </span>
);

const product = (cell, row) => row.service.name;

const satisfaction = (cell, row) => (
  <span>
    {displayYesNo(row.case_solved)}
    <i
      className={classNames('icon icon-arrow', {
        'icon-arrow-up icon--success': row.case_solved,
        'icon-arrow-down icon--danger': !row.case_solved
      })}
    >
      {getIcon('arrowIcon')}
    </i>
  </span>
);

const date = (cell, row) => displayTimestamp(row.created_at);
