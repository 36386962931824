import React, { Component } from 'react';
import { connect } from 'react-redux';

import { changePage } from '@common/actions';
import { getServices, resetServices } from '@services/actions';
import { SERVICE_TYPE_MOBILE } from '@services/consts';
import { getMobileSubscriptions } from './store/actions';
import MobileSubscriptionsFilters from './MobileSubscriptionsFilters';
import SubscriptionsData from '../common/SubscriptionsData';
import { getLanguages } from '../../resources/actions';
import { getMobileSubscriptionsSelector, getMobileSubscriptionStatus } from './store/mobileReducer';

class MobileSubscriptions extends Component {
  constructor(props) {
    super(props);
    this.getMobileSubscriptions = this.getMobileSubscriptions.bind(this);
  }

  componentDidMount() {
    if (!this.props.services) {
      this.props.getServices(SERVICE_TYPE_MOBILE, true);
    }
    this.props.changePage('Find mobile subscriptions');
    this.props.getLanguages();
  }

  getMobileSubscriptions(requestData) {
    this.props.getMobileSubscriptions(requestData);
  }

  render() {
    return (
      <div className="container mt-15">
        <MobileSubscriptionsFilters
          filtersData={this.props.services}
          isDataFetching={this.props.fetchingData}
          onSearch={this.getMobileSubscriptions}
          initialCheck={true}
        />

        {this.props.subscriptions ? (
          <SubscriptionsData
            mobile={true}
            fetchDataStatus={this.props.fetchDataStatus}
            fetchedData={this.props.subscriptions}
            languages={this.props.languages}
            modalActionStatus={this.props.modalActionStatus}
          />
        ) : null}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    subscriptions: getMobileSubscriptionsSelector(state),
    fetchDataStatus: getMobileSubscriptionStatus(state),
    services: state.services.mobile,
    languages: state.resources.languages,
    modalActionStatus: state.subscriptions.notifications
  };
}

export default connect(mapStateToProps, {
  getMobileSubscriptions,
  changePage,
  getServices,
  resetServices,
  getLanguages
})(MobileSubscriptions);
