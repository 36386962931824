import axios from 'axios';
import * as TYPES from './types';
import { ACCOUNT, CHARGES, LOGS, STATUS, USER_SUBSCRIPTIONS_NEW_SEARCH } from '../../mocks';
import {
  isSubscriptionChargeRefundable,
  SUBSCRIPTIONS_CANCEL_PAYMENT_URL,
  SUBSCRIPTIONS_CANCEL_URL,
  SUBSCRIPTIONS_SEARCH_URL
} from '../../consts';
import {
  createRefundRequest,
  manualRefundRequest,
  notifyConfirmSuccess,
  notifyCreateErrors,
  singleConfirmRefundRequest
} from '../../../refunds/actions';
import { API_RESPONSE_VALIDATION_FAILED_CODE } from '../../../common/consts';
import { showErrorAlert, showSuccessAlert } from '../../../common/actions';
import { mock } from '../../../common/mocks';
import { objectToQueryString } from '../../../common/helpers';
import { CONTACT_CASE_URL } from '../../../contact_cases/consts';
import { CONTACT_CASES_MOCKS } from '../../../contact_cases/mocks';
import { REFUNDS_URL } from '../../../refunds/consts';
import {
  MANUAL_REFUND_ERROR,
  MANUAL_REFUND_SUCCESS,
  SINGLE_REFUND_CONFIRMED,
  SINGLE_REFUND_CONFIRMED_ERROR,
  SINGLE_REFUND_CONFIRMING
} from '../../../refunds/types';
import { createUniqueId } from './cardReducer';

// CARD SUBSCRIPTIONS - PRIVATE
function fetchCardSubscriptions(dispatch, requestData, queryParams) {
  dispatch({ type: TYPES.FETCH_CARD_SUBSCRIPTIONS.REQUEST });

  if (MOCK_DATA) {
    const fakeResponse = USER_SUBSCRIPTIONS_NEW_SEARCH;
    return mock(
      'FETCH_USER_SUBSCRIPTIONS_MOCK_RESPONSE',
      function () {
        dispatch({ type: TYPES.FETCH_CARD_SUBSCRIPTIONS.SUCCESS, payload: fakeResponse });
      },
      function () {
        showErrorAlert(dispatch, 'Server Error. Cannot load subscription list');
      }
    );
  }

  queryParams = objectToQueryString(queryParams);
  return axios
    .post(`${SUBSCRIPTIONS_SEARCH_URL}?${queryParams}`, requestData)
    .then(response => {
      dispatch({ type: TYPES.FETCH_CARD_SUBSCRIPTIONS.SUCCESS, payload: response.data });
      response.data.subscriptions.forEach(async sub => {
        dispatch({
          type: TYPES.CARD_SUBSCRIPTION_STATUS.REQUEST,
          payload: { id: createUniqueId(sub) }
        });
        const res = await fetchSingleSubscriptionStatus(sub.id);
        dispatch({
          type: TYPES.CARD_SUBSCRIPTION_STATUS.SUCCESS,
          payload: { ...res, id: createUniqueId(sub) }
        });
        return res;
      });
    })
    .catch(error => {
      if (error.response && error.response.status === API_RESPONSE_VALIDATION_FAILED_CODE) {
        showErrorAlert(dispatch, error.response.data.errors, { list: true });
      } else if (error.response && error.response.status === 500) {
        showErrorAlert(dispatch, 'Server Error! Cannot load subscription list.');
      } else {
        showErrorAlert(dispatch, error.message || 'Something went wrong');
      }
    });
}

function fetchSingleSubscriptionStatus(subscriptionID) {
  if (MOCK_DATA) {
    return STATUS;
  }
  return axios.get(`/subscriptions/${subscriptionID}/status`).then(res => res.data);
}

function fetchSingleSubscriptionAccount(subscriptionID) {
  if (MOCK_DATA) {
    return { data: ACCOUNT };
  }
  return axios.get(`/subscriptions/${subscriptionID}/account`);
}

function fetchSingleSubscriptionCharges(subscriptionID) {
  if (MOCK_DATA) {
    return { data: { charges: CHARGES } };
  }
  return axios.get(`/subscriptions/${subscriptionID}/charges`);
}

function fetchSingleSubscriptionHistory(subscriptionID) {
  if (MOCK_DATA) {
    return { data: { logs: LOGS } };
  }
  return axios.get(`/subscriptions/${subscriptionID}/logs`);
}

// action for multiple subscriptions
function cancelMultipleSubscriptions(subscriptions) {
  return function (dispatch, getState) {
    const store = getState();
    dispatch({ type: TYPES.CANCEL_MULTIPLE_SUBSCRIPTIONS.REQUEST });

    if (MOCK_DATA) {
      const promises = [];
      subscriptions.forEach(() => {
        promises.push(
          new Promise((resolve, reject) => {
            mock(
              'CANCEL_MULTIPLE_SUBSCRIPTIONS_MOCK_RESPONSE',
              function () {
                resolve();
                dispatch({
                  type: TYPES.CANCEL_MULTIPLE_SUBSCRIPTIONS.SUCCESS,
                  payload: { status: 'success' },
                  store
                });
              },
              function () {
                reject(new Error('MULTIPLE_SUBSCRIPTIONS_CANCEL_ERROR'));
                dispatch({
                  type: TYPES.CANCEL_MULTIPLE_SUBSCRIPTIONS.ERROR,
                  store
                });
                showErrorAlert(dispatch, [{ code: 202, msg: 'Service id is invalid' }], {
                  list: true
                });
              }
            );
          })
        );
      });
      return Promise.all(promises);
    }

    const promises = [];
    for (let i = 0; i < subscriptions.length; i++) {
      const subscription = subscriptions[i];
      const data = {
        service_id: subscription.service.id,
        payment_uid: subscription.id
      };
      const op = axios
        .post(SUBSCRIPTIONS_CANCEL_URL, data)
        .then(response => {
          dispatch({ type: TYPES.CANCEL_MULTIPLE_SUBSCRIPTIONS.SUCCESS, store });
        })
        .catch(error => {
          dispatch({ type: TYPES.CANCEL_MULTIPLE_SUBSCRIPTIONS.ERROR, store });
          throw error;
        });
      promises.push(op);
    }

    return axios.all(promises).then(axios.spread((acct, perms) => {}));
  };
}

function refundMultipleCharges(subscriptions) {
  const chargesToRefund = [];
  subscriptions.forEach(itemData => {
    itemData.charges.forEach(chargeData => {
      if (isSubscriptionChargeRefundable(chargeData)) {
        return;
      }
      const chargeToRefund = {
        service_id: itemData.service.id,
        user_email: itemData.user.email,
        user_reference: itemData.user.reference,
        user_masked_cc: itemData.card.num_masked,
        payment_uid: itemData.id,
        charge_uid: chargeData.id,
        charge_value: chargeData.value
      };
      chargesToRefund.push(chargeToRefund);
    });
  });

  return function (dispatch, getState) {
    const store = getState();
    dispatch({ type: TYPES.REFUND_MULTIPLE_SUBSCRIPTIONS.REQUEST });

    if (MOCK_DATA) {
      const promises = [];
      chargesToRefund.forEach(() => {
        promises.push(
          new Promise((resolve, reject) => {
            mock(
              'CANCEL_MULTIPLE_SUBSCRIPTIONS_MOCK_RESPONSE',
              function () {
                resolve();
                dispatch({
                  type: TYPES.REFUND_MULTIPLE_SUBSCRIPTIONS.SUCCESS,
                  payload: { status: 'success' },
                  store
                });
              },
              function () {
                reject(new Error('MULTIPLE_SUBSCRIPTIONS_REFUND_ERROR'));
                dispatch({ type: TYPES.REFUND_MULTIPLE_SUBSCRIPTIONS.ERROR, store });
                showErrorAlert(dispatch, [{ code: 202, msg: 'Service id is invalid' }], {
                  list: true
                });
              }
            );
          })
        );
      });
      return Promise.all(promises);
    }

    const promises = [];
    for (let i = 0; i < chargesToRefund.length; i++) {
      const charge = chargesToRefund[i];

      const op = axios
        .post(REFUNDS_URL, charge)
        .then(response => {
          dispatch({ type: TYPES.REFUND_MULTIPLE_SUBSCRIPTIONS.SUCCESS, store });
        })
        .catch(error => {
          dispatch({ type: TYPES.REFUND_MULTIPLE_SUBSCRIPTIONS.ERROR, store });
          throw error;
        });
      promises.push(op);
    }

    return axios.all(promises).then(axios.spread((acct, perms) => {}));
  };
}

// CARD SUBSCRIPTIONS - PUBLIC
export function getCardSubscriptions(requestData, queryParams) {
  return function (dispatch) {
    return fetchCardSubscriptions(dispatch, requestData, queryParams);
  };
}

export function getCardSubscriptionDetails({ subscriptionID, subscriptionIDUnique }) {
  return function (dispatch) {
    dispatch({
      type: TYPES.FETCH_CARD_SUBSCRIPTION_DETAILS.REQUEST,
      payload: { subscriptionID: subscriptionIDUnique }
    });
    const requests = [
      fetchSingleSubscriptionAccount(subscriptionID),
      fetchSingleSubscriptionCharges(subscriptionID),
      fetchSingleSubscriptionHistory(subscriptionID)
    ];
    return axios
      .all(requests)
      .then(
        axios.spread((account, charges, history) => {
          return dispatch({
            type: TYPES.FETCH_CARD_SUBSCRIPTION_DETAILS.SUCCESS,
            payload: {
              service: account.data,
              charges: charges.data.charges,
              logs: history.data.logs
            },
            requestParams: { subscriptionID: subscriptionIDUnique }
          });
        })
      )
      .catch(error => {
        dispatch({
          type: TYPES.FETCH_CARD_SUBSCRIPTION_DETAILS.ERROR,
          payload: { subscriptionID: subscriptionIDUnique }
        });
        if (error.response.status === API_RESPONSE_VALIDATION_FAILED_CODE) {
          showErrorAlert(dispatch, error.response.data.errors, { list: true });
        } else if (error.response.status === 500) {
          showErrorAlert(dispatch, 'Server Error! Cannot load subscription.');
        }
      });
  };
}

export function createSubscriptionRefund({ refundData, subscriptionID }) {
  return function (dispatch) {
    const payload = {
      chargeID: refundData.charge_uid,
      subscriptionID
    };

    const successCallback = () => {
      dispatch({ type: TYPES.SUBSCRIPTION_REFUND_CREATED, payload });
    };

    const errorCallback = () => {
      dispatch({ type: TYPES.SUBSCRIPTION_REFUND_ERROR, payload });
      notifyCreateErrors(dispatch, refundData.payment_uid, refundData.charge_uid);
    };

    return createRefundRequest(refundData, successCallback, errorCallback);
  };
}

export function confirmSubscriptionRefund(data) {
  return function (dispatch) {
    const payload = {
      chargeID: data.charge_uid,
      subscriptionID: data.subscriptionID
    };

    const successCallback = (res = {}) => {
      dispatch({
        type: TYPES.SUBSCRIPTION_REFUND_CONFIRM.SUCCESS,
        payload: { ...payload, ...res }
      });
      notifyConfirmSuccess(dispatch, data.refundID);
    };

    const errorCallback = (res = {}) => {
      dispatch({ type: TYPES.SUBSCRIPTION_REFUND_CONFIRM.ERROR, payload: { ...payload, ...res } });
    };

    return singleConfirmRefundRequest(data, successCallback, errorCallback);
  };
}

export function subscriptionManualRefund({ refundID, chargeID, subscriptionID }) {
  return function (dispatch) {
    const payload = { chargeID, subscriptionID };

    const successCallback = () => {
      dispatch({ type: TYPES.SUBSCRIPTION_MANUAL_REFUND.SUCCESS, payload });
    };

    const errorCallback = () => {
      dispatch({ type: TYPES.SUBSCRIPTION_MANUAL_REFUND.ERROR, payload });
      notifyCreateErrors(dispatch, subscriptionID, chargeID);
    };

    return manualRefundRequest(refundID, successCallback, errorCallback);
  };
}

export function cancelSubscription(cancelParams) {
  const { subscriptionId, ...params } = cancelParams;
  return function (dispatch) {
    if (MOCK_DATA) {
      return new Promise((resolve, reject) => {
        mock(
          'CANCEL_SUBSCRIPTION_MOCK_RESPONSE',
          function () {
            resolve();
            dispatch({ type: TYPES.CANCEL_SUBSCRIPTION.SUCCESS, payload: { subscriptionId } });
            showSuccessAlert(dispatch, 'Subscription has been cancelled successfully');
          },
          function () {
            reject(new Error('Service id is invalid'));
            showErrorAlert(dispatch, [{ code: 202, msg: 'Service id is invalid' }], { list: true });
          }
        );
      });
    }

    return axios
      .post(SUBSCRIPTIONS_CANCEL_URL, params)
      .then(response => {
        dispatch({ type: TYPES.CANCEL_SUBSCRIPTION.SUCCESS, payload: { subscriptionId } });
        showSuccessAlert(dispatch, 'Subscription has been cancelled successfully');
      })
      .catch(error => {
        if (error.response.status == API_RESPONSE_VALIDATION_FAILED_CODE) {
          showErrorAlert(dispatch, error.response.data.errors, { list: true });
        } else {
          showErrorAlert(dispatch, error.response.data.error);
        }
        throw error;
      });
  };
}

// cancel payment directly in Straal only
export function cancelSubscriptionPayment(cancelParams) {
  const { subscriptionId, ...params } = cancelParams;

  return function (dispatch) {
    if (MOCK_DATA) {
      return new Promise((resolve, reject) => {
        mock(
          'CANCEL_SUBSCRIPTION_PAYMENT_MOCK_RESPONSE',
          function () {
            resolve();
            dispatch({
              type: TYPES.CANCEL_SUBSCRIPTION_PAYMENT.SUCCESS,
              payload: { subscriptionId }
            });
            showSuccessAlert(dispatch, 'Subscription payment has been cancelled successfully');
          },
          function () {
            showErrorAlert(dispatch, [{ code: 201, msg: 'Payment id is invalid' }], { list: true });
          }
        );
      });
    }

    return axios
      .post(SUBSCRIPTIONS_CANCEL_PAYMENT_URL, params)
      .then(response => {
        dispatch({ type: TYPES.CANCEL_SUBSCRIPTION_PAYMENT.SUCCESS, payload: { subscriptionId } });
        showSuccessAlert(dispatch, 'Subscription payment has been cancelled successfully');
      })
      .catch(error => {
        if (error.response.status == API_RESPONSE_VALIDATION_FAILED_CODE) {
          showErrorAlert(dispatch, error.response.data.errors, { list: true });
        } else {
          showErrorAlert(dispatch, error.response.data.error);
        }
        throw error;
      });
  };
}

export function fetchEvents(data) {
  return function (dispatch) {
    const customerEmail = data.email || data.user.email;
    const serviceId = data.service.id;
    const id = data.idUnique;

    const queryString = objectToQueryString({
      customer_email: customerEmail,
      service_id: serviceId
    });
    let url = CONTACT_CASE_URL;

    if (queryString) {
      url = `${url}?${queryString}`;
    }

    if (MOCK_DATA) {
      return mock(
        'SUBSCRIPTION_FETCH_EVENTS_MOCK_RESPONSE',
        function () {
          dispatch({
            type: TYPES.FETCH_USER_SUBSCRIPTION_EVENTS.SUCCESS,
            payload: { events: CONTACT_CASES_MOCKS, subscriptionId: id }
          });
        },
        function () {
          showErrorAlert(dispatch, [{ code: 701, msg: 'Limit is invalid' }], { list: true });
        }
      );
    }

    return axios
      .get(url)
      .then(response => {
        dispatch({
          type: TYPES.FETCH_USER_SUBSCRIPTION_EVENTS.SUCCESS,
          payload: { events: response.data.contact_cases, subscriptionId: id }
        });
      })
      .catch(error => {
        if (error.response.status == API_RESPONSE_VALIDATION_FAILED_CODE) {
          showErrorAlert(dispatch, error.response.data.errors, { list: true });
        } else {
          showErrorAlert(dispatch, error.response.statusText);
        }
      });
  };
}

export function cancelTransaction({ chargeID, subscriptionID }) {
  const payload = { chargeID, subscriptionID };

  return function (dispatch) {
    if (MOCK_DATA) {
      return new Promise((resolve, reject) => {
        mock(
          'CANCEL_TRANSACTION_MOCK_RESPONSE',
          function () {
            resolve();
            dispatch({ type: TYPES.CANCEL_TRANSACTION.SUCCESS, payload });
          },
          function () {
            dispatch({ type: TYPES.CANCEL_TRANSACTION.ERROR, payload });
            notifyCreateErrors(dispatch, subscriptionID, chargeID);
            errorCallback();
          }
        );
      });
    }

    return axios
      .post(`/subscriptions/transactions/${chargeID}/cancel`)
      .then(response => {
        dispatch({ type: TYPES.CANCEL_TRANSACTION.SUCCESS, payload });
        return response;
      })
      .catch(error => {
        dispatch({ type: TYPES.CANCEL_TRANSACTION.ERROR, payload });
        showErrorAlert(dispatch, `Error! Cannot cancel ${chargeId} charge`);
        return Promise.reject(error.response);
      });
  };
}

export function actionOnMultipleSubscriptions(subscriptions, type) {
  if (type === 'cancel') {
    return cancelMultipleSubscriptions(subscriptions);
  }
  if (type === 'refund') {
    return refundMultipleCharges(subscriptions);
  }
}

export function resetMultipleSubscriptionAction() {
  return function (dispatch) {
    dispatch({ type: TYPES.RESET_MULTIPLE_SUBSCRIPTIONS });
  };
}

export function clearCardSubscriptions() {
  return function (dispatch) {
    dispatch({ type: TYPES.CLEAR_CARD_SUBSCRIPTIONS });
  };
}
