export const CONTACT_REASONS_FETCHED = 'contact_reasons_fetched';
export const CONTACT_SOLUTIONS_FETCHED = 'contact_solutions_fetched';
export const SERVICES_FETCHED = 'services_fetched';
export const SERVICE_FETCHED = 'service_fetched';
export const LANGUAGES_FETCHED = 'languages_fetched';
export const TEST_CARDS_FETCHED = 'test_card_fetched';
export const COMMUNICATORS_FETCHED = 'communicators_fetched';
export const CANCEL_NOTIFICATION_SENT = 'cancel_notification_sent';
export const SERVICE_PROCESSING = 'service_processing';
export const SERVICE_CREATED = 'service_created';
export const SERVICE_CREATED_ERROR = 'service_created_error';
export const SERVICE_UPDATED = 'service_updated';
export const SERVICE_UPDATED_ERROR = 'service_updated_error';
export const FILTER_SERVICES = 'filter_services';

export const RESET_SERVICES = 'reset_services';
export const RESET_SERVICE = 'reset_service';
