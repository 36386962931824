import React, { Component } from 'react';
import { MultiSelectBox as Msb } from '../msb';

export default class MultiSelectBox extends Component {
  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps) {
    if (this.props.reset && prevProps.reset !== this.props.reset) {
      this.msb.reset();
    }
    if (
      this.props.data &&
      prevProps.data.length !== this.props.data.length &&
      this.msb
    ) {
      this.msb.update(this.props.data);
    }
  }

  componentDidMount() {
    this.msb = new Msb(this.refs.ads, this.props.data, this.props.options);
    this.msb.on('selected', e => {
      this.props.onChange(e.detail.selected);
    });
  }

  render() {
    return <div ref="ads" />;
  }
}
