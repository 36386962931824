import React from 'react';
import { connect } from 'react-redux';
import {
  getContactCaseSolutions,
  deleteContactCaseSolution,
  createContactCaseSolution,
  editContactCaseSolution
} from './../actions';
import ProductItemContainer from './ProductItemContainer';

const ContactCaseSolutions = props => {
  return (
    <ProductItemContainer
      name="solution"
      data={props.contactCaseSolutions}
      getItems={props.getContactCaseSolutions}
      deleteItem={props.deleteContactCaseSolution}
      createItem={props.createContactCaseSolution}
      editItem={props.editContactCaseSolution}
    />
  );
};

const mapStateToProps = state => {
  return {
    contactCaseSolutions: state.services.contactCaseSolutions
  };
};

export default connect(mapStateToProps, {
  getContactCaseSolutions,
  deleteContactCaseSolution,
  createContactCaseSolution,
  editContactCaseSolution
})(ContactCaseSolutions);
