import React, { Component } from 'react';
import Select from 'react-select';

export default class ServicesWizardStep extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <Select
          name="service_id"
          options={this.props.items}
          classNamePrefix="select"
          onChange={option => this.props.handleNext({ service: option.id })}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
        />
      </div>
    );
  }
}
