import React from 'react';
import ProductDetailsStep from './ProductDetailsStep';
import LanguagesStep from './LanguagesStep';
import ContactReasonsStep from './ContactReasonsStep';
import ContactSolutionsStep from './ContactSolutionsStep';
import CommunicatorsStep from './CommunicatorsStep';
import SummaryStep from './SummaryStep';
import UsersStep from './UsersStep';
import AdditionalInfoStep from './AdditionalInfoStep';
import { ROLE_SUPER_ADMIN } from '../../../users/consts';

export const WIZARD_STEPS = [
  {
    name: 'Product details',
    component: obj => (
      <ProductDetailsStep
        item={obj.props.initialData.serviceSelected || { name: '' }}
        handleNext={obj.handleNextStep}
        reset={obj.reset}
      />
    )
  },
  {
    name: 'Languages',
    component: obj => (
      <LanguagesStep
        items={obj.props.initialData.languages}
        handleNext={obj.handleNextStep}
        reset={obj.reset}
      />
    )
  },
  {
    name: 'Contact reasons',
    component: obj => (
      <ContactReasonsStep
        items={obj.props.initialData.contactReasons}
        handleNext={obj.handleNextStep}
        reset={obj.reset}
        handleAdditionalAction={obj.props.onAdditionalAction}
      />
    )
  },
  {
    name: 'Contact solutions',
    component: obj => (
      <ContactSolutionsStep
        items={obj.props.initialData.contactSolutions}
        handleNext={obj.handleNextStep}
        reset={obj.reset}
        handleAdditionalAction={obj.props.onAdditionalAction}
      />
    )
  },
  {
    name: 'Communicators',
    component: obj => (
      <CommunicatorsStep
        items={obj.props.initialData.communicators}
        handleNext={obj.handleNextStep}
        reset={obj.reset}
        handleAdditionalAction={obj.props.onAdditionalAction}
      />
    )
  },
  {
    name: 'Users',
    component: obj => (
      <UsersStep
        items={obj.props.initialData.users}
        handleNext={obj.handleNextStep}
        reset={obj.reset}
      />
    )
  },
  {
    name: 'Additional information',
    component: obj => (
      <AdditionalInfoStep
        item={obj.props.initialData.serviceSelected || {}}
        handleNext={obj.handleNextStep}
        reset={obj.reset}
      />
    ),
    hasPermission: [ROLE_SUPER_ADMIN]
  },
  {
    name: 'Summary',
    component: obj => (
      <SummaryStep
        initialData={obj.props.initialData}
        stepsData={obj.state.data}
        notAllowedSteps={obj.props.notAllowedSteps}
        handleNext={obj.handleSubmit}
      />
    )
  }
];
