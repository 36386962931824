import React, { Component } from 'react';
import { Collapse, Navbar, NavbarToggler, Nav, NavItem, NavLink } from 'reactstrap';
import { NavLink as Link } from 'react-router-dom';
import {
  hasSubscriptionsPermissionTo,
  hasAccountManagementPermissionTo,
  hasContactCasesPermissionTo,
  hasRefundsPermissionTo,
  hasServicesPermissionTo,
  hasAlertsPermissionTo,
  action
} from './users/permissions';

class Header extends Component {
  render() {
    return (
      <Navbar className="navbar-expand-lg navbar-dark" color="secondary" expand>
        <NavbarToggler onClick={this.toggle} />
        <Collapse className="navbar-expand-lg" isOpen={true} navbar>
          <Nav navbar className="mx-auto align-items-center">
            {hasSubscriptionsPermissionTo('showPayCard') ? (
              <NavItem>
                <NavLink activeClassName="active" tag={Link} to="/subscription/find/paycard">
                  Card search
                </NavLink>
              </NavItem>
            ) : null}
            {hasSubscriptionsPermissionTo('showMobile') ? (
              <NavItem>
                <NavLink activeClassName="active" tag={Link} to="/subscription/find/mobile">
                  Mobile search
                </NavLink>
              </NavItem>
            ) : null}
            {hasContactCasesPermissionTo(action.add) ? (
              <NavItem>
                <NavLink activeClassName="active" tag={Link} to="/contact_cases/add">
                  Submit events
                </NavLink>
              </NavItem>
            ) : null}
            {hasContactCasesPermissionTo(action.list) ? (
              <NavItem>
                <NavLink activeClassName="active" tag={Link} to="/contact_cases/list">
                  Event list
                </NavLink>
              </NavItem>
            ) : null}
            {hasRefundsPermissionTo(action.list) ? (
              <NavItem>
                <NavLink activeClassName="active" tag={Link} to="/subscription/find/refunds">
                  Refunds
                </NavLink>
              </NavItem>
            ) : null}
            {hasAlertsPermissionTo(action.list) ? (
              <NavItem>
                <NavLink activeClassName="active" tag={Link} to="/alerts/nethone">
                  Nethone Alerts
                </NavLink>
              </NavItem>
            ) : null}
            {hasAccountManagementPermissionTo(action.list) ? (
              <NavItem>
                <NavLink activeClassName="active" tag={Link} to="/admin_gate/users/list">
                  User management
                </NavLink>
              </NavItem>
            ) : null}
            {hasServicesPermissionTo(action.list) ? (
              <NavItem>
                <NavLink activeClassName="active" tag={Link} to="/admin_gate/services/list">
                  Services management
                </NavLink>
              </NavItem>
            ) : null}
            <NavItem>
              <NavLink onClick={this.props.onLogoutClick}>Logout</NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
}

export default Header;
