import axios from 'axios';

import { showErrorAlert, showSuccessAlert } from '@common/actions';
import { API_RESPONSE_VALIDATION_FAILED_CODE } from '@common/consts';
import { CANCEL_NOTIFICATION_URL } from '@resources/consts';

import {
  SUBSCRIPTIONS_CLEAR_DATA
} from './consts';
import {
  NOTIFICATION_RESET,
  NOTIFICATION_SEND_ERROR,
  NOTIFICATION_SEND_SUCCESS,
  NOTIFICATION_SENDING,
  CLEAR_SUBSCRIPTIONS_DATA
} from './types';
import { mock } from '@common/mocks';
import { REFUND_NOTIFICATION_URL } from '../refunds/consts';

// COMMON FOR CARD AND MOBILE SUBSCRIPTIONS
export function sendCancelNotification(cancelParams) {
  return function(dispatch) {
    dispatch({ type: NOTIFICATION_SENDING });

    if (MOCK_DATA) {
      return mock(
        'SEND_CANCEL_NOTIFICATION_MOCK_RESPONSE',
        function() {
          dispatch({ type: NOTIFICATION_SEND_SUCCESS });
        },
        function() {
          dispatch({ type: NOTIFICATION_SEND_ERROR });
        }
      );
    }

    return axios
      .post(CANCEL_NOTIFICATION_URL, cancelParams)
      .then(response => {
        dispatch({ type: NOTIFICATION_SEND_SUCCESS });
      })
      .catch(error => {
        dispatch({ type: NOTIFICATION_SEND_ERROR });
      });
  };
}

export function sendRefundNotification(refundParams) {
  return function(dispatch) {
    dispatch({ type: NOTIFICATION_SENDING });

    if (MOCK_DATA) {
      return mock(
        'SEND_REFUND_NOTIFICATION_MOCK_RESPONSE',
        function() {
          dispatch({ type: NOTIFICATION_SEND_SUCCESS });
        },
        function() {
          dispatch({ type: NOTIFICATION_SEND_ERROR });
        }
      );
    }

    return axios
      .post(REFUND_NOTIFICATION_URL, refundParams)
      .then(response => {
        dispatch({ type: NOTIFICATION_SEND_SUCCESS });
      })
      .catch(error => {
        dispatch({ type: NOTIFICATION_SEND_ERROR });
      });
  };
}

export function resetNotificationStatus() {
  return function(dispatch) {
    dispatch({ type: NOTIFICATION_RESET });
  };
}

// remove account from database in product
export function clearData(cancelParams) {
  const url = SUBSCRIPTIONS_CLEAR_DATA;
  const { subscriptionId, ...params } = cancelParams;

  return function(dispatch) {
    if (MOCK_DATA) {
      return new Promise((resolve, reject) => {
        mock(
          'SUBSCRIPTIONS_CLEAR_DATA_MOCK_RESPONSE',
          function() {
            resolve();
            dispatch({ type: CLEAR_SUBSCRIPTIONS_DATA.SUCCESS, payload: { subscriptionId } });
            showSuccessAlert(dispatch, 'Subscription data has been cleared successfully');
          },
          function() {
            showErrorAlert(dispatch, [{ code: 201, msg: 'Payment id is invalid' }], { list: true });
          }
        );
      });
    }

    return axios
      .post(url, params)
      .then(response => {
        dispatch({ type: CLEAR_SUBSCRIPTIONS_DATA.SUCCESS, payload: { subscriptionId } });
        showSuccessAlert(dispatch, 'Subscription data has been cleared successfully');
      })
      .catch(error => {
        if (error.response.status == API_RESPONSE_VALIDATION_FAILED_CODE) {
          showErrorAlert(dispatch, error.response.data.errors, { list: true });
        } else {
          showErrorAlert(dispatch, error.response.data.error);
        }
        throw error;
      });
  };
}
