// API status codes
export const API_RESPONSE_VALID_CODE = 200;
export const API_RESPONSE_CREATED_VALID_CODE = 201;
export const API_RESPONSE_ACTION_VALID_CODE = 204;
export const API_RESPONSE_UNATHORIZED_CODE = 401;
export const API_RESPONSE_FORBIDDEN_CODE = 403;
export const API_RESPONSE_NOT_FOUND_CODE = 404;
export const API_RESPONSE_VALIDATION_FAILED_CODE = 422;

// API response key in local storage
export const API_AUTH_TOKEN_KEY = 'auth_token';
export const API_AUTH_VALID_KEY = 'valid_to';
