import React from 'react';
import localeEn from 'moment/locale/en-gb';
import luhn from 'luhn';
import moment from 'moment';
import queryString from 'query-string';
import sha1 from 'sha1';

moment.updateLocale('en', localeEn);

export function validateEmail(email) {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+/i.test(email);
}

export function displayYesNo(value) {
  return value ? 'Yes' : 'No';
}

export function isNumberType(value) {
  return /^(?:[0-9 ]+$)/.test(value);
}

export function displayTimestamp(timestamp, format = 'DD-MM-YYYY hh:mm:ss a') {
  return moment(timestamp, 'X').format(format);
}

export function displayUnixMsTimestamp(timestamp, format = 'DD-MM-YYYY hh:mm:ss a') {
  return moment(timestamp, 'x').format(format);
}

export function getTimestamp(date, format = 'DD-MM-YYYY h:mm:ss') {
  if (date) {
    return moment(date, format).format('X');
  }
}

export function getTimestampFromUTC(date, format = 'YYYY-MM-DD HH:mm:ss') {
  return moment(displayUTCDate(date, format)).valueOf();
}

export function displayUTCDate(date, format = 'DD-MM-YYYY hh:mm:ss') {
  return moment(date).utc().format(format);
}

export function addToDate(date, number = 0, timeUnit = 'days') {
  if (date) {
    return moment(date).add(number, timeUnit);
  }
}

export function getTokenValidationTimestamp() {
  return moment()
    .add(7, 'days')
    .format('X');
}

export function isTimestampValid(timestamp) {
  return moment(timestamp, 'X').isAfter(moment());
}

export function isValidMonth(monthString) {
  return moment(monthString, 'MM').isValid();
}

export function objectToQueryString(obj) {
  return queryString.stringify(obj);
}

export function isCardNumberValid(cardNumber) {
  return luhn.validate(cardNumber);
}

export function getSha1Hash(message) {
  return sha1(message);
}

export function sortAsc(a, b) {
  if (typeof a === 'number' && typeof b === 'number') {
    return a - b;
  } else {
    return (a > b) - (a < b);
  }
}

export function sortDesc(a, b) {
  if (typeof a === 'number' && typeof b === 'number') {
    return b - a;
  } else {
    return (b > a) - (b < a);
  }
}

export function sortOrderRename(sortOrder) {
  if (sortOrder === 'desc' || sortOrder === 'asc') {
    return sortOrder === 'asc' ? 'oldest' : 'newest';
  } else if (sortOrder === 'newest' || sortOrder === 'oldest') {
    return sortOrder === 'oldest' ? 'asc' : 'desc';
  }
}

export function rename(string) {
  return string.replace(/_/g, ' ');
}

export function scrollTo(element, to, duration) {
  if (duration <= 0) return;
  const difference = to - element.scrollTop;
  const perTick = difference / duration * 10;

  setTimeout(function() {
    element.scrollTop = element.scrollTop + perTick;
    if (element.scrollTop === to) return;
    scrollTo(element, to, duration - 10);
  }, 10);
}

export function howManyDaysLeft(endDate) {
  const day = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const currentDate = +new Date();
  const diff = endDate - currentDate;
  const days = Math.round(diff / day);
  return days > 0 ? days : 0;
}

export function removeEmptyObjectKeys(object) {
  Object.keys(object).forEach(
    key => (object[key] === '' || object[key] === null) && delete object[key]
  );
  return object;
}

export function isObject(obj) {
  return obj != null && obj.constructor.name === 'Object';
}

const valToString = val => {
  if (typeof val === 'boolean') {
    return val ? 'true' : 'false';
  }
  switch (val) {
    case null:
      return 'null';
    case undefined:
      return 'undefined';
    default:
      return val;
  }
};

export const parseObjectToHTML = (obj, name) => {
  const getValueFromObj = value => {
    if (isObject(value)) {
      return Object.keys(value).map((key, index) => {
        const val = value[key];
        if (isObject(val)) {
          return parseObjectToHTML(val, key);
        } else {
          return (
            <p key={key + val + index} style={{ marginLeft: 20 }}>
              <span>{key}: </span>
              {valToString(val)}
            </p>
          );
        }
      });
    }
    return null;
  };
  return (
    <div key={JSON.stringify(obj)} style={{ marginLeft: 20 }} className="json-to-html-wrapper">
      <p>
        {name ? (
          <React.Fragment>
            <span>{name}: </span>
            {'{'}
          </React.Fragment>
        ) : (
          '{'
        )}
      </p>
      {getValueFromObj(obj)}
      <p>{'}'}</p>
    </div>
  );
};

export const maskIBAN = iban => {
  if (iban) {
    return `${iban.substr(0, 6)}****${iban.substr(10)}`;
  }
};

export const debounce = (func, wait, immediate) => {
  let timeout;
  return function(...args) {
    const context = this;
    const later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};
