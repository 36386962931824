/**
 * @type {Object}
 * @static
 * @memberof MultiSelectBox
 */
export default {
  /**
   * MultiSelectBox's placement.
   * @prop {Boolean} searchInput=true
   * @prop {String} groupBy='role'
   */
  searchInput: true,
  labels: true,
  selectableLabel: 'Any',
  selectedLabel: 'Choosen',
  selectAllButtons: true,
  groupBy: '',
  onCreate() {},
  onUpdate() {}
};
