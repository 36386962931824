import React from 'react';
import { displayTimestamp, maskIBAN } from '../../common/helpers';
import Loading from '../../common/components/Loading';

export function SubscriptionsItemHeader(props) {
  return (
    <div className="row card-header-content">
      {props.mobile ?
        subscriptionsItemHeaderMobile(props.item, props.subscriptionStatus) :
        subscriptionsItemHeader(props.item, props.status)}
      <div className="col-1">
        {props.show ? (
          <button
            className="btn btn-collapse-chevron btn-collapse-chevron-up"
            onClick={props.handleItemClick}
          />
        ) : (
          <button
            className="btn btn-collapse-chevron btn-collapse-chevron-down"
            onClick={props.handleItemClick}
          />
        )}
      </div>
    </div>
  );
}

function subscriptionsItemHeader(item, status) {
  return (
    <React.Fragment>
      <div className="col-3">
        {
          status.loading ?
            <Loading loadingClass="throbber--relative mr-1 d-inline-block align-top" /> : (
              <span
                className={'status-dot status-dot-' + (status.active ? 'active' : 'deactivated')}
              />
            )
        }
        <span className="ui-heading">{item.user.email}</span>
      </div>
      <div className="col-2">
        <p className="ui-heading mb-1">Created:</p>
        <span className="text-nowrap">{displayTimestamp(item.created)}</span>
      </div>
      <div className="col-2">
        <span className="ui-heading">Product:</span>
        <span className="ui-heading ui-heading-dark">{item.service.name}</span>
      </div>
      {
        item.card && (
          <div className="col-4">
            <span className="ui-heading mb-1">
              <p>Credit card number: {item.card.num_masked}</p>
              <p className="mb-0">Cardholder: {item.card.name}</p>
            </span>
          </div>
        )
      }
      {
        item.bank_transfer && (
          <div className="col-4">
            <span className="ui-heading">
              IBAN: {maskIBAN(item.bank_transfer.iban)}
            </span>
          </div>
        )
      }
    </React.Fragment>
  );
}

function subscriptionsItemHeaderMobile(item, subscriptionStatus) {
  return (
    <React.Fragment>
      <div className="col-3">
        <span className="ui-heading ui-heading-dark">{item.user.name}</span>
        <span className="ui-heading">{item.user.email}</span>
      </div>
      <div className="col-2">
        <span className="ui-heading">Subscription:</span>
        <span
          className={
            'ui-heading ' +
            'ui-heading-' +
            (subscriptionStatus() === 'active' ? 'success' : 'danger')
          }
        >
          {subscriptionStatus()}
        </span>
      </div>
      <div className="col-3">
        <span className="ui-heading">Product:</span>
        <span className="ui-heading ui-heading-dark">{item.service.name}</span>
      </div>
      <div className="col-3">
        <span className="ui-heading">Phone number:</span>
        <span className="ui-heading ui-heading-dark">
          +{item.msisdn.code} {item.msisdn.number}
        </span>
      </div>
    </React.Fragment>
  );
}
