import React, { Component } from 'react';
import { withFormik } from 'formik';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';

import { displayYesNo, rename } from '@common/helpers';
import { SelectField } from '@common/form';

class FiltersModal extends Component {
  constructor(props) {
    super(props);
  }

  prepareFilterOptions(options, key) {
    return options.map((option, index) => {
      const id = option.id ? option.id : null;
      const name = option[key] ? option[key] : option;
      if (key) {
        return { id: id, name: name };
      } else {
        return { id: name, name: rename(name) };
      }
    });
  }

  prepareSatisfactionOptions(options) {
    return options.map(option => {
      return { id: option, name: displayYesNo(option) };
    });
  }

  render() {
    return (
      <Card className="card-dropdown">
        <CardHeader>Filters</CardHeader>
        <CardBody>
          <form onSubmit={this.props.handleSubmit}>
            <div className="row">
              <div className="form-group col-6">
                <SelectField
                  label="User"
                  name="user_id"
                  options={this.prepareFilterOptions(this.props.filters.users, 'email')}
                  emptyOption="All"
                />
              </div>
              <div className="form-group col-6">
                <SelectField
                  label="Product"
                  name="service_id"
                  options={this.prepareFilterOptions(this.props.filters.services, 'name')}
                  emptyOption="All"
                />
              </div>
              <div className="form-group col-6">
                <SelectField
                  label="Case"
                  name="contact_case_reason_id"
                  options={this.prepareFilterOptions(
                    this.props.filters.contactReasons,
                    'description'
                  )}
                  emptyOption="All"
                />
              </div>
              <div className="form-group col-6">
                <SelectField
                  label="Communicator"
                  name="communicator"
                  options={this.prepareFilterOptions(
                    this.props.filters.communicators,
                    'description'
                  )}
                  emptyOption="All"
                />
              </div>
              <div className="form-group col-6">
                <SelectField
                  label="Case result"
                  name="contact_case_solution_id"
                  options={this.prepareFilterOptions(
                    this.props.filters.contactSolutions,
                    'description'
                  )}
                  emptyOption="All"
                />
              </div>
              <div className="form-group col-6">
                <SelectField
                  label="Case solved"
                  name="case_solved"
                  options={this.prepareSatisfactionOptions(this.props.filters.caseSolved)}
                  emptyOption="All"
                />
              </div>
              <div className="form-group col-6">
                <SelectField
                  label="Language"
                  name="language_id"
                  options={this.prepareFilterOptions(this.props.filters.languages, 'name')}
                  emptyOption="All"
                />
              </div>
              <div className="form-group col-6">
                <Button type="submit" color="primary" className="btn-block">
                  Apply filters
                </Button>
              </div>
            </div>
          </form>
        </CardBody>
      </Card>
    );
  }
}

export default withFormik({
  displayName: 'FiltersModal',
  mapPropsToValues: props => props.initialValues,
  handleSubmit: (values, { props }) => {
    props.onSubmit(values);
  }
})(FiltersModal);
