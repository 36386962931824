export const LOGIN_URL = '/users/sign_in';
export const LOGOUT_URL = 'users/sign_out';
export const USERS_URL = '/users';
export const USERS_GROUP_URL = '/user_groups';
export const USER_ROLE_KEY = 'user_role';
export const USER_EMAIL_KEY = 'user_email';
export const USER_GROUP_KEY = 'user_group';

export const ROLE_SUPPORT = 'support';
export const ROLE_FINNANCIAL = 'financial';
export const ROLE_ADMIN = 'admin';
export const ROLE_SUPER_ADMIN = 'super_admin';

