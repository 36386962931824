import React, { Component } from 'react';
import MultiSelectBox from '../../../../lib/msb/react/msb';

export default class ContactReasonsStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contactCaseReasonIds: []
    };
    this.handleNext = this.handleNext.bind(this);
    this.handleMultiSelectChange = this.handleMultiSelectChange.bind(this);
  }

  get reasonOptions() {
    return this.props.items.map(item => {
      return { id: item.id, name: item.description, value: item.id, selected: item.selected };
    });
  }

  handleNext() {
    if (!this.state.contactCaseReasonIds.length) {
      return;
    }

    this.props.handleNext(this.state);
  }

  handleMultiSelectChange(reasons) {
    const contactCaseReasonIds = reasons.map(reason => reason.value);

    this.setState({
      contactCaseReasonIds
    });
  }

  render() {
    return (
      <div className="col-10 mx-auto">
        <MultiSelectBox
          data={this.reasonOptions}
          options={{ selectableLabel: 'Reasons' }}
          onChange={this.handleMultiSelectChange}
          reset={this.props.reset}
        />
        <span
          role="link" tabIndex="0"
          className="ui-heading-info cursor-pointer"
          onClick={() => this.props.handleAdditionalAction('reason')}
        >
          + Add new reason
        </span>
        <div className={'text-center'}>
          <button
            type="button"
            className={`btn btn-primary mt-6 ${
              !this.state.contactCaseReasonIds.length ? 'disabled' : ''
            }`}
            onClick={() => this.handleNext()}
          >
            Next
          </button>
        </div>
      </div>
    );
  }
}
