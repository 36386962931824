import { ROLE_ADMIN, ROLE_FINNANCIAL, ROLE_SUPER_ADMIN, ROLE_SUPPORT, USER_ROLE_KEY } from './consts';

const getUserRole = () => {
  return localStorage.getItem(USER_ROLE_KEY);
};

// popular actions' name
const list = 'list';
const add = 'add';
const deletee = 'delete';
const cancel = 'cancel';
const confirm = 'confirm';

export const action = {
  list: list,
  add: add,
  delete: deletee,
  cancel: cancel,
  confirm: confirm
};

// PERMISSIONS LIST
const SUBSCRIPTIONS = {
  showPayCard: false,
  showMobile: false,
  [cancel]: false,
  cancelPayment: false,
  showCardBillingIds: false
};

const REFUNDS = {
  [list]: false,
  [add]: false,
  [deletee]: false,
  [confirm]: false
};

const CONTACT_CASES = {
  [list]: false,
  [add]: false,
  [deletee]: false
};

const ACCOUNT_MANAGEMNT = {
  [list]: false,
  actions: []
};

const SERVICES = {
  [list]: false,
  [add]: false,
  [deletee]: false
};

const ALERTS = {
  [list]: false
};

// SUPPORT PERMISSIONS
const SUPPORT_PERMISSIONS = {
  SUBSCRIPTIONS: {
    ...SUBSCRIPTIONS,
    showPayCard: true,
    showMobile: true,
    [cancel]: true,
    cancelPayment: true
  },
  REFUNDS: {
    ...REFUNDS,
    [add]: true
  },
  ALERTS: {
    ...ALERTS
  },
  CONTACT_CASES: {
    ...CONTACT_CASES,
    [list]: true,
    [add]: true
  },
  ACCOUNT_MANAGEMNT,
  SERVICES
};

// FINANCIAL PERMISSIONS
// Support permissions and:
const FINANCIAL_PERMISSIONS = {
  ...SUPPORT_PERMISSIONS,
  SUBSCRIPTIONS: {
    ...SUPPORT_PERMISSIONS.SUBSCRIPTIONS,
    showCardBillingIds: true
  },
  REFUNDS: {
    ...SUPPORT_PERMISSIONS.REFUNDS,
    [list]: true,
    [deletee]: true,
    [confirm]: true
  },
  ALERTS: {
    ...SUPPORT_PERMISSIONS.ALERTS,
    [list]: true
  },
};

// ADMIN PERMISSIONS
// Support permissions and:
const ADMIN_PERMISSIONS = {
  ...SUPPORT_PERMISSIONS,
  SUBSCRIPTIONS: {
    ...SUPPORT_PERMISSIONS.SUBSCRIPTIONS,
    showCardBillingIds: true
  },
  CONTACT_CASES: {
    ...SUPPORT_PERMISSIONS.CONTACT_CASES,
    [list]: true,
    [deletee]: true
  },
  ACCOUNT_MANAGEMNT: {
    ...SUPPORT_PERMISSIONS.ACCOUNT_MANAGEMNT,
    [list]: true,
    actions: [ROLE_SUPPORT, ROLE_FINNANCIAL, ROLE_ADMIN]
  },
  SERVICES: {
    [list]: true,
    [add]: true,
    [deletee]: false
  }
};

// SUPER ADMIN PERMISSIONS
// Support and admin permissions and:
const SUPER_ADMIN_PERMISSIONS = {
  ...ADMIN_PERMISSIONS,
  ACCOUNT_MANAGEMNT: {
    ...ADMIN_PERMISSIONS.ACCOUNT_MANAGEMNT,
    actions: [ROLE_SUPPORT, ROLE_FINNANCIAL, ROLE_ADMIN, ROLE_SUPER_ADMIN]
  },
  REFUNDS: {
    ...ADMIN_PERMISSIONS.REFUNDS,
    [list]: true,
    [deletee]: true,
    [confirm]: true
  },
  ALERTS: {
    ...ADMIN_PERMISSIONS.ALERTS,
    [list]: true
  },
  SERVICES: {
    ...ADMIN_PERMISSIONS.SERVICES,
    [list]: true,
    [add]: true,
    [deletee]: true
  }
};

const hasSectionPermission = section => {
  switch (getUserRole()) {
    case ROLE_SUPPORT:
      return SUPPORT_PERMISSIONS[section];
    case ROLE_FINNANCIAL:
      return FINANCIAL_PERMISSIONS[section];
    case ROLE_ADMIN:
      return ADMIN_PERMISSIONS[section];
    case ROLE_SUPER_ADMIN:
      return SUPER_ADMIN_PERMISSIONS[section];
    default:
      return SUPPORT_PERMISSIONS[section];
  }
};

// public functions
export const hasPermission = () => {
  switch (getUserRole()) {
    case ROLE_SUPPORT:
      return SUPPORT_PERMISSIONS;
    case ROLE_FINNANCIAL:
      return FINANCIAL_PERMISSIONS;
    case ROLE_ADMIN:
      return ADMIN_PERMISSIONS;
    case ROLE_SUPER_ADMIN:
      return SUPER_ADMIN_PERMISSIONS;
    default:
      return SUPPORT_PERMISSIONS;
  }
};

export const hasSubscriptionsPermissionTo = action => {
  return hasSectionPermission('SUBSCRIPTIONS')[action];
};

export const hasRefundsPermissionTo = action => {
  return hasSectionPermission('REFUNDS')[action];
};

export const hasAlertsPermissionTo = action => {
  return hasSectionPermission('ALERTS')[action];
};

export const hasContactCasesPermissionTo = action => {
  return hasSectionPermission('CONTACT_CASES')[action];
};

export const hasAccountManagementPermissionTo = action => {
  return hasSectionPermission('ACCOUNT_MANAGEMNT')[action];
};

export const hasServicesPermissionTo = action => {
  return hasSectionPermission('SERVICES')[action];
};

export const hasCurrentUserPermission = roles => {
  return roles.find(role => role === getUserRole());
};
