import React from 'react';

import Loading from '@common/components/Loading';
import { getIcon } from './../../icons/icons';
import { Table, TableColumn } from '@common/components/Table';

const tableOptions = {
  defaultSortName: 'description',
  defaultSortOrder: 'asc'
};

class ProductItemData extends React.Component {
  render() {
    return (
      <div className="mt-3 mb-3">
        {this.props.fetchingData ? (
          <Loading loadingClass="throbber--middle" />
        ) : (
          <div className="table-striped table-sm">
            <Table data={this.props.fetchedData} options={tableOptions}>
              <TableColumn
                headerLabel={this.props.name}
                dataKey="description"
                dataSort={true}
                cellCustomClass="text-nowrap"
              />
              <TableColumn
                dataKey="more_info"
                headerLabel="Additional info"
                width="300px"
                hidden={this.props.name === 'communicator'}
                dataFormat={this.renderMoreInfoFields.bind(this)}
              />
              <TableColumn
                dataKey="action"
                headerLabel="Action"
                dataFormat={this.renderActionFields.bind(this)}
                cellCustomClass="table-column-width-auto"
                width="150px"
              />
            </Table>
          </div>
        )}
      </div>
    );
  }

  renderMoreInfoFields(cell, row) {
    if (row.more_info) {
      return <div className="tick-info">{getIcon('tickIcon')}</div>;
    } else {
      return <span>&nbsp;</span>;
    }
  }

  renderActionFields(cell, row) {
    return (
      <div className="action-icons">
        <i
          className="icon icon--secondary icon-action cursor-pointer edit-icon"
          onClick={() => {
            this.props.editItem(row);
          }}
        >
          {getIcon('editIcon')}
        </i>
        <i
          className="icon icon--secondary icon-hover--danger icon-action cursor-pointer"
          onClick={() => {
            this.props.deleteItem(row.id);
          }}
        >
          {getIcon('deleteIcon')}
        </i>
      </div>
    );
  }
}

export default ProductItemData;
