export const SHOW_MODAL = '[Modal] Show modal';
export const DELETE_MODAL = '[Modal] Delete modal';

export function showModal(modal) {
  return { type: SHOW_MODAL, payload: modal };
}

export function deleteModal(modalId) {
  return { type: DELETE_MODAL, payload: modalId };
}
