import axios from 'axios';

import { showErrorAlert } from '@common/actions';
import { mock } from '@common/mocks';
import {
  API_AUTH_TOKEN_KEY,
  API_AUTH_VALID_KEY,
  API_RESPONSE_ACTION_VALID_CODE,
  API_RESPONSE_CREATED_VALID_CODE,
  API_RESPONSE_VALID_CODE,
  API_RESPONSE_VALIDATION_FAILED_CODE
} from '@common/consts';
import { getTokenValidationTimestamp } from '@common/helpers';

import { createUserMockResponse, USER, USERS_LIST } from './mocks';
import {
  LOGIN_URL,
  USER_EMAIL_KEY,
  USER_GROUP_KEY,
  USER_ROLE_KEY,
  USERS_GROUP_URL,
  USERS_URL
} from './consts';
import {
  FILTER_USERS,
  LOGIN_ERROR,
  LOGIN_USER,
  LOGOUT_USER,
  RESET_SELECTED_USER,
  USER_CREATED,
  USER_CREATED_ERROR,
  USER_DEACTIVATED,
  USER_FETCHED,
  USER_GROUPS_FETCHED,
  USER_PASSWORD_RESET,
  USER_PASSWORD_RESET_ERROR,
  USER_PROCESSING,
  USER_UPDATED,
  USER_UPDATED_ERROR,
  USERS_FETCHED,
  USERS_FETCHING
} from './types';

export function proceedLogout(dispatch) {
  localStorage.clear();
  dispatch({ type: LOGOUT_USER });
}

export function loginUser({ email, password, remember }, history) {
  return function (dispatch) {
    axios
      .post(LOGIN_URL, { user: { email, password } })
      .then(response => {
        dispatch({ type: LOGIN_USER, payload: response.data });
        localStorage.setItem(API_AUTH_TOKEN_KEY, response.data.auth_token);
        localStorage.setItem(USER_ROLE_KEY, response.data.role);
        localStorage.setItem(USER_EMAIL_KEY, response.data.email);
        localStorage.setItem(USER_GROUP_KEY, response.data.user_group);
        if (remember) {
          localStorage.setItem(API_AUTH_VALID_KEY, getTokenValidationTimestamp());
        }
        history.push('/');
      })
      .catch(error => {
        dispatch({ type: LOGIN_ERROR, payload: error.response.data.error });
      });
  };
}

export function logoutUser() {
  return function (dispatch) {
    return proceedLogout(dispatch);
  };
}

export function fetchUsers() {
  return function (dispatch) {
    dispatch({ type: USERS_FETCHING });

    if (MOCK_DATA) {
      return mock(
        'FETCH_USERS_MOCK_RESPONSE',
        function () {
          dispatch({ type: USERS_FETCHED, payload: USERS_LIST });
        },
        function () {
          showErrorAlert(dispatch, 'Server error. Cannot load users list');
        }
      );
    }

    return axios
      .get(USERS_URL)
      .then(response => {
        dispatch({ type: USERS_FETCHED, payload: response.data });
      })
      .catch(error => {
        showErrorAlert(dispatch, 'Server error. Cannot load users list');
      });
  };
}

export function fetchUser({ id }) {
  return function (dispatch) {
    if (MOCK_DATA) {
      return mock(
        'FETCH_USER_MOCK_RESPONSE',
        function () {
          dispatch({ type: USER_FETCHED, payload: USER });
        },
        function () {
          showErrorAlert(dispatch, 'Server error. Cannot get user details');
        }
      );
    }

    return axios
      .get(`${USERS_URL}/${id}`)
      .then(response => {
        dispatch({ type: USER_FETCHED, payload: response.data });
      })
      .catch(error => {
        showErrorAlert(dispatch, 'Server error. Cannot get user details');
      });
  };
}

export function fetchUserGroups() {
  return function (dispatch) {
    return axios
      .get(`${USERS_GROUP_URL}`)
      .then(response => {
        dispatch({ type: USER_GROUPS_FETCHED, payload: response.data.user_groups });
      })
      .catch(error => {
        showErrorAlert(dispatch, 'Server error. Cannot get user groups');
      });
  };
}

export function createUser({ email, service_ids, user_group_id }) {
  return function (dispatch) {
    dispatch({ type: USER_PROCESSING });

    if (MOCK_DATA) {
      return mock(
        'CREATE_USER_MOCK_RESPONSE',
        function () {
          dispatch({ type: USER_CREATED, payload: createUserMockResponse(email, user_group_id) });
        },
        function () {
          dispatch({
            type: USER_CREATED_ERROR,
            payload: [{ code: 1001, msg: 'Email already exists' }]
          });
        }
      );
    }

    axios
      .post(USERS_URL, { user: { email, service_ids, user_group_id } })
      .then(response => {
        dispatch({ type: USER_CREATED, payload: response.data });
      })
      .catch(error => {
        if (error.response.status == API_RESPONSE_VALIDATION_FAILED_CODE) {
          dispatch({ type: USER_CREATED_ERROR, payload: error.response.data.errors });
        } else {
          dispatch({ type: USER_CREATED_ERROR, payload: error.response.data.error });
        }
      });
  };
}

export function updateUser({ id, email, service_ids, user_group_id }) {
  return function (dispatch) {
    dispatch({ type: USER_PROCESSING });

    if (MOCK_DATA) {
      return mock(
        'UPDATE_USER_MOCK_RESPONSE',
        function () {
          dispatch({ type: USER_UPDATED, payload: { id, email, user_group_id } });
        },
        function () {
          dispatch({
            type: USER_UPDATED_ERROR,
            payload: [{ code: 1001, msg: 'Email already exists' }]
          });
        }
      );
    }

    axios
      .put(`${USERS_URL}/${id}`, { user: { email, service_ids, user_group_id } })
      .then(response => {
        dispatch({ type: USER_UPDATED, payload: { id, email, user_group_id } });
      })
      .catch(error => {
        if (error.response.status == API_RESPONSE_VALIDATION_FAILED_CODE) {
          dispatch({ type: USER_UPDATED_ERROR, payload: error.response.data.errors });
        } else {
          dispatch({ type: USER_UPDATED_ERROR, payload: error.response.data.error });
        }
      });
  };
}

export function resetPassword({ id }) {
  return function (dispatch) {
    dispatch({ type: USER_PROCESSING });

    if (MOCK_DATA) {
      return mock(
        'RESET_PASSWORD_MOCK_RESPONSE',
        function () {
          dispatch({ type: USER_PASSWORD_RESET, payload: { id, password: '12345678' } });
        },
        function () {
          dispatch({ type: USER_PASSWORD_RESET_ERROR, payload: 'Reset password failed.' });
        }
      );
    }

    axios
      .post(`${USERS_URL}/${id}/password`, null)
      .then(response => {
        dispatch({ type: USER_PASSWORD_RESET, payload: { id, password: response.data.password } });
      })
      .catch(error => {
        dispatch({ type: USER_PASSWORD_RESET_ERROR, payload: 'Reset password failed.' });
      });
  };
}

export function resetUserSelected() {
  return function (dispatch) {
    dispatch({ type: RESET_SELECTED_USER });
  };
}

export function filterUsers(term) {
  return function (dispatch) {
    dispatch({ type: FILTER_USERS, payload: term });
  };
}

export function deactivateUser({ id }) {
  return function (dispatch) {
    if (MOCK_DATA) {
      return mock(
        'DEACTIVATE_USER_MOCK_RESPONSE',
        function () {
          dispatch({ type: USER_DEACTIVATED, payload: id });
        },
        function () {
          showErrorAlert(dispatch, 'Deactivation failed.');
        }
      );
    }

    axios
      .post(`${USERS_URL}/${id}/deactivate`, null)
      .then(response => {
        dispatch({ type: USER_DEACTIVATED, payload: id });
      })
      .catch(error => {
        showErrorAlert(dispatch, 'Deactivation failed.');
      });
  };
}
