import { CHANGE_PAGE } from '@common/types';
import {
  LOGIN_ERROR,
  LOGIN_USER,
  LOGOUT_USER,
  USERS_FETCHED,
  USERS_FETCHING,
  USER_CREATED,
  USER_CREATED_ERROR,
  RESET_SELECTED_USER,
  USER_DEACTIVATED,
  USER_PASSWORD_RESET,
  USER_PASSWORD_RESET_ERROR,
  FILTER_USERS,
  USER_FETCHED,
  USER_UPDATED,
  USER_UPDATED_ERROR,
  USER_PROCESSING,
  USER_GROUPS_FETCHED
} from './types';

export default function(state = {}, { type, payload }) {
  switch (type) {
    case LOGIN_USER:
      return {
        ...state,
        authenticated: true,
        role: payload.role,
        email: payload.email,
        userGroup: payload.user_group,
        loginError: null
      };

    case LOGOUT_USER:
      return { ...state, authenticated: false, role: null, email: null };

    case LOGIN_ERROR:
      return { ...state, loginError: payload };

    case USERS_FETCHED:
      return {
        ...state,
        usersList: payload.users,
        searchableUserList: payload.users,
        fetchingData: false
      };

    case USER_FETCHED:
      return {
        ...state,
        userSelected: payload
      };

    case USERS_FETCHING:
      return {
        ...state,
        usersList: [],
        fetchingData: true
      };

    case USER_GROUPS_FETCHED:
      return {
        ...state,
        userGroups: payload
      };

    case FILTER_USERS:
      return {
        ...state,
        usersList: state.searchableUserList.filter(user => user.email.includes(payload))
      };

    case USER_PROCESSING:
      return {
        ...state,
        userActionStatus: 'processing'
      };

    case USER_CREATED:
      const userCreated = {
        ...payload,
        user_group: payload.user_group.name
      };
      return {
        ...state,
        usersList: [...state.usersList, userCreated],
        userSelected: payload,
        userActionStatus: 'success'
      };

    case USER_CREATED_ERROR:
      return {
        ...state,
        userActionStatus: 'error',
        userActionError: payload
      };

    case USER_UPDATED:
      return {
        ...state,
        usersList: state.usersList.map(user => {
          if (user.id === payload.id) {
            user.email = payload.email;
            user.user_group = state.userGroups.find(
              group => group.id === payload.user_group_id
            ).name;
          }
          return user;
        }),
        userActionStatus: 'success'
      };

    case USER_UPDATED_ERROR:
      return {
        ...state,
        userActionStatus: 'error',
        userActionError: payload
      };

    case USER_PASSWORD_RESET:
      const user = state.usersList.find(user => user.id === payload.id);
      user.password = payload.password;
      return {
        ...state,
        userSelected: user,
        userActionStatus: 'success'
      };

    case USER_PASSWORD_RESET_ERROR:
      return {
        ...state,
        userActionStatus: 'error',
        userActionError: payload
      };

    case USER_DEACTIVATED:
      return {
        ...state,
        usersList: state.usersList.map(user => {
          if (user.id === payload) {
            user.active = false;
          }
          return user;
        })
      };

    case RESET_SELECTED_USER:
      return {
        ...state,
        userSelected: null,
        userActionStatus: null,
        userActionError: null
      };

    case CHANGE_PAGE:
      return { ...state, fetchingData: false };
  }
  return state;
}
