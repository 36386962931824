import {
  SERVICES_FETCHED,
  SERVICE_FETCHED,
  RESET_SERVICES,
  RESET_SERVICE,
  SERVICE_PROCESSING,
  SERVICE_CREATED,
  SERVICE_CREATED_ERROR,
  SERVICE_UPDATED,
  SERVICE_UPDATED_ERROR,
  FILTER_SERVICES,
  RESET_STATUS,
  REASONS_FETCHED,
  REASON_CREATED,
  REASON_DELETED,
  REASON_UPDATED,
  SOLUTIONS_FETCHED,
  SOLUTION_CREATED,
  SOLUTION_DELETED,
  SOLUTION_UPDATED,
  COMMUNICATORS_FETCHED,
  COMMUNICATOR_CREATED,
  COMMUNICATOR_DELETED,
  COMMUNICATOR_UPDATED,
  SERVICE_DELETED_ERROR,
  SERVICE_DELETED,
  SERVICE_UNSELECT
} from './types';
import { SERVICE_TYPE_PAYCARD, SERVICE_TYPE_MOBILE } from './consts';
import { USERS_FETCHED } from '../users/types';

const defaultState = {
  all: null,
  mobile: null,
  paycard: null,
  serviceSelected: null,
  serviceActionStatus: null,
  serviceCreated: null,
  contactCaseReasons: [],
  contactCaseSolutions: [],
  communicators: [],
  users: [],
  errors: null
};

let arrayCopy = [];

export default function(state = defaultState, { type, payload }) {
  switch (type) {
    case SERVICES_FETCHED:
      const data = {};
      const key = genereteKey(payload.paymentType);
      data[key] = payload.services;
      return { ...state, ...data, searchableServicesList: payload.services };
    case RESET_SERVICES:
      return { ...state, all: null, mobile: null, paycard: null, searchableServicesList: null };
    case SERVICE_FETCHED:
      return { ...state, serviceSelected: payload };
    case RESET_SERVICE:
      return { ...state, serviceSelected: null, serviceActionStatus: null };
    case RESET_STATUS:
      return { ...state, serviceActionStatus: null };
    case SERVICE_PROCESSING:
      return { ...state, serviceActionStatus: 'loading' };
    case SERVICE_CREATED:
    case SERVICE_UPDATED:
      return { ...state, serviceActionStatus: 'success', errors: null };
    case SERVICE_CREATED_ERROR:
    case SERVICE_UPDATED_ERROR:
    case SERVICE_DELETED_ERROR:
      return { ...state, serviceActionStatus: 'error', errors: payload };

    case SERVICE_DELETED:
      return {
        ...state,
        serviceActionStatus: 'success',
        all: state.all.filter(service => service.id !== payload)
      };

    case SERVICE_UNSELECT:
      return {
        ...state,
        serviceSelected: null
      };

    case FILTER_SERVICES:
      return {
        ...state,
        all: state.searchableServicesList.filter(service => {
          const lowerCaseName = service.name.toLowerCase();
          if (lowerCaseName.includes(payload)) {
            return service;
          }
        })
      };
    case REASONS_FETCHED:
      return {
        ...state,
        contactCaseReasons: payload.contact_case_reasons
      };
    case REASON_DELETED:
      return {
        ...state,
        contactCaseReasons: [...state.contactCaseReasons].filter(item => item.id !== payload)
      };
    case REASON_CREATED:
      arrayCopy = [...state.contactCaseReasons];
      arrayCopy.push(payload);
      return {
        ...state,
        contactCaseReasons: arrayCopy
      };
    case REASON_UPDATED:
      return {
        ...state,
        contactCaseReasons: [...state.contactCaseReasons].map(
          item => (item.id === payload.id ? payload : item)
        )
      };
    case SOLUTIONS_FETCHED:
      return {
        ...state,
        contactCaseSolutions: payload.contact_case_solutions
      };
    case SOLUTION_DELETED:
      return {
        ...state,
        contactCaseSolutions: [...state.contactCaseSolutions].filter(item => item.id !== payload)
      };
    case SOLUTION_CREATED:
      arrayCopy = [...state.contactCaseSolutions];
      arrayCopy.push(payload);
      return {
        ...state,
        contactCaseSolutions: arrayCopy
      };
    case SOLUTION_UPDATED:
      return {
        ...state,
        contactCaseSolutions: [...state.contactCaseSolutions].map(
          item => (item.id === payload.id ? payload : item)
        )
      };
    case COMMUNICATORS_FETCHED:
      return {
        ...state,
        communicators: payload.communicators
      };
    case COMMUNICATOR_DELETED:
      return {
        ...state,
        communicators: [...state.communicators].filter(item => item.id !== payload)
      };
    case COMMUNICATOR_CREATED:
      arrayCopy = [...state.communicators];
      arrayCopy.push(payload);
      return {
        ...state,
        communicators: arrayCopy
      };
    case COMMUNICATOR_UPDATED:
      return {
        ...state,
        communicators: [...state.communicators].map(
          item => (item.id === payload.id ? payload : item)
        )
      };
    case USERS_FETCHED:
      return {
        ...state,
        users: payload.users
      };
  }

  return state;
}

const genereteKey = paymentType => {
  switch (paymentType) {
    case SERVICE_TYPE_PAYCARD:
      return 'paycard';
    case SERVICE_TYPE_MOBILE:
      return 'mobile';
    default:
      return 'all';
  }
};
