import Defaults from './defaults';

export class MultiSelectBox {
  static Defaults = Defaults;

  get hasLabels() {
    return this.options.labels;
  }

  get hasSearchInput() {
    return this.options.searchInput;
  }

  get hasSelectAllButtons() {
    return this.options.selectAllButtons;
  }

  /**
   * Creates a new MultiSelectBox instance
   * @param {HTMLElement} reference - MultiSelectBox wrapper element
   * @param {Array} data - collection of data
   * {id: string, name: string, selected: boolean, value: string }
   * @param {Object} options
   */
  constructor(reference, data, options = {}) {
    this.reference = reference;
    this.options = { ...MultiSelectBox.Defaults, ...options };
    this.selectables = [];
    this.groupActive = new Set();
    this.selectablesSearchCopy = [];
    this.selectablesListElement = document.createElement('ul');
    this.selectedListElement = document.createElement('ul');
    this.searchInputElement = document.createElement('input');

    this.prepareData(data);
    this.render();
  }

  /**
   * Prepares data format for MultiSelectBox
   * @param {Array} data
   */
  prepareData(data) {
    this.selectables = data.map(option => {
      return {
        ...option,
        id: option.id.toString(),
        selected: option.selected || false
      };
    });
  }

  /**
   * Inital function which renders all componentes
   */
  render() {
    const fragment = document.createDocumentFragment();
    const container = document.createElement('div');
    container.className = 'msb-container';

    const selectableContainer = document.createElement('div');
    selectableContainer.className = 'msb-selectable-container';

    const selectedContainer = selectableContainer.cloneNode();

    this.selectablesListElement.className = 'msb-selectable-list';
    this.selectedListElement.className = 'msb-selectable-list';

    container.addEventListener('click', ({ target }) => {
      if (target && target.nodeName === 'LI') {
        this.selectables = this.selectables.map(selectable => {
          if (selectable.id === target.getAttribute('data-id')) {
            selectable.selected = !selectable.selected;
          }
          return selectable;
        });
        this.renderList();
        this.emit();
      }
    });

    this.renderList();
    this.renderLabels(selectableContainer, selectedContainer);
    this.renderSearchInput(selectableContainer, selectedContainer);

    container.appendChild(selectableContainer);
    this.renderSelectAllButtons(container);
    container.appendChild(selectedContainer);
    selectableContainer.appendChild(this.selectablesListElement);
    selectedContainer.appendChild(this.selectedListElement);

    fragment.appendChild(container);
    this.reference.parentNode.insertBefore(fragment, this.reference);
  }

  renderLabels(selectableContainer, selectedContainer) {
    if (this.hasLabels) {
      const selectableLabel = document.createElement('label');
      selectableLabel.className = 'msb-label';
      selectableLabel.innerHTML = this.options.selectableLabel;

      const selectedLabel = selectableLabel.cloneNode();

      const selectedLabelName = document.createElement('span');
      selectedLabelName.className = 'msb-label-name';
      selectedLabelName.innerHTML = this.options.selectedLabel;

      const selectedLabelCounter = document.createElement('span');
      selectedLabelCounter.className = 'msb-label-counter';
      selectedLabelCounter.innerHTML = `(${0} ${this.options.selectableLabel})`;

      this.reference.addEventListener('selected', () => {
        selectedLabelCounter.innerHTML = `(${this.selectables.filter(s => s.selected).length} ${
          this.options.selectableLabel
        })`;
      });

      selectableContainer.appendChild(selectableLabel);

      selectedLabel.appendChild(selectedLabelName);
      selectedLabel.appendChild(selectedLabelCounter);
      selectedContainer.appendChild(selectedLabel);
    }
  }

  renderSearchInput(selectableContainer, selectedContainer) {
    if (!this.selectablesSearchCopy.length) {
      this.selectablesSearchCopy = JSON.parse(JSON.stringify(this.selectables));
    }

    if (this.hasSearchInput) {
      this.searchInputElement.className = 'msb-search-input';
      this.searchInputElement.setAttribute('type', 'text');
      this.searchInputElement.setAttribute('placeholder', 'Search...');
      this.searchInputElement.addEventListener('keyup', e => {
        this.selectables = this.selectablesSearchCopy
          .map(selectableCopy => {
            const selectable = this.selectables.find(
              selectable => selectable.id === selectableCopy.id
            );
            if (selectable) {
              return {
                ...selectableCopy,
                selected: selectable.selected
              };
            }
            return {
              ...selectableCopy
            };
          })
          .filter(selectable => {
            return (
              selectable.selected ||
              selectable.name.toLowerCase().includes(e.target.value.toLowerCase())
            );
          });
        this.renderList();
      });

      //let selectedSearchInput = selectableSearchInput.cloneNode();

      selectableContainer.appendChild(this.searchInputElement);
      //selectedContainer.appendChild(selectedSearchInput);
    }
  }

  renderSelectAllButtons(container) {
    if (this.hasSelectAllButtons) {
      const buttonsContainer = document.createElement('div');
      buttonsContainer.className = 'msb-buttons-container';
      buttonsContainer.addEventListener('click', ({ target }) => {
        if (target && target.nodeName === 'BUTTON') {
          this.selectables = this.selectables.map(selectable => {
            selectable.selected = target.id === 'msb-select-all-button';
            return selectable;
          });
          this.renderList();
          this.emit();
        }
      });

      const selectAllButton = document.createElement('button');
      selectAllButton.id = 'msb-select-all-button';
      selectAllButton.className = 'msb-all-button';
      selectAllButton.setAttribute('type', 'button');
      const deselectAllButton = selectAllButton.cloneNode();
      deselectAllButton.id = 'msb-deselect-all-button';

      buttonsContainer.appendChild(selectAllButton);
      buttonsContainer.appendChild(deselectAllButton);
      container.appendChild(buttonsContainer);
    }
  }

  renderList() {
    this.selectablesListElement.innerHTML = '';
    this.selectedListElement.innerHTML = '';
    if (this.options.groupBy) {
      const groupedBySelectables = this.selectables.reduce((rv, x) => {
        (rv[x[this.options.groupBy]] = rv[x[this.options.groupBy]] || []).push(x);
        return rv;
      }, {});
      Object.keys(groupedBySelectables).forEach(groupName => {
        const [
          selectableGroupContainer,
          selectableGroupContainerList
        ] = this.createGroupedContainer(groupedBySelectables, groupName, false);
        const [selectedGroupContainer, selectedGroupContainerList] = this.createGroupedContainer(
          groupedBySelectables,
          groupName,
          true
        );
        /////
        groupedBySelectables[groupName].forEach(selectable => {
          const selectableElement = document.createElement('li');
          selectableElement.textContent = selectable.name;
          selectableElement.className = 'msb-selectable';
          selectableElement.setAttribute('data-id', selectable.id);

          if (selectable.selected) {
            selectedGroupContainerList.appendChild(selectableElement);
            this.selectedListElement.appendChild(selectedGroupContainer);
          } else {
            selectableGroupContainerList.appendChild(selectableElement);
            this.selectablesListElement.appendChild(selectableGroupContainer);
          }
        });
      });
    } else {
      this.selectables.forEach(selectable => {
        const selectableElement = document.createElement('li');
        selectableElement.textContent = selectable.name;
        selectableElement.className = 'msb-selectable';
        selectableElement.setAttribute('data-id', selectable.id);
        if (!selectable.selected) {
          this.selectablesListElement.appendChild(selectableElement);
        } else {
          this.selectedListElement.appendChild(selectableElement);
        }
      });
    }
  }

  createGroupedContainer(groupedBySelectables, groupName, selected) {
    const selectedGroupContainer = document.createElement('li');
    selectedGroupContainer.className = `msb-group-container ${
      !this.groupActive.has(groupName) ? 'msb-group-container--hidden' : null
    }`;
    selectedGroupContainer.addEventListener('click', e => {
      if (
        e.target &&
        !e.target.className.includes('msb-selectable') &&
        !e.target.className.includes('msb-group-select-all-button')
      ) {
        selectedGroupContainer.classList.toggle('msb-group-container--hidden');
        if (selectedGroupContainer.className.includes('msb-group-container--hidden')) {
          this.groupActive.delete(groupName);
        } else {
          this.groupActive.add(groupName);
        }
      } else if (e.target && e.target.className.includes('msb-group-select-all-button')) {
        this.selectables = this.selectables.map(selectable => {
          if (selectable.group === groupName) {
            selectable.selected = !!e.target.className.includes(
              'msb-group-select-all-button--selectable'
            );
          }
          return selectable;
        });
        this.renderList();
        this.emit();
      }
    });
    const selectedGroupContainerLabel = document.createElement('div');
    selectedGroupContainerLabel.className = 'msb-group-label';

    const selectedGroupContainerLabelIndicator = document.createElement('i');
    selectedGroupContainerLabelIndicator.className = 'msb-group-label-indicator';

    const selectedGroupContainerLabelCounter = document.createElement('span');
    selectedGroupContainerLabelCounter.className = 'msb-group-counter';

    const selectedGroupContainerLabelSelectAllButton = document.createElement('button');
    selectedGroupContainerLabelSelectAllButton.setAttribute('type', 'button');
    if (selected) {
      selectedGroupContainerLabelSelectAllButton.className =
        'msb-group-select-all-button msb-group-select-all-button--selected';
    } else {
      selectedGroupContainerLabelSelectAllButton.className =
        'msb-group-select-all-button msb-group-select-all-button--selectable';
    }

    let selectedGroupContainerLableCounterValue;
    if (selected) {
      selectedGroupContainerLableCounterValue = groupedBySelectables[groupName].filter(
        s => s.selected && s.group === groupName
      ).length;
    } else {
      selectedGroupContainerLableCounterValue = groupedBySelectables[groupName].filter(
        s => !s.selected && s.group === groupName
      ).length;
    }

    selectedGroupContainerLabelCounter.innerHTML = ` (${selectedGroupContainerLableCounterValue} ${
      this.options.selectableLabel
    })`;

    selectedGroupContainerLabel.appendChild(selectedGroupContainerLabelIndicator);
    selectedGroupContainerLabel.appendChild(document.createTextNode(`${groupName}`));
    selectedGroupContainerLabel.appendChild(selectedGroupContainerLabelCounter);
    selectedGroupContainerLabel.appendChild(selectedGroupContainerLabelSelectAllButton);
    selectedGroupContainer.appendChild(selectedGroupContainerLabel);

    const selectedGroupContainerList = document.createElement('ul');
    selectedGroupContainerList.className = 'msb-group-list';
    selectedGroupContainer.appendChild(selectedGroupContainerList);

    return [selectedGroupContainer, selectedGroupContainerList];
  }

  /**
   * Emits custom event with all selected values
   */
  emit() {
    this.reference.value = this.reference.dispatchEvent(
      new CustomEvent('selected', {
        detail: { selected: this.selectables.filter(selectable => selectable.selected) }
      })
    );
  }

  /**
   * Updates MultiSelectBox data
   * Useful for dynamic data, merging previous with incoming data
   * @param {Array} data - collection of data
   * {id: string, name: string, selected: boolean, value: string }
   */
  update(data) {
    const mergedData = data.map(el => {
      const selectable = this.selectables.find(selectable => selectable.id === el.id.toString());
      if (selectable) {
        return {
          ...el,
          ...selectable
        };
      }
      return el;
    });
    this.prepareData(mergedData);
    this.renderList();
  }

  /**
   * Resets MultiSelectBox to inital state
   */
  reset() {
    this.selectables = JSON.parse(JSON.stringify(this.selectablesSearchCopy));
    this.searchInputElement.value = '';
    this.renderList();
  }

  /**
   * Register event listeneres
   * @param {string} event - event to handle
   * @param {Function} cb - callback to invoke when event occurs
   */
  on(event, cb) {
    if (event === 'selected') {
      this.reference.addEventListener(event, cb);
      this.emit();
    }
  }
}
