import React from 'react';

import { displayYesNo } from '@common/helpers';
import { ADDITIONAL_INFO } from './AdditionalInfoStep';

const getNameById = (values, ids, key = 'name') => {
  if (ids) {
    return values.map(el => {
      if (ids.includes(el.id)) {
        return <div key={el.id}>{el[key]}</div>;
      }
    });
  }
  return [];
};

const SummaryStep = props => (
  <div>
    <dl className="col-8 dl-list mx-auto text-left">
      <div className="dl-list--item">
        <dt className="dl-list--item-term">Product:</dt>
        <dd className="dl-list--item-description float-right">{props.stepsData.name}</dd>
      </div>

      <div className="dl-list--item">
        <dt className="dl-list--item-term">Language:</dt>
        <dd className="dl-list--item-description float-right text-right">
          {getNameById(props.initialData.languages, props.stepsData.languageIds)}
        </dd>
      </div>

      <div className="dl-list--item">
        <dt className="dl-list--item-term">Contact reason:</dt>
        <dd className="dl-list--item-description float-right text-right">
          {getNameById(
            props.initialData.contactReasons,
            props.stepsData.contactCaseReasonIds,
            'description'
          )}
        </dd>
      </div>

      <div className="dl-list--item">
        <dt className="dl-list--item-term">Contact solution:</dt>
        <dd className="dl-list--item-description float-right text-right">
          {getNameById(
            props.initialData.contactSolutions,
            props.stepsData.contactCaseSolutionIds,
            'description'
          )}
        </dd>
      </div>

      <div className="dl-list--item">
        <dt className="dl-list--item-term">Communicators:</dt>
        <dd className="dl-list--item-description float-right text-right">
          {getNameById(
            props.initialData.communicators,
            props.stepsData.communicatorIds,
            'description'
          )}
        </dd>
      </div>

      <div className="dl-list--item">
        <dt className="dl-list--item-term">Users:</dt>
        <dd className="dl-list--item-description float-right text-right">
          {getNameById(props.initialData.users, props.stepsData.userIds, 'email')}
        </dd>
      </div>

      {
        !props.notAllowedSteps.includes('Additional information') &&
          Object.keys(ADDITIONAL_INFO).map(key => (
            <div className="dl-list--item border-0 pb-0" key={key}>
              <dt className="dl-list--item-term">{ADDITIONAL_INFO[key].label}:</dt>
              <dd className="dl-list--item-description float-right">
                {
                  ADDITIONAL_INFO[key].type === 'checkbox' ?
                    displayYesNo(props.stepsData[key]) : props.stepsData[key]
                }
              </dd>
            </div>
          ))
      }

    </dl>
    <div className={'text-center'}>
      <button type="button" className="btn btn-primary mt-6" onClick={() => props.handleNext()}>
        Submit
      </button>
    </div>
  </div>
);

export default SummaryStep;
